import { createHttpClient, HTTP_METHOD } from './httpClient';

export const getVehicleAll = () => {
  return createHttpClient('/v5/vehicle/list/user?ut=usr&dash=1&page=all' , HTTP_METHOD.GET);
};

export const getVehicleInfo = (params) => {
  return createHttpClient(`/v5/vehicle/web/${params.reg_no}?ut=usr` , HTTP_METHOD.GET);
};

export const addVehicle = (params) => {
  return createHttpClient('/v5/vehicle?ut=usr', HTTP_METHOD.POST, params, false, false, true);
};

export const editVehicleInfo = (formData) => {
  return createHttpClient(`/v5/vehicle/${formData.get('reg_no')}?ut=usr`, HTTP_METHOD.PUT, formData,false, false, true);
};

export const deleteVehicle = (params) => {
  return createHttpClient(`/v5/vehicle/${params.reg_no}?ut=usr`, HTTP_METHOD.DELETE);
};

export const uploadImage = (params) => {
  return createHttpClient('/v5/fleetManagement/preSignUrl?ut=usr', HTTP_METHOD.POST, params);
};

export const searchVehicle = (params) => {
  const searchTags = params.tags.length > 0 ? `&tags=${params.tags}` : '';
  return createHttpClient(`/v5/vehicle/search?ut=usr&sortParam=${params.sort}&searchText=${params.text}${searchTags}`);
};

export const deleteDeviceFromVehicle = (params) => {
  return createHttpClient(`/v1/dash-cam/${params.id}?ut=usr`, HTTP_METHOD.DELETE);
}

export const transferDevices = (params) => {
  return createHttpClient(`/v5/vehicle/transfer-devices?ut=usr`, HTTP_METHOD.PUT, params);
}

export const getVehicleDevices = (params) => {
  return createHttpClient(`/v5/vehicle/${params.reg_no}/dash-cams?ut=usr`, HTTP_METHOD.GET);
}