export const TRIPNODE = {
    GET_START: 'TRIPNODE_GET_START',
    GET_SUCCESS: 'TRIPNODE_GET_SUCCESS',
    GET_FAIL: 'TRIPNODE_GET_FAIL',
}

export const getTripNodeStart = (payload) => {
    return {
        type: TRIPNODE.GET_START,
        payload
    };
};

export const getTripNodeSuccess = (payload) => {
    return {
        type: TRIPNODE.GET_SUCCESS,
        payload
    };
};

export const getTripNodeFail = (payload) => {
    return {
        type: TRIPNODE.GET_FAIL,
        payload
    };
};