import {take, call, put} from "redux-saga/effects";
import {
    TRIP,
    getNodesSuccess,
    getNodesFail
} from "../actions/trip";
import { getTripNodes } from "../../api/trip";

export function* handleGetTripNodesStart(){
    while( true ) {
        const {payload} = yield take(TRIP.GET_NODES_START);
        try {
            const response = yield call(getTripNodes, payload);
            yield put(getNodesSuccess(response));
        } catch (error) {
            yield put(getNodesFail(error));
        }
    }
}