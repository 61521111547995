import React from 'react';
import {useSelector} from 'react-redux';

import AuthenticatedNavBar from '../AuthenticatedNavBar';
import NavBar from '../Navbar';
import { useLocation } from 'react-router-dom';

const AppNavBar = () => {
    const location = useLocation();
    const user = useSelector(state => state.user);
    if(location.pathname === '/account-cleanup' || location.pathname === '/account-deletion'){
        return (<></>);
    }
    return (
        user.data ? <AuthenticatedNavBar/> : <NavBar/>
    )
}

export default AppNavBar;