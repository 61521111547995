import styled from 'styled-components';

export const VideoContainer = styled.video`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  border-radius: ${({ borderRadius }) => borderRadius};
  position: ${({ position }) => position};
  object-fit:cover;
  border-left: ${({ selected }) => {
    if(selected){
      return '6px solid #64D2FF;';
    }
    return '';
  }};
  background: #363F45;
  max-height: ${({ maxHeight }) => maxHeight};
`;
