import styled from 'styled-components';

export const Container = styled.div`
    width: calc(100% - 30px);
    margin-right: auto;
    margin-left: auto;
    @media (max-width: 768px) {
        width:100%;
    }
`;

export const Nav = styled.nav`
    height: 6rem;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 1rem 0rem;
    color: black;
    justify-content: space-between;
`;

export const NavigationMenu = styled.div`
    max-width: 72rem;
    width:100%;
`;

export const List = styled.ul`
    display: flex;
    padding: 0;
    @media (max-width: 768px) {
        display: ${({expanded}) => expanded ? 'block': 'none'};
        position: absolute;
        z-index: 999;
        top: 79px;
        left: 0;
        // stacks the li tags vertically 
        flex-direction: column;
    // makes menu span full height and width
        width: 100%;
        height: calc(100vh - 8rem);
        background-color: #F5F5F5;
        border-top: 1px solid black;
        margin:0;
    }
`;

export const ListElement = styled.li`
    list-style-type: none;
    margin: 0 1rem;     
    max-width: 18rem;
    width:100%;
    text-align:center;
    & a{
        color: #5C6670;
        font-size: 2rem;
        border-bottom: 4px solid ${({active})=> active? '#0071A4' : '#F5F5F5'};
        &:hover{
            border-bottom: 4px solid #0071A4;
            color: #0071A4;
        }
        
    }
    @media (max-width: 768px) {
        width:100%;
        max-width:100%;
        text-align: center;
        margin: 0;  
        & a{
            border:0 !important;
        }        
    }
`;

export const ProfileMenuContainer = styled.div`
    @media (max-width: 768px) {
        display none;
    }
`;

export const LogoContainer = styled.div`
    @media (max-width: 768px) {
        padding: 0 2.5rem;
    }
`;