import {ACTIVATION} from '../actions/accountActivation';

const initialState = {
    data: null,
    isLoading: false,
    error: null
};

const activationReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACTIVATION.START:
            return {data: null, isLoading:true, error: null};
        case ACTIVATION.SUCCESS:
            return {data: action.payload, isLoading: false, error: null}
        case ACTIVATION.FAIL:
            return {data: null, isLoading: false, error: action.payload}
        case ACTIVATION.RESEND_START:
            return {data: null, isLoading:true, error: null};
        case ACTIVATION.RESEND_SUCCESS:
            return {data: action.payload, isLoading: false, error: null}
        case ACTIVATION.RESEND_FAIL:
            return {data: null, isLoading: false, error: action.payload}
        default:
            return state;
    };
};

export default activationReducer;