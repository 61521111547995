import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {resolve} from "nested-prop-resolver";
import { useHistory } from 'react-router-dom';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import validator from "validator";
import {Container, Row, Col, Hidden} from "react-grid-system";

import { videoLoadSelected } from '../../../redux/actions/video';

import {getDateTime} from "../../../utils/timeFormatters";
import {getDirection, getGsensor} from '../../../utils/helpers';

import Popup from "../../molecules/Popup";
import TextInput from "../../molecules/TextInput";
import PhoneNumberSelector from "../../molecules/PhoneNumberSelector";
import PDFLink from "../../molecules/PDFLink";
import {Text3, Text4, H1, Text5, Text1, P1} from "../../atoms/Typography";
import HBox from "../../atoms/HBox";
import Switch from "../../atoms/Switch";
import Button from "../../atoms/Button";
import Video from '../../atoms/Video';

import DownloadIcon from "../../../assets/DownloadIcon";


import { incidentsSendStart } from "../../../redux/actions/incident";
import {MAP_ZOOM_LEVEL, GOOGLE_MAPS_KEY} from '../../../constants';
import {Section, Status, IncidentTime, TitleContainer, Title, DetailsBox, SectionBody, VideoContainer} from "./styles";

const initialFormErrors = {
    insurranceEmail: "",
    phoneNumber: "",
    extraEmail: ""
}

const IncidentReportPopup = ({incident, onClose, onIncidentUpdated, onDeleteIncidentReport}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useSelector(state => state.profile);
    const countries = useSelector(state => state.countries.data);
    const isLoading = useSelector(state => state.incident.isLoading);
    const error = useSelector(state => state.incident.error);
    const videos = useSelector((state) => state.video.all);
    const [formErrors, setFormErrors] = useState(initialFormErrors);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_KEY
      });

    useEffect(()=>{
        if(isLoading === false && formSubmitted && !error){
            onIncidentUpdated()
        }
    }, [isLoading, error])

    const sent = !!incident.report_generated_at;
    const sensor = {
        latitude: resolve(incident, 'event_data.0.sensor.latitude', 0),
        longitude: resolve(incident, 'event_data.0.sensor.longitude', 0),
        gyro: resolve(incident, 'event_data.0.sensor.gyro', null),
        speed: resolve(incident, 'event_data.0.sensor.speed', null),
        direction: resolve(incident, 'event_data.0.sensor.direction', null),
    };

    let selectedCountry = countries[0];
    
    //if report is already sent get data from report
    let incidentData;
    if(incident.report_generated_at){
        incidentData = {
            insurranceCarier: resolve(incident, 'user_params.insurance_data.insurance_carrier', ''),
            phoneNumber: resolve(incident, 'user_params.insurance_data.insurance_mobile', ''),
            insurranceEmail: resolve(incident, 'user_params.insurance_data.insurance_email', ''),
            policyNumber: resolve(incident, 'user_params.insurance_data.policy_number', ''),
            sendToEmail: resolve(incident, 'user_params.send_to_email_recipients', false),
            extraEmail: resolve(incident, 'user_params.email_recipients[0]', ''),
        }
    }else{
        //if not get from incident user data
        incidentData = {
            insurranceCarier: resolve(profile, 'data.first_notice.insurance_carrier', ''),
            phoneNumber: resolve(profile, 'data.first_notice.insurance_mobile', ''),
            phoneNumberCountry: selectedCountry,
            insurranceEmail: resolve(profile, 'data.first_notice.insurance_email', ''),
            policyNumber: resolve(profile, 'data.first_notice.policy_number', ''),
            sendToEmail: false,
            extraEmail: ''
        }
    }
    if(incidentData.phoneNumber){
        selectedCountry= countries.reduce((accumulator, currentValue)=>{
            const phonePrefix = `+${currentValue.phone_prefix.replace('-','')}`
            if(incidentData.phoneNumber.startsWith(phonePrefix)){
                if(currentValue.phone_prefix.length > accumulator.phone_prefix.length){
                    return currentValue;
                }
            }
            return accumulator;
        },{phone_prefix:''});
        if(!selectedCountry.id){
            selectedCountry = countries[0];
        }
        incidentData.phoneNumber = incidentData.phoneNumber.replace(`+${selectedCountry.phone_prefix.replace('-','')}`, '')

    }    
    incidentData.phoneNumberCountry = selectedCountry;
    
    const [userInput, setUserInput] = useState(incidentData);

    const onChangeUserInput = (key, value) => {
        setUserInput(prev => {
            return {
                ...prev,
                [key]: value
            };
        });
    }

    const sendIncidentReport = () => {
        setFormErrors(initialFormErrors);
        if(!userInput.insurranceEmail && !userInput.extraEmail){
            setFormErrors(prev=>{
                return {...prev, insurranceEmail: "One of insurance or recipient emails must be filled"};
            });
            return;
        }

        if(userInput.insurranceEmail && !validator.isEmail(userInput.insurranceEmail)){
            setFormErrors(prev=>{
                return {...prev, insurranceEmail: "Not a valid email"};
            });
            return;
        }
        let phoneNumber = "";
        if(!userInput.phoneNumber.trim()){
            setUserInput(prev=>{
                return {...prev, phoneNumber: ''}
            });
        }else{
            phoneNumber = "+"+userInput.phoneNumberCountry.phone_prefix.replace('-','') + userInput.phoneNumber
            if(!validator.isMobilePhone(phoneNumber, 'any',{strictMode:true})){
                setFormErrors({...formErrors, phoneNumber: 'Not a valid phone number'})
                return;
            }
        }
        if(userInput.extraEmail && !validator.isEmail(userInput.extraEmail)){
            setFormErrors(prev=>{
                return {...prev, extraEmail: "Not a valid email"};
            });
            return;
        }
        if(!userInput.insurranceEmail && userInput.extraEmail && !userInput.sendToEmail){
            setFormErrors(prev=>{
                return {...prev, extraEmail: "Please check 'Send to Email'"};
            });
            return;
        }
        const data = {
            report_id: incident.report_id,
            sendToInsurance: userInput.insurranceEmail? true : false,
            sendToEmail: userInput.sendToEmail,
            emails: [
              userInput.extraEmail
            ],
            insuranceData: {
              carrier: userInput.insurranceCarier,
              phoneNumber: phoneNumber,
              email: userInput.insurranceEmail,
              policyNumber: userInput.policyNumber
            },
            user_cog_id: profile.data.user_id,
            vehicle_id: incident.vehicle.id
        };
        setFormSubmitted(true);
        dispatch(incidentsSendStart(data));
    }

    const handleClickVideo = (videoUrl) => {
        console.og
        let video = null;
        if(videos.all_recordings){
            video = videos.all_recordings.find(element => element.video_url === videoUrl)
        }
        if(video){
            dispatch(videoLoadSelected(video));
        }
        history.push('/gallery');
    }

    return (
        <Popup backgroundColor="#fff" title='' onClose={onClose} width="115.6rem"> 
            <Container fluid>
                <Section>
                    <Row nogutter>
                        <TitleContainer>
                            <H1 fontSize="2.4rem">Incident Report</H1>
                            <Status sent={sent}><Text3 color="#FFF">{sent? "Sent": "Not Sent"}</Text3></Status>
                            {incident.report_url?
                                    <a href={incident.report_url} target="_blank"><DownloadIcon color="#0071A4"/></a>
                                :
                                    <PDFLink report={incident}>
                                        <DownloadIcon color="#0071A4"/>
                                    </PDFLink>
                                }
                        </TitleContainer>
                    </Row>
                    <Row nogutter>
                        <IncidentTime>
                            <Text4 color="#5C6670">{getDateTime(incident.start_at)} {incident.vehicle.alias}</Text4>
                        </IncidentTime>
                    </Row>
                </Section>
                <SectionBody>
                    <Row gutter={10}>
                        <Col md={7}>
                            <Title>
                                <Text4>Incident Details</Text4>
                            </Title>
                            <Row nogutter>
                                <Col xl={6}>
                                    <DetailsBox>
                                        <div>
                                            <Text5>Car</Text5>
                                        </div>
                                        <div>
                                            <Text1>{incident.vehicle.alias}</Text1><br/>
                                            <Text1>{incident.vehicle.vehicle_make} {incident.vehicle.vehicle_model}</Text1>
                                        </div>
                                    </DetailsBox>
                                </Col>
                                <Col xl={6}>
                                    <DetailsBox>
                                        <div>
                                            <Text5>Location</Text5>
                                        </div>
                                        <div>
                                            <Text1>{sensor.latitude}, {sensor.longitude}</Text1><br/>
                                        </div>
                                    </DetailsBox>
                                </Col>
                                <Col xl={6}>
                                    <DetailsBox>
                                        <div>
                                            <Text5>Estimated Speed and Direction</Text5>
                                        </div>
                                        <div>
                                            <Text1>{(!sensor.speed || !sensor.speed.value || !sensor.speed.unit) ? "Not Available" : `${sensor.speed.value} ${sensor.speed.unit}`}</Text1><br/>
                                            <Text1>{!sensor.direction? "Not Available" : getDirection(sensor.direction)}</Text1>
                                        </div>
                                    </DetailsBox>
                                </Col>
                                <Col xl={6}>
                                    <DetailsBox>
                                        <div>
                                            <Text5>Recorded G-Sensor Value</Text5>
                                        </div>
                                        <div>
                                            <Text1>{!sensor.gyro? "Not Available" : `${getGsensor(sensor.gyro)} G`}</Text1>
                                        </div>
                                    </DetailsBox>                                    
                                </Col>
                            </Row>
                            <Row nogutter>
                                <DetailsBox>
                                    <div>
                                        <Text5>Insurance Provider</Text5>
                                    </div>
                                    <Row gutter={24}>
                                        <Col xl={6}>
                                            <TextInput
                                                label="Insurance Carier"
                                                value={userInput.insurranceCarier}
                                                error='' 
                                                onChange={value => onChangeUserInput("insurranceCarier", value)}
                                                background="#F4F5F6"
                                                margin="0 2.4rem 1.6rem 0"
                                            />
                                        </Col>
                                        <Col xl={6}>
                                            <HBox gap="0.8rem" justify='space-between'>
                                                <PhoneNumberSelector 
                                                    countries={countries} 
                                                    selectedCountry={userInput.phoneNumberCountry} 
                                                    onSelect={value => onChangeUserInput('phoneNumberCountry', value)}
                                                />
                                                <TextInput
                                                    width="60%"
                                                    label="Phone number"
                                                    value={userInput.phoneNumber}
                                                    error={formErrors.phoneNumber}
                                                    onChange={value => onChangeUserInput("phoneNumber", value)}
                                                    background="#F4F5F6"
                                                />
                                            </HBox>
                                        </Col>
                                        <Col xl={6}>
                                            <TextInput
                                                label="Insurance Email"
                                                value={userInput.insurranceEmail}
                                                error={formErrors.insurranceEmail}
                                                onChange={value => onChangeUserInput("insurranceEmail", value)}
                                                background="#F4F5F6"
                                                margin="0 2.4rem 1.6rem 0"
                                            />
                                        </Col>
                                        <Col xl={6}>
                                            <TextInput
                                                label="Policy Number"
                                                value={userInput.policyNumber}
                                                error='' 
                                                onChange={value => onChangeUserInput("policyNumber", value)}
                                                background="#F4F5F6"
                                            />
                                        </Col>
                                    </Row>
                                </DetailsBox>                                
                            </Row>
                            <Row nogutter>
                                <DetailsBox>
                                    <div>
                                        <Text5>Recipient Information</Text5>
                                    </div>
                                    <HBox gap="1.6rem" align="center" isWrap={true}>
                                        <HBox align="center" gap="1.6rem">
                                            <Switch showText={false} value={userInput.sendToEmail} onChange={(value)=> onChangeUserInput("sendToEmail", value)}/>
                                            <Text1>Send to Email</Text1>
                                        </HBox>
                                        <TextInput
                                            width="31rem"
                                            label="Email"
                                            value={userInput.extraEmail}
                                            error={formErrors.extraEmail}
                                            onChange={value => onChangeUserInput("extraEmail", value)}
                                            background="#F4F5F6"
                                        />
                                    </HBox>
                                </DetailsBox>
                            </Row>
                        </Col>
                        <Col md={5}>
                            <Hidden xs sm>
                                <Row nogutter>
                                    <DetailsBox>
                                        <div>
                                            <Text5>Map</Text5>
                                        </div>
                                    </DetailsBox>
                                    { isLoaded && <GoogleMap           
                                        mapContainerStyle={{
                                            height: '32rem',
                                            width: '100%'
                                        }}
                                        center={{lat: sensor.latitude, lng: sensor.longitude}}
                                        zoom={MAP_ZOOM_LEVEL}
                                        version="weekly">
                                            <Marker position={{lat: sensor.latitude, lng: sensor.longitude}}/>
                                    </GoogleMap>
                                    }
                                </Row>
                                <Row nogutter>
                                    <DetailsBox>
                                        <div>
                                            <Text5>Related Videos</Text5>
                                        </div>
                                        <div>
                                            {incident.videos && incident.videos.length > 0 ?
                                                (incident.videos.map((el, i) =>
                                                    el && el.url && <VideoContainer  key={i} onClick={()=>handleClickVideo(el.url)}>
                                                        <Video
                                                            height="7.4rem"
                                                            width="15.2rem"
                                                            src={`${el.url}#t=0.1`}
                                                            margin="0.5rem"
                                                        />
                                                    </VideoContainer>
                                                    )
                                                )
                                                :
                                                (<P1>No videos avaliable.</P1>)
                                            }
                                        </div>
                                    </DetailsBox>
                                </Row>
                            </Hidden>
                        </Col>
                    </Row>
                </SectionBody>
                <Section>
                    <div style={{width: '100%'}}>
                        <HBox gap="1.6rem" justify="space-between" align="center" isWrap={true}>
                            <Button onClick={()=>onDeleteIncidentReport(incident)} buttonType="danger2" >Delete Report</Button>
                            <Button isLoading={isLoading} onClick={sendIncidentReport} buttonType="primary">Confirm and Send</Button>
                        </HBox>
                    </div>
                </Section>
            </Container>
        </Popup>
    );
};

IncidentReportPopup.propTypes = {
    incident: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onIncidentUpdated: PropTypes.func.isRequired,
    onDeleteIncidentReport: PropTypes.func.isRequired
}

export default IncidentReportPopup;