import { NOTIFICATION } from '../actions/notification';

const initialState = {
    data: null,
    isLoading: false,
    error: null,
    successMessage: null,
}

const notificationReducer = (state = initialState, action ) => {
    switch(action.type) {
        case NOTIFICATION.GET_ALL_START:
            return {data: null, isLoading: true, error: null, successMessage: null};
        case NOTIFICATION.GET_ALL_SUCCESS:
            return { data: action.payload, isLoading: false, error: null, successMessage: null}
        case NOTIFICATION.GET_ALL_FAIL:
            return {data: null, isLoading: false, error: action.payload, successMessage: null}
        case NOTIFICATION.DELETE_START:
            return {...state, isLoading: true, error: null, successMessage: null}
        case NOTIFICATION.DELETE_SUCCESS:
            return {...state, isLoading: false, error: null, successMessage: "Notification deleted successfully"}
        case NOTIFICATION.DELETE_FAIL:
            return {...state, isLoading: false, error: action.payload, successMessage: null} 
        case NOTIFICATION.DELETE_ALL_START:
            return {...state, isLoading: true, error: null, successMessage: null}
        case NOTIFICATION.DELETE_ALL_SUCCESS:            
            return {...state, isLoading: false, error: null, successMessage: "Notifications deleted successfully"}
        case NOTIFICATION.DELETE_ALL_FAIL:
            return {...state, isLoading: false, error: action.payload, successMessage: null}                      
        default:
            return state;
    }
};

export default notificationReducer;