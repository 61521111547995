import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GoogleMap,  Circle, Rectangle, Polygon } from '@react-google-maps/api';
import { resolve } from 'nested-prop-resolver';

import { Container, ContentContainer, ButtonContainer } from './styles';
import ClickWrapper from '../../atoms/ClickWrapper';
import Text from '../../atoms/Text';
import Popup from '../../molecules/Popup';
import {
    MAP_ZOOM_LEVEL
  } from '../../../constants';
const ViewVehicleGeofencePopup = ({ vehicle, onCancel }) => {
    const geofences = useSelector((state) => state.geofence.data.filter( (el) => el.vehicle_ids.includes(vehicle.vehicle_id)));
    const location = {lat: geofences[0].shape.geofence_center_point[0], lng: geofences[0].shape.geofence_center_point[1]}

    const drawShape = (shape, index) => {
      switch (shape.type) {
        case 'circle':
          return (
            <Circle
              options={{
                strokeColor: '#007aff',
                strokeWeight: 1,
                fillColor: '#007aff',
                fillOpacity: 0.33                
              }}
              center={ {lat: shape.geofence_center_point[0], lng: shape.geofence_center_point[1]}}
              radius={shape.radius}
              key={index}
            />
          );
        case 'rectangle':
          const rectanglePoints = {
            north: resolve(shape.points, '[0][0][0]', 0),
            west: resolve(shape.points, '[0][0][1]', 0),
            south: resolve(shape.points, '[0][1][0]', 0),
            east: resolve(shape.points, '[0][1][1]', 0)
          }
          return (
            <Rectangle
              options={{
                strokeColor: '#007aff',
                strokeWeight: 1,
                fillColor: '#007aff',
                fillOpacity: 0.33
              }}
              bounds={rectanglePoints}
              key={index}
            />
          );
        case 'polygon':
          const polygonPoints = resolve(shape.points, '[0]', []);
          return (
            <Polygon
              options={{
                strokeColor: '#007aff',
                strokeWeight: 1,
                fillColor: '#007aff',
                fillOpacity: 0.33,
              }}
              path={polygonPoints.map(p => ({ lat: p[0], lng: p[1] }))}
              key={index}
            />
          );
        default:
          return null;
        }
        
    }
    const drawGeofences = () => {
        return (geofences.map((geofence, index) => drawShape(geofence.shape, index)  ))
    }

    return (
        <Container>
            <Popup
                title='Geofences'
                subtitle={vehicle.alias}
                onClose={onCancel}
                backgroundColor="#424242"
                height="52.5rem"
            >
                <ContentContainer>
                <GoogleMap
                    mapContainerStyle={{
                    height: '39rem',
                    width: '100%'
                    }}
                    center={location}
                    zoom={MAP_ZOOM_LEVEL-2}
                    version="weekly"
                    on
                >
                  {drawGeofences()}
                </GoogleMap>
                </ContentContainer>
                <ButtonContainer>
                    <ClickWrapper padding="0.5rem 2rem" onClick={onCancel} >
                        <Text fontSize="1.8rem" fontWeight={500} color="#CCCCCC">CANCEL</Text>
                    </ClickWrapper>
                </ButtonContainer>
            </Popup>
        </Container>
    )

};

ViewVehicleGeofencePopup.propTypes = {
    onCancel: PropTypes.func.isRequired,
}

export default ViewVehicleGeofencePopup;