import React from 'react';
import PropTypes from 'prop-types';

const WarningIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18">
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <path fill="#FFF" d="M20.718 15.451L11.92.816C11.614.305 11.075 0 10.479 0S9.344.305 9.038.815L.24 15.451c-.312.519-.32 1.168-.022 1.695.297.527.858.854 1.464.854h17.595c.605 0 1.167-.327 1.464-.854.298-.527.289-1.176-.023-1.695z" transform="translate(-396 -762) translate(360 160) translate(0 464) translate(28 51) translate(8 87)" />
                  <path fill={color} d="M20.718 15.451L11.92.816C11.614.305 11.075 0 10.479 0S9.344.305 9.038.815L.24 15.451c-.312.519-.32 1.168-.022 1.695.297.527.858.854 1.464.854h17.595c.605 0 1.167-.327 1.464-.854.298-.527.289-1.176-.023-1.695zM11.478 15h-2v-2h2v2zm0-4h-2V6h2v5z" transform="translate(-396 -762) translate(360 160) translate(0 464) translate(28 51) translate(8 87)" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

WarningIcon.propTypes = {
  color: PropTypes.string
};

WarningIcon.defaultProps = {
  color: '#FC0207'
};

export default WarningIcon;
