import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode  from 'jwt-decode';

import CenterWrapper from '../../atoms/CenterWrapper';
import TextInput from '../../molecules/TextInput';
import AuthContainer from '../../organisms/AuthContainer';
import Button from '../../atoms/Button';
import {H1} from '../../atoms/Typography';
import {errorNotification} from '../../../utils/notifications';

import { resetPasswordStart } from '../../../redux/actions/password';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const password = useSelector(state => state.password);

  const [formData, setFormData] = useState({});
  const [formDataError,  setFormDataError] = useState({password: '', confirmPassword: ''});

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  
  let passwordReset = {
    email: '',
    confirmationCode: ''
  };
  if(params.has('token')){
    const decoded = jwt_decode(params.get('token'));
    passwordReset = {
      email: decoded.email,
      confirmationCode: params.get('token')
    };
  }

  useEffect(() => {
    if(password.error){
      errorNotification({
        title: '',
        text: 'Verification request failed ! Invalid or expired token'
      });
    }
    if (user.data || password.resetSuccess) {
      history.push('/');
    }
  }, [user, password]);

  const onChangeFormData = (key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  const onSubmit = () => {
    if(!formData.password || formData.password.length === 0){
      setFormDataError({confirmPassword:'', password: 'Field is emty'});
      return false;
    }
    if(formData.password.length < 6){
      setFormDataError({confirmPassword: '', password: 'Password should have at least 6 characters'});
      return false;
    }
    if(!formData.confirmPassword || formData.confirmPassword.length === 0){
      setFormDataError({password: '', confirmPassword: 'Field is emty'});
      return false;
    }
    if(formData.password !== formData.confirmPassword){
      setFormDataError({password: '', confirmPassword: 'Passwords does not match!'});
      return false;
    }
    setFormDataError({password: '', confirmPassword: ''});
    const dataObj = {
      email: passwordReset.email,
      confirmationCode: passwordReset.confirmationCode,
      password: formData.password
    };
    dispatch(resetPasswordStart(dataObj));
  };

  return (
    <AuthContainer>
      <CenterWrapper>
        <H1>RESET</H1>
      </CenterWrapper>
      <TextInput 
        placeholder="New Password" 
        isPassword={true} 
        value={formData.password} 
        error={formDataError.password} 
        onChange={value => onChangeFormData('password', value)} 
        margin="1.6rem 0 0 0" 
      />
      <TextInput 
        placeholder="Confirm New Password"  
        isPassword={true} 
        margin="1.6rem 0 1.6rem 0" 
        value={formData.confirmPassword} 
        error={formDataError.confirmPassword} 
        onChange={value => onChangeFormData('confirmPassword', value)} 
      />
      <Button 
        buttonType='primary' 
        fullWidth 
        onClick={onSubmit} 
        isLoading={password.isLoading}>
        Submit
      </Button>
    </AuthContainer>
  );
};

export default ResetPassword;
