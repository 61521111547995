import React from 'react';
import PropTypes from 'prop-types';

const HeartIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18">
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <g>
            <path d="M50.5 137c-2.895 0-4.5 2.05-4.5 2.05S44.395 137 41.5 137c-3.038 0-5.5 2.415-5.5 5.395 0 4.092 4.912 8.056 6.281 9.31C43.858 153.145 46 155 46 155s2.142-1.854 3.719-3.296c1.369-1.253 6.281-5.217 6.281-9.309 0-2.98-2.462-5.395-5.5-5.395z" transform="translate(-396 -297) translate(360 160)" />
          </g>
        </g>
      </g>
    </svg>
  );
};

HeartIcon.propTypes = {
  color: PropTypes.string
};

HeartIcon.defaultProps = {
  color: 'white'
};

export default HeartIcon;
