import { COUNTRIES } from '../actions/countries';

const initialState = {
  data: [],
  isLoading: false,
  error: null
};

const countriesReducer = (state = initialState, action) => {
  switch(action.type) {
  case COUNTRIES.GET_START:
    return { data: [], isLoading: true, error: null };
  case COUNTRIES.GET_SUCCESS:
    return { data: action.payload.sort((a,b)=> (a.name === "United States" || a.name === "Canada") ? -1 : 1), isLoading: false, error: null };
  case COUNTRIES.GET_FAIL:
    return { data: [], isLoading: false, error: action.payload };
  default:
    return state;
  }
};

export default countriesReducer;
