import styled from 'styled-components';

export const Container = styled.div`
  height: 36.5rem;
`;

export const InputElement = styled.input.attrs({ type: 'text' })`
  width: 100%;
  height: 4rem;
  border: none;
  border-bottom: ${({ locationError }) => `solid ${locationError ? 'red' : 'white'} 0.1rem`};
  outline: none;
  font-size: 1.6rem;
  background: transparent;
  color: white;
  // margin-bottom: 1rem;
`;

export const DropDownContainer = styled.div`
  background-color: #1F1F1F;
  position: absolute;
  z-index: 1;
  width: 100%;
`;

export const LoadingContainer = styled.div`
  color: white;
  background-color: #1F1F1F;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
`;

export const OptionContainer = styled.div`
  color: white;
  background-color: #1F1F1F;
  padding: 1rem 0;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
`;
export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ width }) => width};
`;

export const AutocompleteContainer = styled.div`
  width: 39rem;
`

export const ShapeSelectorButtonContainer = styled.div`
&:hover{
  background: rgba(217, 217, 217, 0.2);
};
padding: 0.5rem;
border-radius: 0.2rem;
`
export const GoogleMapContainer = styled.div`
  position:relative;
  height:100%;
  margin-left:-4rem;
  margin-right:-4rem;
`;