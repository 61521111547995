import React, {useState, useEffect} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {Container} from 'react-grid-system';

import Logo from '../../../assets/logo-blue.png';
import Image from '../../atoms/Image';
import HBox from '../../atoms/HBox';
import VBox from '../../atoms/VBox';
import {H1, P4, Text1} from '../../atoms/Typography';
import PageCheckBox from '../../molecules/PageCheckBox'
import ExclamationMark from '../../../assets/exclamationmark.circle.png';
import Loader from '../../atoms/Loader';
import Button from '../../atoms/Button';
import Text from '../../atoms/Text';
import TextInput from '../../molecules/TextInput';

import styles from './AccountDeletion.module.css';
import { errorNotification, notify, confirmAccountDeletion } from "../../../utils/notifications";
import {accountDeletionRequestStart, accountDeletionStart} from "../../../redux/actions/accountDeletion";
import { USER_OBJECT_KEY } from '../../../constants';

const AccountDeletion = () => {
    
    const location = useLocation();
    const token = location.search.split('=')[1];
    const dispatch = useDispatch();
    const history = useHistory();
    const deleteAccountState = useSelector(state => state.accountDeletion);
    
    const [formData, setFormData] = useState({
        accept: false,
        email: '',
        reason: 'Other'
    });
    const [formErrors, setFormErrors] = useState({
        accept: null,
        email: null,
        reason: null
    }); 

    useEffect(() => {
        if(token){
            dispatch(accountDeletionStart(token));
        }
    }, []);

    useEffect(() => {
        if(deleteAccountState.data && deleteAccountState.data.message && !token){
           if(token){
            localStorage.removeItem(USER_OBJECT_KEY);
           }else{
            notify({title: "Email Sent", text: deleteAccountState.data.message});
           }
        }
        if(deleteAccountState.error && !token ){
            errorNotification({title: deleteAccountState.error.title, text: deleteAccountState.error.text});
         }
    }, [deleteAccountState]);
   
    const onSubmit = async (e) => {
        e.preventDefault();
        if(!formData.accept){
            setFormErrors({accept: "This needs to be checked!"})
            return false;
        }
        if(!formData.email){
            setFormErrors({email: "Email is empty!"});
            return false;
        }
        if(!formData.email.toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )){
            setFormErrors({email: "Invalid email"});
            return false;
        }
        setFormErrors({
            accept: null,
            email: null,
            reason: null
        });
        const result = await confirmAccountDeletion();
        if(result){
            dispatch(accountDeletionRequestStart({...formData, is_fleet:1}));
            setFormData({
                accept: false,
                email: '',
                reason: 'Other'
            });
        }
    }

    const onChangeFormData = (key, value) => {
        setFormData({
          ...formData,
          [key]: value
        });
    };

    const showForm = () =>{
        history.push('/account-deletion')
    }

    const onCancel = () => {
        history.push('/login');
    }
    
    return (
        <Container fluid className={`main-content-container`}>
            <div className={styles["logo-container"]}>
                <a href="/login"><Image src={Logo}/></a>
            </div>
            <div className={styles['content-container']}>
            {token && 
                    <div>
                        <HBox justify='center'>
                            <H1 >Account Deletion</H1>
                        </HBox>
                        {deleteAccountState.data && deleteAccountState.data.message && 
                            <VBox justify='center' align='center'>
                                <P4 className="text-center">
                                Your account and all information has been deleted from our database. You can create a new account if you would like to use Drive Smarter again.
                                </P4>
                            </VBox>
                        }
                        {deleteAccountState.error &&
                            <VBox justify='center' align='center'>
                                <P4 className="text-center">
                                    We could not delete you account, something went wrong. Please try again to complete the request.
                                </P4>
                                <Button buttonType="danger" onClick={showForm}>Request Delete Account</Button>
                            </VBox>    
                        }
                        {!deleteAccountState.data && !deleteAccountState.error && 
                            <VBox justify='center' align='center'>
                                <P4>
                                    Please wait while account is beeing deleted 
                                </P4>
                                <Loader margin="0rem" />
                            </VBox>
                        }
                    </div>
                }
                {!token && 
                    <div>
                        <HBox justify='center'>
                            <H1 >Account Deletion</H1>
                        </HBox>
                        <HBox justify='center' align='center' margin="0.75rem 0 0 0">
                            <Image src={ExclamationMark}/>
                            <P4>
                                <strong>Deleting your account cannot be undone.</strong>
                            </P4>
                        </HBox>
                        <P4>
                            If you are experiencing problems with the app, please reach out to our Customer Support and we will do our best to help you.
                        </P4>
                        <P4>
                            If you decide to proceed with deleting your account, please be aware that this is an action which cannot be undone. <br/>
                            If you decide to proceed, your account will be deleted and you will lose all data associated with it (all files from your devices, your personal data, contact data including emergency information). You will need to register again to use Drive Smarter.
                            If you are experiencing problems with the app or data management, please reach out to our <a href="https://drivesmarter.com/support/">Customer Support</a> and we will do our best to help you. 
                        </P4>
                        <P4>
                            Please check the option below if you would like to proceed.
                        </P4>
                        
                        <form onSubmit={onSubmit}>
                            <div className={styles["checkbox-container"]}>
                                <PageCheckBox value={formData.accept} name="accept" onChange={()=>onChangeFormData("accept", !formData.accept)} checked={formData.accept}> </PageCheckBox>
                                <div>
                                    <Text1>I want to delete my Drive Smarter account with all data</Text1>
                                    <div className={styles["additional-info"]}>If you select this option, your account and its information will be deleted from Drive Smarter database. Your account will no longer be active.</div>
                                </div>
                            </div>
                            {formErrors.accept &&  <Text margin="0.2rem 0 0 1.6rem" color="#E24444" fontSize="1.2rem">{formErrors.accept}</Text>}
                            <VBox margin="2rem 0">
                                <Text1>Indicate your email which is connected to the account<span className={styles.red}>*</span></Text1>
                                <TextInput
                                    placeholder="Enter your email here"
                                    name="email"
                                    type="text"
                                    value={formData.email}
                                    onChange={(value) => onChangeFormData("email", value)}
                                    id="email"
                                    error={formErrors.email}
                                    maxLength={100}
                                />
                            </VBox>
                            <VBox margin="2rem 0">
                                <Text1>Please state the reason for the decision to delete your account to help us improve.</Text1>
                                <select name="reason" className={styles["custom-select"]} defaultValue={formData.reason} onChange={value=>onChangeFormData("reason", value.target.value)}>
                                    <option value="Drive Smarter app won't connect my device">Drive Smarter app won't connect my device</option>
                                    <option value="I've decided to use a competitor's product">I've decided to use a competitor's product</option>
                                    <option value="I don't use my dashcam or radar detector anymore">I don't use my dashcam or radar detector anymore</option>
                                    <option value="I want my data deleted">I want my data deleted</option>
                                    <option value="Other">Other</option>
                                </select>
                            </VBox>
                            <HBox justify='flex-end' gap="1rem">
                                <Button buttonType="secondary" onClick={onCancel}>Cancel</Button>
                                <Button isLoading={deleteAccountState.loading} buttonType="danger" disabled={deleteAccountState.loading} onClick={onSubmit}>
                                    Delete Account
                                </Button> 
                            </HBox>
                        </form>
                    </div>
                }
            </div>
        </Container>
    )
    
};

export default AccountDeletion;