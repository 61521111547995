import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-grid-system';


import {
  Container,
  VideoContainer,
  IconContainer,
  DateTitle
} from './VideoPreviewer.styles';
import Video from '../../atoms/Video';
import HeartIcon from '../../../assets/HeartIcon';
import WarningIcon from '../../../assets/WarningIcon';
import ParkingIcon from '../../../assets/ParkingIcon';
import { getDate, getTime } from '../../../utils/timeFormatters';
import {Text2, Text1} from "../../atoms/Typography";
import Image from "../../atoms/Image";

const VideoPreviewer = ({
  time,
  src,
  type,
  height,
  onVideoClick,
  selected,
  isPhoto,
  showDay
}) => {
  return (
    <>
      {showDay &&
        <Col xs={12}>
          <DateTitle>
            <Text1 color="#5C6670" fontSize="1.4rem">{getDate(time)}</Text1>
          </DateTitle>
        </Col>
      }
      <Col xs={6}>
        <Container height={height} onClick={onVideoClick}>
          <VideoContainer>
            {isPhoto ? 
              <Image 
                width="100%" 
                src={src} 
                selected={selected}
                maxHeight="14.5rem"
              />
              :
              <Video
                selected={selected}
                width="100%"
                src={`${src}#t=0.1`}
                borderRadius="0.2rem"
                maxHeight="14.5rem"
              />
            }
            <IconContainer>
              {type === 'favorite' && (
                  <HeartIcon />
              )}
              {['emergency', 'fnol', 'mayday'].includes(type) && (
                  <WarningIcon />
              )}
              {['parkmode'].includes(type) && (
                  <ParkingIcon/>
              )}
            </IconContainer>          
          </VideoContainer>
          <div>
            <Text2 color="#5C6670">{getTime(time)}</Text2>
          </div>
        </Container>
      </Col>
    </>    
  );
};

VideoPreviewer.propTypes = {
  time: PropTypes.string,
  src: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  onVideoClick: PropTypes.func,
  isPhoto: PropTypes.bool,
  showDay: PropTypes.bool
};

VideoPreviewer.defaultProps = {
  favorite: false,
  height: 'auto',
  type: '', // can add favorite, emergency and parking items to display relevant icons
  isPhoto: false,
  showDay: false
};

export default VideoPreviewer;
