import React from 'react';
import PropTypes from 'prop-types';

const ExpandedIcon = () => {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.50293 1.9375L7.19141 8.77539C7.41992 9.02148 7.69238 9.13574 8 9.13574C8.30762 9.13574 8.58887 9.0127 8.81738 8.77539L15.4971 1.9375C15.6904 1.74414 15.7959 1.49805 15.7959 1.20801C15.7959 0.619141 15.3389 0.162109 14.7588 0.162109C14.4775 0.162109 14.2051 0.267578 14.0029 0.478516L8.00879 6.63965L1.99707 0.478516C1.80371 0.276367 1.53125 0.162109 1.24121 0.162109C0.661133 0.162109 0.204102 0.619141 0.204102 1.20801C0.204102 1.48926 0.30957 1.74414 0.50293 1.9375Z" fill="#1C1C1E"/>
    </svg>
  );
};

export default ExpandedIcon;
