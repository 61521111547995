import { take, call, put } from 'redux-saga/effects';
import { 
  LOCATION,
  locationGetSuccess,
  locationGetFail
} from '../actions/location';

export function* handleLocationGetStart() {
  while(true) {
    yield take(LOCATION.GET_START);
    try {
      const response = yield call(getBrowserLocation);
      yield put(locationGetSuccess(response));
    } catch (error) {
      yield put(locationGetFail(error.toString()));
    }
  }
}

const getBrowserLocation = () => {
  return new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition(obj => {
      const { coords } = obj;
      const result = { 
        lat: coords.latitude, 
        lng: coords.longitude 
      };
      resolve(result);
    }, error => {
      reject(error);
    });
  });
};
