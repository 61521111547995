import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const VideoHeaderContainer = styled.div`
  padding: 1.6rem 2.4rem;
  position: absolute;
  top:0;
  left:0;
  width: calc(100% - 63px);
  background: rgba(255, 255, 255, 0.70);
  z-index: 1;
`;

export const CircleButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 4.8rem;
  background: #E24444;
  cursor: pointer;
`;

export const VideoContainer = styled.video`
  width: 100%;
  height: auto;
  background: #363F45;
  object-fit: cover;
`;

export const ImageContainer = styled.img`
  width: 100%;
  height: auto;
  background: #363F45;
  object-fit: cover;
`;

export const NoVideo = styled.div`
  display:flex;
  height:54rem;
  align-items:center;
  justify-content:center;
  background: #ffffff;
  width:100%;
  background: #363F45;
`;