import { take, call, put } from 'redux-saga/effects';
import { 
  USER,
  userLoginSuccess, 
  userLoginFail
} from '../actions/user';
import { 
  PROFILE,
  profileGetStart
} from '../actions/profile';
import { 
  vehicleGetAllStart
} from '../actions/vehicle';
import { 
  geofenceGetAllStart
} from '../actions/geofence';
import { 
  locationGetStart
} from '../actions/location';
import { login } from '../../api/user';
import { USER_OBJECT_KEY } from '../../constants';

export function* handleLogin() {
  while(true) {
    const { payload } = yield take(USER.LOGIN_START);
    try {
      const response = yield call(login, payload);
      const userObj = {
        email: payload.email,
        ...response.data
      };
      // save user session
      localStorage.setItem(USER_OBJECT_KEY, JSON.stringify(userObj));
      yield put(userLoginSuccess(response));
    } catch (error) {
      yield put(userLoginFail(error));
    }
  }
}

export function* handleLoginSuccess() {
  while(true) {
    yield take(USER.LOGIN_SUCCESS);
    yield put(profileGetStart());
    const { payload } = yield take(PROFILE.GET_SUCCESS);
    yield put(vehicleGetAllStart({user_cog_id: payload.user_id}));
    yield put(geofenceGetAllStart());
    yield put(locationGetStart());
  }
}

export function* handleLogout() {
  while(true) {
    yield take(USER.LOGOUT);
    // clear user session
    localStorage.removeItem(USER_OBJECT_KEY);
  }
}
