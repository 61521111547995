import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import AppNavBar from './components/organisms/AppNavBar';
import ProtectedRoute from './components/atoms/ProtectedRoute';
import Login from './components/pages/Login';
import ForgotPassword from './components/pages/ForgotPassword';
import ResetPassword from './components/pages/ResetPassword';
import Home from './components/pages/Home';
import LiveView from './components/pages/LiveView';
import Gallery from './components/pages/Gallery';
import Fleet from './components/pages/Fleet';
import NotFound from './components/pages/NotFound';
import VerifyAccount from './components/pages/VerifyAccount';
import AccountCleanup from './components/pages/AccountCleanup';
import AccountDeletion from './components/pages/AccountDeletion';

const store = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppNavBar/>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route exact path="/forgotPassword/verify">
            <ResetPassword />
          </Route>
          <Route exact path="/verify">
            <VerifyAccount />
          </Route>
          <Route path="/account-cleanup">
            <AccountCleanup/>
          </Route>
          <Route path="/account-deletion">
            <AccountDeletion/>
          </Route>
          <ProtectedRoute exact path="/">
            <Home />
          </ProtectedRoute>
          <ProtectedRoute exact path="/live-view">
            <LiveView />
          </ProtectedRoute>
          <ProtectedRoute exact path="/gallery">
            <Gallery />
          </ProtectedRoute>
          <ProtectedRoute exact path="/fleet">
            <Fleet />
          </ProtectedRoute>
          <Route path = '*'>
            <NotFound/>
          </Route>
        </Switch>        
      </BrowserRouter>
    </Provider>
  );
};

export default App;
