import React from 'react';

const PlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M1.36038 11.3604H8.63962V18.6396C8.63962 19.3795 9.24821 20 10 20C10.7518 20 11.3604 19.3795 11.3604 18.6396V11.3604H18.6396C19.3795 11.3604 20 10.7518 20 10C20 9.24821 19.3795 8.63962 18.6396 8.63962H11.3604V1.36038C11.3604 0.620525 10.7518 0 10 0C9.24821 0 8.63962 0.620525 8.63962 1.36038V8.63962H1.36038C0.620525 8.63962 0 9.24821 0 10C0 10.7518 0.620525 11.3604 1.36038 11.3604Z" fill="white"/>
    </svg>
  );
};

export default PlusIcon;
