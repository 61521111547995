import React, {useState, useEffect} from "react";
import { PropTypes } from "prop-types";
import {Row, Col} from "react-grid-system";

import {getDateTime} from "../../../utils/timeFormatters";

import Popup from "../../molecules/Popup";
import Card from "../../molecules/Card";
import PDFLink from "../../molecules/PDFLink";
import Pagination from "../../molecules/Pagination";
import {Text3, Text2} from "../../atoms/Typography";
import Link from "../../atoms/Link";
import Loader from "../../atoms/Loader";
import DeleteIcon from "../../../assets/DeleteIcon";
import DownloadIcon  from "../../../assets/DownloadIcon";
import CheckMarkIcon from "../../../assets/CheckMarkIcon";
import { ContentContainer, ListHeader, ListItem, Status, Button, PaginationWrapper} from "./styles";


const IncidentsListPopup = ({isLoading, onClose, incidentsList, handleClickSend, updatedIncident, onDeleteIncidentReport}) => {
    const [pageIndex, setPageIndex] = useState(1);
    const [pageItems, setPageItems] = useState([]);
    const [hasIncidentUpdated, setHasIncidentUpdated ] = useState(false);
    
    useEffect(() => {
        setPageItems(incidentsList.slice(0,5))
        setPageIndex(1);
    }, [incidentsList]);
    useEffect(() => {
        if(updatedIncident){
            setHasIncidentUpdated(true)
            const timer = setTimeout(() => {
                setHasIncidentUpdated(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [updatedIncident]);
    
    const handleOnChangePage= (index) => {
        setPageIndex(index);
        setPageItems(incidentsList.slice((index-1)*5, index*5));
    }

    
    return (
        <Popup backgroundColor="#fff" title='' onClose={onClose}>
            <Card expand={false} title='Incident Reports'>
                {isLoading && 
                    <center>
                        <Loader margin="20rem"/>
                    </center>
                }
                {!isLoading &&
                    <ContentContainer>
                        <ListHeader>
                            <Row gutterWidth={10}>
                                <Col xs={3}><Text2>Status</Text2></Col>
                                <Col xs={3}><Text2>Date/Time</Text2></Col>
                                <Col xs={3}><Text2></Text2></Col>
                                <Col xs={3}></Col>
                            </Row>
                        </ListHeader>
                        {(pageItems.map((item, i) => (
                            <ListItem key={i}>
                                <Row gutterWidth={10} align="center">
                                    <Col xs={3}>
                                        <Status sent={!!item.report_url}>
                                            <Text3 color="#fff">{!!item.report_url? "Sent": "Not Sent"}</Text3>
                                        </Status>
                                    </Col>
                                    <Col xs={3}><Text2>{getDateTime(item.start_at)}</Text2></Col>
                                    <Col xs={3} >
                                        {/* TODO: something with checkmark after report is sent */}
                                        {updatedIncident && updatedIncident.report_id=== item.report_id && hasIncidentUpdated?
                                            <CheckMarkIcon/>
                                            :
                                            <Link onClick={()=>handleClickSend(item)}>Send</Link>
                                        }
                                    </Col>

                                    <Col xs={3}>
                                        {item.report_url?
                                            <a href={item.report_url} target="_blank"><DownloadIcon /></a>
                                        :
                                            <PDFLink report={item}><DownloadIcon/></PDFLink>
                                        }
                                        <Button onClick={()=>onDeleteIncidentReport(item)}><DeleteIcon /></Button>
                                    </Col>
                                </Row>  
                            </ListItem>
                        )))}
                    </ContentContainer>
                }
                <PaginationWrapper>
                    <Pagination 
                        count={incidentsList.length}
                        pageIndex={pageIndex}
                        onChangePage={handleOnChangePage}
                    />
                </PaginationWrapper>
            </Card>
        </Popup>
    );
};

IncidentsListPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    incidentsList: PropTypes.array.isRequired,
    handleClickSend: PropTypes.func.isRequired,
    onDeleteIncidentReport: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    updatedIncident: PropTypes.object,
}

IncidentsListPopup.defaultProps = {
    isLoading: false,
    updatedIncident: null
}

export default IncidentsListPopup;