import React from "react";
import PropTypes from "prop-types";
import HBox from "../../atoms/HBox";
import {Text2} from "../../atoms/Typography";
import XMark from '../../../assets/XMark';
import ClickWrapper from '../../atoms/ClickWrapper';

import {Container} from './styles.js';

const Snackbar = ({type, text, onClose}) => {
    return(
        <Container type={type}>
            <HBox justify='space-between' align='center' >
                <Text2 color="#FFF">{text}</Text2>
                <ClickWrapper padding="1rem 1rem" onClick={onClose} >
                    <XMark color="#FFF"/>
                </ClickWrapper>
            </HBox>
        </Container>
    );
}; 
Snackbar.propTypes = {
    type: PropTypes.oneOf(['success', 'error']).isRequired,
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
};

export default Snackbar;