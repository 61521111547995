import React from 'react';

const SquareDashedIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="square.dashed">
        <path id="square.dashed_2" d="M5.78223 10.1211H7.53125V8.47754C7.53125 7.625 7.97949 7.20312 8.78809 7.20312H10.4229V5.4541H8.67383C6.77539 5.4541 5.78223 6.42969 5.78223 8.31934V10.1211ZM11.7148 7.20312H16.2764V5.4541H11.7148V7.20312ZM20.46 10.1211H22.209V8.31934C22.209 6.43848 21.2158 5.4541 19.3174 5.4541H17.5684V7.20312H19.2031C20.0029 7.20312 20.46 7.625 20.46 8.47754V10.1211ZM20.46 15.9219H22.209V11.4131H20.46V15.9219ZM5.78223 15.9219H7.53125V11.4131H5.78223V15.9219ZM17.5684 21.8809H19.3174C21.2158 21.8809 22.209 20.8965 22.209 19.0156V17.2051H20.46V18.8574C20.46 19.71 20.0029 20.1318 19.2031 20.1318H17.5684V21.8809ZM8.67383 21.8809H10.4229V20.1318H8.78809C7.97949 20.1318 7.53125 19.71 7.53125 18.8574V17.2051H5.78223V19.0156C5.78223 20.8965 6.77539 21.8809 8.67383 21.8809ZM11.7148 21.8809H16.2764V20.1318H11.7148V21.8809Z" fill="#5C6670"/>
      </g>
    </svg>
  );
};

export default SquareDashedIcon;