import React from 'react';

import {H1, H2, P1} from '../../atoms/Typography';
import CenterWrapper from '../../atoms/CenterWrapper';
import AuthContainer from '../../organisms/AuthContainer';


const NotFound = () => {

    return (
    <AuthContainer>
      <CenterWrapper>
        <H2>Uh-OH</H2>
        <P1>The page you are looking for may have been moved, deleted, or possibly never existed.</P1>
        <H1>404</H1>
      </CenterWrapper>
    </AuthContainer>
  );
};

export default NotFound;
