import React from 'react';

const CircleDashedSelectedIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9824 2.37695C14.7471 2.37695 15.4766 2.5 16.1621 2.71094L16.8564 1.04102C15.9512 0.733398 14.9844 0.566406 13.9824 0.566406C12.9893 0.566406 12.0137 0.733398 11.1084 1.04102L11.8115 2.71094C12.4971 2.5 13.2266 2.37695 13.9824 2.37695ZM20.4424 6.28809L22.1035 5.59375C21.2246 3.84473 19.792 2.4209 18.043 1.5332L17.3574 3.20312C18.6758 3.87988 19.7568 4.96094 20.4424 6.28809ZM10.6162 3.20312L9.92188 1.54199C8.18164 2.4209 6.74902 3.85352 5.87012 5.59375L7.54004 6.28809C8.22559 4.96973 9.29785 3.88867 10.6162 3.20312ZM21.2686 9.6543C21.2686 10.4102 21.1455 11.1484 20.9434 11.834L22.6045 12.5283C22.9121 11.623 23.0879 10.6562 23.0879 9.6543C23.0879 8.65234 22.9121 7.68555 22.6045 6.78027L20.9346 7.47461C21.1543 8.16016 21.2686 8.89844 21.2686 9.6543ZM6.73145 9.6543C6.73145 8.89844 6.8457 8.16016 7.05664 7.4834L5.36914 6.78027C5.07031 7.68555 4.90332 8.65234 4.90332 9.6543C4.90332 10.6562 5.07031 11.6143 5.37793 12.5195L7.05664 11.8252C6.8457 11.1484 6.73145 10.4102 6.73145 9.6543ZM17.3574 16.1055L18.043 17.7754C19.792 16.8877 21.2246 15.4551 22.1035 13.7148L20.4512 13.0205C19.7656 14.3477 18.6758 15.4199 17.3574 16.1055ZM7.54883 13.0205L5.87891 13.7148C6.75781 15.4551 8.18164 16.8877 9.93066 17.7666L10.6162 16.0967C9.29785 15.4111 8.22559 14.3389 7.54883 13.0205ZM13.9912 16.9229C13.2266 16.9229 12.4971 16.8086 11.8115 16.5889L11.1172 18.2676C12.0225 18.5752 12.998 18.7422 13.9912 18.7422C14.9932 18.7422 15.96 18.5752 16.8652 18.2764L16.1621 16.5977C15.4766 16.8086 14.7471 16.9229 13.9912 16.9229Z" fill="#0071A4"/>
        <rect x="4" y="26" width="20" height="2" rx="1" fill="#0071A4"/>
    </svg>
  );
};

export default CircleDashedSelectedIcon;