import React from 'react';

const PolygonIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.43945 19.5342C6.2832 19.5342 7.0127 19.0596 7.39941 18.3652L20.4336 22.1885C20.7148 23.0938 21.5674 23.7617 22.5605 23.7617C23.791 23.7617 24.793 22.751 24.793 21.5205C24.793 20.624 24.2568 19.8506 23.4922 19.4902L23.1318 10.9473C23.9316 10.6045 24.4854 9.81348 24.4854 8.89062C24.4854 7.66016 23.4834 6.6582 22.2617 6.6582C21.4707 6.6582 20.7676 7.08008 20.3721 7.7041L13.3848 5.69141C13.3232 4.52246 12.3389 3.58203 11.1611 3.58203C9.93066 3.58203 8.92871 4.58398 8.92871 5.81445C8.92871 6.42969 9.18359 6.99219 9.58789 7.39648L5.59766 15.0781C5.54492 15.0693 5.49219 15.0693 5.43945 15.0693C4.20898 15.0693 3.20703 16.0713 3.20703 17.3018C3.20703 18.5322 4.20898 19.5342 5.43945 19.5342ZM11.1611 8.04688C11.8555 8.04688 12.4707 7.73047 12.8838 7.22949L20.0645 9.28613C20.2051 10.0859 20.7764 10.7451 21.5322 11.0088L21.8398 19.4111C21.2598 19.6133 20.7939 20.0439 20.5391 20.5889L7.61035 16.7832C7.51367 16.4141 7.33789 16.0713 7.08301 15.7988L11.0645 8.04688C11.0996 8.04688 11.1348 8.04688 11.1611 8.04688Z" fill="#5C6670"/>
    </svg>
  );
};

export default PolygonIcon;