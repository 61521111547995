import {take, call, put} from 'redux-saga/effects';
import {
    TRIPNODE,
    getTripNodeSuccess,
    getTripNodeFail
} from '../actions/tripnode';

import {getLastTripNode} from '../../api/tripnode';

export function* handleGetTripNodeStart() {
    while(true){
        const {payload} = yield take(TRIPNODE.GET_START);
        try {
            const response = yield call(getLastTripNode, payload);
            yield put(getTripNodeSuccess(response));
        } catch (error) {
            yield put(getTripNodeFail(error));
        }
    }
}