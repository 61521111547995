import {TRIPNODE} from '../actions/tripnode';

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

const tripnodeReducer = (state = initialState, action) => {
    switch(action.type) {
        case TRIPNODE.GET_START:
            return {data:null, isLoading: true, error:null};
        case TRIPNODE.GET_SUCCESS:
            return {data:action.payload, isLoading:false, error:null};
        case TRIPNODE.GET_FAIL:
            return {data:null, isLoading:false, error: action.payload};
        default:
            return state;
    }
};

export default tripnodeReducer;