import styled from 'styled-components';

export const Section = styled.div`
    margin: 1.2rem 0;
`;

export const SectionBody = styled.div`
    margin: 1.2rem 0;
    @media(max-width:768px){
        height: calc(100vh - 30rem);
        overflow-y: auto;
        overflow-x: hidden;
    }
`;

export const Status = styled.div`
    display: flex;
    padding: 8px 16px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
    background: ${({sent})=> sent? "#55B48E": "#E24444"};
    color:#fff;
`;

export const IncidentTime = styled.div`
    margin-top: 0.6rem;
`;

export const TitleContainer = styled.div`
    display:flex;
    gap: 1.6rem;
    flex-direction:row;
    align-items:center;
`;

export const Title = styled.div`
    margin-top:1.2rem;
`;

export const DetailsBox = styled.div`
    display:flex;
    flex-direction:column;
    gap:8px;
    padding-top:1.6rem;
`

export const VideoContainer = styled.div`
    cursor:pointer;
`;