import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resolve } from 'nested-prop-resolver';
import {Row} from "react-grid-system";

import { videoLoadSelected } from '../../../redux/actions/video';
import { DEFAULT_EMPTY_DATA } from '../../../constants';
import {getNodesStart} from '../../../redux/actions/trip'
 
import Loader from '../../atoms/Loader';
import {Text1} from "../../atoms/Typography";
import TabMenuItem from '../../molecules/TabMenuItem';
import VideoPreviewer from '../../molecules/VideoPreviewer';
import Pagination from '../../molecules/Pagination';
import { getDate } from '../../../utils/timeFormatters';

import { 
  Container,
  HeaderContainer,
  ContentContainer,
} from './VideoCollectionSection.styles';

const setDisplayDate = (items) => {
  let selectedDay = '';
  for(let i = 0; i< items.length; i++){
    if(selectedDay !== getDate(items[i].device_time)){
      selectedDay = getDate(items[i].device_time)
      items[i].showDay = true;
    }else{
      items[i].showDay = false;
    }
  }
  return items;
}

const GalleryVideoCollection = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [videosList, setVideosList] = useState([]);
  const [menu, setMenu] = useState([
    {
      title: 'All',
      selected: true
    },
    {
      title: 'Videos',
      selected: false
    },
    {
      title: 'Photos',
      selected: false
    }
  ]);
 
  const video = useSelector((state) => state.video.all);
  const photos = useSelector((state) => state.video.photos);
  const isLoadingAll = useSelector((state) => state.video.isLoadingAll);
  const selectedVideo = useSelector((state) => state.video.selected);
  
  useEffect(() => {
    let data = [];
    let loading = false;
    const { title } = menu.find(m => m.selected === true);
    let selected = selectedVideo;
    switch(title) {
      case 'All':
        data = resolve(video, 'all_recordings', []);
        //if selected previously find index to set the correct page
        if(selected){
          const selectedIndex = data.findIndex(video=>{
            return video.video_url === selected.video_url
          });
          const page = parseInt(selectedIndex/10)+1;
          setVideosList(setDisplayDate(data.slice((page-1)*10, page*10)));
          setPageIndex(page);
        }else{
          setVideosList(setDisplayDate(data.slice(0, 10)));
          setPageIndex(1);
          dispatch(videoLoadSelected(data[0]))
          selected = data[0];
        }
        loading = isLoadingAll;
       
        if(selected && selected.trip_id && !!selected.video_url === true){
          dispatch(getNodesStart({tripId: selected.trip_id}));
        }
        break;
      case 'Videos':
          data = resolve(video, 'all_recordings', []);
          const videos = data.filter(item => !!item.video_url === true)
          setVideosList(setDisplayDate(videos.slice(0, 10)));
          setPageIndex(1);
          dispatch(videoLoadSelected(videos[0]))
          if(videos.length>0){
            dispatch(getNodesStart({tripId: videos[0].trip_id}));
          }
          loading = isLoadingAll;
          break;
      case 'Photos':
          if(photos){
            data = photos;
          }
          loading = isLoadingAll;
          setVideosList(setDisplayDate(data.slice(0, 10)));
          setPageIndex(1);
          dispatch(videoLoadSelected(data[0]))
          break;
      default:
        break;
    }

    setData(data);
    setIsLoading(loading);
  }, [video, photos, isLoadingAll, menu]);

  const handleOnChangePage= (index) => {
    setPageIndex(index);
    setVideosList(setDisplayDate(data.slice((index-1)*10, index*10)));
}

  const onTabClick = (title) => {
    const newMenu = menu.map(m => {
      if(m.title === title) {
        m.selected = true;
      } else {
        m.selected = false;
      }
      return m;
    });

    setMenu(newMenu);
  };

  const onVideoClick = (obj) => {
    if(obj.video_url){
      dispatch(getNodesStart({tripId: obj.trip_id}));
    }
    dispatch(videoLoadSelected(obj));
  };
  
  const getVideoPreview = (obj) => {
    const time = resolve(obj, 'device_time', DEFAULT_EMPTY_DATA);
    const url = resolve(obj, 'video_url', DEFAULT_EMPTY_DATA);
    const attribute = resolve(obj, 'attributes', DEFAULT_EMPTY_DATA).toLowerCase();
    const selectedNodeVideoUrl = resolve(selectedVideo, 'video_url', '');

    return <VideoPreviewer
      key={url}
      time={time}
      src={url}
      type={attribute}
      onVideoClick={() => onVideoClick(obj)}
      selected={selectedNodeVideoUrl===obj.video_url}
      isPhoto={false}
      showDay={obj.showDay}
    />;
  };

  const getPhotoPreview = (obj) => {
    const time = resolve(obj, 'device_time', DEFAULT_EMPTY_DATA);
    const url = resolve(obj, 'trip_node[0].image_url', DEFAULT_EMPTY_DATA);
    const attribute = "";
    const selectedNodeVideoUrl = resolve(selectedVideo, 'trip_node[0].image_url', '');

    return <VideoPreviewer
      key={url}
      time={time}
      src={url}
      type={attribute}
      onVideoClick={() => onVideoClick(obj)}
      selected={selectedNodeVideoUrl===url}
      isPhoto={true}
      showDay={obj.showDay}
    />;
  }

  return (
    <Container>
      <HeaderContainer>
        {menu && menu.map(m => <TabMenuItem height="4.5rem" key={m.title} title={m.title} selected={m.selected} onClick={() => onTabClick(m.title)} />)}
      </HeaderContainer>
      {isLoading ?
        <ContentContainer>
          <Loader margin="auto" />
        </ContentContainer>
        :
        <div>
          <ContentContainer>
            <Row gutterWidth={8}>
              {data.length > 0 ? (
                videosList.map((item) => item.video_url? getVideoPreview(item) : getPhotoPreview(item) )
              ) : (
                <Text1>
                  There are no files here. Save files to cloud to display them on User Portal.
                </Text1>
              )}
            </Row>
          </ContentContainer>
          {data.length > 0 && <Pagination 
              count={data.length}
              pageIndex={pageIndex}
              onChangePage={handleOnChangePage}
              pageSizes={{ value: 10, label: '10' }}
            />
          }
        </div>        
      }
    </Container>
  );
};

export default GalleryVideoCollection;
