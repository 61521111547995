import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  min-width: 32rem;
  width: 100%;
  max-width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${({ color }) => color};
  box-shadow: -2px -2px 4px rgba(37, 50, 61, 0.15);
  border-radius: 16px;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ color }) => color};
  z-index: 1;
  border-radius: 16px;
  box-shadow: -2px -2px 4px rgba(37, 50, 61, 0.15);
`;

export const ContentContainer = styled.div`
  padding: ${({ padding }) => padding};
`;
