import { take, call, put } from 'redux-saga/effects';
import { 
  PROFILE,
  profileGetSuccess,
  profileGetFail,
  profileUpdateSuccess,
  profileUpdateFail,
  emergencyUpdateSuccess,
  emergencyUpdateFail,
  incidentReportingUpdateSuccess,
  incidentReportingUpdateFail
} from '../actions/profile';
import { getInfo, updateInfo, updateEmergency, updateIncidentReporting } from '../../api/profile';
import {countriesGetStart} from '../actions/countries';

export function* handleProfileGetStart() {
  while(true) {
    yield take(PROFILE.GET_START);
    try {
      const response = yield call(getInfo);
      yield put(profileGetSuccess(response));
      yield put(countriesGetStart());
    } catch (error) {
      yield put(profileGetFail(error.toString()));
    }
  }
}

export function* handleProfileUpdateStart(){
  while(true){
    const {payload} = yield take(PROFILE.UPDATE_START);
    try {
      yield call(updateInfo,payload)
      yield put(profileUpdateSuccess(payload));
    } catch (error) {
      yield put(profileUpdateFail(error));
    }
  }
}

export function* handleProfileEmergencyUpdateStart() {
  while(true){
    const {payload} = yield take(PROFILE.EMERGENCY_UPDATE_START);
    try {
      yield call(updateEmergency, payload);
      yield put(emergencyUpdateSuccess(payload));
    } catch (error) {
      yield put(emergencyUpdateFail(error));
    }
  }
}

export function* handleProfileIncidentReportingUpdateStart() {
  while(true){
    const {payload} = yield take(PROFILE.INCIDENT_REPORTING_UPDATE_START);
    try {
      yield call(updateIncidentReporting, payload);
      yield put(incidentReportingUpdateSuccess(payload));
    } catch (error) {
      yield put(incidentReportingUpdateFail(error));
    }
  }
}