import styled from 'styled-components';

export const ContentContainer = styled.div`
  justify-content: flex-start;
  height: 53rem;
  padding: 0 1.6rem;
  position: relative;
  @media(max-width: 991px) {
    height: 58rem;
  }
`;