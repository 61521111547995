// API constants

/**
  * Base URL config
  */
let BASE_URL;
let AUTH_URL;
let DEVICE_URL;

switch (process.env.REACT_APP_STAGE) { // setup in the AWS ECS
  case "qa":
    BASE_URL = 'https://qa-api.drivesmarter.com/superadmin';
    AUTH_URL = 'https://qa-api.drivesmarter.com/auth';
    DEVICE_URL = 'https://qa-api.drivesmarter.com/device';
    break;
  case "stage":
    BASE_URL = 'https://stg-api.drivesmarter.com/superadmin';
    AUTH_URL = 'https://stg-api.drivesmarter.com/auth';
    DEVICE_URL = 'https://stg-api.drivesmarter.com/device';
    break;
  case "prod":
    BASE_URL = 'https://api.drivesmarter.com/superadmin';
    AUTH_URL = 'https://api.drivesmarter.com/auth';
    DEVICE_URL = 'https://api.drivesmarter.com/device';
    break;
  default:
    BASE_URL = 'https://dev-api.drivesmarter.com/superadmin';
    AUTH_URL = 'https://dev-api.drivesmarter.com/auth';
    DEVICE_URL = 'https://dev-api.drivesmarter.com/device';      
}
export {BASE_URL, AUTH_URL, DEVICE_URL};

// Key constants
export const USER_OBJECT_KEY = 'SESSION_USER_OBJECT';

// Default data constants
export const DEFAULT_UNKNOWN_DATA = 'Unknown';
export const DEFAULT_EMPTY_DATA = '';
export const DEFAULT_LOCATION_DATA = '';
export const DEFAULT_GEOFENCE_COORDINATES = { lat: 41.881832, lng: -87.623177 };

// Map constants
export const MAP_ZOOM_LEVEL = 17;
export const MAP_ANCHOR_SIZE = 24;
export const MAP_SCALE_SIZE = 48;
export const MAP_CIRCLE_RADIUS = 100;
export const MAP_SQUARE_DIFF = 0.0007;
export const GOOGLE_MAPS_KEY = 'AIzaSyBpF7ZresVJVBjvsJnHHlqZA0BAiAgjf4E';

// Dropdown option constants
export const TABLE_PAGE_SIZE_OPTIONS = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 25, label: '25' }
];

export const DEFAULT_CAR_IMAGE='https://keeneye-cvm-media-store-dev-qa-stage.s3.amazonaws.com/dev/default/vehicles/default-car.jpg'
