import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resolve } from 'nested-prop-resolver';
import PropTypes from "prop-types";

import EmergencyContactIcon from '../../../assets/EmergencyContactIcon';
import InsuranceDetailsIcon from '../../../assets/InsuranceDetailsIcon';
import PencilIcon from '../../../assets/PencilIcon';
import { userLogout } from '../../../redux/actions/user';
import {confirm, errorNotification} from '../../../utils/notifications';
import ProfileIcon from '../../../assets/ProfileIcon';
import {Text2, Text5, Text4} from '../../atoms/Typography';
import { Container, Card, LogoutButton, CardHeader, Title, CardBody, Overlay } from './styles';
import Button from '../../atoms/Button';
import ProfileForm from '../../molecules/ProfileForm';
import MayDayForm from '../../molecules/MayDayForm';
import IncidentReportingForm from '../../molecules/IncidentReportingForm';
import ClickWrapper from '../../atoms/ClickWrapper';
import XMark from '../../../assets/XMark';
import HBox from '../../atoms/HBox';

import { profileUpdateStart, emergencyUpdateStart, incidentReportingUpdateStart } from '../../../redux/actions/profile';
import { Visible } from 'react-grid-system';

const UserProfilePopup = ({onClose}) => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile);
  const countries = useSelector(state => state.countries.data);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showEditMayday, setShowEditMayday] = useState(false);
  const [showEditInsurance, setShowEditInsurance] = useState(false);

  const userCountryId = resolve(profile, 'data.my_information.country_id', 0);
  const userCountry = countries.filter(country => country.id === userCountryId);

  const myInformation = {
    firstName: resolve(profile, 'data.my_information.firstname', 'Not Provided'),
    lastName: resolve(profile, 'data.my_information.lastname', ''),
    mobile: resolve(profile, 'data.my_information.mobile', ''),
    email: resolve(profile, 'data.my_information.email', ''),
  };

  const emergencyContact = {
    firstName: resolve(profile, 'data.emergency_contact.firstname', 'Not Provided'),
    mobile: resolve(profile, 'data.emergency_contact.mobile', ''),
    email: resolve(profile, 'data.emergency_contact.email', '')
  };

  const insuranceDetails = {
    name: resolve(profile, 'data.first_notice.insurance_carrier', 'Not Provided'),
    mobile: resolve(profile, 'data.first_notice.insurance_mobile', ''),
    email: resolve(profile, 'data.first_notice.insurance_email', ''),
    policy: resolve(profile, 'data.first_notice.policy_number', '')
  };

  const onSaveMyInformation=(values)=>{
    const params = {
      name: values.name,      
      email: values.email,
      country_id: values.country.id,
      last_name:' '
    }
    if (values.phoneNumber){
      params.phone_number = '+' + values.phoneNumberCountry.phone_prefix.replace('-','') + values.phoneNumber;
    }
    dispatch(profileUpdateStart(params));
  }

  const onSaveEmergencyContact=(values)=>{
    let phoneNumber='';
    if (values.phoneNumber){
      phoneNumber = '+' + values.phoneNumberCountry.phone_prefix.replace('-','') + values.phoneNumber;
    }
    const params = {
      user_id: profile.data.user_id,
      data: {
        emergency_contact:{
          firstname: values.name,
          country_code: values.phoneNumberCountry.phone_prefix,
          email: values.email,
          mobile: phoneNumber,
          smsTrigger: values.phoneNumber? true : false,
          emailTrigger: values.email? true : false
        }
      }
    }
    dispatch(emergencyUpdateStart(params));
  }

  const onSaveInsurraceContact = (values) => {
    let phoneNumber='';
    if (values.phoneNumber){
      phoneNumber = '+' + values.phoneNumberCountry.phone_prefix.replace('-','') + values.phoneNumber;
    }
    const params = {
      insurance_name: values.name,
      insurance_email: values.email,
      insurance_policy: values.policy,
      insurance_phone_number: phoneNumber,
      emailTrigger: false,
      smsTrigger: false
    }
    dispatch(incidentReportingUpdateStart(params));
  }

  const onLogout = () => {
    confirm({title: "Are you sure you want to logout now?"}).then( (result) => {
      if( result.isConfirmed ) {
        dispatch(userLogout());
      }
    })
  };

  const onShowForm = (profile, mayday, insurrance) => {
    setShowEditProfile(profile);
    setShowEditMayday(mayday);
    setShowEditInsurance(insurrance);
  }

  return (
    <Container>
      <Visible sm xs>
        <HBox justify="flex-end">
          <ClickWrapper padding="1rem 1rem" onClick={onClose} >
            <XMark/>
          </ClickWrapper>
        </HBox>
      </Visible>
      <Card>
        <CardHeader>
          <Title>
            <ProfileIcon />
            <Text4>Profile</Text4>
          </Title>
          {!showEditProfile && <Button onClick={()=>onShowForm(true, false, false)} buttonType='secondary'><PencilIcon /></Button>}
        </CardHeader>
        {!showEditProfile? 
          <CardBody>
              <Text5>
                {myInformation.lastName.trim().length>0 ? `${myInformation.firstName} ${myInformation.lastName}`: myInformation.firstName}
              </Text5>
              <Text2>{myInformation.email}</Text2>
              {myInformation.mobile &&<Text2>{myInformation.mobile}</Text2>}
              {userCountry.length > 0 && <Text2>{userCountry[0].name}</Text2>}
          </CardBody>
          :
          <ProfileForm 
            countries={countries} 
            selectedCountry={userCountry[0] ? userCountry[0]: countries[0]}
            name={myInformation.lastName.trim().length>0 ? `${myInformation.firstName} ${myInformation.lastName}`: myInformation.firstName}
            email={myInformation.email}
            phoneNumber={myInformation.mobile}
            onSave={onSaveMyInformation}
            onCancel={()=>setShowEditProfile(false)}
            isLoading={profile.isLoading}
          />
        }
      </Card>
      <Card>
        <CardHeader>
          <Title>
            <EmergencyContactIcon />
            <Text4>MayDay Setup</Text4>
          </Title>
          {!showEditMayday && <Button onClick={()=>onShowForm(false, true, false)} buttonType='secondary'><PencilIcon /></Button>}
        </CardHeader>
        {!showEditMayday? 
          <CardBody>
              <Text5>{emergencyContact.firstName}</Text5>
              <Text2>{emergencyContact.email}</Text2>
              <Text2>{emergencyContact.mobile}</Text2>
          </CardBody> 
          :
          <MayDayForm 
            countries={countries} 
            name={emergencyContact.firstName}
            email={emergencyContact.email}
            phoneNumber={emergencyContact.mobile}
            onSave={onSaveEmergencyContact}
            onCancel={()=>setShowEditMayday(false)}
            isLoading={profile.isLoading}
          />
        } 
      </Card>
      <Card>
        <CardHeader>
          <Title>
            <InsuranceDetailsIcon />
            <Text4>Incident Reporting</Text4>
          </Title>
          {!showEditInsurance && <Button onClick={()=>onShowForm(false, false, true)} buttonType='secondary'><PencilIcon /></Button>}
        </CardHeader>
        {!showEditInsurance? 
          <CardBody>
            <Text5>{insuranceDetails.name}</Text5>
            <Text2>{insuranceDetails.email}</Text2>
            <Text2>{insuranceDetails.mobile}</Text2>
            <Text2>{insuranceDetails.policy}</Text2>
          </CardBody>
        :
          <IncidentReportingForm 
            countries={countries} 
            name={insuranceDetails.name}
            email={insuranceDetails.email}
            phoneNumber={insuranceDetails.mobile}
            policy={insuranceDetails.policy}
            onSave={onSaveInsurraceContact}
            onCancel={()=>setShowEditInsurance(false)}
            isLoading={profile.isLoading}
          />
        }
      </Card>
      
      <LogoutButton onClick={onLogout}>
        <Button onClick={onLogout} buttonType='danger2'>Logout</Button>
      </LogoutButton>
    </Container>
  );
};

UserProfilePopup.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default UserProfilePopup;
