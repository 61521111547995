import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Container } from './CameraDetails.styles';
import VBox from '../../atoms/VBox';
import Image from '../../atoms/Image';
import DeleteIcon from '../../../assets/DeleteIcon';
import HBox from '../../atoms/HBox';
import Loader from '../../atoms/Loader';
import { getDeviceImage } from '../../../utils/helpers';
import {Text1, Text4} from '../../atoms/Typography';
import Button from '../../atoms/Button';


const CameraDetails = ({ cameraName, serialNumber, deviceType, icon, onDelete, id }) => {
  const vehicle = useSelector((state) => state.vehicle);
  const [showDelete, setShowDelete] = useState(false);
  
  const handleClickDelete = () => {
    setShowDelete(true)
  }
  const handleClickCancelDelete = () => {
    setShowDelete(false)
  }

  return (
    <Container>
      <HBox justify='space-between' >
        <HBox align='center'>
          <Image src={getDeviceImage(icon)} width="4.8rem" height="4.8rem"  margin="0 1.6rem 0 0" />
          <VBox>
            <Text4>{cameraName}</Text4>
            <Text1 fontSize="1.2rem" color="#5C6670">{deviceType}, SN {serialNumber}</Text1>
          </VBox>
        </HBox>
        <HBox justify='flex-end' align='center'>
          {
            !showDelete ? (
              <Button buttonType="secondary" onClick={handleClickDelete}>
                <DeleteIcon/>
              </Button>
            )
            :
            (vehicle && vehicle.isLoading ? 
              <Loader margin="0rem" />
              :
              <>
                <Button buttonType="danger2" onClick={()=>onDelete(id)}>
                  Delete
                </Button>
                <Button buttonType="secondary" onClick={handleClickCancelDelete}>
                  Cancel
                </Button>
              </>  
            )
          }
        </HBox>
      </HBox> 
    </Container>
  );
};

CameraDetails.propTypes = {
  cameraName: PropTypes.string.isRequired,
  serialNumber: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default CameraDetails;
