import React from 'react';
import PropTypes from 'prop-types';

import {Text4, Text2} from "../../atoms/Typography";
import Button from "../../atoms/Button";
import HBox from '../../atoms/HBox';
import VBox from '../../atoms/VBox';
import {Container, Icon, Elipse} from "./styles";

const LiveViewDevice = ({name, icon, isConnected, onStart, onStop, isStreaming}) => {
   
    return(
        <Container active={isConnected}>
            <HBox gap='1.3rem'>
                <Icon bgImg={icon}/>
                <VBox>
                    <Text4>{name}</Text4>
                    <HBox gap="0.8rem" align="center">
                        <Elipse active={isConnected}/>
                        {isConnected? <Text2 color="#55B48E">Available</Text2> : <Text2 color="#E24444">Not Available</Text2>}
                    </HBox>
                </VBox>
            </HBox>
            {isConnected && !isStreaming && <Button buttonType='secondary' onClick={onStart}>Start Live View</Button>}
            {isConnected && isStreaming && <Button buttonType='danger2' onClick={onStop}>Stop Live View</Button>}
        </Container>
    );
}

LiveViewDevice.propTypes = {
    name: PropTypes.string.isRequired,
    isConnected: PropTypes.bool,
    icon: PropTypes.string,
    onStart: PropTypes.func.isRequired,
    onStop: PropTypes.func.isRequired,
    isStreaming: PropTypes.bool
};

LiveViewDevice.defaultProps = {
    isConnected: false,
    icon: '/images/DashcamIcon.png',
    isStreaming: false
};

export default LiveViewDevice;