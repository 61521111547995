import { LOCATION } from '../actions/location';
import { DEFAULT_GEOFENCE_COORDINATES } from '../../constants';

const initialState = {
  data: DEFAULT_GEOFENCE_COORDINATES,
  isLoading: false,
  error: null
};

const locationReducer = (state = initialState, action) => {
  switch(action.type) {
  case LOCATION.GET_START:
    return { data: DEFAULT_GEOFENCE_COORDINATES, isLoading: true, error: null };
  case LOCATION.GET_SUCCESS:
    return { data: action.payload, isLoading: false, error: null };
  case LOCATION.GET_FAIL:
    return { data: DEFAULT_GEOFENCE_COORDINATES, isLoading: false, error: action.payload };
  default:
    return state;
  }
};

export default locationReducer;
