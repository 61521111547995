import React, {useState, useEffect} from 'react';
import {GoogleMap, Marker} from '@react-google-maps/api';
import PropTypes from 'prop-types';

import {ContentContainer, ListItem, Container, Button, PaginationWrapper } from './styles';
import Popup from '../../molecules/Popup';
import Card from '../../molecules/Card';
import {Text2, Text3} from '../../atoms/Typography';
import {getDateTime} from '../../../utils/timeFormatters'; 
import DeleteIcon from '../../../assets/DeleteIcon';
import MapIcon from '../../../assets/MapIcon';
import Pagination from '../../molecules/Pagination';
import Loader from '../../atoms/Loader';
import {
    MAP_ZOOM_LEVEL,
  } from '../../../constants';
const NotificationsListPopup = ({notifications, onClose, onDeleteNotification, onDeleteAllNotifications, isLoading}) => {
    const [pageIndex, setPageIndex] = useState(1);
    const [pageItems, setPageItems] = useState([]);
    const [activeNotificationId, setActiveNotificationId] = useState(null);


    useEffect(() => {
        setPageItems(notifications.slice(0,5))
    }, [notifications]);


    const handleMapClick = (notificationId) => {
        if(activeNotificationId === notificationId){
            setActiveNotificationId(null);
        }else{
            setActiveNotificationId(notificationId);
        }
    }

    const handleOnChangePage= (index) => {
        setPageIndex(index);
        setPageItems(notifications.slice((index-1)*5, index*5));
    }

    return (
        <Popup backgroundColor="#fff" title='' onClose={onClose}>
            <Card expand={false} height='100%' title='Notifications' button="Clear All" buttonOnClick={()=>onDeleteAllNotifications()}>
                {isLoading && 
                    <center>
                        <Loader margin="20rem"/>
                    </center>
                }
                {!isLoading &&
                    <ContentContainer>
                        {pageItems.map((notification, index) => (
                            <ListItem key={index}>
                                <Container textColor="#5C6670">
                                    <Text3>{getDateTime(notification.createdAt)}</Text3>
                                    <Button onClick={()=>onDeleteNotification(notification)}><DeleteIcon /></Button>
                                </Container>
                                <Container>
                                    <Text2>{notification.body}</Text2>
                                    <Button onClick={()=>handleMapClick(notification.id)}><MapIcon/></Button>
                                </Container>
                                {activeNotificationId === notification.id && 
                                    <GoogleMap           
                                    mapContainerStyle={{
                                        height: '37rem',
                                        width: '100%'
                                    }}
                                    center={{lat: Number(notification.latitude), lng: Number(notification.longitude)}}
                                    zoom={MAP_ZOOM_LEVEL}
                                    version="weekly">
                                        <Marker position={{lat: Number(notification.latitude), lng: Number(notification.longitude)}}/>
                                    </GoogleMap>       
                                }
                                
                            </ListItem>
                        ))}
                        {notifications.length === 0 && (
                        <ListItem>
                                <Container>
                                    <Text2>No notifications</Text2>
                                </Container>
                            </ListItem> 
                        )}                    
                    </ContentContainer>
                }
                <PaginationWrapper>
                    <Pagination 
                        count={notifications.length}
                        pageIndex={pageIndex}
                        onChangePage={handleOnChangePage}
                    />
                </PaginationWrapper>
            </Card>
        </Popup>
    );
}

NotificationsListPopup.propTypes = {
    notifications: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    onDeleteNotification: PropTypes.func.isRequired,
    onDeleteAllNotifications: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
}

NotificationsListPopup.defaultProps = {
    notifications: [],
    isLoading: false
}

export default NotificationsListPopup;