import { GEOFENCE } from '../actions/geofence';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  successMessage: null,
};

const geofenceReducer = (state = initialState, action) => {
  switch(action.type) {
  case GEOFENCE.GET_ALL_START:
    return { ...state, isLoading: true, error: null};
  case GEOFENCE.GET_ALL_SUCCESS:
    let successMessage = null;
    const prevList = state.data;
    if(action.payload[0] && prevList && prevList[0] ){
      prevList.sort((a,b) => a.id < b.id ? 1: -1)
      if(action.payload[0].id !== prevList[0].id && prevList.length < action.payload.length){
        action.payload = [action.payload[0], ...prevList]
        successMessage="New Geofence";
      }else{
        action.payload.sort((a,b) => a.location_name < b.location_name? -1 : 1);
      }
    }else{
      action.payload.sort((a,b) => a.location_name < b.location_name? -1 : 1);
    }
    return { data: action.payload, isLoading: false, error: null, successMessage: successMessage };
  case GEOFENCE.GET_ALL_FAIL:
    return { data: null, isLoading: false, error: action.payload};
  case GEOFENCE.CREATE_START:
    return { ...state, isLoading: true, error: null, successMessage: null };
  case GEOFENCE.CREATE_SUCCESS:
    return { ...state, isLoading: false, error: null };
  case GEOFENCE.CREATE_FAIL:
    return { ...state, isLoading: false, error: action.payload };
  case GEOFENCE.EDIT_START:
    return { ...state, isLoading: true, error: null, successMessage: null };
  case GEOFENCE.EDIT_SUCCESS:
    return { ...state, isLoading: false, error: null, successMessage: 'Geofence information saved successfully' };
  case GEOFENCE.EDIT_FAIL:
    return { ...state, isLoading: false, error: action.payload };
  case GEOFENCE.DELETE_START:
    return { ...state, isLoading: true, error: null, successMessage: null };
  case GEOFENCE.DELETE_SUCCESS:
    return { ...state, isLoading: false, error: null, successMessage: 'Geofence deleted successfully' };
  case GEOFENCE.DELETE_FAIL:
    return { ...state, isLoading: false, error: action.payload };
  case GEOFENCE.ADD_VEHICLE_START:
    return { ...state, isLoading: true, error: null, successMessage: null };
  case GEOFENCE.ADD_VEHICLE_SUCCESS:
    return { ...state, isLoading: false, error: null };
  case GEOFENCE.ADD_VEHICLE_FAIL:
    return { ...state, isLoading: false, error: action.payload };
  case GEOFENCE.REMOVE_VEHICLE_START:
    return { ...state, isLoading: true, error: null, successMessage: null };
  case GEOFENCE.REMOVE_VEHICLE_SUCCESS:
    return { ...state, isLoading: false, error: null };
  case GEOFENCE.REMOVE_VEHICLE_FAIL:
    return { ...state, isLoading: false, error: action.payload };  
  default:
    return state;
  }
};

export default geofenceReducer;
