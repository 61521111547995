export const COUNTRIES = {
  GET_START: 'COUNTRIES_GET_START',
  GET_SUCCESS: 'COUNTRIES_GET_SUCCESS',
  GET_FAIL: 'COUNTRIES_GET_FAIL'
};

export const countriesGetStart = () => {
  return {
    type: COUNTRIES.GET_START
  };
};

export const countriesGetSuccess = (payload) => {
  return {
    type: COUNTRIES.GET_SUCCESS,
    payload
  };
};

export const countriesGetFail = (payload) => {
  return {
    type: COUNTRIES.GET_FAIL,
    payload
  };
};
