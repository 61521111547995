export const PASSWORD = {
  // Fogot password
  FORGOT_START: 'PASSWORD_FORGOT_START',
  FORGOT_SUCCESS: 'PASSWORD_FORGOT_SUCCESS',
  FORGOT_FAIL: 'PASSWORD_FORGOT_FAIL',
  // Reset password
  RESET_START: 'PASSWORD_RESET_START',
  RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  RESET_FAIL: 'PASSWORD_RESET_FAIL'
};

export const forgotPasswordStart = (payload) => {
  return {
    type: PASSWORD.FORGOT_START,
    payload
  };
};

export const forgotPasswordSuccess = (payload) => {
  return {
    type: PASSWORD.FORGOT_SUCCESS,
    payload
  };
};

export const forgotPasswordFail = (payload) => {
  return {
    type: PASSWORD.FORGOT_FAIL,
    payload
  };
};

export const resetPasswordStart = (payload) => {
  return {
    type: PASSWORD.RESET_START,
    payload
  };
};

export const resetPasswordSuccess = () => {
  return {
    type: PASSWORD.RESET_SUCCESS
  };
};

export const resetPasswordFail = (payload) => {
  return {
    type: PASSWORD.RESET_FAIL,
    payload
  };
};
