import React from 'react';
import PropTypes from 'prop-types';

import {LinkElement} from './NavLink.styles';

const NavLink = ({href, target, onClick, children}) => {
    return (
        <LinkElement href={href} target={target} onClick={onClick}>
            {children}
        </LinkElement>
    );
};

NavLink.propTypes = {
    children: PropTypes.node.isRequired,
    href: PropTypes.string.isRequired,
}

NavLink.defaultProps={
    target: ""
}

export default NavLink;