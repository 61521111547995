import {STREAM} from '../actions/stream';

const initialState = {
    streamDetails: null,
    isLoading: false,
    error: null
};

const streamReducer = ( state = initialState, action) =>{
    switch(action.type) {
        case STREAM.START_START:
            return {streamDetails:null, isLoading: true, error: null};
        case STREAM.START_SUCCESS:
            return {streamDetails: action.payload, isLoading: false, error: null}
        case STREAM.START_FAIL:
            return {streamDetails: null, isLoading: false, error: action.payload}
        case STREAM.STOP_START:
            return {...state, isLoading: true, error: null}
        case STREAM.STOP_SUCCESS:
            return {streamDetails: null, isLoading: false, error: null}
        case STREAM.STOP_FAIL:
            return {...state, isLoading: false, error: action.payload}
        case STREAM.CONTINUE_START:
            return {...state, isLoading: true, error: null}
        case STREAM.CONTINUE_SUCCESS:
            return {...state, isLoading: false, error:null}
        case STREAM.CONTINUE_FAIL:
            return {...state, isLoading: false, error: action.payload}        
        default:
            return state;
    }
};

export default streamReducer;