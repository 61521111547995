import React from 'react';

const XMark = (props) => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.18836 13.0507C0.819223 13.4198 0.801645 14.079 1.19715 14.4657C1.58387 14.8524 2.24305 14.8436 2.61219 14.4745L7.9911 9.0868L13.3788 14.4745C13.7567 14.8524 14.4071 14.8524 14.7938 14.4657C15.1718 14.0702 15.1806 13.4286 14.7938 13.0507L9.41493 7.66297L14.7938 2.28407C15.1806 1.90614 15.1806 1.25575 14.7938 0.869028C14.3983 0.491098 13.7567 0.482309 13.3788 0.860239L7.9911 6.24793L2.61219 0.860239C2.24305 0.491098 1.57508 0.47352 1.19715 0.869028C0.810434 1.25575 0.819223 1.91493 1.18836 2.28407L6.57606 7.66297L1.18836 13.0507Z" fill={props.color?? "black"}/>
    </svg>
  );
};

export default XMark;