import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const defaultOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' }
];

const Dropdown = ({ selection, fontSize, fontWeight, width, color, backgroundColor, margin, options, disabled, borderBottom, borderRadius, optionBorderTop, optionBackground, optionPadding, handleSelect, defaultValue }) => {

  // custom styles for dropdown
  const background = disabled ? '#303030' : backgroundColor;
  const colourStyles = {
    menu: styles => ({ ...styles, backgroundColor: background , width: width, margin: margin}),
    menuList: styles => ({...styles, padding: '0'}),
    control: styles => ({ ...styles, backgroundColor: background , width: width, border: '0', margin: margin, borderBottom:borderBottom, borderRadius: borderRadius, padding: '0.8rem' }),
    option: styles => ({ ...styles, padding: '1.6rem', backgroundColor: background , width: width, color: color, fontFamily: 'Arimo', fontSize: fontSize, fontWeight: fontWeight, borderTop: optionBorderTop, background: optionBackground }),
    input: styles => ({...styles, fontSize:fontSize, color:color}),
    placeholder: styles => ({...styles, fontSize:fontSize}),
    noOptionsMessage: styles => ({...styles, fontSize:fontSize}),
    singleValue: styles => ({ ...styles, color: color, fontFamily: 'Arimo', fontSize: fontSize, fontWeight: fontWeight }),
    dropdownIndicator: styles => ({ ...styles, color: color, padding: '0' }),
    indicatorSeparator: () => null
  };
  if(defaultValue){
    return (
      <Select
        styles={colourStyles}
        value={selection}
        onChange={handleSelect}
        options={options}
        isDisabled={disabled}
        defaultValue={defaultValue}
      />
    );
  } else {
    return (
      <Select
        styles={colourStyles}
        value={selection}
        onChange={handleSelect}
        options={options}
        isDisabled={disabled}
      />
    );
  }
};

Dropdown.propTypes = {
  fontSize: PropTypes.string,
  fontWeight: PropTypes.number,
  width: PropTypes.string, 
  color: PropTypes.string, 
  backgroundColor: PropTypes.string, 
  margin: PropTypes.string, 
  options: PropTypes.array.isRequired, 
  disabled: PropTypes.bool, 
  selection: PropTypes.object,
  handleSelect: PropTypes.func.isRequired,
  defaultValue: PropTypes.object,
  optionPadding: PropTypes.string
};

Dropdown.defaultProps = {
  fontSize: '1.6rem',
  fontWeight: 400,
  width: '15rem', 
  color: '#363F45',
  backgroundColor: '#FFF', 
  margin: '0',
  options: defaultOptions, 
  disabled: false,
  borderBottom: '',
  borderRadius: '0',
  optionPadding: '0.5rem'
};

export default Dropdown;
