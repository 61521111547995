import React from 'react';
import PropTypes from 'prop-types';

const MapIcon = ({ color }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.9668 22.1797C6.25684 22.1797 6.52051 22.083 6.87207 21.8809L11.1436 19.5781L15.8018 22.1885C16.1709 22.3906 16.5576 22.4873 16.9355 22.4873C17.3047 22.4873 17.665 22.3994 17.9902 22.2061L22.3584 19.7451C22.9648 19.4023 23.2285 18.9189 23.2285 18.251V6.44727C23.2285 5.7002 22.7803 5.25195 22.042 5.25195C21.752 5.25195 21.4883 5.36621 21.1279 5.55957L16.6982 8.02051L12.0928 5.24316C11.7588 5.0498 11.3984 4.95312 11.0293 4.95312C10.6689 4.95312 10.3086 5.04102 9.99219 5.22559L5.65039 7.69531C5.04395 8.03809 4.77148 8.50391 4.77148 9.17188V20.9932C4.77148 21.7402 5.21973 22.1797 5.9668 22.1797ZM10.3525 18.0049L6.74023 19.9912C6.67871 20.0176 6.62598 20.0439 6.58203 20.0439C6.47656 20.0439 6.42383 19.9648 6.42383 19.8418V9.62891C6.42383 9.38281 6.51172 9.22461 6.74023 9.09277L10.0625 7.16797C10.1592 7.10645 10.2471 7.0625 10.3525 7.00977V18.0049ZM12.0225 18.0488V7.2207C12.1104 7.26465 12.207 7.31738 12.2949 7.37012L15.9775 9.59375V20.2637C15.8633 20.2021 15.749 20.1406 15.626 20.0703L12.0225 18.0488ZM17.6562 20.3867V9.42676L21.2686 7.45801C21.3125 7.43164 21.3652 7.41406 21.4092 7.41406C21.5146 7.41406 21.585 7.49316 21.585 7.60742V17.7676C21.585 18.0225 21.4971 18.1807 21.2598 18.3125L18.0605 20.167C17.9287 20.2461 17.7881 20.3252 17.6562 20.3867Z" fill="#0071A4"/>
    </svg>
  );
};

MapIcon.propTypes = {
  color: PropTypes.string
};

MapIcon.defaultProps = {
  color: 'white'
};

export default MapIcon;
