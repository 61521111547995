import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-grid-system';

import Card from '../../molecules/Card';
import {Text2} from '../../atoms/Typography';
import Pagination from '../../molecules/Pagination';
import { truncateName } from '../../../utils/helpers';
import {ListHeader,ListItem} from './styles';

const ActiveGeofences = ({geofences, onGeofenceSelect}) => {
    const [pageIndex, setPageIndex] = useState(1);
    const [pageItems, setPageItems] = useState([]);
    const [selectedGeofenceId, setSelectedGeofenceId] = useState(0);

    useEffect(() => {
        setPageItems(geofences.slice(0,5))
        if(selectedGeofenceId === 0 && geofences.length>0){
            setSelectedGeofenceId(geofences[0].id);
        }
    }, [geofences]);

    const handleOnChangePage= (index) => {
        setPageIndex(index);
        setPageItems(geofences.slice((index-1)*5, index*5));
    }

    const handleGeofenceSelect = (geofence) => {
        setSelectedGeofenceId(geofence.id);
        onGeofenceSelect(geofence);
    }

    return (
        <Card height='46rem' title='Active Geofences'>
            <ListHeader>
                <Row>
                    <Col xs={5}><Text2>Geofence</Text2></Col>
                    <Col xs={7}><Text2>Location</Text2></Col>
                </Row>
            </ListHeader>
            {pageItems.length>0? 
                (pageItems.map((geofence, index)=>(
                    <ListItem key={index} active={geofence.id === selectedGeofenceId} onClick={() => handleGeofenceSelect(geofence)}>
                        <Row>
                            <Col style={{overflowWrap: "break-word"}} xs={5}><Text2>{truncateName(geofence.location_name)}</Text2></Col>
                            <Col xs={7}><Text2>{geofence.shape.geofence_center_point[0]}, {geofence.shape.geofence_center_point[1]}</Text2></Col>
                        </Row>
                    </ListItem>
                )))
                :
                (   
                    <ListItem>
                        <Row>
                            <Col xs={12}><Text2>No Geofences</Text2></Col>
                        </Row>
                    </ListItem>
                )
            }
            <Pagination 
                count={geofences.length}
                pageIndex={pageIndex}
                onChangePage={handleOnChangePage}
            />
        </Card>
    );
}

ActiveGeofences.propTypes = {
    geofences: PropTypes.array.isRequired,
    onGeofenceSelect: PropTypes.func.isRequired
}

ActiveGeofences.defaultProps = {
    geofences: []
}

export default ActiveGeofences;