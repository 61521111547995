import styled, {css} from "styled-components";

export const Container = styled.div`
    display:flex;
    height: 4.4rem;
    padding: 1rem 2.4rem;
    gap: 2.4rem;
    align-items:center;
    ${({active})=> active && css`
        border-radius: 34px;
        border: 2px solid #0071A4;
        background: #FFF;
    `}
`;

export const Icon = styled.div`
    width: 4.4rem;
    height: 4.4rem;
    background-image: url("${({bgImg}) => bgImg }");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
`;

export const Elipse = styled.div`
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 0.8rem;
    background: ${({active})=> active? "#55B48E" : "#E24444"}
`;