import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Container, Row, Col} from 'react-grid-system';

import { MenuContainer, BrowseContainer, TabContainer } from './styles';
import HBox from '../../atoms/HBox';
import VBox from '../../atoms/VBox';
import {Text3, Text2} from '../../atoms/Typography';
import Button from '../../atoms/Button';
import TextInput from '../../molecules/TextInput';
import Popup from '../../molecules/Popup';
import BrowseImage from '../../molecules/BrowseImage';
import CameraDetails from '../../molecules/CameraDetails';
import VehiclePopupTabMenu from '../VehiclePopupTabMenu';
import { resolve } from 'nested-prop-resolver';
import {errorNotification} from '../../../utils/notifications';
import DeleteIcon from '../../../assets/DeleteIcon';
import {vehicleDeleteDeviceStart} from '../../../redux/actions/vehicle';

const DEFAULT_CAR_IMAGE = 'https://keeneye-cvm-media-store-dev-qa-stage.s3.amazonaws.com/dev/default/vehicles/default-car.jpg'; 

const AddEditPopup = ({ isEdit, data, onCancel, onSave, onDelete }) => {
  const dispatch = useDispatch();
  
  const [selectedTab, setSelectedTab] = useState('Details');
  const [formData, setFormData] = useState({}); 
  const [formErrors, setFormErrors] = useState({alias: ''});
  const [imageUrl, setImageUrl] = useState('');
  const vehicle = useSelector((state) => state.vehicle);
  const profile = useSelector(state=>state.profile.data);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  useEffect(() => {
    const uploadedImageUrl = vehicle.uploadResponse && vehicle.uploadResponse.url + '/' + vehicle.uploadResponse.fields.key;
    setImageUrl(vehicle.uploadResponse ? uploadedImageUrl : data.image_url ? data.image_url : '');
    if(!_.isEmpty(formData)) {
      setFormData({
        ...formData,
        image_url: imageUrl
      });
    }    
  }, [vehicle]);

  const onSelectMenuTab = (name) => {
    setSelectedTab(name);
  };

  const onChangeText = (key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  const onImageSelect = (e) => {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if(!validImageTypes.includes(e.target.files[0].type)){
      errorNotification(
        {
          title: "File type is not valid",
          text:'Only jpg, jpeg or png supported'
        })
      return false;
    }
    setFormData({
      ...formData,
      image: e.target.files[0]
    });
    setImageUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleOnSave = () => {

    if(!formData.alias || formData.alias.length === 0){
      setFormErrors({
        ...formErrors,
        alias: 'Field is required'
      });
      return false;
    }
    const data = new FormData();   
    data.append('vehicle_id', resolve(formData, 'vehicle_id', ''));
    data.append('reg_no', resolve(formData, 'reg_no', ''));
    data.append('alias', resolve(formData, 'alias', ''));
    data.append('vehicle_make', resolve(formData, 'vehicle_make', ''));
    data.append('vehicle_model', resolve(formData, 'vehicle_model', ''));
    data.append('year', resolve(formData, 'year', ''));
    data.append('color', resolve(formData, 'color', ''));
    if(formData.image){
      data.append('image', formData.image);
    }
    onSave(data);
  };

  const handleOnDeleteDevice = (id) => {
    dispatch(vehicleDeleteDeviceStart({id: id, user_cog_id: profile.user_id }));
  }

  return (
    <Popup 
      title={`${isEdit ? 'Edit' : 'Add'} Vehicle`} 
      onClose = {onCancel}
      background="#F4F5F6"
      width="82rem"
    >
      <VBox gap="3.2rem" margin="3.2rem 0 0 0">
        <MenuContainer>
          <VehiclePopupTabMenu isEdit={isEdit} onSelectTab={onSelectMenuTab} />
        </MenuContainer>
        {selectedTab === 'Details' ? (
          <Container component={TabContainer} fluid>
            <Row>
              <Col md={7}>
                <Row>
                  <Col>
                    <TextInput 
                      label="Vehicle Name"
                      required={true}
                      value={formData.alias? formData.alias : '' } 
                      error={formErrors.alias}  
                      onChange={txt => onChangeText('alias', txt)}
                    />
                  </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <TextInput 
                        label="Make"
                        value={formData.vehicle_make? formData.vehicle_make : ''} 
                        onChange={txt => onChangeText('vehicle_make', txt)}
                        margin="1.9rem 0 0 0"
                      />
                    </Col>
                    <Col sm={6}>
                      <TextInput 
                        label="Model"
                        value={formData.vehicle_model? formData.vehicle_model : ''} 
                        onChange={txt => onChangeText('vehicle_model', txt)}
                        margin="1.9rem 0 0 0"
                      />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                      <TextInput 
                        label="Year"
                        value={formData.year? formData.year : ''} 
                        onChange={txt => onChangeText('year', txt)}
                        margin="1.9rem 0 0 0"
                      />
                    </Col>
                    <Col sm={6}>
                      <TextInput 
                        label="Color"
                        value={formData.color? formData.color : ''} 
                        onChange={txt => onChangeText('color', txt)}
                        margin="1.9rem 0 0 0"
                      />
                    </Col>
                </Row>
              </Col>
              <Col md={5}>
                <BrowseContainer>
                  <BrowseImage onImageSelect={onImageSelect} src={imageUrl} hasImage={DEFAULT_CAR_IMAGE!==imageUrl && imageUrl!==''}/>
                </BrowseContainer>
                {DEFAULT_CAR_IMAGE!==imageUrl && imageUrl!=='' &&
                  <HBox justify="center">
                    <Text3 color="#5C6670">Click on image to replace it</Text3>
                  </HBox>
                }
              </Col>
            </Row>
          </Container>
        ) : (
          <Container component={TabContainer} fluid>
            {formData.dashcams.length>0 ? 
              formData.dashcams.map(
                (item) => (
                  <CameraDetails 
                    icon={item.icon_url} 
                    cameraName={item.model_name} 
                    serialNumber={item.cam_id} 
                    deviceType={item.device_type}
                    key={item.id}
                    id={item.id}
                    onDelete={handleOnDeleteDevice} />
                  )
              )
          :
            (<Text2>No devices</Text2>)
          }
          </Container>
        )}
        <HBox justify={isEdit? "space-between": "flex-end"}>
          {isEdit && <Button onClick={onDelete} buttonType="secondary"><DeleteIcon/></Button>}
          <HBox gap="1rem">
                <Button onClick={onCancel} buttonType="secondary">Cancel</Button>
                <Button onClick={handleOnSave} buttonType="primary">Save</Button>
          </HBox>
        </HBox>
      </VBox>
    </Popup>
  );
};

AddEditPopup.propTypes = {
  isEdit: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func
};

AddEditPopup.defaultProps = {
  isEdit: false
};

export default AddEditPopup;
