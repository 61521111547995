import React from 'react';
import PropTypes from 'prop-types';
import { Container, ContentContainer, TopBar, ChildContainer } from './Popup.styles';
import Text from '../../atoms/Text';
import ClickWrapper from '../../atoms/ClickWrapper';
import XMark from '../../../assets/XMark';
import VBox from '../../atoms/VBox';
import {H1} from "../../atoms/Typography";

const Popup = ({ title, width, height, backgroundColor, margin, children, onClose, subtitle }) => {
  return (
    <Container >
      <ContentContainer width={width} height={height} backgroundColor={backgroundColor} margin={margin}>
        <TopBar >
          <VBox justify='flex-start'>
            <H1 fontSize="2.4rem"><strong>{title}</strong></H1>
            {subtitle && <Text fontSize="1.4rem" color="#CECECE" margin="0 0 0 1.7rem">{subtitle}</Text> }
          </VBox>
          {onClose && 
            <ClickWrapper padding="1rem 1rem" onClick={onClose} >
              <XMark/>
            </ClickWrapper>
          }
        </TopBar>
        <ChildContainer>
          {children}
        </ChildContainer>
      </ContentContainer>
    </Container>
  );
};

Popup.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  margin: PropTypes.string,
  children: PropTypes.node
};

Popup.defaultProps = {
  width: '80rem',
  backgroundColor: '#F4F5F6',
};

export default Popup;
