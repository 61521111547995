import React from 'react'
import PropTypes from 'prop-types';

const DownloadIcon = ({color}) => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.31543 19.1816H12.6846C14.5742 19.1816 15.5498 18.1885 15.5498 16.29V8.30957C15.5498 7.0791 15.3916 6.5166 14.627 5.73438L10.0303 1.06738C9.2832 0.311523 8.66797 0.135742 7.56055 0.135742H3.31543C1.43457 0.135742 0.450195 1.12891 0.450195 3.03613V16.29C0.450195 18.1885 1.43457 19.1816 3.31543 19.1816ZM3.46484 17.4238C2.62109 17.4238 2.19922 16.9844 2.19922 16.1758V3.1416C2.19922 2.3418 2.62109 1.89355 3.47363 1.89355H7.2002V6.6748C7.2002 7.94922 7.82422 8.56445 9.08984 8.56445H13.8008V16.1758C13.8008 16.9844 13.3789 17.4238 12.5264 17.4238H3.46484ZM9.25684 7.02637C8.8877 7.02637 8.72949 6.86816 8.72949 6.50781V2.12207L13.5635 7.02637H9.25684ZM8.77344 10.2783C8.77344 9.84766 8.42188 9.53125 8 9.53125C7.57812 9.53125 7.22656 9.84766 7.22656 10.2783V12.9326L7.29688 14.2861L6.62012 13.583L5.96094 12.9062C5.8291 12.7656 5.62695 12.6689 5.44238 12.6689C5.03809 12.6689 4.73926 12.959 4.73926 13.3457C4.73926 13.5654 4.82715 13.7324 4.99414 13.8818L7.41113 16.0967C7.61328 16.2812 7.78027 16.3691 8 16.3691C8.21973 16.3691 8.38672 16.2812 8.58887 16.0967L11.0059 13.8818C11.1729 13.7324 11.2607 13.5654 11.2607 13.3457C11.2607 12.959 10.9619 12.6689 10.5576 12.6689C10.373 12.6689 10.1709 12.7656 10.0391 12.9062L9.37988 13.583L8.70312 14.2861L8.77344 12.9326V10.2783Z" fill={color}/>
      <path d="M3.31543 19.1816H12.6846C14.5742 19.1816 15.5498 18.1885 15.5498 16.29V8.30957C15.5498 7.0791 15.3916 6.5166 14.627 5.73438L10.0303 1.06738C9.2832 0.311523 8.66797 0.135742 7.56055 0.135742H3.31543C1.43457 0.135742 0.450195 1.12891 0.450195 3.03613V16.29C0.450195 18.1885 1.43457 19.1816 3.31543 19.1816ZM3.46484 17.4238C2.62109 17.4238 2.19922 16.9844 2.19922 16.1758V3.1416C2.19922 2.3418 2.62109 1.89355 3.47363 1.89355H7.2002V6.6748C7.2002 7.94922 7.82422 8.56445 9.08984 8.56445H13.8008V16.1758C13.8008 16.9844 13.3789 17.4238 12.5264 17.4238H3.46484ZM9.25684 7.02637C8.8877 7.02637 8.72949 6.86816 8.72949 6.50781V2.12207L13.5635 7.02637H9.25684ZM8.77344 10.2783C8.77344 9.84766 8.42188 9.53125 8 9.53125C7.57812 9.53125 7.22656 9.84766 7.22656 10.2783V12.9326L7.29688 14.2861L6.62012 13.583L5.96094 12.9062C5.8291 12.7656 5.62695 12.6689 5.44238 12.6689C5.03809 12.6689 4.73926 12.959 4.73926 13.3457C4.73926 13.5654 4.82715 13.7324 4.99414 13.8818L7.41113 16.0967C7.61328 16.2812 7.78027 16.3691 8 16.3691C8.21973 16.3691 8.38672 16.2812 8.58887 16.0967L11.0059 13.8818C11.1729 13.7324 11.2607 13.5654 11.2607 13.3457C11.2607 12.959 10.9619 12.6689 10.5576 12.6689C10.373 12.6689 10.1709 12.7656 10.0391 12.9062L9.37988 13.583L8.70312 14.2861L8.77344 12.9326V10.2783Z" fill="black" fillOpacity="0.2"/>
    </svg>


  )
}

DownloadIcon.propTypes = {
  color: PropTypes.string
}

DownloadIcon.defaultProps = {
  color: "#363F45"
}

export default DownloadIcon
