import {take, call, put} from 'redux-saga/effects';

import {
    ACTIVATION,
    activationSuccess,
    activationFail,
    resendActivationLinkSuccess,
    resendActivationLinkFail
} from '../actions/accountActivation';
import {activateAccount, resendActivationLink} from '../../api/activationAccount';


export function* handleActivation() {
    while(true){
        const {payload} = yield take(ACTIVATION.START);
        try {
            const response = yield call (activateAccount, payload);
            yield put(activationSuccess(response))
        } catch (error) {
            yield put(activationFail(error));
        }
    }
}

export function* handleResendActivationLink() {
    while(true){
        const {payload} = yield take(ACTIVATION.RESEND_START);
        try {
            const response = yield call (resendActivationLink, payload);
            yield put(resendActivationLinkSuccess(response))
        } catch (error) {
            yield put(resendActivationLinkFail(error));
        }
    }
}