import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex: 1 0 0;
  border-bottom: ${({ border }) => border};
`;
