import { combineReducers } from 'redux';
import userReducer from './user';
import passwordReducer from './password';
import profileReducer from './profile';
import vehicleReducer from './vehicle';
import geofenceReducer from './geofence';
import videoReducer from './video';
import incidentReducer from './incident';
import locationReducer from './location';
import notificationReducer from './notification';
import activationReducer from './activationAccount';
import countriesReducer from './countries';
import streamReducer from './stream';
import tripnodeReducer from './tripnode';
import tripReducer from './trip';
import accountCleanupReducer from './accountCleanup';
import accountDeletionReducer from './accountDeletion';

const appReducer = combineReducers({
  user: userReducer,
  password: passwordReducer,
  profile: profileReducer,
  vehicle: vehicleReducer,
  geofence: geofenceReducer,
  video: videoReducer,
  incident: incidentReducer,
  location: locationReducer,
  notification: notificationReducer,
  activation: activationReducer,
  countries: countriesReducer,
  stream: streamReducer,
  tripnode: tripnodeReducer,
  trip: tripReducer,
  accountCleanup: accountCleanupReducer,
  accountDeletion: accountDeletionReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action);
}

export default rootReducer;
