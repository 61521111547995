import styled, {css} from 'styled-components';

export const ListHeader = styled.div`
    padding: 0.8rem 1.2rem;
`;

export const ListItem = styled.div`
    background: #F4F5F6;
    border-radius: 4px;
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.25);
    margin-bottom: 8px;
    padding: 0.8rem 1.2rem;
    border: 1px solid #F4F5F6;
    ${({active})=> active && css`
        border: 1px solid #0071A4;
    `};
    &:hover{
        border: 1px solid #0071A4;
        cursor:pointer;
    }
`;