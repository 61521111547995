import React from "react";
import { BlobProvider } from '@react-pdf/renderer';
import DownloadIcon from "../../../assets/DownloadIcon";
import PropTypes from "prop-types";
import PDFIncidentReport from "../../organisms/PDFIncidentReport";

const PDFLink = ({report, children}) => {
    return (
        <BlobProvider document={<PDFIncidentReport report={report}/>}>
        {({ url, ...rest }) => {
          return (
            <a href={url} target="_blank">
                {children}
            </a>
          );
        }}
      </BlobProvider>
    )
}

PDFLink.propTypes = {
    report: PropTypes.object.isRequired
}

export default PDFLink;