import DashcamIcon from '../assets/DashcamIcon.png';
import DetectorIcon from '../assets/DetectorIcon.png';

export const getRectangleCoordinates = (mapCenter, diff) => {
  const coords = {
    north: mapCenter.lat + diff,
    south: mapCenter.lat - diff,
    east: mapCenter.lng + diff,
    west: mapCenter.lng - diff
  };

  return coords;
};

export const getPolygonCoordinate = (mapCenter, radius, sides) => {
  const coords = [];
  const step  = 2 * Math.PI / sides;
  const shift = (Math.PI / 180.0) * -18;

  for (var i = 0; i <= sides; i++) {
    const curStep = i * step + shift;

    coords.push({
      lat: mapCenter.lat + radius * Math.sin(curStep),
      lng: mapCenter.lng + radius * Math.cos(curStep)
    });
  }

  return coords;
};

export const getCapitalizeText = (string) => {
  if (string.length > 1) {
    return string[0].toUpperCase() + string.slice(1);
  }
  return string[0].toUpperCase();
};

export const generateGeoJsonCircle = (center, radius, numSides) => {
  let points = [],
  degreeStep = 360 / numSides;

  for(let i = 0; i < numSides; i++){
    var gpos = google.maps.geometry.spherical.computeOffset(center, radius, degreeStep * i);
    points.push([gpos.lat(), gpos.lng()]);
  };

  // Duplicate the last point to close the geojson ring
  points.push(points[0]);

  return  points;
}

export const getDeviceImage = (url, deviceType) => {
  if(url){
      return url;
  }else{
      if(deviceType === 'RADAR'){
          return DetectorIcon;
      }else{
          return DashcamIcon;
      }
  }
}

export const getDirection = (angle) => {
  const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
  const index = Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 45) % 8;
  return directions[index];
}

export const getGsensor = (value) => {
  if(typeof value !== 'string'){
    return value;
  }
  let gValues = value.split(" ");
  if(gValues.length != 3){
    gValues = value.split(" ");
  } 
  return (Math.sqrt(gValues[0] * gValues[0] + gValues[1] * gValues[1] + gValues[2] * gValues[2])).toFixed(2);
}

export const truncateName = (value, length=30) => {
  if(value.length > length) {
    return value.slice(0, length)+'...';
  }
  return value;
}