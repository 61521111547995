import styled, {css} from 'styled-components';

export const Loader = styled.span`    
    display: inline-block;
    border-radius: 50%;
    width: 1.8rem;
    height: 1.8rem;
    margin-right:0.5rem;
    ${({buttonType}) => (buttonType === 'primary') && css`
        border: 0.2rem solid #ffffff;
        border-top: 0.2rem solid #005880;
    `};
    ${({buttonType}) => (buttonType === 'secondary') && css`
        border: 0.2rem solid #ffffff;
        border-top: 0.2rem solid rgba(0, 113, 164, 0.1);
    `};
    ${({buttonType}) => (buttonType === 'danger') && css`
        border: 0.2rem solid #ffffff;
        border-top: 0.2rem solid #A92734;
    `};    
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}`

export const ButtonElement = styled.button`
    cursor: pointer;
    display: inline-flex;
    align-items: center; 
    justify-content: center;
    border: 0;
    padding: 1.2rem 2rem;
    font-size: 1.6rem;
    font-weight: 700;
    line-height:22px;
    ${({fullWidth}) => fullWidth && css`
        width:100%;
    `}
    ${({buttonType}) => (buttonType === 'primary') && css`
        background: #0071A4;
        color: #FFFFFF;
        border-radius: 1rem;
        &:hover{
            background: #008DCC;
        }
        &:active{
            background: #00497A;
        }
        &:focus{
            background: #005880;
            color: #FFFFFF;
        }
        &:disabled{
            background: #00497A;
        }
    `};
    ${({buttonType}) => (buttonType === 'secondary') && css`
        background: none;
        color: #0071A4;
        border-radius: 1rem;
        &:hover{
            color: #008DCC;
        }
        &:active{
            background: #B2D4E4;
            color: #FFFFFF;
        }
        &:disabled{
            background: none;
        }
    `};
    ${({buttonType}) => (buttonType === 'danger') && css`
        background: #E24444;
        color: #FFFFFF;
        border-radius: 1rem;
        &:hover{
            background: #A93232;
        }
        &:active{
            background: #A92734;
        }
        &:focus{
            background: #A92734;
            color: #FFFFFF;
        }
        &:disabled{
            background: #A92734;
        }
    `};
    ${({buttonType}) => (buttonType === 'danger2') && css`
        background: transparent;
        color: #E24444;
        padding:0.7rem;
        &:hover{
            color: #B33951;
        }    
    `};
    ${({circle}) => circle && css`
        width:4.8rem;
        height:4.8rem;
        border-radius: 3.2rem;
        box-shadow: 0px 5px 5px rgba(0, 26, 38, 0.2), 0px 9px 10px rgba(0, 40, 57, 0.14), 0px 5px 14px rgba(0, 113, 164, 0.1);
        font-size:2rem;
        padding: 12px;
        justify-content: center;
        align-items: center;
    `};
`