import React, {useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { resolve } from 'nested-prop-resolver';
import {Container, Row, Col} from "react-grid-system";
import { GoogleMap,  Circle, Rectangle, Polygon, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';

import Popup from "../../molecules/Popup";
import Pagination from '../../molecules/Pagination';
import Card from '../../molecules/Card';
import {
    MAP_ZOOM_LEVEL,
    MAP_ANCHOR_SIZE,
    GOOGLE_MAPS_KEY
  } from '../../../constants';

import {Text2} from '../../atoms/Typography';
import LocationIcon from '../../../assets/LocationIcon.png';
import {ListHeader,ListItem} from './styles';
import { truncateName } from '../../../utils/helpers';

const GeoAreas = ({geofences}) => {
    const shapeOptions = {
        strokeColor: '#007aff',
        strokeWeight: 1,
        fillColor: '#007aff',
        fillOpacity: 0.33                
    };
    return geofences.map((geofence, index) => {
        switch (geofence.shape.type) {
            case 'circle':
            return (
                <Circle
                    options={shapeOptions}
                    center={ {lat: geofence.shape.geofence_center_point[0], lng: geofence.shape.geofence_center_point[1]}}
                    radius={geofence.shape.radius}
                    key={index}
                    />
            );
            case 'rectangle':
            const rectanglePoints = {
                north: resolve(geofence.shape.points, '[0][0][0]', 0),
                east: resolve(geofence.shape.points, '[0][0][1]', 0),
                south: resolve(geofence.shape.points, '[0][1][0]', 0),
                west: resolve(geofence.shape.points, '[0][2][1]', 0)
            }
            return (
                <Rectangle
                options={shapeOptions}
                bounds={rectanglePoints}
                key={index}
                />
            );
            case 'polygon':
            const polygonPoints = resolve(geofence.shape.points, '[0]', []);
            return (
                <Polygon
                options={shapeOptions}
                path={polygonPoints.map(p => ({ lat: p[0], lng: p[1] }))}
                key={index}
                />
            );
            default:
            return null;
        }
    });
};

const GeoMarkers = ({geofences, activeGeofenceId}) => {
    const [activeMarker, setActiveMarker] = useState(null);

    useEffect(()=>{
        setActiveMarker(activeGeofenceId);
    },[activeGeofenceId])

    const handleActiveMarker = (marker) =>{
        if (marker === activeMarker){
            return;
        }
        setActiveMarker(marker);
    }
    return geofences.map((item, index) => {
        return (
            <Marker
                key={index}
                position={{lat: Number(item.shape.geofence_center_point[0]), lng: Number(item.shape.geofence_center_point[1])}}
                icon={{
                    url: LocationIcon,
                    anchor: {
                        x: MAP_ANCHOR_SIZE,
                        y: MAP_ANCHOR_SIZE
                    }
                }}
                onClick = {()=>handleActiveMarker(item.id)}               
            >
                {activeMarker === item.id ? (
                    <InfoWindow position={{lat: Number(item.shape.geofence_center_point[0]), lng: Number(item.shape.geofence_center_point[1])}} onCloseClick={() => setActiveMarker(null)}>
                        <div>{item.location_name}</div>
                    </InfoWindow>
                ) : null}
            </Marker>
        )
    })
}

const AllGeofencesPopup = ({onClose, selected}) => {
    const geofence = useSelector((state) => state.geofence);
    const geofencesList = resolve(geofence, 'data', []);

    const [pageIndex, setPageIndex] = useState(1);
    const [geofencesData, setGeofencesData] = useState([]);
    const [activeGeofence, setActiveGeofence] = useState(null);
    const [mapCenter, setMapCenter] = useState({lat:0, lng: 0});;

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_KEY
      });

    useEffect(() => {
        if (geofencesList) {
            setGeofencesData(geofencesList.slice(0, 5));
            if(selected){
                setActiveGeofence(selected);
                setMapCenter({
                    lat: selected.shape.geofence_center_point[0],
                    lng: selected.shape.geofence_center_point[1]
                });
                const index = geofencesList.findIndex(geofence => geofence.id === selected.id);
                handleOnChangePageIndex(Math.floor(index/5)+1);
            }
            if(!activeGeofence && !selected){
                setMapCenter({
                    lat: resolve(geofencesList, '[0].shape.geofence_center_point[0]', 0),
                    lng: resolve(geofencesList, '[0].shape.geofence_center_point[1]', 0),
                })
            }
        }
    }, [geofence]);

    const handleOnChangePageIndex = (index) => {
        setPageIndex(index);
        setGeofencesData(geofencesList.slice((index-1)*5, index*5));
    };

    const handleGeofenceSelect = (obj) => {
        setActiveGeofence(obj);
        setMapCenter({
            lat: obj.shape.geofence_center_point[0],
            lng: obj.shape.geofence_center_point[1]
        });
    }
    return(
        <Popup backgroundColor="#fff" title="Geofences Map" onClose={onClose} width="115.6rem">
            <Container fluid>
                <Row gutterWidth={16}>
                    <Col md={8}>
                        { isLoaded && <GoogleMap           
                            mapContainerStyle={{
                                height: '46rem',
                                width: '100%'
                            }}
                            center={mapCenter}
                            zoom={MAP_ZOOM_LEVEL-2}
                            version="weekly"
                        >   
                            <GeoAreas geofences={geofencesList}/>
                            <GeoMarkers geofences={geofencesList}  activeGeofenceId={activeGeofence? activeGeofence.id : null }/>
                        </GoogleMap>
                        }
                    </Col>
                    <Col md={4}>
                        <Card height='46rem' title=''>
                            <ListHeader>
                                <Row>
                                    <Col xs={5}><Text2>Geofence</Text2></Col>
                                    <Col xs={7}><Text2>Location</Text2></Col>
                                </Row>
                            </ListHeader>
                            {geofencesData.length>0? 
                                (geofencesData.map((geofence, index)=>(
                                    <ListItem key={index} active={activeGeofence && geofence.id === activeGeofence.id} onClick={() => handleGeofenceSelect(geofence)}>
                                        <Row>
                                            <Col style={{overflowWrap: "break-word"}} xs={5}><Text2>{truncateName(geofence.location_name, 20)}</Text2></Col>
                                            <Col xs={7}><Text2>{geofence.shape.geofence_center_point[0]}, {geofence.shape.geofence_center_point[1]}</Text2></Col>
                                        </Row>
                                    </ListItem>
                                )))
                                :
                                (   
                                    <ListItem>
                                        <Row>
                                            <Col xs={12}><Text2>No Geofences</Text2></Col>
                                        </Row>
                                    </ListItem>
                                )
                            }
                            {geofencesData.length > 0 && 
                            <Pagination 
                                count={geofencesList? geofencesList.length: 0}
                                pageIndex={pageIndex}
                                onChangePage={handleOnChangePageIndex}
                            />}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Popup>
    )
}

AllGeofencesPopup.propTypes = {
    onClose: PropTypes.func.isRequired
}

export default AllGeofencesPopup;