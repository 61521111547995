import { INCIDENT } from '../actions/incident';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  successMessage: null,
};

const incidentReducer = (state = initialState, action) => {
  switch(action.type) {
  case INCIDENT.GET_ALL_START:
    return { data: null, isLoading: true, error: null, successMessage: null };
  case INCIDENT.GET_ALL_SUCCESS:
    return { data: action.payload, isLoading: false, error: null, successMessage: null };
  case INCIDENT.GET_ALL_FAIL:
    return { data: null, isLoading: false, error: action.payload, successMessage: null };
  case INCIDENT.SEND_START:
    return { ...state, isLoading: true, error: null, successMessage: null}
  case INCIDENT.SEND_SUCCESS:
      return { ...state, isLoading: false, error: null, successMessage: 'Incident report sent successfully'}  
  case INCIDENT.SEND_FAIL:
        return { ...state, isLoading: false, error: action.payload, successMessage: null}
  case INCIDENT.DELETE_START: 
        return { ...state, isLoading: true, error: null, successMessage: null}
  case INCIDENT.DELETE_SUCCESS:
        return { ...state, isLoading: false, error: null, successMessage: 'Incident report deleted successfully'}
  case INCIDENT.DELETE_FAIL:
        return {...state, isLoading: false, error: action.payload, successMessage: null}      
  default:
    return state;
  }
};

export default incidentReducer;
