import React from 'react';
import PropTypes from 'prop-types';

const NotExpandedIcon = () => {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.50293 8.0625L7.19141 1.22461C7.41992 0.978516 7.69238 0.864258 8 0.864258C8.30762 0.864258 8.58887 0.987305 8.81738 1.22461L15.4971 8.0625C15.6904 8.25586 15.7959 8.50195 15.7959 8.79199C15.7959 9.38086 15.3389 9.83789 14.7588 9.83789C14.4775 9.83789 14.2051 9.73242 14.0029 9.52148L8.00879 3.36035L1.99707 9.52148C1.80371 9.72363 1.53125 9.83789 1.24121 9.83789C0.661133 9.83789 0.204102 9.38086 0.204102 8.79199C0.204102 8.51074 0.30957 8.25586 0.50293 8.0625Z" fill="#1C1C1E"/>
    </svg>
  );
};

export default NotExpandedIcon;
