import { USER } from '../actions/user';

const initialState = {
  data: null,
  isLoading: false,
  error: null
};

const userReducer = (state = initialState, action) => {
  switch(action.type) {
  case USER.LOGIN_START:
    return { data: null, isLoading: true, error: null };
  case USER.LOGIN_SUCCESS:
    return { data: action.payload, isLoading: false, error: null };
  case USER.LOGIN_FAIL:
    return { data: null, isLoading: false, error: action.payload };
  case USER.LOGOUT:
    return { data: null, isLoading: false, error: null };
  default:
    return state;
  }
};

export default userReducer;
