import React from 'react';
import PropTypes from 'prop-types';

import { ImageContainer } from './Image.styles';

const Image = ({ width, height, margin, borderRadius, position, src, selected, maxHeight }) => {
  return <ImageContainer selected={selected} width={width} height={height} margin={margin} borderRadius={borderRadius} position={position} src={src} maxHeight={maxHeight} />;
};

Image.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  borderRadius: PropTypes.string,
  position: PropTypes.string,
  src: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  maxHeight: PropTypes.string
};

Image.defaultProps = {
  width: 'fit-content',
  height: 'fit-content',
  margin: '0',
  borderRadius: '0',
  position: 'static',
  selected: false
};

export default Image;
