import {take, call, put} from 'redux-saga/effects';
import {
    ACCOUNT_DELETION,
    accountDeletionFail,
    accountDeletionRequestFail,
    accountDeletionRequestSuccess,
    accountDeletionSuccess
} from '../actions/accountDeletion';

import {requestAccountDeletion, accountDeletion} from '../../api/accountDeletion';

export function* handleRequestAccountDeletion() {
    while(true) {
        const {payload} = yield take(ACCOUNT_DELETION.REQUEST_START);
        try {
            const response = yield call(requestAccountDeletion, payload);
            yield put(accountDeletionRequestSuccess(response));
        } catch (error) {
            yield put(accountDeletionRequestFail(error))
        }
    }
}

export function* handleAccountDeletion() {
    while(true){
        const {payload} = yield take(ACCOUNT_DELETION.START);
        try {
            const response = yield call(accountDeletion, payload);
            yield put(accountDeletionSuccess(response));
        } catch (error) {
            yield put(accountDeletionFail(error));
        }
    }
}