import {take, call, put} from 'redux-saga/effects';
import{
    STREAM,
    streamStartSuccess,
    streamStartFail,
    streamStopSuccess,
    streamStopFail,
    streamContinueSuccess,
    streamContinueFail
} from '../actions/stream';
import {startStream, stopStream, continueStream} from '../../api/stream';

export function* handleStreamStart() {
    while(true){
        const {payload} = yield take(STREAM.START_START);
        try {
            const response = yield call(startStream, payload);
            yield put(streamStartSuccess(response));
        } catch (error) {
            yield put(streamStartFail(error));
        }
    }
}

export function* handleStreamStop(){
    while(true){
        const {payload} = yield take(STREAM.STOP_START);
        try {
            const response = yield call(stopStream, payload);
            yield put(streamStopSuccess(response));
        } catch (error) {
            yield put(streamStopFail(error));
        }
    }
}

export function* handleContinueStream(){
    while(true){
        const {payload} = yield take(STREAM.CONTINUE_START);
        try {
            const response = yield call(continueStream, payload);
            yield put(streamContinueSuccess(response));
        } catch (error) {
            yield put(streamContinueFail(error));
        }
    }
}