import React from 'react';
import PropTypes from 'prop-types';

const MenuDropDownIcon = ({ color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0001 13.2058C10.2199 13.1995 10.4208 13.1179 10.584 12.9421L15.3552 8.05793C15.4933 7.91982 15.5686 7.74404 15.5686 7.53687C15.5686 7.12252 15.2422 6.78979 14.8278 6.78979C14.627 6.78979 14.4323 6.87141 14.2879 7.0158L10.0064 11.4166L5.71233 7.0158C5.56794 6.87769 5.3796 6.78979 5.17243 6.78979C4.75809 6.78979 4.43164 7.12252 4.43164 7.53687C4.43164 7.74404 4.50698 7.91982 4.64509 8.05793L9.42257 12.9421C9.59208 13.1179 9.78041 13.2058 10.0001 13.2058Z" fill={color}/>
    </svg>
  );
};

MenuDropDownIcon.propTypes = {
  color: PropTypes.string
};

MenuDropDownIcon.defaultProps = {
  color: '#5C6670'
};

export default MenuDropDownIcon;
