import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import { resolve } from "nested-prop-resolver";
import { Document, Page, Text, View, StyleSheet, Image, Link} from "@react-pdf/renderer";
import Logo from "../../../assets/logo-black.png";
import {getDateTime} from "../../../utils/timeFormatters"
import {getDirection, getGsensor} from '../../../utils/helpers';

// Create styles
const styles = StyleSheet.create({
    page: {
      margin: 40,
      fontSize:12,
      fontFamily:'Helvetica',
    },
    section: {
        marginTop:15
    },
    logo: {
        width: 230  
    },
    title: {
        fontSize:15,
        fontFamily:'Helvetica-Bold',
        marginTop:10,
    },
    subtitle: {
        fontSize:12,
        textDecoration: 'underline',
        fontFamily:'Helvetica-Bold',
    },
    boldText:{
        fontSize:12,
        fontFamily:'Helvetica-Bold',
    },
    map:{
        width:150
    }
  });

const PDFIncidentReport = ({report}) => {
    const profile = "";
    const name = resolve(profile, 'data.my_information.firstname', 'N/A');
    const policy = resolve(report, 'user_params.insurance_data.policy_number', 'N/A');
    const carier = resolve(report, 'user_params.insurance_data.insurance_carrier', 'N/A');
    const email = resolve(report, 'user_params.insurance_data.insurance_email', 'N/A');
    const mobile = resolve(report, 'user_params.insurance_data.insurance_mobile', 'N/A');
    const latitude = resolve(report, 'event_data.0.sensor.latitude', null);
    const longitude = resolve(report, 'event_data.0.sensor.longitude', null);
    const gyro = resolve(report, 'event_data.0.sensor.gyro', null);
    const speed = resolve(report, 'event_data.0.sensor.speed', null);
    const direction = resolve(report, 'event_data.0.sensor.direction', null);
    return(
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Image style={styles.logo} src={Logo}/>
                </View>
                <View style={styles.section}>
                    <Text style={styles.title}>INCIDENT REPORT: FIRST NOTICE OF LOSS (FNOL)</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.subtitle}>INSURANCE INFORMATION</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.boldText}>Name</Text>
                    <Text>{name}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.boldText}>Account / Policy Number</Text>
                    <Text>{policy}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.boldText}>Insurance Provider Details</Text>
                    <Text>{carier}</Text>
                    <Text>{email}</Text>
                    <Text>{mobile}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.subtitle}>INCIDENT DETAILS</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.boldText}>Incident Date and Time</Text>
                    <Text>{getDateTime(report.start_at)}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.boldText}>Incident Map </Text>
                    {!latitude && !longitude?
                        <Text>N/A</Text>
                        :
                        <Image style={styles.map} src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&markers=${latitude},${longitude}&zoom=17&size=400x400&key=AIzaSyCZFe4_hE3FDPQjxtJMEqHMzHZnWsAUs7w`}/>
                    }
                </View>
                <View style={styles.section}>
                    <Text style={styles.boldText}>Incident Location</Text>
                    <Text>GPS Coordinates: {!longitude || !latitude? "N/A" : `${latitude}, ${longitude}`}</Text>
                    <Text>Near: N/A</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.boldText}>Estimated Speed and Direction</Text>
                    <Text>Speed: {!speed ? "N/A" : `${speed.value} ${speed.unit}`}</Text>
                    <Text>Direction: {direction? "N/A" : getDirection(direction)}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.boldText}>Recorded G-Sensor Value</Text>
                    <Text>{!gyro? "N/A" : `${getGsensor(gyro)} G`}</Text>
                </View>
            </Page>
        </Document>
    )
};

PDFIncidentReport.propTypes = {
    report: PropTypes.object.isRequired
}

export default PDFIncidentReport;

