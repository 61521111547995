import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';

import MenuDropDownIcon from '../../../assets/MenuDropDownIcon';
import {Text1, Text2} from '../../atoms/Typography';
import TextInput from '../../molecules/TextInput';
import HBox from '../../atoms/HBox';
import {Container, Item, SelectedItem, List, FlagContainer, ListContainer, Star} from './styles';


export const CountrySelector = ({countries, selectedCountry, onSelect}) => {
    const searchRef = useRef();
    const [activeCountry, setActiveCountry] = useState(selectedCountry);
    const [showList, setShowList] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        setSearchValue("");
        if (showList && searchRef.current) {
          searchRef.current.focus();
        }
    }, [showList]);

    const getCountries = () => {
        if (!searchValue) {
          return countries;
        }
    
        return countries.filter(
          (country) =>
            country.name.toLowerCase().startsWith(searchValue.toLowerCase())
        );
    };

    const handleSelectCountry = (country) => {
        setActiveCountry(country);
        setShowList(false);
        onSelect(country)
    }

    return(
        <div>

            <Text2 color="#5C6670">Country<Star>*</Star></Text2>            
            <Container>
                <SelectedItem onClick={()=>setShowList(!showList)}>
                    <HBox align='center' gap="0.8rem" >
                        <FlagContainer flagUrl={activeCountry.flag}/>
                        <Text1 color="#5C6670">{activeCountry.name}</Text1>
                    </HBox>  
                    <MenuDropDownIcon/>
                </SelectedItem>
                <ListContainer show={showList}>
                    <TextInput 
                        reference={searchRef}
                        value={searchValue}
                        placeholder="Search Country"
                        onChange={setSearchValue}
                        margin="0 auto"
                        width="95%"
                    />
                    <List>
                        {getCountries().map((country, key)=>
                            <Item key={key} onClick={()=>handleSelectCountry(country)}>
                                <FlagContainer flagUrl={country.flag}/>
                                <Text2 color="#5C6670">{country.name}</Text2>
                            </Item>
                        )}
                    </List>
                </ListContainer>
            </Container>
        </div>
    );
}

CountrySelector.propTypes = {
    countries: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        phone_prefix: PropTypes.string.isRequired,
        flag: PropTypes.string.isRequired
    })).isRequired,
    selectedCountry: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        phone_prefix: PropTypes.string.isRequired,
        flag: PropTypes.string.isRequired
    }).isRequired,
    onSelect: PropTypes.func.isRequired
}

export default CountrySelector;