import React from 'react';
import { useHistory } from 'react-router-dom';
import {useDispatch} from 'react-redux';

import Card from '../../molecules/Card';
import Video from '../../atoms/Video';
import Image from '../../atoms/Image';
import {Row, Col} from 'react-grid-system';
import Link from '../../atoms/Link';
import {Text2} from '../../atoms/Typography';

import { videoLoadSelected } from '../../../redux/actions/video';
import {Container, VideoContainer, ListItem} from './styles';

const HomeRecentFiles = ({videos}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleGoToGallery = () => {
        history.push('/gallery');
    }

    const handleClickVideo = (video) => {
        dispatch(videoLoadSelected(video));
        history.push('/gallery');
    }

    return(
        <Card height='35rem' title='Recent Files'>
            {videos.length > 0 ? 
                <Container>
                    <Row gutterWidth={6}>
                        {videos.map((video, index) => (
                            <Col xs={6} key={index}>                                
                                <VideoContainer onClick={()=>handleClickVideo(video)}>
                                {video.video_url ? 
                                    <Video
                                        height="100%"
                                        width="100%"
                                        src={`${video.video_url}#t=0.1`}
                                    />
                                    :
                                    <Image 
                                        height="100%"
                                        width="100%"
                                        src={`${video.trip_node[0].image_url}`}
                                    />
                                }
                                    
                                </VideoContainer>
                            </Col>
                        ))}
                    </Row>
                    <br/>
                    <Link onClick={handleGoToGallery}>Go to Gallery</Link>
                </Container>
                :
                <ListItem>
                        <Text2>No files</Text2>
                </ListItem> 
            
            }
        </Card>
    );
};

export default HomeRecentFiles;