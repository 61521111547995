import { VIDEO } from '../actions/video';

const initialState = {
  all: null,
  favorite: null,
  emergency: null,
  photos: null,
  selected: null,
  isLoadingAll: false,
  isLoadingFavorite: false,
  isLoadingEmergency: false,
  errorAll: null,
  errorFavorite: null,
  errorEmergency: null,
  deletedSuccessfuly: false
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIDEO.GET_ALL_START:
      return { ...state, all: null, isLoadingAll: true, errorAll: null, selected: null, deletedSuccessfuly: false };
    case VIDEO.GET_ALL_SUCCESS:
      if(action.payload && action.payload.all_recordings){
        action.payload.all_recordings = action.payload.all_recordings.reduce(
          (nodes, currentTrip) => {
            currentTrip.node_type.map(node => {
              // if(node.node_id){
                node.trip_id = currentTrip._id
                nodes.push(node);          
              // }
            });
            return nodes;
          },
          []
        );
        action.payload.all_recordings.sort(function(a, b) {
          return new Date(b.device_time) - new Date(a.device_time);
        });
      }else{
        action.payload.all_recordings = [];
      }
      return { ...state, all: action.payload, isLoadingAll: false, errorAll: null, deletedSuccessfuly: false };
    case VIDEO.GET_ALL_FAIL:
      return { ...state, all: null, emergency:null, favorite: null, isLoadingAll: false, errorAll: action.payload, deletedSuccessfuly: false };
    case VIDEO.LOAD_SELECTED:
      return { ...state, selected: action.payload, deletedSuccessfuly: false };
    case VIDEO.DELETE_START:
      return {...state, isLoadingAll: true, errorAll: null, deletedSuccessfuly: false}
    case VIDEO.DELETE_SUCCESS:
      return {...state, isLoadingAll: false, errorAll: null, deletedSuccessfuly: true};  
    case VIDEO.DELETE_FAIL:
      return {...state, isLoadingAll: false, errorAll: action.payload, deletedSuccessfuly: false}  
    case VIDEO.PHOTOS_GET_ALL_START: 
      return {...state, isLoadingAll: true, errorAll: null, deletedSuccessfuly: false}
    case VIDEO.PHOTOS_GET_ALL_SUCCESS:
      const all = [...state.all.all_recordings, ...action.payload];
      all.sort(function(a, b) {
        return new Date(b.device_time) - new Date(a.device_time);
      });
      return {...state, all: {all_recordings: all}, isLoadingAll: false, errorAll: false, photos: action.payload, deletedSuccessfuly: false}
    case VIDEO.PHOTOS_GET_ALL_FAIL:
      return {...state, isLoadingAll:false, errorAll: action.payload, deletedSuccessfuly: false}      
    default:
      return state;
  }
};

export default videoReducer;
