import React from 'react';

const CircleDashedIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="circle.dashed">
        <path id="circle.dashed_2" d="M13.9824 6.37695C14.7471 6.37695 15.4766 6.5 16.1621 6.71094L16.8564 5.04102C15.9512 4.7334 14.9844 4.56641 13.9824 4.56641C12.9893 4.56641 12.0137 4.7334 11.1084 5.04102L11.8115 6.71094C12.4971 6.5 13.2266 6.37695 13.9824 6.37695ZM20.4424 10.2881L22.1035 9.59375C21.2246 7.84473 19.792 6.4209 18.043 5.5332L17.3574 7.20312C18.6758 7.87988 19.7568 8.96094 20.4424 10.2881ZM10.6162 7.20312L9.92188 5.54199C8.18164 6.4209 6.74902 7.85352 5.87012 9.59375L7.54004 10.2881C8.22559 8.96973 9.29785 7.88867 10.6162 7.20312ZM21.2686 13.6543C21.2686 14.4102 21.1455 15.1484 20.9434 15.834L22.6045 16.5283C22.9121 15.623 23.0879 14.6562 23.0879 13.6543C23.0879 12.6523 22.9121 11.6855 22.6045 10.7803L20.9346 11.4746C21.1543 12.1602 21.2686 12.8984 21.2686 13.6543ZM6.73145 13.6543C6.73145 12.8984 6.8457 12.1602 7.05664 11.4834L5.36914 10.7803C5.07031 11.6855 4.90332 12.6523 4.90332 13.6543C4.90332 14.6562 5.07031 15.6143 5.37793 16.5195L7.05664 15.8252C6.8457 15.1484 6.73145 14.4102 6.73145 13.6543ZM17.3574 20.1055L18.043 21.7754C19.792 20.8877 21.2246 19.4551 22.1035 17.7148L20.4512 17.0205C19.7656 18.3477 18.6758 19.4199 17.3574 20.1055ZM7.54883 17.0205L5.87891 17.7148C6.75781 19.4551 8.18164 20.8877 9.93066 21.7666L10.6162 20.0967C9.29785 19.4111 8.22559 18.3389 7.54883 17.0205ZM13.9912 20.9229C13.2266 20.9229 12.4971 20.8086 11.8115 20.5889L11.1172 22.2676C12.0225 22.5752 12.998 22.7422 13.9912 22.7422C14.9932 22.7422 15.96 22.5752 16.8652 22.2764L16.1621 20.5977C15.4766 20.8086 14.7471 20.9229 13.9912 20.9229Z" fill="#5C6670"/>
      </g>
    </svg>
  );
};

export default CircleDashedIcon;