import styled from "styled-components";

export const Container = styled.div`
    background: #F4F5F6;  
    border-radius: 8px;
    position:relative;  
`;

export const Item = styled.div`
    display:flex;
    align-items:center;
    gap:0.8rem;
    padding: 0.8rem 1.6rem;
    cursor: pointer;
`;
export const FlagContainer = styled.div.attrs(props => ({
    style: {
      backgroundImage: `url('${props.flagUrl}')`,
    },
  }))`
    width: 2.4rem;
    height: 2.4rem;
    border-radius:2.4rem;
    background-size: cover;
    background-position: center;
    background-color: lightgray;
    background-repeat: no-repeat;
`
export const ListContainer = styled.div`
    border-radius: 8px;
    display: ${({show})=> show? 'block':'none'};
    position:absolute;
    z-index: 999;
    left:0;
    background: #F4F5F6;
    padding-top:0.8rem;
`

export const List = styled.div`
  max-height: 31rem;
  height:auto;
  overflow: auto;
  padding-top:0.8rem;
`;
