import styled from 'styled-components';

export const Container =  styled.div`
  position: absolute;
  top: 7rem;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 39.3rem;
  padding: 1.6rem;
  border-radius: 0.8rem;
  box-shadow: -2px 2px 16px 0px rgba(36, 36, 36, 0.15);
  background: #F4F5F6;
  z-index: 100;
  gap: 24px;
  @media (max-width: 768px) {
    top: 0;
    width:100%;
    left:0;
    padding:1.6rem 0;
  }
`;

export const Card = styled.div`
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 2.4rem;
  border-radius: 0.8rem;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: #FFF;
`;

export const CardHeader = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const CardBody = styled.div`
  display:flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 0 0 0 3.6rem;
  align-items:flex-start;
`;

export const Title = styled.div`
  display:flex;
  align-items:center;
  gap: 0.8rem;
`;

export const LogoutButton = styled.div`
  align-self: center;
  cursor: pointer;
`;