import {take, call, put} from 'redux-saga/effects';
import {
    NOTIFICATION,
    notificationDeleteFail,
    notificationDeleteSuccess,
    notificationGetAllFail,
    notificationGetAllSuccess,
    notificationGetAllStart,
    notificationDeleteAllSuccess,
    notificationDeleteAllFail
} from '../actions/notification';

import { 
    getAllNotifications, 
    deleteNotification,
    deleteAllNotifications
 } from '../../api/notification';

export function* handleNotificationGetStart() {
    while(true) {
        const { payload } = yield take(NOTIFICATION.GET_ALL_START);
        try {
            const response = yield call(getAllNotifications, payload);
            yield put(notificationGetAllSuccess(response));
        } catch (error) {
            yield put(notificationGetAllFail(error.toString()));
        }
    }
}

export function* handleNotificationDeleteStart() {
    while(true) { 
        const { payload } = yield take(NOTIFICATION.DELETE_START)
        try {
            yield call(deleteNotification, payload);
            yield put(notificationDeleteSuccess(payload));
            yield put(notificationGetAllStart({ vehicleId : payload.vehicleId}));
        } catch (error) {
            yield put(notificationDeleteFail(error.toString));
        }
    }
}

export function* handleNotificationDeleteAllStart() {
    while(true) {
        const {payload} = yield take(NOTIFICATION.DELETE_ALL_START);
        try {
            yield call(deleteAllNotifications, payload);
            yield put(notificationDeleteAllSuccess(payload));
            yield put(notificationGetAllStart({vehicleId: payload.vehicleId}))
        } catch (error) {
            yield put(notificationDeleteAllFail(error.toString));
        }
    }
}