import { GEOFENCE } from "./geofence";

export const NOTIFICATION = {
    //Get notifications
    GET_ALL_START: 'NOTIFICATION_GET_ALL',
    GET_ALL_SUCCESS: 'NOTIFICATION_GET_ALL_SUCCESS',
    GET_ALL_FAIL: 'NOTIFICATION_GET_ALL_FAIL',
    //Delete notifications
    DELETE_START: 'NOTIFICATION_DELETE_START',
    DELETE_SUCCESS: 'NOTIFICATION_DELETE_SUCCESS',
    DELETE_FAIL: 'NOTIFICATION_DELETE_FAIL', 
    //Delete all notifications
    DELETE_ALL_START: 'NOTIFICATION_DELETE_ALL_START',
    DELETE_ALL_SUCCESS: 'NOTIFICATION_DELETE_ALL_SUCCESS',
    DELETE_ALL_FAIL: 'NOTIFICATION_DELETE_ALL_FAIL',
}

export const notificationGetAllStart = (payload) => { 
    return {
        type: NOTIFICATION.GET_ALL_START,
        payload
    }
};

export const notificationGetAllSuccess = (payload) => { 
    return {
        type: NOTIFICATION.GET_ALL_SUCCESS,
        payload
    }
};

export const notificationGetAllFail = (payload) => { 
    return {
        type: NOTIFICATION.GET_ALL_FAIL,
        payload
    }
};

export const notificationDeleteStart = (payload) => {
    return {
        type: NOTIFICATION.DELETE_START,
        payload
    }
}

export const notificationDeleteSuccess = (payload) => {
    return {
        type: NOTIFICATION.DELETE_SUCCESS,
        payload
    }
}

export const notificationDeleteFail = (payload) => {
    return {
        type: NOTIFICATION.DELETE_FAIL,
        payload
    }
}

export const notificationDeleteAllStart = (payload) => {
    return {
        type: NOTIFICATION.DELETE_ALL_START,
        payload
    }
}

export const notificationDeleteAllSuccess = (payload) => {
    return {
        type: NOTIFICATION.DELETE_ALL_SUCCESS,
        payload
    }
}

export const notificationDeleteAllFail = (payload) => {
    return {
        type: NOTIFICATION.DELETE_ALL_FAIL,
        payload
    }
}