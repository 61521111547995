import styled from 'styled-components';

export const CardBody = styled.div`
    padding-bottom: 5rem;
`

export const GeofenceRow = styled.div`
    border-bottom: 1px solid #EAECE9;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
    border-radius: 4px;
    align-items: center !important;
    padding: 2.4rem 1.7rem;
`;