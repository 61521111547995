import React from 'react';

const SquareDashedSelectedIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="square.dashed">
        <path id="square.dashed_2" d="M5.78223 5.12109H7.53125V3.47754C7.53125 2.625 7.97949 2.20312 8.78809 2.20312H10.4229V0.454102H8.67383C6.77539 0.454102 5.78223 1.42969 5.78223 3.31934V5.12109ZM11.7148 2.20312H16.2764V0.454102H11.7148V2.20312ZM20.46 5.12109H22.209V3.31934C22.209 1.43848 21.2158 0.454102 19.3174 0.454102H17.5684V2.20312H19.2031C20.0029 2.20312 20.46 2.625 20.46 3.47754V5.12109ZM20.46 10.9219H22.209V6.41309H20.46V10.9219ZM5.78223 10.9219H7.53125V6.41309H5.78223V10.9219ZM17.5684 16.8809H19.3174C21.2158 16.8809 22.209 15.8965 22.209 14.0156V12.2051H20.46V13.8574C20.46 14.71 20.0029 15.1318 19.2031 15.1318H17.5684V16.8809ZM8.67383 16.8809H10.4229V15.1318H8.78809C7.97949 15.1318 7.53125 14.71 7.53125 13.8574V12.2051H5.78223V14.0156C5.78223 15.8965 6.77539 16.8809 8.67383 16.8809ZM11.7148 16.8809H16.2764V15.1318H11.7148V16.8809Z" fill="#0071A4"/>
        <rect id="Rectangle 18" x="4" y="26" width="20" height="2" rx="1" fill="#0071A4"/>
      </g>
    </svg>
  );
};

export default SquareDashedSelectedIcon;