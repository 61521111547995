import { take, call, put } from 'redux-saga/effects';
import { 
  VIDEO,
  videoGetAllSuccess,
  videoGetAllFail,
  videoDeleteSuccess,
  videoDeleteFail,
  videoGetAllStart,
  videoLoadSelected,
  photosGetAllSuccess
} from '../actions/video';
import { getAll, deleteVideo, getAllPhotos} from '../../api/video';

export function* handleVideoGetAllStart() {
  while(true) {
    const { payload } = yield take(VIDEO.GET_ALL_START);
    try {
      const response = yield call(getAll, payload);
      yield put(videoGetAllSuccess(response));
      const photos = yield call(getAllPhotos, payload)
      yield put(photosGetAllSuccess(photos));
      yield put(videoLoadSelected(null));
    } catch (error) {
      yield put(videoGetAllFail(error.toString()));
    }
  }
}

export function* handleVideoDeleteStart() {
  while(true){
    const {payload} = yield take(VIDEO.DELETE_START);
    try {
      const response = yield call(deleteVideo, {url: payload.video_url});
      yield put(videoDeleteSuccess(response));
      yield put(videoGetAllStart(payload.selectedVehicle));
      yield put(videoLoadSelected(null));
    } catch (error) {
      yield put(videoDeleteFail(error.toString()));
    }
  }
}