import React from 'react';
import styled from 'styled-components';

export const H1 = ({fontSize, children})=> {
    return (
        <H1Styled fontSize={fontSize}>{children}</H1Styled>
    );
}

export const Text1 = ({color, fontSize, align, children})=> {
    return (
        <Text1Styled color={color} fontSize={fontSize} align={align}>{children}</Text1Styled>
    );
}
export const Text2 = ({color, bold, children})=> {
    return (
        <Text2Styled color={color} bold={bold}>{children}</Text2Styled>
    );
}
export const Text3 = ({color, children})=> {
    return (
        <Text3Styled color={color}>{children}</Text3Styled>
    );
}

export const Text4 = ({color, children})=> {
    return (
        <Text4Styled color={color}>{children}</Text4Styled>
    );
}
const H1Styled = styled.h1`
    font-family: Arimo;
    font-weight: 500;
    font-size: ${({fontSize})=> fontSize? fontSize: '4.8rem' };
    margin:0;
`;
    
export const H2 = styled.h2`
    font-family: Arimo;
    font-weight: 700;
    font-size: 2rem;
`;
export const H3 = styled.h3`
    font-family: Arimo;
    font-weight: 700;
    font-size: 1.6rem;
`;
export const P1 = styled.p`
    font-family: Arimo;
    font-weight: 400;
    font-size: 1.6rem;
`;
export const P2 = styled.p`
    font-family: Arimo;
    font-weight: 400;
    font-size: 1.4rem;
`;
export const P3 = styled.p`
    font-family: Arimo;
    font-weight: 500;
    font-size: 1.2rem;
`;

export const P4 = styled.p`
    font-family: Arimo;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.5;
    margin:2rem 0;
`;

export const Text1Styled = styled.span`
    font-family: Arimo;
    font-weight: 400;
    font-size: ${({fontSize})=> fontSize? fontSize: "1.6rem"};
    color: ${({color})=> color? color: "#000000"};
    text-align: ${({align})=> align ?? "left"}
`;

export const Text2Styled = styled.span`
    font-family: Arimo;
    font-weight: ${({bold})=> bold? "500": "400"};
    font-size: 1.4rem;
    color: ${({color})=> color? color: "#000000"}
`;

export const Text3Styled = styled.span`
    font-family: Arimo;
    font-weight: 500;
    font-size: 1.2rem;
    color: ${({color})=> color? color: "#000000"};
`;

export const Text4Styled = styled.span`
    font-family: Arimo;
    font-weight: 700;
    font-size: 2rem;
    color: ${({color})=> color? color: "#000000"};
`
export const Text5 = styled.span`
    font-family: Arimo;
    font-weight: 700;
    font-size: 1.6rem;
`