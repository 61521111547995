import React from 'react';
import PropTypes from 'prop-types';

import { Container, ContentContainer } from './OverlayContainer.styles';

const OverlayContainer = ({ width, height, padding, color,children }) => {
  return (
    <Container width={width} height={height} color={color}>
      <ContentContainer padding={padding}>
        {children}
      </ContentContainer>
    </Container>
  );
};

OverlayContainer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  opacity: PropTypes.number,
  color: PropTypes.string,
  children: PropTypes.node.isRequired
};

OverlayContainer.defaultProps = {
  width: 'fit-content',
  height: 'fit-content',
  padding: '2rem',
  color: 'rgba(244, 245, 246, 0.9)'
};

export default OverlayContainer;
