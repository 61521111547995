import React from 'react';

const ParkingIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g transform="translate(-428 -761) translate(360 160) translate(0 464) translate(28 51) translate(42 88)">
                                    <path fill="#007AFF" d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm.887 2.389H4.55V13.51H6.84V9.592h2.01c1.318 0 2.35-.314 3.097-.943.746-.629 1.119-1.488 1.119-2.578 0-.718-.17-1.359-.512-1.921-.341-.563-.829-.997-1.463-1.303-.634-.305-1.368-.458-2.204-.458zm.054 1.856c.565.01 1.008.183 1.33.52.32.336.48.776.48 1.321 0 .53-.159.937-.477 1.222-.318.286-.78.428-1.386.428H6.84v-3.49z" />
                                    <circle cx="8" cy="8" r="9" stroke="#FFF" strokeWidth="2" />
                                    <path fill="#FFF" fillRule="nonzero" d="M6.422 9.124V13H4V2h4.585c.882 0 1.658.151 2.328.453.67.302 1.186.732 1.546 1.288.36.557.541 1.19.541 1.9 0 1.078-.394 1.928-1.183 2.55-.788.622-1.879.933-3.273.933H6.422zm0-1.836h2.163c.64 0 1.128-.14 1.465-.423.336-.282.504-.685.504-1.208 0-.54-.17-.975-.508-1.307-.34-.333-.807-.504-1.405-.514h-2.22v3.452z" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ParkingIcon;
