import styled from 'styled-components';
import LoginBackgroundImage from '../../../assets/login_bg.jpg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${LoginBackgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 8rem);
`;
