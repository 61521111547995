import React, {useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { TABLE_PAGE_SIZE_OPTIONS } from '../../../constants';
import {Container, Button} from './styles';

const getPageCount = (dataCount, pageSize) => {
    return Math.ceil(dataCount / pageSize);
  };

const Pagination = ({count, pageIndex, onChangePage, pageSizes}) => {
    const [pageSize, setPageSize] = useState(pageSizes);
    const pageCount = getPageCount(count, pageSize.value);
    //page buttons
    let pages = [];
    if(pageIndex<=3){
        const end = pageCount <= 6? pageCount: 5
        for(let i = 1; i <= end; i++){
            pages.push(i);
        }
    }else if( pageIndex >= pageCount-5 ){
        for(let i = pageCount-5; i <= pageCount; i++){
            if(i>0){
                pages.push(i);
            }
        }
    }else{
        pages = [pageIndex-2, pageIndex-1, pageIndex, pageIndex+1, pageIndex+2];
    }

    const handlePrevPage = () => {
        if(pageIndex>1){
            onChangePage(pageIndex-1)
        }
    }

    const handleNextPage = () => {
        if(pageCount>pageIndex){
            onChangePage(pageIndex+1);
        }
    }

    return (
        <Container>
            <Button disabled={pageIndex === 1} onClick={()=>handlePrevPage()}>{'<'}</Button>
            {pages.map((element, i)=>(
                <Button active={element === pageIndex} key={i} onClick={()=>onChangePage(element)}>{element}</Button>
            ))}
            <Button disabled={pageIndex === pageCount} onClick={()=>handleNextPage()}>{'>'}</Button>
        </Container>
    );
};

Pagination.propTypes = {
    count: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    pageSizes: PropTypes.object
}

Pagination.defaultProps = {
    pageSizes: TABLE_PAGE_SIZE_OPTIONS[0]
}

export default Pagination;