import React from 'react';
import {Container, Row, Col} from 'react-grid-system';

import VehicleTable from '../../organisms/VehicleTable';
import GeofencesTable from '../../organisms/GeofencesTable';

import {VehicleContainer, GeofenceContainer, FleetContainer} from './styles';

const FleetTab = () => {
  return (
    <Container fluid component={FleetContainer}>
      <Row gutter={16}>
        <Col component={VehicleContainer}>
            <VehicleTable/>
        </Col>          
      </Row>
      <Row gutter={16}>
        <Col component={GeofenceContainer}>
          <GeofencesTable/>
        </Col>
      </Row>
    </Container>
  );
};

export default FleetTab;
