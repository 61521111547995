import React from 'react';
import { PropTypes } from 'prop-types';

import {ButtonElement, Loader} from './Button.styles';

const Button = ({buttonType, circle, fullWidth, onClick, isLoading, children, ...props}) => {
    return (
        <ButtonElement
            buttonType={buttonType}
            circle={circle}
            fullWidth={fullWidth}
            onClick={onClick}
            props
        >
            {isLoading && <Loader buttonType={buttonType}/> }
            {children}
        </ButtonElement>
    )
};

Button.propTypes = {
    buttonType: PropTypes.oneOf(['primary', 'secondary', 'danger', 'danger2']),
    circle: PropTypes.bool,
    fullWidth: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func.isRequired
}
Button.defaultProps = {
    buttonType: 'primary',
    circle: false,
    fullWidth: false,
    isLoading: false
}

export default Button;