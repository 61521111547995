export const STREAM = {
    //START STREAM
    START_START: 'STREAM_START_START',
    START_SUCCESS: 'STREAM_START_SUCCESS',
    START_FAIL: 'STREAM_START_FAIL',
    //STOP STREAM
    STOP_START: 'STREAM_STOP_START',
    STOP_SUCCESS: 'STREAM_STOP_SUCCESS',
    STOP_FAIL: 'STREAM_STOP_FAIL',
    //CONTINUE STREAM
    CONTINUE_START: 'STREAM_CONTINUE_START',
    CONTINUE_SUCCESS: 'STREAM_CONTINUE_SUCCESS',
    CONTINUE_FAIL: 'STREAM_CONTINUE_FAIL',
};

export const streamStart = (payload) => {
    return {
      type: STREAM.START_START,
      payload
    };
  };
  
export const streamStartSuccess = (payload) => {
    return {
        type: STREAM.START_SUCCESS,
        payload
    };
};
export const streamStartFail = (payload) => {
    return {
        type: STREAM.START_FAIL,
        payload
    };
};

export const streamStopStart = (payload) => {
    return {
      type: STREAM.STOP_START,
      payload
    };
  };
  
export const streamStopSuccess = (payload) => {
    return {
        type: STREAM.STOP_SUCCESS,
        payload
    };
};
export const streamStopFail = (payload) => {
    return {
        type: STREAM.STOP_FAIL,
        payload
    };
};

export const streamContinueStart = (payload) => {
    return {
      type: STREAM.CONTINUE_START,
      payload
    };
  };
  
export const streamContinueSuccess = (payload) => {
    return {
        type: STREAM.CONTINUE_SUCCESS,
        payload
    };
};
export const streamContinueFail = (payload) => {
    return {
        type: STREAM.CONTINUE_FAIL,
        payload
    };
};