import styled from 'styled-components';

export const ButtonElement = styled.button`
// removes default border on button element
    border: 0;
    height: 40px;
    padding: 0.5rem;
    background-color: transparent;
    cursor: pointer;
// positions the icon to the right and center aligns it vertically
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
    @media (max-width: 768px) {
        display:block;
    }
`;

export const IconBar = styled.span`
    background-color: #acacac;
    display:block;
    width:22px;
    height:2px;
    border-radius:1px;
    margin-top:4px;
`;