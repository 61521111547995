import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resolve } from 'nested-prop-resolver';
import {Container, Row, Col} from 'react-grid-system';
import ReactDOM from 'react-dom';

import PlusIcon from '../../../assets/PlusIcon';
import PencilIcon from '../../../assets/PencilIcon';
import DeleteIcon from '../../../assets/DeleteIcon';
import MapIcon from '../../../assets/MapIcon';

import Loader from '../../atoms/Loader';
import HBox from '../../atoms/HBox';
import VBox from '../../atoms/VBox';
import {H1, Text1} from '../../atoms/Typography';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';
import Popup from '../../molecules/Popup';

import Card from '../../molecules/Card';
import Pagination from '../../molecules/Pagination';
import Snackbar from '../../molecules/Snackbar';
import ConfirmDeletePopup from '../../molecules/ConfirmDeletePopup';

import AddEditGeofencePopup from '../AddEditGeofencePopup';
import AllGeofencesPopup from '../../organisms/AllGeofencesPopup';
import {
  geofenceCreateStart,
  geofenceEditStart,
  geofenceDeleteStart
} from '../../../redux/actions/geofence';
import {
  errorNotification
} from '../../../utils/notifications';
import { truncateName } from '../../../utils/helpers';
import { DEFAULT_UNKNOWN_DATA } from '../../../constants';

import {CardBody, GeofenceRow} from './styles';

const GeofencesTable = () => {
  const dispatch = useDispatch();
  const geofence = useSelector((state) => state.geofence);
  const geofencesList = resolve(geofence, 'data', []);

  const [isShowAddGeofencePopup, setIsShowAddGeofencePopup] = useState(false);
  const [isShowEditGeofencePopup, setIsShowEditGeofencePopup] = useState(false);
  const [isShowGeofencePopup, setIsShowGeofencePopup] = useState(false);
  const [geofencesData, setGeofencesData] = useState([]);
  const [geofenceSelected, setGeofenceSelected] = useState({});
  const [geofenceOnMap, setGeofenceOnMap] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [notification, setNotification] = useState({
    message: null,
    type: 'success'
  });
  const [isNewGeofenceCreated, setIsNewGeofenceCreated] = useState(false);
  const [showDeleteGeofence, setShowDeleteGeofence] = useState(false);
  const [selectedGeofence, setSelectedGeofence] = useState(null);
  const listRef = useRef(null);

  useEffect(() => {
    if (geofencesList) {
      setGeofencesData(geofencesList.slice(0, 5));
      if(notification){
        listRef.current?.lastElementChild?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }
    }
    
    if(geofence.error){
      setNotification({message: 'An error occured', type:'error' })
    }
    if(geofence.successMessage){
      if(geofence.successMessage === 'New Geofence'){
        setIsNewGeofenceCreated(true);
      }else{
        setNotification({message: geofence.successMessage, type:'success' })
      }      
    }
  }, [geofence]);

  const onAddGeofence = () => {
    if(geofencesList.length > 49){
      errorNotification({ 
        title:'',
        text:'Maximum limit of 50 created geofences is reached' 
      })
      return false;
    }
    setIsShowAddGeofencePopup(true);
  };

  const onEditGeofence = (obj) => {
    setGeofenceSelected(obj);
    setIsShowEditGeofencePopup(true);
  };

  const onAddGeofenceSave = (data) => {
    const newGeofenceObj = {
      vehicle_id: data.vehicle_id,
      location_name: data.name,
      city: data.address,
      shape: data.shape
    };
    dispatch(geofenceCreateStart(newGeofenceObj));
    setIsShowAddGeofencePopup(false);
  };

  const onAddGeofenceCancel = () => {
    setIsShowAddGeofencePopup(false);
  };

  const onEditGeofenceSave = (data) => {
    const editGeofenceObj = {
      id: data.id,
      vehicle_id: data.vehicle_id,
      location_name: data.name,
      city: data.address,
      shape: data.shape
    };
    dispatch(geofenceEditStart(editGeofenceObj));
    setIsShowEditGeofencePopup(false);
  };

  const onEditGeofenceCancel = () => {
    setIsShowEditGeofencePopup(false);
  };

  const onShowGeofence = () => {
    setGeofenceOnMap(null);
    setIsShowGeofencePopup(true);
  };

  const handleCloseShowGeofencePopup = () => {
    setIsShowGeofencePopup(false);
  };

  const handleOnChangePageIndex = (index, range) => {
    setPageIndex(index);
    setGeofencesData(geofencesList.slice((index-1)*5, index*5));
  };

  const handleOpenGeofencesMap = (geofence) => {
    setGeofenceOnMap(geofence);
    setIsShowGeofencePopup(true);
  }

  const handleDelete = (geofence) => {
    setShowDeleteGeofence(true);
    setSelectedGeofence(geofence);
  }

  const getGeofenceTableRow = (obj) => {
    const id = resolve(obj, 'id', 0);
    const name = resolve(obj, 'location_name', DEFAULT_UNKNOWN_DATA);
    const city = resolve(obj, 'city', DEFAULT_UNKNOWN_DATA);

    return (
      <Row key={id} component={GeofenceRow}>
        <Col style={{overflowWrap: "break-word"}} xs={4}><Text1>{truncateName(name, 50)}</Text1></Col>
        <Col xs={4}>
          <HBox align="center" justify="space-between">
            <Text1>{city}</Text1>
            <Button buttonType="danger2" onClick={() => handleOpenGeofencesMap(obj)}>
              <MapIcon color="#c3e9fa"/>
            </Button>
          </HBox>
        </Col>
        <Col xs={4}>
          <HBox justify="flex-end" isWrap={true}>
            <Button buttonType="danger2" onClick={() => onEditGeofence(obj)}><PencilIcon/></Button>
            <Button buttonType="danger2" onClick={() => handleDelete(obj)}><DeleteIcon/></Button>
          </HBox>
        </Col>
      </Row>
    );
  };

  const noGeofencesRow = () => {
    return (
      <VBox align="center" gap="1rem">
        <Text1><strong>You haven’t created any geofences yet. Click the button below to create new geofence.</strong></Text1>
        <Button buttonType="primary" onClick={onAddGeofence}>Create Geofence</Button>
      </VBox>
    )
  }  

  const getGeofenceDeleteMesssage = () => {
    return (
      <>
        Are you sure you want to delete <br/><strong>{truncateName(selectedGeofence.location_name, 30)}?</strong>
      </>
    );
  };

  const handleCloseNewNotification = () => {
    listRef.current?.lastElementChild?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    setIsNewGeofenceCreated(false)
  }

  return (
    <>
      {notification && notification.message && ReactDOM.createPortal(
        <Snackbar 
          onClose={()=>setNotification(null)} 
          text={notification.message}
          type={notification.type}/>,
        document.getElementById('root-notification'))}
      <Card>
      <CardBody>
          <HBox justify='space-between' align='center' margin='0 0 1.2rem 0' isWrap={true}>
              <H1 fontSize='2.4rem'>Geofences</H1>
              <HBox gap="2rem" >
                <Button buttonType="primary" circle={true} onClick={onAddGeofence}><PlusIcon/></Button>
              </HBox>
          </HBox>
          <HBox justify="flex-end" >
            <Link onClick={()=>onShowGeofence()}>
              <HBox align="center">
                <MapIcon color="#c3e9fa"/>
                <Text1 fontSize="1.5rem" color="#0071A4">Geofences Map</Text1>
              </HBox>
              </Link>
          </HBox>
          
          {geofence.isLoading &&
                <center>
                    <Loader/>
                </center>
            }
            {!geofence.isLoading && 
            <Container fluid ref={listRef}>
              {geofencesData.length > 0 &&
                  <Row >
                    <Col xs={4}><Text1>Name</Text1></Col>
                    <Col xs={4}><Text1>Location</Text1></Col>
                    <Col xs={4}></Col>
                  </Row>
              }
              {geofencesData.length > 0 ? 
                geofencesData.map((obj) => getGeofenceTableRow(obj)) : noGeofencesRow() 
              }
            </Container>        
          }
        </CardBody>
        {geofencesData.length > 0 && <Pagination 
          count={geofencesList? geofencesList.length: 0}
          pageIndex={pageIndex}
          onChangePage={handleOnChangePageIndex}
        />}
      </Card>
      {isShowAddGeofencePopup && (
        <AddEditGeofencePopup
          onCancel={onAddGeofenceCancel}
          onSave={onAddGeofenceSave}
        />
      )}
      {isShowEditGeofencePopup && (
        <AddEditGeofencePopup
          isEdit={true}
          data={geofenceSelected}
          onCancel={onEditGeofenceCancel}
          onSave={onEditGeofenceSave}
        />
      )}
      {isShowGeofencePopup && (
        <AllGeofencesPopup
          onClose={handleCloseShowGeofencePopup}
          selected={geofenceOnMap}
        />
      )}
      {isNewGeofenceCreated && (
        <Popup 
          width="52rem"
          title="Create Geofence" 
          backgroundColor="#F4F5F6" 
          onClose = {()=>setIsNewGeofenceCreated(false)}
        >
        <HBox direction="row" margin="3.2rem 0">
          <Text1 align='center'>
            Your geofence <strong>{geofencesData[0].location_name}</strong> was created successfully. Please, activate this geofence for required vehicles in Vehicles table.
          </Text1>
        </HBox>
        <HBox gap="1rem" justify="flex-end">
          <Button buttonType="primary" onClick={handleCloseNewNotification}>OK</Button>
        </HBox>
      </Popup>
      )}
      {showDeleteGeofence && 
        <ConfirmDeletePopup
          title="Delete Geofence"
          description={getGeofenceDeleteMesssage()} 
          onCancel={()=>setShowDeleteGeofence(false)} 
          onDelete={()=>{dispatch(geofenceDeleteStart(selectedGeofence)); setShowDeleteGeofence(false)}}
          deleteButtonText="Delete Geofence"
        />}
    </>
  );
};

export default GeofencesTable;
