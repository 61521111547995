import styled from 'styled-components';

export const Column = styled.div`
  width: 50%;
  padding: ${({ padding }) => padding};
  min-height: 25rem
`;

export const ContentContainer = styled.div`
  justify-content: flex-start;
  padding: 0 1.6rem;
  position: relative;
`;