import React from 'react';

const EmergencyContactIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.67383 21.8809H19.3174C21.2246 21.8809 22.209 20.8965 22.209 19.0156V8.31934C22.209 6.43848 21.2246 5.4541 19.3174 5.4541H8.67383C6.77539 5.4541 5.78223 6.42969 5.78223 8.31934V19.0156C5.78223 20.8965 6.77539 21.8809 8.67383 21.8809ZM8.78809 20.1318C7.97949 20.1318 7.53125 19.71 7.53125 18.8574V8.47754C7.53125 7.625 7.97949 7.20312 8.78809 7.20312H19.2031C20.0029 7.20312 20.46 7.625 20.46 8.47754V18.8574C20.46 19.71 20.0029 20.1318 19.2031 20.1318H8.78809ZM14 15.1045C14.4834 15.1045 14.7559 14.832 14.7734 14.3223L14.9053 10.0947C14.9229 9.57617 14.5273 9.20703 13.9912 9.20703C13.4463 9.20703 13.0684 9.56738 13.0859 10.0859L13.209 14.3311C13.2266 14.832 13.5078 15.1045 14 15.1045ZM14 18.0225C14.5713 18.0225 15.0371 17.6094 15.0371 17.0557C15.0371 16.4932 14.5713 16.0889 14 16.0889C13.4287 16.0889 12.9629 16.502 12.9629 17.0557C12.9629 17.6094 13.4375 18.0225 14 18.0225Z" fill="#363F45"/>
      <path d="M8.67383 21.8809H19.3174C21.2246 21.8809 22.209 20.8965 22.209 19.0156V8.31934C22.209 6.43848 21.2246 5.4541 19.3174 5.4541H8.67383C6.77539 5.4541 5.78223 6.42969 5.78223 8.31934V19.0156C5.78223 20.8965 6.77539 21.8809 8.67383 21.8809ZM8.78809 20.1318C7.97949 20.1318 7.53125 19.71 7.53125 18.8574V8.47754C7.53125 7.625 7.97949 7.20312 8.78809 7.20312H19.2031C20.0029 7.20312 20.46 7.625 20.46 8.47754V18.8574C20.46 19.71 20.0029 20.1318 19.2031 20.1318H8.78809ZM14 15.1045C14.4834 15.1045 14.7559 14.832 14.7734 14.3223L14.9053 10.0947C14.9229 9.57617 14.5273 9.20703 13.9912 9.20703C13.4463 9.20703 13.0684 9.56738 13.0859 10.0859L13.209 14.3311C13.2266 14.832 13.5078 15.1045 14 15.1045ZM14 18.0225C14.5713 18.0225 15.0371 17.6094 15.0371 17.0557C15.0371 16.4932 14.5713 16.0889 14 16.0889C13.4287 16.0889 12.9629 16.502 12.9629 17.0557C12.9629 17.6094 13.4375 18.0225 14 18.0225Z" fill="black" fillOpacity="0.2"/>
      </svg>
  );
};

export default EmergencyContactIcon;
