import { createHttpClient, HTTP_METHOD } from './httpClient';

export const getAllGeofences = () => {
  return createHttpClient('/v5/geo-fence?ut=usr&skip=0&limit=1000', HTTP_METHOD.GET);
};

export const addGeofence = (params) => {
  return createHttpClient('/v5/geo-fence?ut=usr', HTTP_METHOD.POST, params);
};

export const editGeofence = (params) => {
  const id = params.id;
  delete params.id;
  return createHttpClient(`/v5/geo-fence/id/${id}?ut=usr`, HTTP_METHOD.PUT, params);
};

export const deleteGeofence = (params) => {
  return createHttpClient(`/v5/geo-fence/id/${params.id}?ut=usr`, HTTP_METHOD.DELETE);
};

export const addVehicleToGeofence = (params) => {
  const id = params.id;
  delete params.id;
  return createHttpClient(`/v5/geo-fence/id/${id}/add-vehicle?ut=usr`, HTTP_METHOD.PATCH, params);
};

export const removeVehicleFromGeofence = (params) => {
  const id = params.id;
  delete params.id;
  return createHttpClient(`/v5/geo-fence/id/${id}/remove-vehicle?ut=usr`, HTTP_METHOD.PATCH, params);
};
