import React from "react";
import PropTypes from "prop-types";

import HBox from "../../atoms/HBox";
import VBox from "../../atoms/VBox";
import Image from "../../atoms/Image";
import {Text1, Text4} from "../../atoms/Typography";
import {getDeviceImage} from "../../../utils/helpers";
import {DeviceContainer} from './styles';

const Device = ({iconUrl, deviceType, alias, serialNumber}) => {
    return (
        <DeviceContainer>
            <HBox align='center'>
                <Image src={getDeviceImage(iconUrl, deviceType)} width="4.8rem" height="4.8rem"  margin="0 1.6rem 0 0" />
                <VBox>
                    <Text4>{alias}</Text4>
                    <Text1 fontSize="1.2rem" color="#5C6670">{deviceType}, SN {serialNumber}</Text1>
                </VBox>
            </HBox>
        </DeviceContainer>
    )
}

Device.propTypes = {
    deviceType: PropTypes.string.isRequired,
    alias: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
    iconUrl: PropTypes.string
}

Device.defaultProps = {
    iconUrl: null
}

export default Device;