import styled from 'styled-components';

export const Container = styled.div`
  height: auto;
  background: #1F1F1F;
`;
export const MapPlaceholder = styled.div`
  display:flex;
  height:33rem;
  align-items:center;
  justify-content:center;
  background: #ffffff;
  width:100%;
  background: #363F45;
`
