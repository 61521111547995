import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import {vehicleSelectOne} from '../../../redux/actions/vehicle';
import {videoGetAllStart} from '../../../redux/actions/video';
import {incidentGetAllStart} from '../../../redux/actions/incident';
import {notificationGetAllStart} from '../../../redux/actions/notification';

import { Container, SelectedContainer, BgImage, Vehicle, VehicleInfo, VehicleList, CheckedMarkPlaceholder } from './VehicleSelector.styles';
import MenuDropDownIcon from '../../../assets/MenuDropDownIcon';
import CheckedMark from '../../../assets/CheckedMark';
import {H3, P3, P2} from '../../atoms/Typography';
import {DEFAULT_CAR_IMAGE} from '../../../constants';

const VehicleSelector = () => {
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicle);
  const profile = useSelector((state) => state.profile);

  const [toggle, setToggle] = useState(false);
  
  const handleVehicleClick = (vehicle) => {
    dispatch(vehicleSelectOne(vehicle));
    dispatch(videoGetAllStart(vehicle));
    dispatch(incidentGetAllStart({vehicle: vehicle, user_cog_id: profile.data.user_id}));
    dispatch(notificationGetAllStart({vehicleId: vehicle.vehicle_id}));
    setToggle(!toggle)
  }

  useEffect(() => {
    if(vehicle && vehicle.selectedVehicle){
      const incidentsTimer = setInterval(()=>{
        dispatch(incidentGetAllStart({vehicle: vehicle.selectedVehicle, user_cog_id: profile.data.user_id}));
        dispatch(videoGetAllStart(vehicle.selectedVehicle));
      }, 3600000);
      return () => {
        clearInterval(incidentsTimer);
      }
    }
  }, [vehicle, profile]);

  return (
    vehicle.vehicles && vehicle.selectedVehicle?
    (<Container>
      <SelectedContainer onClick={()=>setToggle(!toggle)}>
        <Vehicle>
          <BgImage source={vehicle.selectedVehicle.image_url || DEFAULT_CAR_IMAGE}/>
          <VehicleInfo>
            <H3>{vehicle.selectedVehicle.alias}</H3>
            <P3>Cameras: {vehicle.selectedVehicle.dashcams.length}</P3>
          </VehicleInfo>
          <MenuDropDownIcon/>
        </Vehicle>
      </SelectedContainer>
      <VehicleList show={toggle}>
        {vehicle.vehicles.map((obj) => 
          <Vehicle key={obj.vehicle_id} onClick={()=>{handleVehicleClick(obj)}}>
            <BgImage source={obj.image_url || DEFAULT_CAR_IMAGE}/>
            <VehicleInfo>
              <H3>{obj.alias}</H3>
              <P3>Cameras: {obj.dashcams.length}</P3>
            </VehicleInfo>
            {obj.vehicle_id === vehicle.selectedVehicle.vehicle_id? <CheckedMark/> : <CheckedMarkPlaceholder/>  }
        </Vehicle>
        )}
      </VehicleList>
    </Container>)
    : <P2>You have no vehicles</P2>
  );
};

export default VehicleSelector;
