import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  ${({ justify }) => `justify-content: ${justify};`};
  ${({ align }) => `align-items: ${align};`};
  ${({ gap }) => `gap: ${gap};`};
  ${({ height }) => height && `height: ${height};`};
  width:100%;
`;