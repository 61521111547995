import styled from 'styled-components';

export const Container = styled.div`    
    background: #ffffff;
    border-radius: 12px;
    height: ${({elHeight})=> elHeight}; 
    @media(max-width:991px){
        height: auto;
    }
`;

export const BodyContainer = styled.div`
    padding: 1.2rem 1.6rem;
`
export const ExpandedDiv = styled.div`
    display:block;
    @media(max-width:991px){
        display: ${({expanded}) => expanded? 'block':'none'};
    }
`