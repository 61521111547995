import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AuthContainer from '../../organisms/AuthContainer';
import CenterWrapper from '../../atoms/CenterWrapper';
import TextInput from '../../molecules/TextInput';
import { forgotPasswordStart } from '../../../redux/actions/password';
import {H1, P1} from '../../atoms/Typography';
import Button from '../../atoms/Button';
import {notify} from '../../../utils/notifications';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const password = useSelector(state => state.password);

  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (user.data) {
      history.push('/');
    }
  }, [user]);

  useEffect(() => {
    if (password.forgotSuccess) {
      notify({title: '', text: 'Check your email and click on the link received to update your password.'})
    }
  }, [password]);

  const onChangeFormData = (key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  const onSubmit = () => {
    dispatch(forgotPasswordStart(formData));
  };

  return (
    <AuthContainer>
      <CenterWrapper>
        <H1>FORGOT PASSWORD?</H1>
      </CenterWrapper>
      <P1>Enter the email address you used to register. We’ll send reset instructions.</P1>
      <TextInput 
        placeholder="Email" 
        margin="1.6rem 0 1.6rem 0"
        value={formData.email} 
        error={password.error && 'Invalid email'} 
        onChange={value => onChangeFormData('email', value)}/>
      <Button 
        buttonType='primary' 
        fullWidth 
        onClick={onSubmit} 
        isLoading={password.isLoading}>
        Submit
      </Button>
    </AuthContainer>
  );
};

export default ForgotPassword;
