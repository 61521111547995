import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import HBox from '../../atoms/HBox';
import Link from '../../atoms/Link';
import {H1} from '../../atoms/Typography';
import Button from '../../atoms/Button';
import CenterWrapper from '../../atoms/CenterWrapper';
import AuthContainer from '../../organisms/AuthContainer';
import TextInput from '../../molecules/TextInput';
import PageCheckBox from '../../molecules/PageCheckBox';
import { userLoginStart } from '../../../redux/actions/user';
import {accountNotActivated, errorNotification, notify} from '../../../utils/notifications';
import {resendActivationLinkStart} from '../../../redux/actions/accountActivation';
import { isMobile } from 'react-device-detect';
import VBox from '../../atoms/VBox';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const activation = useSelector(state => state.activation);
  const password = useSelector(state => state.password);

  const [formData, setFormData] = useState({
    email: localStorage.getItem("ds-email") || "",
    password: localStorage.getItem("ds-password") || ""    
  });
  const [rememberMe, setRememberMe] = useState(localStorage.getItem("ds-remember-me")==='true' || false);
  
  useEffect(() => {
    if (user.data) {
      history.push('/');
    }
    if(user.error && user.error.text === 'User is not confirmed.'){
      const resendActivationLink = async () => {
        const resend = await accountNotActivated();
        if(resend){
            dispatch(resendActivationLinkStart({email: formData.email}));
        }
      }
      resendActivationLink().catch((error)=>{
          errorNotification({title:'Something went wrong', text:'Please try again later!'})
      });
    }
  }, [user]);

  useEffect(() => {
    if(activation.data && activation.data.message && activation.data.message === 'Successfully resend'){
        notify({title:'', text:'Please check your email for confirmation link'})
    }
    if(activation.error && activation.error.text!='Token mismatched'){
      errorNotification({title:'', text:activation.error.text})
    }
  }, [activation]);


  const onChangeFormData = (key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });
  };

  const onSignIn = (e) => {
    e.preventDefault();
    if(rememberMe){
      localStorage.setItem("ds-email", formData.email); 
      localStorage.setItem("ds-password", formData.password)
      localStorage.setItem("ds-remember-me", true)
    }else{
      localStorage.setItem("ds-email", ""); 
      localStorage.setItem("ds-password", "");
      localStorage.setItem("ds-remember-me", false);
    }
    dispatch(userLoginStart(formData));
  };

  const onForgotPassword = () => {
    history.push('/forgot-password');
  };

  const onRememberMe = () => {
    setRememberMe(!rememberMe);
  }

  const openApp = () =>{
    window.location = 'drivesmarter://resetpassword';
  };

  return (
    <AuthContainer>
      <CenterWrapper>
        <H1>SIGN IN</H1>
      </CenterWrapper>
      <form onSubmit={onSignIn}>
        <TextInput 
          margin="1.6rem 0 0 0"
          placeholder="Username" 
          value={formData.email} 
          error={user.error && 'Invalid credentials'} 
          onChange={value => onChangeFormData('email', value)}
          name="username"
        />
        <TextInput 
          placeholder="Password" 
          isPassword={true} 
          margin="1.6rem 0 0 0" 
          value={formData.password} 
          error={user.error && 'Invalid credentials'} 
          onChange={value => onChangeFormData('password', value)} 
          name="password"
        />
        <HBox margin='1.5rem -1.3rem' justify='space-between' align='center'>
          <PageCheckBox value={rememberMe} onChange={onRememberMe}>Remember Me</PageCheckBox>
          <Link onClick={onForgotPassword}>Forgot password?</Link>
        </HBox>
        <VBox gap="1rem">
          <Button 
            buttonType='primary' 
            fullWidth 
            onClick={onSignIn} 
            isLoading={user.isLoading}>
              Sign In
          </Button>
          { password.resetSuccess && isMobile && <Button buttonType='primary'  fullWidth  onClick={()=>openApp()}>Go to DriveSmarter App</Button>}
        </VBox>
        
      </form>
    </AuthContainer>
  );
};

export default Login;
