import { VEHICLE } from '../actions/vehicle';

const initialState = {
  vehicles: [],
  selectedVehicle: null,
  isLoading: false,
  error: null,
  successMessage: null,
};

const vehicleReducer = (state = initialState, action) => {
  switch(action.type) {
  case VEHICLE.GET_ALL_START:
    return { ...state, vehicles: null, isLoading: true, error: null };
  case VEHICLE.GET_ALL_SUCCESS:
    return { ...state, vehicles: action.payload, isLoading: false, error: null };
  case VEHICLE.GET_ALL_FAIL:
    return { ...state, vehicles: [], isLoading: false, error: action.payload };
  case VEHICLE.SELECT_ONE:
    return { ...state, selectedVehicle: action.payload, successMessage: null };
  case VEHICLE.CREATE_START:
    return { ...state, isLoading: true, error: null, successMessage: null };
  case VEHICLE.CREATE_SUCCESS:
    return { ...state, vehicles: [...state.vehicles, action.payload], isLoading: false, error: null, successMessage: 'Vehicle created successfully' };
  case VEHICLE.CREATE_FAIL:
    return { ...state, isLoading: false, error: action.payload };
  case VEHICLE.EDIT_START:
    return { ...state, isLoading: true, error: null, successMessage: null };
  case VEHICLE.EDIT_SUCCESS:
    return { ...state, vehicles: state.vehicles.map(obj => obj.reg_no === action.payload.reg_no ? action.payload : obj), isLoading: false, error: null, successMessage: 'Vehicle information saved successfully' };
  case VEHICLE.EDIT_FAIL:
    return { ...state, isLoading: false, error: action.payload };
  case VEHICLE.DELETE_START:
    return { ...state, isLoading: true, error: null, successMessage: null };
  case VEHICLE.DELETE_SUCCESS:
    return { ...state, vehicles: state.vehicles.filter(obj => obj.reg_no !== action.payload.reg_no), isLoading: false, error: null, successMessage: 'Vehicle deleted successfully' };
  case VEHICLE.DELETE_FAIL:
    return { ...state, vehicles: null, isLoading: false, error: action.payload };
  case VEHICLE.UPLOAD_START:
    return { ...state, isLoading: true, error: null,  successMessage: null};
  case VEHICLE.UPLOAD_SUCCESS:
    return { ...state, vehicles: state.vehicles.map(obj => obj.reg_no === action.payload.reg_no ? action.payload : obj), isLoading: false, error: null };
  case VEHICLE.UPLOAD_FAIL:
    return { ...state, isLoading: false, error: action.payload };
  case VEHICLE.SEARCH_START:
    return { ...state, isLoading: true, error: null, successMessage: null };
  case VEHICLE.SEARCH_SUCCESS:
    return { ...state, vehicles: action.payload, isLoading: false, error: null };
  case VEHICLE.SEARCH_FAIL:
    return { ...state, isLoading: false, error: action.payload };
  case VEHICLE.DELETE_DEVICE_START:
    return {...state, isLoading:true, error: null, successMessage: null};
  case VEHICLE.DELETE_DEVICE_SUCCESS:
    return {...state, isLoading: false, error:null, successMessage: 'Device deleted successfully'};
  case VEHICLE.DELETE_DEVICE_FAIL:
    return {...state, isLoading:false, error: action.payload}
  case VEHICLE.TRANSFER_DEVICES_START:
    return {...state, isLoading:true, error: null, successMessage: null};
  case VEHICLE.TRANSFER_DEVICES_SUCCESS:
    return {...state, isLoading: false, error:null, successMessage: 'Device transferred successfully'};
  case VEHICLE.TRANSFER_DEVICES_FAIL:
    return {...state, isLoading:false, error: action.payload}
  case VEHICLE.GET_DEVICES_START:
    return {...state, isLoading:false, error: null, successMessage: null};
  case VEHICLE.GET_DEVICES_SUCCESS:
    //UPDATE SELECTED VEHICLE STATE WITH THE DEVICES
    const selectedVehicle = {
      ...state.selectedVehicle,
      dashcams: action.payload.dash_cams
    }
    return {...state, selectedVehicle: selectedVehicle, isLoading: false, error:null};
  case VEHICLE.GET_DEVICES_FAIL:
    return {...state, isLoading:false, error: action.payload}    
  default:
    return state;
  }
};

export default vehicleReducer;
