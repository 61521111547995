import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    z-index: 999;
    bottom: 3rem;
    right: 3rem;
    background-color: ${({type}) => type === 'success'? '#55B48E' : '#E24444'};
    padding: 0.9rem 0.6rem 0.9rem 1.6rem;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    width: 34.4rem;
    border-radius: 4px;
`;