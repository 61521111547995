import styled from 'styled-components';

export const VehicleContainer = styled.div`
  margin-top: 6.8rem;
`;
export const GeofenceContainer = styled.div`
  margin-top:1.5rem;
`;
export const FleetContainer = styled.div`
  margin-bottom: 2.4rem;
`
