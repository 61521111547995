import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align: 
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 0.2rem;
  margin: 0.8rem;
  cursor: pointer;
  
`;

export const VideoContainer = styled.div`
  width:100%;
  height:100%;
  position: relative;  
`;

export const IconContainer = styled.div`
  width:2rem;
  height:2rem;
  position: absolute;
  top:1rem;
  right:1rem;
`;
export const DateTitle = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #C6C6C8;
`
