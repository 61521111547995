import { PASSWORD } from '../actions/password';

const initialState = {
  email: null,
  forgotSuccess: false,
  resetSuccess: false,
  isLoading: false,
  error: null
};

const passwordReducer = (state = initialState, action) => {
  switch(action.type) {
  case PASSWORD.FORGOT_START:
    return { ...state, email: null, forgotSuccess: false, isLoading: true, error: null };
  case PASSWORD.FORGOT_SUCCESS:
    return { ...state, email: action.payload, forgotSuccess: true, isLoading: false, error: null };
  case PASSWORD.FORGOT_FAIL:
    return { ...state, email: null, forgotSuccess: false, isLoading: false, error: action.payload };
  case PASSWORD.RESET_START:
    return { ...state, resetSuccess: false, isLoading: true, error: null };
  case PASSWORD.RESET_SUCCESS:
    return { ...state, resetSuccess: true, isLoading: false, error: null };
  case PASSWORD.RESET_FAIL:
    return { ...state, resetSuccess: false, isLoading: false, error: action.payload };
  default:
    return state;
  }
};

export default passwordReducer;
