import styled, {css} from 'styled-components';

export const Container = styled.div`
    position: absolute;
    width:100%;
    left: -1.2rem;
    bottom: 2rem;
    display:flex;
    gap: 1rem;
    justify-content:center;
    @media(max-width:991px){
        position: static;
    }
`;

export const Button = styled.button`
    padding: 0.7rem 1.2rem;
    border:0;
    background: #F4F5F6;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    ${({active}) => active && css`
    background: #0071A4;
        box-shadow: 0px 5px 5px rgba(0, 26, 38, 0.2), 0px 9px 10px rgba(0, 40, 57, 0.14), 0px 5px 14px rgba(0, 113, 164, 0.1);
        color: #ffffff;
    `};
    &:hover{
        background: #0071A4;
        box-shadow: 0px 5px 5px rgba(0, 26, 38, 0.2), 0px 9px 10px rgba(0, 40, 57, 0.14), 0px 5px 14px rgba(0, 113, 164, 0.1);
        color: #ffffff;
    }
    &:disabled&:hover{
        background: #F4F5F6;
        color:#C6C6C8;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
    }
`;