import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Hidden} from 'react-grid-system';
import {Container, BodyContainer, ExpandedDiv} from './styles';
import {H1} from '../../atoms/Typography';
import Link from '../../atoms/Link';
import HBox from '../../atoms/HBox';
import ExpandedIcon from '../../../assets/ExpandedIcon';
import NotExpandedIcon from '../../../assets/NotExpandedIcon';


const Card = ({title, height, button, buttonOnClick, expand,children}) => {
    const [expanded, setExpanded] = useState(true);
    return(
        <Container elHeight={height}>
            <BodyContainer>
                <HBox justify='space-between' align='center' margin='0 0 1.2rem 0'>
                    <H1 fontSize='2.4rem'>{title}</H1>
                    {button && 
                        <Link color='#E24444' onClick={buttonOnClick}>{button}</Link>
                    }
                    {expand &&
                    <Hidden lg xl xxl>
                        <Link onClick={()=>{setExpanded(!expanded)}}>
                            {expanded ? <ExpandedIcon/> : <NotExpandedIcon/>}
                        </Link>
                    </Hidden>}
                </HBox>
                <ExpandedDiv expanded={expanded}>
                    {children}
                </ExpandedDiv>    
            </BodyContainer>
        </Container>
    );
};

Card.propTypes = {
    title: PropTypes.string,
    height: PropTypes.string,
    expand: PropTypes.bool
}

Card.defaultProps = {
    title: '',
    height: 'auto',
    expand: true
}

export default Card;