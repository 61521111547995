import React from 'react';

const SpeedIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="gauge">
        <g id="gauge_2">
          <path d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9912 20.9316C9.95703 20.9316 6.73145 17.6885 6.73145 13.6543C6.73145 9.62012 9.95703 6.38574 13.9824 6.38574C18.0166 6.38574 21.2598 9.62012 21.2686 13.6543C21.2773 17.6885 18.0254 20.9316 13.9912 20.9316ZM13.9824 9.82227C14.4834 9.82227 14.8877 9.41797 14.8877 8.92578C14.8877 8.4248 14.4922 8.02051 13.9824 8.02051C13.499 8.02051 13.0859 8.4248 13.0859 8.92578C13.0859 9.41797 13.4902 9.82227 13.9824 9.82227ZM16.5576 10.666C17.0586 10.666 17.4629 10.2617 17.4629 9.76074C17.4629 9.26855 17.0586 8.85547 16.5576 8.85547C16.0654 8.85547 15.6611 9.26855 15.6611 9.76074C15.6611 10.2617 16.0654 10.666 16.5576 10.666ZM11.3193 10.6748C11.8203 10.6748 12.2246 10.2617 12.2246 9.76074C12.2246 9.26855 11.8203 8.85547 11.3193 8.85547C10.8184 8.85547 10.4141 9.26855 10.4141 9.76074C10.4141 10.2617 10.8184 10.6748 11.3193 10.6748ZM9.6582 12.626C10.1592 12.626 10.5635 12.2217 10.5635 11.7207C10.5635 11.2197 10.1592 10.8154 9.6582 10.8154C9.16602 10.8154 8.76172 11.2197 8.76172 11.7207C8.76172 12.2217 9.16602 12.626 9.6582 12.626ZM18.2275 12.6436C18.7285 12.6436 19.1328 12.2393 19.1328 11.7383C19.1328 11.2373 18.7285 10.833 18.2275 10.833C17.7266 10.833 17.3223 11.2373 17.3223 11.7383C17.3223 12.2393 17.7266 12.6436 18.2275 12.6436ZM13.9912 18.998C14.7998 18.998 15.4502 18.3389 15.4502 17.5215C15.4502 16.9414 15.0986 16.4316 14.5977 16.2031V12.2568C14.5977 11.8965 14.334 11.624 13.9912 11.624C13.6484 11.624 13.376 11.8965 13.376 12.2568V16.2031C12.875 16.4316 12.5234 16.9414 12.5234 17.5215C12.5234 18.3389 13.1738 18.998 13.9912 18.998Z" fill="#363F45"/>
          <path d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9912 20.9316C9.95703 20.9316 6.73145 17.6885 6.73145 13.6543C6.73145 9.62012 9.95703 6.38574 13.9824 6.38574C18.0166 6.38574 21.2598 9.62012 21.2686 13.6543C21.2773 17.6885 18.0254 20.9316 13.9912 20.9316ZM13.9824 9.82227C14.4834 9.82227 14.8877 9.41797 14.8877 8.92578C14.8877 8.4248 14.4922 8.02051 13.9824 8.02051C13.499 8.02051 13.0859 8.4248 13.0859 8.92578C13.0859 9.41797 13.4902 9.82227 13.9824 9.82227ZM16.5576 10.666C17.0586 10.666 17.4629 10.2617 17.4629 9.76074C17.4629 9.26855 17.0586 8.85547 16.5576 8.85547C16.0654 8.85547 15.6611 9.26855 15.6611 9.76074C15.6611 10.2617 16.0654 10.666 16.5576 10.666ZM11.3193 10.6748C11.8203 10.6748 12.2246 10.2617 12.2246 9.76074C12.2246 9.26855 11.8203 8.85547 11.3193 8.85547C10.8184 8.85547 10.4141 9.26855 10.4141 9.76074C10.4141 10.2617 10.8184 10.6748 11.3193 10.6748ZM9.6582 12.626C10.1592 12.626 10.5635 12.2217 10.5635 11.7207C10.5635 11.2197 10.1592 10.8154 9.6582 10.8154C9.16602 10.8154 8.76172 11.2197 8.76172 11.7207C8.76172 12.2217 9.16602 12.626 9.6582 12.626ZM18.2275 12.6436C18.7285 12.6436 19.1328 12.2393 19.1328 11.7383C19.1328 11.2373 18.7285 10.833 18.2275 10.833C17.7266 10.833 17.3223 11.2373 17.3223 11.7383C17.3223 12.2393 17.7266 12.6436 18.2275 12.6436ZM13.9912 18.998C14.7998 18.998 15.4502 18.3389 15.4502 17.5215C15.4502 16.9414 15.0986 16.4316 14.5977 16.2031V12.2568C14.5977 11.8965 14.334 11.624 13.9912 11.624C13.6484 11.624 13.376 11.8965 13.376 12.2568V16.2031C12.875 16.4316 12.5234 16.9414 12.5234 17.5215C12.5234 18.3389 13.1738 18.998 13.9912 18.998Z" fill="black" fillOpacity="0.2"/>
        </g>
      </g>
    </svg>

  );
};

export default SpeedIcon;