import React from 'react';

const PolygonSelectedIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.43945 16.5342C6.2832 16.5342 7.0127 16.0596 7.39941 15.3652L20.4336 19.1885C20.7148 20.0938 21.5674 20.7617 22.5605 20.7617C23.791 20.7617 24.793 19.751 24.793 18.5205C24.793 17.624 24.2568 16.8506 23.4922 16.4902L23.1318 7.94727C23.9316 7.60449 24.4854 6.81348 24.4854 5.89062C24.4854 4.66016 23.4834 3.6582 22.2617 3.6582C21.4707 3.6582 20.7676 4.08008 20.3721 4.7041L13.3848 2.69141C13.3232 1.52246 12.3389 0.582031 11.1611 0.582031C9.93066 0.582031 8.92871 1.58398 8.92871 2.81445C8.92871 3.42969 9.18359 3.99219 9.58789 4.39648L5.59766 12.0781C5.54492 12.0693 5.49219 12.0693 5.43945 12.0693C4.20898 12.0693 3.20703 13.0713 3.20703 14.3018C3.20703 15.5322 4.20898 16.5342 5.43945 16.5342ZM11.1611 5.04688C11.8555 5.04688 12.4707 4.73047 12.8838 4.22949L20.0645 6.28613C20.2051 7.08594 20.7764 7.74512 21.5322 8.00879L21.8398 16.4111C21.2598 16.6133 20.7939 17.0439 20.5391 17.5889L7.61035 13.7832C7.51367 13.4141 7.33789 13.0713 7.08301 12.7988L11.0645 5.04688C11.0996 5.04688 11.1348 5.04688 11.1611 5.04688Z" fill="#0071A4" />
            <rect x="4" y="26" width="20" height="2" rx="1" fill="#0071A4" />
        </svg>
    );
};

export default PolygonSelectedIcon;