import React from 'react';
import PropTypes from 'prop-types';

const CheckIcon = ({ isChecked }) => {
  if (isChecked) {
    return (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="11" y="11" width="18" height="18" rx="2" fill="#0071A4"/>
        <path d="M18 24.4L14 20.4L15.4 19L18 21.6L24.6 15L26 16.4L18 24.4Z" fill="white"/>
      </svg>

    );
  }

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="12" y="12" width="16" height="16" rx="1" stroke="#0071A4" strokeWidth="2"/>
    </svg>
  );
};

CheckIcon.propTypes = {
  isChecked: PropTypes.bool
};

CheckIcon.defaultProps = {
  isChecked: false
};

export default CheckIcon;
