export const ACCOUNT_CLEANUP = {
    REQUEST_START: 'ACCOUNT_CLEANUP_REQUEST_START',
    REQUEST_SUCCESS: 'ACCOUNT_CLEANUP_REQUEST_SUCCESS',
    REQUEST_FAIL: 'ACCOUNT_CLEANUP_REQUEST_FAIL',
    START: 'ACCOUNT_CLEANUP_START',
    SUCCESS: 'ACCOUNT_CLEANUP_SUCCESS',
    FAIL: 'ACCOUNT_CLEANUP_FAIL',
}

export const accountCleanupRequestStart = (payload) => {
    return {
        type: ACCOUNT_CLEANUP.REQUEST_START,
        payload,
    };
};

export const accountCleanupRequestSuccess = (payload) => {
    return {
        type: ACCOUNT_CLEANUP.REQUEST_SUCCESS,
        payload,
    };
};

export const accountCleanupRequestFail = (payload) => {
    return {
        type: ACCOUNT_CLEANUP.REQUEST_FAIL,
        payload,
    };
};

export const accountCleanupStart = (payload) => {
    return {
        type: ACCOUNT_CLEANUP.START,
        payload,
    };
};

export const accountCleanupSuccess = (payload) => {
    return {
        type: ACCOUNT_CLEANUP.SUCCESS,
        payload,
    };
};

export const accountCleanupFail = (payload) => {
    return {
        type: ACCOUNT_CLEANUP.FAIL,
        payload,
    };
};