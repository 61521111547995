import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ color }) => color};
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 1.5rem;
`;
