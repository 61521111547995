import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './TextMenuItem.styles';
import Text from '../../atoms/Text';

const TextMenuItem = ({color, children, onClick }) => {
  return (
    <Container color={color} onClick={onClick}>
      <Text color="white">{children}</Text>
    </Container>
  );
};

TextMenuItem.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

TextMenuItem.defaultProps = {
  color: 'transparent'
};

export default TextMenuItem;
