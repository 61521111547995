import React, {useState, useEffect} from "react";
import {Container} from 'react-grid-system';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import styles from "./AccountCleanUp.module.css";
import PageCheckBox from "../../molecules/PageCheckBox";
import TextInput from "../../molecules/TextInput";
import Button from "../../atoms/Button";
import Logo from '../../../assets/logo-blue.png';
import Image from '../../atoms/Image';
import ExclamationMark from "../../../assets/exclamationmark.circle.png"; 
import {H1, P4} from '../../atoms/Typography';
import HBox from '../../atoms/HBox';
import VBox from '../../atoms/VBox';
import Text from '../../atoms/Text';
import Loader from '../../atoms/Loader';
import {confirmCleanup, notify, errorNotification} from '../../../utils/notifications';
 
import { accountCleanupRequestStart, accountCleanupStart } from '../../../redux/actions/accountCleanup';

const AccountCleanup = () => {
    const location = useLocation();
    const token = location.search.split('=')[1];
    const dispatch = useDispatch();
    const history = useHistory();
    const cleanupAccountState = useSelector(state => state.accountCleanup);
    const [formData, setFormData] = useState({
        accept: false,
        email: '',
        reason: 'Other'
    });
    const [formErrors, setFormErrors] = useState({
        accept: null,
        email: null,
        reason: null
    }); 

    
    useEffect(() => {
        if(token){
            dispatch(accountCleanupStart(token));
        }
    }, []);

    useEffect(() => {
        if(cleanupAccountState.data && cleanupAccountState.data.message && !token){
           notify({title: "Email Sent", text: cleanupAccountState.data.message});
        }
        if(cleanupAccountState.error && !token ){
            errorNotification({title: cleanupAccountState.error.title, text: cleanupAccountState.error.text});
         }
      }, [cleanupAccountState]);

    const onSubmit = async (e) => {
        e.preventDefault();
        if(!formData.accept){
            setFormErrors({accept: "This needs to be checked!"})
            return false;
        }
        if(!formData.email){
            setFormErrors({email: "Email is empty!"});
            return false;
        }
        if(!formData.email.toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )){
            setFormErrors({email: "Invalid email"});
            return false;
        }
        setFormErrors({
            accept: null,
            email: null,
            reason: null
        });
        const result = await confirmCleanup();
        if(result){
            dispatch(accountCleanupRequestStart({...formData, is_fleet:1}));
            setFormData({
                accept: false,
                email: '',
                reason: 'Other'
            });
        }
    }

    const onCancel = () => {
        history.push('/login');
    }

    const onChangeFormData = (key, value) => {
        setFormData({
          ...formData,
          [key]: value
        });
    };
      
    const showForm = () =>{
        history.push('/account-cleanup')
    }

    return (
        <Container fluid className={styles["delete-account-container"]}>
                <div className={styles["logo-container"]}>
                    <a href="/login"><Image src={Logo}/></a>
                </div>
                <div className={styles['content-container']}>
                    {token && 
                        <div>
                            <HBox justify='center'>
                                <H1 >Account Cleanup</H1>
                            </HBox>
                            {cleanupAccountState.data && cleanupAccountState.data.message && 
                                <VBox justify='center' align='center'>
                                    <P4 className="text-center">
                                        Your account has been successfully cleaned up. You can log in to your account in your Drive Smarter app or on the User Portal using the button below.
                                    </P4>
                                    <Button buttonType="primary" onClick={onCancel}>Log In to User Portal</Button>
                                </VBox>
                            }
                            {cleanupAccountState.error &&
                                 <VBox justify='center' align='center'>
                                    <P4 className="text-center">
                                        We could not clean up you account. Please try again to complete the request.
                                    </P4>
                                    <Button buttonType="danger" onClick={showForm}>Request Clean Up Account</Button>
                                </VBox>
                            }
                            {!cleanupAccountState.data && !cleanupAccountState.error && 
                                <VBox justify='center' align='center'>
                                    <P4>
                                        Please wait while account is beeing cleaned up 
                                    </P4>
                                    <Loader margin="0rem" />
                                </VBox>
                            }
                        </div>
                    }
                    {!token && 
                        <div>
                            <HBox justify='center'>
                                <H1 >Account Cleanup</H1>
                            </HBox>
                            <HBox justify='center' align='center' margin="0.75rem 0 0 0">
                                <Image src={ExclamationMark}/>
                                <P4 lineHeight="1.5">You will not be able to recover data after this action is completed</P4>
                            </HBox>
                            <P4>
                                If you are experiencing problems with the app or data management, please reach out to our <a href="https://drivesmarter.com/support/">Customer Support</a> and we will do our best to help you.
                            </P4>
                            <P4>
                                If you decide to proceed with deleting the data from your account, please be aware that this is an action which cannot be undone. 
                            </P4>
                            <P4>
                                If you decide to proceed with deletion, your data will be deleted, but your account will still be active. In this case you will lose all files from your devices, emergency information, but your personal data will remain to keep your account alive (your name, email and country). You will still be able to log in to your Drive Smarter account.
                            </P4>
                            <P4>
                                Please check the option below if you would like to proceed.
                            </P4>
                            
                            <form onSubmit={onSubmit}>
                                <div className={styles["checkbox-container"]}>
                                    <PageCheckBox value={formData.accept} onChange={()=>onChangeFormData("accept", !formData.accept)}> </PageCheckBox>
                                    <div>
                                        <div>I want to clean up my Drive Smarter account</div>
                                        <div className={styles["additional-info"]}>If you select this option, your personal data (name, email and country) will be saved on your account to keep it active. Your account will be active and you will be able to log in to Drive Smarter services.</div>
                                    </div>
                                </div>
                                {formErrors.accept &&  <Text margin="0.2rem 0 0 1.6rem" color="#E24444" fontSize="1.2rem">{formErrors.accept}</Text>}
                                <VBox margin="2rem 0">
                                    <div>Indicate your email which is connected to the account<span className={styles.red}>*</span></div>
                                    <TextInput
                                        placeholder="Enter your email here"
                                        name="email"
                                        type="text"
                                        value={formData.email}
                                        onChange={(value) => onChangeFormData("email", value)}
                                        id="email"
                                        error={formErrors.email}
                                        maxLength={100}
                                    />
                                </VBox>
                                <VBox margin="2rem 0">
                                    <div>Please state the reason for the decision to clean up your account to help us improve.</div>
                                    <select name="reason" className={styles["custom-select"]} defaultValue={formData.reason} onChange={value=>onChangeFormData("reason", value.target.value)}>
                                        <option value="Drive Smarter app won't connect my device">Drive Smarter app won't connect my device</option>
                                        <option value="I've decided to use a competitor's product">I've decided to use a competitor's product</option>
                                        <option value="I don't use my dashcam or radar detector anymore">I don't use my dashcam or radar detector anymore</option>
                                        <option value="I want my data deleted">I want my data deleted</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </VBox>
                                <HBox justify='flex-end' gap="1rem">
                                    <Button buttonType="secondary" onClick={onCancel}>Cancel</Button>
                                    <Button isLoading={cleanupAccountState.loading} buttonType="danger" disabled={cleanupAccountState.loading} onClick={onSubmit}>
                                        Clean Up Account
                                    </Button>
                                </HBox>
                            </form>
                        </div>
                    }
                </div>
            </Container>
    );
};

export default AccountCleanup;