export const VIDEO = {
  // Get all videos
  GET_ALL_START: 'VIDEO_GET_ALL_START',
  GET_ALL_SUCCESS: 'VIDEO_GET_ALL_SUCCESS',
  GET_ALL_FAIL: 'VIDEO_GET_ALL_FAIL',
  // Load a selected video for playback
  LOAD_SELECTED: 'VIDEO_LOAD_SELECTED',
  DELETE_START: 'VIDEO_DELETE_START',
  DELETE_SUCCESS: 'VIDEO_DELETE_SUCCESS',
  DELETE_FAIL: 'VIDEO_DELETE_FAIL',
  //Get photos for vehicle
  PHOTOS_GET_ALL_START: 'PHOTOS_GET_ALL_START',
  PHOTOS_GET_ALL_SUCCESS: 'PHOTOS_GET_ALL_SUCCESS',
  PHOTOS_GET_ALL_FAIL: 'PHOTOS_GET_ALL_FAIL',
};

export const videoGetAllStart = (payload) => {
  return {
    type: VIDEO.GET_ALL_START,
    payload
  };
};

export const videoGetAllSuccess = (payload) => {
  return {
    type: VIDEO.GET_ALL_SUCCESS,
    payload
  };
};

export const videoGetAllFail = (payload) => {
  return {
    type: VIDEO.GET_ALL_FAIL,
    payload
  };
};

export const videoLoadSelected = (payload) => {
  return {
    type: VIDEO.LOAD_SELECTED,
    payload
  };
};

export const videoDeleteStart = (payload) => {
  return {
    type: VIDEO.DELETE_START,
    payload
  }
}

export const videoDeleteSuccess = (payload) => {
  return {
    type: VIDEO.DELETE_SUCCESS,
    payload
  }
}

export const videoDeleteFail = (payload) => {
  return {
    type: VIDEO.DELETE_FAIL,
    payload
  }
}

export const photosGetAllStart = (payload) => {
  return {
    type: VIDEO.PHOTOS_GET_ALL_START,
    payload
  }
}

export const photosGetAllSuccess = (payload) => {
  return {
    type: VIDEO.PHOTOS_GET_ALL_SUCCESS,
    payload
  }
};

export const photosGetAllFail = (payload) => {
  return {
    type: VIDEO.PHOTOS_GET_ALL_FAIL,
    payload
  }
};