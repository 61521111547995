import { createHttpClient, HTTP_METHOD } from './httpClient';

export const getAllIncidents = (params) => {
  return createHttpClient(`/v1/incidents/user/${params.user_cog_id}?ut=usr&filterByVehicle=${params.vehicle.vehicle_id}`, HTTP_METHOD.GET);
};

export const sendIncident = (params) => {
  return createHttpClient(`/v1/incidents/send-email-confirm?ut=usr`, HTTP_METHOD.POST, params);
};

export const deleteIncident = (params) => {
  return createHttpClient(`/v5/incidents/${params.report_id}?ut=usr`, HTTP_METHOD.DELETE);
}