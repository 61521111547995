import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-image: url("${({backgroundImage}) => backgroundImage }");
  background-position: center;
  background-repeat: no-repeat;
  background-size:contain;
`;

