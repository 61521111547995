import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { resolve } from 'nested-prop-resolver';
import { Hidden, Visible } from 'react-grid-system';

import VBox from '../../atoms/VBox';
import HBox from '../../atoms/HBox';
import Button from '../../atoms/Button';
import {H1, Text2, Text1, Text5} from "../../atoms/Typography";
import DeleteIcon from '../../../assets/DeleteIcon';
import DownloadVideoIcon from '../../../assets/DownloadVideoIcon';
import SpeedIcon from '../../../assets/SpeedIcon';
import GsensorIcon from '../../../assets/GsensorIcon';
import { 
  Container, 
  VideoHeaderContainer,
  CircleButtonWrapper,
  VideoContainer,
  NoVideo,
  ImageContainer
} from './VideoPlayerSection.styles';
import { getTime, getDate } from '../../../utils/timeFormatters';
import { getGsensor } from '../../../utils/helpers';
import { DEFAULT_EMPTY_DATA } from '../../../constants';

const GalleryVideoPlayer = ({onDeleteVideo, onDownloadVideo, isDownloading, handleVideoTimeUpdate}) => {
  const vehicle = useSelector((state) => state.vehicle);
  const video = useSelector(state => state.video);
  const tripNodes = useSelector(state=> state.trip.data);
  const [videoUrl, setVideoUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [time, setTime] = useState(DEFAULT_EMPTY_DATA);
  const [date, setDate] = useState(DEFAULT_EMPTY_DATA);
  const [speed, setSpeed] = useState('N/A');
  const [gyro, setGyro] = useState('N/A');
  const [currentTime, setCurrentTime] = useState(0);

  const updateSpeedAndGyro = node => {
    const speed = resolve(node, 'sensor.speed', 'N/A');
    const gsensor = resolve(node, 'sensor.gyro', null);
    let speedLabel = 'N/A'; 
    if(speed.mph) {
      speedLabel = `${speed.mph} mph`;
    }
    if(speed.unit) {
      speedLabel = `${speed.value} ${speed.unit}`;
    }
    
    setSpeed(speedLabel); 
    setGyro(gsensor)
  }

  useEffect(() => {
    const node = resolve(video, 'selected', {});
    const deviceTime = resolve(node, 'device_time');
    const videoUploadStatus = resolve(node, 'videoUploadStatus');
    const selectedVideoUrl = resolve(node, 'video_url');
    const selectedImageUrl = resolve(node, 'trip_node[0].image_url', null);
    
    updateSpeedAndGyro(node);    

    //This should be uncommented once the process for incident on android is alligned with ios
    // if (videoUploadStatus === 'COMPLETED') {
    //   setVideoUrl(selectedVideoUrl);
    // }else{
    //   setVideoUrl('');
    // }
    setVideoUrl(selectedVideoUrl);

    if(selectedImageUrl){
      setImageUrl(selectedImageUrl);
    }else{
      setImageUrl(null);
    }

    if (deviceTime) {
      setTime(getTime(deviceTime));
      setDate(getDate(deviceTime));
    }
  }, [video, vehicle]);
  
  const timeUpdate = (event) => {
    const updatedTime = parseInt(event.target.currentTime);
    if( updatedTime != currentTime ){
      setCurrentTime(parseInt(updatedTime));
      if(tripNodes && tripNodes[updatedTime]){
          updateSpeedAndGyro(tripNodes[updatedTime]);
          handleVideoTimeUpdate(updatedTime);
      }
    }
  }

  if(!video.selected || video.selected.length === 0){
    return (
      <NoVideo>
        <Text2 color="#FFF">Select video from the list to display it</Text2>
      </NoVideo>
    )
  }

  return (
    <Container>
      <Hidden xs>
        <VideoHeaderContainer>
          <HBox justify="space-between" align="center">
            <HBox>
              <VBox>
                <H1 fontSize="2.4rem">{date}</H1>
                <Text1 fontSize="2rem">{time}</Text1>
              </VBox>
            </HBox>
            {videoUrl &&
              <HBox gap="2.4rem">
                <HBox align="center">
                  <SpeedIcon/>
                  <div>
                    <div><Text1 fontSize="1.5rem">Speed</Text1></div>
                    <div><Text5 >{speed}</Text5></div>
                  </div>
                </HBox>
                <HBox align="center">
                  <GsensorIcon/>
                  <div>
                    <div><Text1 fontSize="1.5rem">G-Sensor</Text1></div>
                    <div><Text5>{gyro? getGsensor(gyro)+"G": "N/A"}</Text5></div>
                  </div>
                </HBox>
              </HBox>
            }
            <HBox gap="1.6rem">           
              <Button isLoading={isDownloading} onClick={onDownloadVideo}>
                <HBox gap="0.8rem" align="center">
                  {isDownloading ?
                    <>Downloading...</>:
                    <><DownloadVideoIcon color="#fff"/>Download</>
                  }
                </HBox>
              </Button>
              <CircleButtonWrapper onClick={() => onDeleteVideo()}>
                <DeleteIcon color="#fff" />
              </CircleButtonWrapper>
            </HBox>
          </HBox>
        </VideoHeaderContainer>
      </Hidden>
      {videoUrl && <VideoContainer src={videoUrl && `${videoUrl}#t=0.1`} controls onTimeUpdate={event => timeUpdate(event)} disablePictureInPicture/>}
      {imageUrl && <ImageContainer src={imageUrl} />}
      <Visible xs>
        <HBox gap="2.4rem" justify="center" margin="1.5rem 0 0 0">
          <HBox>
            <SpeedIcon/>
            <div>
              <div><Text1 fontSize="1.5rem">Speed</Text1></div>
              <div><Text5 >{speed}</Text5></div>
            </div>
          </HBox>
          <HBox>
            <GsensorIcon/>
            <div>
              <div><Text1 fontSize="1.5rem">G-Sensor</Text1></div>
              <div><Text5>{gyro? getGsensor(gyro)+"G": "N/A"}</Text5></div>
            </div>
          </HBox>
        </HBox>
      </Visible>
    </Container>
  );
};

export default GalleryVideoPlayer;
