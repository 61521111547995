import React from 'react';
import PropTypes from 'prop-types';

const CheckedMark = ({ color }) => {
  return (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.8002 10.9L1.6002 6.70005L0.200195 8.10005L5.8002 13.7L17.8002 1.70005L16.4002 0.300049L5.8002 10.9Z" fill={color}/>
    </svg>
  );
};

CheckedMark.propTypes = {
  color: PropTypes.string
};

CheckedMark.defaultProps = {
  color: '#0071A4'
};

export default CheckedMark;
