import React from 'react';
import PropTypes from 'prop-types';

import { LoaderElement } from './Loader.style';

const Loader = ({ color, size, margin }) => {
  return (<LoaderElement color={color} size={size} margin={margin} />);
};

Loader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  margin: PropTypes.string
};

Loader.defaultProps = {
  color: '#c3e9fa',
  size: '4rem',
  margin: '0rem'
};

export default Loader;
