import styled from 'styled-components';

export const Spacer = styled.div`
  height:1.5rem;
`;

export const CircleButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 4.8rem;
  background: #E24444;
  cursor: pointer;
  align-self:center;
`;