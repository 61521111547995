import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './BrowseImage.styles';
import Button from '../../atoms/Button';
import VBox from '../../atoms/VBox';

const BrowseImage = ({ src, onImageSelect, hasImage }) => {
  let fileInput = null;
  const openUpload = () => {
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <Container onClick={openUpload} backgroundImage={hasImage ? src : '/images/car.png'}>
      {!hasImage &&
        <VBox justify="space-around">
          <Button buttonType="secondary" onClick={openUpload}>Select Image</Button>
        </VBox>
      }
      <input ref={ref => fileInput = ref} accept="image/*" type="file" name="Add Vehicle Image" hidden={true} onChange={onImageSelect} />
    </Container>
  );
};

BrowseImage.propTypes = {
  src: PropTypes.string,
  onImageSelect: PropTypes.func.isRequired,
  hasImage: PropTypes.bool
};

BrowseImage.defaultProps  = {
  hasImage: false
}

export default BrowseImage;
