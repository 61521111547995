import styled, {css} from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
`;

export const InputElement = styled.input.attrs({ type: 'text' })`
  height: 4rem;
  outline: none;
  font-size: 1.6rem;
  background: ${({background})=> background? background:'#FFFFFF'};
  color: #363F45;
  border-radius:8px;
  padding: 0 1.6rem;
  border:0;
  ${({ error }) => error && css`
    border: 0.1rem solid #E24444;
  `};
`;

export const PasswordElement = styled.input.attrs({ type: 'password' })`
  height: 4rem;
  border: none;
  outline: none;
  font-size: 1.6rem;
  background: #FFFFFF;
  border-radius:8px;
  color: #363F45;
  padding: 0 1.6rem;
  ${({ error }) => error && css`
    border: 0.1rem solid #E24444;
  `};
`;

export const Star = styled.span`
  color: #A93232;
`;
