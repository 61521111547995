import styled, {css} from 'styled-components';

export const ListItem = styled.div`
    padding: 0.8rem 1.6rem;
    background: #F4F5F6;
    border: 1px solid #EAECE9;
    border-radius: 4px;
    margin-top: 8px;
    &:hover{
        background:#EAECE9;
    }
`;

export const Container = styled.div`
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin:0;
    ${({textColor}) => textColor && css`
        color: ${textColor};
    `}
`;

export const Button = styled.button`
    background: transparent;
    border:0;
    width:4rem;
    padding:0;
`;
export const ContentContainer = styled.div`
height:41rem;
overflow:auto;
margin-bottom:3rem;
`

export const PaginationWrapper = styled.div`
    position:relative;
    top: 3rem;
    @media(max-width:991px){
        position: static;
    }
`

