import axios from 'axios';
import { AUTH_URL, BASE_URL, DEVICE_URL, USER_OBJECT_KEY } from '../constants';
import { _success, _error } from 'react-color-log';

export const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH'
};

axios.interceptors.request.use(config => {
  const user = JSON.parse(localStorage.getItem(USER_OBJECT_KEY));
  if (user) {
    config.headers['Authorization'] = `Bearer ${user.jwtToken}`;
  }
  if(config.url === `${AUTH_URL}/v0/user` || config.url === `${AUTH_URL}/v0/user/insurance`){
    config.headers['accesstoken'] = user.accessToken;
  }
  config.headers['Content-Type'] = 'application/json';
  return config;
}, error => {
  Promise.reject(error);
});

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  const originalRequest = error.config;

  if (error.response.status === 401 && 
      (originalRequest.url === `${AUTH_URL}/user/login` ||
      originalRequest.url === `${AUTH_URL}/v0/user/email-confirm`)) {
    return Promise.reject(error);
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    const user = JSON.parse(localStorage.getItem(USER_OBJECT_KEY));

    return axios.post(`${AUTH_URL}/v0/user/token-refresh`, {
      email: user.email,
      refresh_token: user.refreshToken
    }).then(res => {
      if (res.status === 200) {
        const responseData = res.data;
        if (responseData.status === 'SUCCESS') {
          // save user session with new token
          const userObj = {
            email: user.email,
            ...responseData.data
          };
          _success(`Updated user session with new token: ${JSON.stringify(userObj)}`);
          localStorage.setItem(USER_OBJECT_KEY, JSON.stringify(userObj));

          // set the new token and invoke the current request
          axios.defaults.headers.common['Authorization'] = `Bearer ${userObj.jwtToken}`;
          return axios(originalRequest);
        } else {
          _error(`Cannot fetch refresh token => ${responseData.status} => ${responseData.message}`);
          logout();
        }
      } else {
        _error('Cannot fetch refresh token');
        logout();
      }
    }).catch(error => {
      _error(`Cannot fetch refresh token => ${error}`);
      logout();
    });
  }

  return Promise.reject(error);
});

export const createHttpClient = (url, method, data, isAuth = false, isDevice = false, isUpload = false) => {
  let baseUrl = BASE_URL;
  if(isAuth){
    baseUrl = AUTH_URL;
  }
  if(isDevice){
    baseUrl = DEVICE_URL;
  }

  return new Promise((resolve, reject) => {
    axios({
      method,
      url: `${baseUrl}${url}`,
      data: (isUpload? data : (data ? JSON.stringify(data): undefined ))
    }).then(response => {
      if (response.status && response.status > 199 && response.status < 300) {
        const responseData = response.data;
        if (responseData.status === 'SUCCESS' || responseData.code === 'SUCCESS' || responseData.status === 'Vehicle successfully deleted' || responseData.status === 'RECORD_NOT_FOUND') {
          if(isAuth){
            resolve(responseData);
          }else{      
            if(responseData.data){
              resolve(responseData.data);
            }else{
              resolve(responseData);
            }      
          }
        } else {
          reject({title: '', status: responseData.status, text: responseData.message});
        }
      } else {
        reject({title: 'Something went wrong', text: 'Please try again later'});
      }
    }).catch(error => {
      if(error.response){       
        reject({
          title: '', 
          text: error.response.data.message? error.response.data.message : error.response.data.data,
          status: error.response.data? error.response.data.status: ''
        });
      }else if (error) {
        reject({title: 'Something went wrong', text: 'Please try again later'});
      }
    });
  });
};

const logout = () => {
  localStorage.removeItem(USER_OBJECT_KEY);
  window.location.href = '/';
};
