import React from "react";
import PropTypes from "prop-types";

import { VideoContainer } from "./Video.styles";

const Video = ({ width, height, margin, borderRadius, position, src, selected, maxHeight }) => {
  return (
    <VideoContainer
      width={width}
      height={height}
      margin={margin}
      borderRadius={borderRadius}
      position={position}
      selected={selected}
      maxHeight={maxHeight}
    >
      <source src={src} type="video/mp4" />
    </VideoContainer>
  );
};

Video.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  borderRadius: PropTypes.string,
  position: PropTypes.string,
  src: PropTypes.string,
  selected: PropTypes.bool,
  maxHeight: PropTypes.string
};

Video.defaultProps = {
  width: "fit-content",
  height: "fit-content",
  margin: "0",
  borderRadius: "0",
  position: "static",
  selected: false
};

export default Video;
