import { take, call, put } from 'redux-saga/effects';
import { 
  INCIDENT,
  incidentGetAllSuccess,
  incidentGetAllFail,
  incidentSendSuccess,
  incidentSendFail,
  incidentGetAllStart,
  incidentDeleteSuccess,
  incidentDeleteFail
} from '../actions/incident';
import { 
  getAllIncidents,
  sendIncident,
  deleteIncident
} from '../../api/incident';

export function* handleIncidentGetAllStart() {
  while(true) {
    const { payload } = yield take(INCIDENT.GET_ALL_START);
    try {
      const response = yield call(getAllIncidents, payload);
      yield put(incidentGetAllSuccess(response));
    } catch (error) {
      yield put(incidentGetAllFail(error.toString()));
    }
  }
}

export function* handleIncidentSendStart() {
  while(true){
    const { payload } = yield take(INCIDENT.SEND_START);
    try {
      const response = yield call(sendIncident, payload);
      yield put(incidentSendSuccess(response));
      yield put(incidentGetAllStart({user_cog_id: payload.user_cog_id, vehicle:{vehicle_id: payload.vehicle_id}}))
    } catch (error) {
      yield put(incidentSendFail(error));
    } 
  }
}

export function* handleIncidentDeleteStart() {
  while(true){
    const {payload} = yield take(INCIDENT.DELETE_START);
    try {
      const response = yield call(deleteIncident, payload);
      yield put(incidentDeleteSuccess(response));
      yield put(incidentGetAllStart({user_cog_id: payload.user_cog_id, vehicle:{vehicle_id: payload.vehicle_id}}))
    } catch (error) {
      yield put(incidentDeleteFail(error));
    }
  }
}