import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { resolve } from 'nested-prop-resolver';

import { Container, MapPlaceholder } from './styles';
import CarIcon from '../../../assets/CarIcon.png';
import {Text2} from '../../atoms/Typography';
import {
  MAP_ZOOM_LEVEL,
  MAP_ANCHOR_SIZE,
  GOOGLE_MAPS_KEY
} from '../../../constants';

const GalleryMap = ({currentVideoPlaySecond}) => {
  const [location, setLocation] = useState({lat:0, lng:0});
  const video = useSelector(state => state.video);
  const tripNodes = useSelector(state => state.trip.data);

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_KEY
  });

  useEffect(() => {
    const loc = resolve(video, 'selected.sensor', {});
    setLocation({
      lat: parseFloat(loc.latitude),
      lng: parseFloat(loc.longitude)
    });
  }, [video]);
  useEffect(()=>{
    if(tripNodes && tripNodes[currentVideoPlaySecond]){
      const loc = resolve(tripNodes[currentVideoPlaySecond], 'sensor', {});
      if(loc && loc.latitude && loc.longitude ){
        let lat = parseFloat(loc.latitude);
        let long = parseFloat(loc.longitude);
        if(lat>90 || lat<-90){
          lat = lat/100;
        }
        if(long>180 || long<-180){
          long = long/100;
        }
        setLocation({
          lat: lat,
          lng: long
        });
      }
      
    }
  },[currentVideoPlaySecond]);
  if((!location.lat || !location.lng) || (location.lat === 0 && location.lng === 0)){
    return (
      <MapPlaceholder>
        <Text2 color="#FFF">{!video.selected? "Select video from the list to display the location" : "Location information is not available for this file"}</Text2>
      </MapPlaceholder>
    )
  }

  return (
    <Container>
      {isLoaded && <GoogleMap
        mapContainerStyle={{
          height: '43rem',
          width: '100%',
        }}
        center={location}
        zoom={MAP_ZOOM_LEVEL}
        version="weekly"
        on
      >        
        <Marker
          position={location}
          icon={{
            url: CarIcon,
            anchor: {
              x: MAP_ANCHOR_SIZE,
              y: MAP_ANCHOR_SIZE
            },
            scaledSize: {
              width: 46,
              height: 68
            }
          }}
        />
      </GoogleMap>
      }
    </Container>
  );
};

export default GalleryMap;
