import {TRIP} from "../actions/trip";

const initialState = {
    data: null,
    isLoading: false,
    error: null
}

const tripReducer = (state = initialState, action) => {
    switch( action.type ){
        case TRIP.GET_NODES_START:
            return {data: null, isLoading: true, error: null};
        case TRIP.GET_NODES_SUCCESS:
            return {data: action.payload, isLoading: false, error: null};
        case TRIP.GET_NODES_FAIL:
            return {data: null, isLoading: false, error: action.payload};
        default:
            return state;
    };
};

export default tripReducer;