import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import { resolve } from 'nested-prop-resolver';

import Popup from '../../molecules/Popup';
import { 
    Column,
    ContentContainer
} from './styles';
import HBox from '../../atoms/HBox';
import Dropdown from '../../atoms/Dropdown';
import VBox from '../../atoms/VBox';
import Loader from '../../atoms/Loader';
import {Text1} from '../../atoms/Typography';
import Button from '../../atoms/Button';
import Device from '../../molecules/Device';
import {errorNotification} from '../../../utils/notifications';

const DeviceTransferPopup = ({onCancel, onTransfer, selectedVehicle, isLoading}) => {
    const vehicles = useSelector((state)=>state.vehicle.vehicles)
    const [vehiclesOptions, setVehiclesOptions] = useState([]);
    const [devices, setDevices] = useState(selectedVehicle? selectedVehicle.dashcams : []);
    const [vehicleFrom, setVehicleFrom] = useState(selectedVehicle? selectedVehicle.vehicle_id : undefined);
    const [vehicleTo, setVehicleTo] = useState();
    let selectedOption =null;
    if(selectedVehicle){
        const year = resolve(selectedVehicle, 'vehicle_make', '-');
        const model = resolve(selectedVehicle, 'vehicle_model', '-');
        selectedOption = {
            value: selectedVehicle.vehicle_id,
            label: `${selectedVehicle.alias}, ${year}, ${model}`, 
            };   
    }
    
    const formatVehiclesList = (vehicles) => {
        const vehicleOptions = vehicles.reduce( (acumulator, vehicle) => {
            const year = resolve(vehicle, 'vehicle_make', '-');
            const model = resolve(vehicle, 'vehicle_model', '-');
            const option = {
                value: vehicle.vehicle_id,
                label: `${vehicle.alias}, ${year}, ${model}`, 
             };            
            acumulator.push(option);
            return acumulator;
        }, []);
        return vehicleOptions;
    }

    useEffect( () => {
        const options = formatVehiclesList(vehicles)
        setVehiclesOptions(options);  
    },[vehicles]);    

    const handleChangeVehicleFrom = (option) => {
        //update device list
        const filteredVehicles = vehicles.filter(vehicle => vehicle.vehicle_id === option.value)
        setDevices(filteredVehicles[0].dashcams);
        //update TO VEHICLE list to remove selected FROM VEHICLE
        const options = formatVehiclesList(vehicles)
        setVehiclesOptions(options); 
        setVehicleFrom(option.value);
    }
    const handleChangeVehicleTo = (option) => {
        setVehicleTo(option.value);
    }

    const handleClickConfirm = () => {
        if(devices.length === 0){
            errorNotification(
                {
                  title: "Error",
                  text:'Selected vehicle has no devices'
                })
            return false;
        }
        if(!vehicleFrom){
            errorNotification(
                {
                  title: "Error",
                  text:'Select From vehicle'
                })
            return false;
        }
        if(!vehicleTo){
            errorNotification(
                {
                  title: "Error",
                  text:'Select To vehicle'
                })
            return false;
        }
        if(vehicleTo === vehicleFrom){
            errorNotification(
                {
                  title: "Error",
                  text:'Can not migrate devices to same vehicle'
                })
            return false;
        }
        onTransfer(vehicleFrom, vehicleTo);
    }

    const displayVehicleDevices = () => {
        return (
            devices.length > 0 ?
                devices.map((device) => (
                    <Device
                       iconUrl={device.icon_url}
                       serialNumber={device.cam_id}
                       deviceType={device.device_type}
                       alias={device.model_name}
                       key={device.cam_id}
                    />   
                ))  
            :
            <Text1>No devices</Text1>
        )
    }

    return (
        <Popup
            title="Device Transfer"
            backgroundColor="#F4F5F6"
            onClose={onCancel}
            width="78rem"
        >
            {isLoading ? (
                <Loader margin="15rem 0 15rem 34rem" />
            ):(
            <>
                <ContentContainer>
                    <HBox margin="2rem 0">
                        <Column padding="0 1rem">
                            <Text1 fontSize="1.2rem" color="#5C6670">Transfer From</Text1>
                            <Dropdown
                                width="100%"
                                options={vehiclesOptions}
                                handleSelect={handleChangeVehicleFrom}
                                optionPadding='0.8rem 1.6rem'
                                defaultValue={selectedOption}
                                borderRadius='0.4rem'
                            />
                            <VBox gap="1.6rem" margin="1.6rem 0 0 0">
                                <Text1 fontSize="1.6rem" ><strong>Selected Device</strong></Text1>
                                {displayVehicleDevices()}
                            </VBox>
                        </Column>
                        <Column padding="0 1rem">
                            <Text1 fontSize="1.2rem" color="#5C6670">Move To</Text1>
                            <Dropdown
                                width="100%"
                                options={vehiclesOptions}
                                handleSelect={handleChangeVehicleTo}
                                optionPadding='0.8rem 1.6rem'
                                borderRadius='0.4rem'
                            />
                        </Column>
                    </HBox>
                </ContentContainer>
                <HBox justify="flex-end" gap="1rem">
                    <Button buttonType="secondary" onClick={onCancel}>Cancel</Button>
                    <Button buttonType="primary" onClick={handleClickConfirm}>Transfer Devices</Button>
                </HBox>
            </>
            )}
        </Popup>
    )
}

DeviceTransferPopup.prototype = {
    onCancel: PropTypes.func.isRequired,
    onTransfer: PropTypes.func.isRequired,
    selectedVehicle: PropTypes.object,
    isLoading: PropTypes.bool
}

export default DeviceTransferPopup;