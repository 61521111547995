import { take, call, put } from 'redux-saga/effects';
import { resolve } from 'nested-prop-resolver';
import { 
  VEHICLE,
  vehicleGetAllSuccess,
  vehicleGetAllFail,
  vehicleSelectOne,
  vehicleCreateSuccess,
  vehicleCreateFail,
  vehicleEditSuccess,
  vehicleEditFail,
  vehicleDeleteSuccess,
  vehicleDeleteFail,
  imageUploadSuccess,
  imageUploadFail,
  vehicleSearchSuccess,
  vehicleSearchFail,
  vehicleDeleteDeviceSuccess,
  vehicleDeleteDeviceFail,
  vehicleGetAllStart,
  vehicleTransferDevicesSuccess,
  vehicleTransferDevicesFail,
  vehicleGetDevicesSuccess,
  vehicleGetDevicesFail
} from '../actions/vehicle';
import {
  videoGetAllStart,
} from '../actions/video';
import { 
  incidentGetAllStart 
} from '../actions/incident';
import {
  notificationGetAllStart
} from '../actions/notification';
import {
  getVehicleAll,
  getVehicleInfo, 
  addVehicle, 
  editVehicleInfo, 
  deleteVehicle, 
  uploadImage,
  searchVehicle,
  deleteDeviceFromVehicle,
  transferDevices,
  getVehicleDevices
} from '../../api/vehicle';
import { USER_OBJECT_KEY } from '../../constants';


export function* handleVehicleGetStart() {
  while(true) {
    const { payload } = yield take(VEHICLE.GET_ALL_START);
    try {
      const response = yield call(getVehicleAll, payload);
      const vehiclesData = resolve(response, 'vehicles', []);
      yield put(vehicleGetAllSuccess(vehiclesData));
      if (vehiclesData.length > 0) {
        let defaultVehicle = vehiclesData[0];
        if(payload && payload.selectedVehicle){          
          defaultVehicle = vehiclesData.find(vehicle => vehicle.vehicle_id === payload.selectedVehicle)
        }
        yield put(vehicleSelectOne(defaultVehicle));
        yield put(videoGetAllStart(defaultVehicle));
        yield put(incidentGetAllStart({user_cog_id: payload.user_cog_id, vehicle:{vehicle_id:defaultVehicle.vehicle_id}}));
        yield put(notificationGetAllStart({vehicleId: defaultVehicle.vehicle_id}));
      }
    } catch (error) {
      yield put(vehicleGetAllFail(error.toString()));
    }
  }
}

export function* handleVehicleAddStart() {
  while(true) {
    const { payload } = yield take(VEHICLE.CREATE_START);
    try {
      yield call(addVehicle, payload);
      const response = yield call(getVehicleInfo, {reg_no:  payload.get('reg_no')});
      yield put(vehicleCreateSuccess(response));
    } catch (error) {
      yield put(vehicleCreateFail(error.toString()));
    }
  }
}

export function* handleVehicleEditStart() {
  while(true) {
    const { payload } = yield take(VEHICLE.EDIT_START);
    try {
      yield call(editVehicleInfo, payload);
      const params = {
        reg_no: payload.get('reg_no')
      }
      const response = yield call(getVehicleInfo, params);
      yield put(vehicleEditSuccess(response));
    } catch (error) {
      yield put(vehicleEditFail(error.toString()));
    }
  }
}

export function* handleVehicleDeleteStart() {
  while(true) {
    const { payload } = yield take(VEHICLE.DELETE_START);
    try {
      yield call(deleteVehicle, payload);
      yield put(vehicleDeleteSuccess(payload));
    } catch (error) {
      yield put(vehicleDeleteFail(error.toString()));
    }
  }
}

export function* handleVehicleImageUploadStart() {
  while(true) {
    const { payload } = yield take(VEHICLE.UPLOAD_START);
    try {
      const response = yield call(uploadImage, payload);
      yield put(imageUploadSuccess(response));
    } catch (error) {
      yield put(imageUploadFail(error.toString()));
    }
  }
}

export function* handleVehicleSearchStart() {
  while(true) {
    const { payload } = yield take(VEHICLE.SEARCH_START);
    try {
      const response = yield call(searchVehicle, payload);
      yield put(vehicleSearchSuccess(response));
    } catch (error) {
      yield put(vehicleSearchFail(error.toString()));
    }
  }
}

export function* handleVehicleDeleteDeviceStart() {
  while(true) {
    const { payload } = yield take(VEHICLE.DELETE_DEVICE_START);
    try {
      const response = yield call(deleteDeviceFromVehicle, payload);
      yield put(vehicleDeleteDeviceSuccess(response));
      yield put(vehicleGetAllStart({user_cog_id: payload.user_cog_id}));
    } catch (error) {
      yield put(vehicleDeleteDeviceFail(error.toString()));      
    }
  }
}

export function* handleVehicleTransferDevicesStart() {
  while(true){
    const { payload } = yield take(VEHICLE.TRANSFER_DEVICES_START);
    try {
      const reponse = yield call(transferDevices, payload);
      yield put(vehicleTransferDevicesSuccess(reponse));
      yield put(vehicleGetAllStart({selectedVehicle: payload.fromVehicleId}));
    } catch (error) {
      yield put(vehicleTransferDevicesFail(error));
    }
  }
}

export function* handleVehicleGetDevicesStart() {
  while(true){
    const {payload} = yield take(VEHICLE.GET_DEVICES_START);
    try{
      const response = yield call(getVehicleDevices, payload);
      yield put(vehicleGetDevicesSuccess(response));
    }catch(error){
      yield put(vehicleGetDevicesFail(error));
    }
  }
}