import { createHttpClient, HTTP_METHOD } from './httpClient';

export const getInfo = () => {
  return createHttpClient('/v5/user/info?ut=usr', HTTP_METHOD.GET);
};

export const updateInfo = (params) => {
  return createHttpClient('/v0/user', HTTP_METHOD.PUT, params, true);
};

export const updateEmergency = (params) => {
  return createHttpClient('/v1/sos-email/add_emergency_contact?ut=usr', HTTP_METHOD.POST, params, false);
};

export const updateIncidentReporting = (params) => {
  return createHttpClient('/v1/user/insurance', HTTP_METHOD.PUT, params, true);
};
