import {take, call, put} from 'redux-saga/effects';
import {
    ACCOUNT_CLEANUP,
    accountCleanupFail,
    accountCleanupRequestFail,
    accountCleanupRequestSuccess,
    accountCleanupSuccess
} from '../actions/accountCleanup';

import {requestAccountCleanup, accountCleanup} from '../../api/accountCleanup';

export function* handleRequestAccountCleanup() {
    while(true) {
        const {payload} = yield take(ACCOUNT_CLEANUP.REQUEST_START);
        try {
            const response = yield call(requestAccountCleanup, payload);
            yield put(accountCleanupRequestSuccess(response));
        } catch (error) {
            yield put(accountCleanupRequestFail(error))
        }
    }
}

export function* handleAccountCleanup() {
    while(true){
        const {payload} = yield take(ACCOUNT_CLEANUP.START);
        try {
            const response = yield call(accountCleanup, payload);
            yield put(accountCleanupSuccess(response));
        } catch (error) {
            yield put(accountCleanupFail(error));
        }
    }
}