export const GEOFENCE = {
  // Get all geofences
  GET_ALL_START: 'GEOFENCE_GET_ALL_START',
  GET_ALL_SUCCESS: 'GEOFENCE_GET_ALL_SUCCESS',
  GET_ALL_FAIL: 'GEOFENCE_GET_ALL_FAIL',
  // Get one geofence
  GET_ONE_START: 'GEOFENCE_GET_ONE_START',
  GET_ONE_SUCCESS: 'GEOFENCE_GET_ONE_SUCCESS',
  GET_ONE_FAIL: 'GEOFENCE_GET_ONE_FAIL',
  // Create a geofence
  CREATE_START: 'GEOFENCE_CREATE_START',
  CREATE_SUCCESS: 'GEOFENCE_CREATE_SUCCESS',
  CREATE_FAIL: 'GEOFENCE_CREATE_FAIL',
  // Edit a geofence
  EDIT_START: 'GEOFENCE_EDIT_START',
  EDIT_SUCCESS: 'GEOFENCE_EDIT_SUCCESS',
  EDIT_FAIL: 'GEOFENCE_EDIT_FAIL',
  // Delete a geofence
  DELETE_START: 'GEOFENCE_DELETE_START',
  DELETE_SUCCESS: 'GEOFENCE_DELETE_SUCCESS',
  DELETE_FAIL: 'GEOFENCE_DELETE_FAIL',
  // add vehicle to geofence
  ADD_VEHICLE_START: 'GEOFENCE_ADD_VEHICLE_START',
  ADD_VEHICLE_SUCCESS: 'GEOFENCE_ADD_VEHICLE_SUCCESS',
  ADD_VEHICLE_FAIL: 'GEOFENCE_ADD_VEHICLE_FAIL',
  // remove vehicle from geofence
  REMOVE_VEHICLE_START: 'GEOFENCE_REMOVE_VEHICLE_START',
  REMOVE_VEHICLE_SUCCESS: 'GEOFENCE_REMOVE_VEHICLE_SUCCESS',
  REMOVE_VEHICLE_FAIL: 'GEOFENCE_REMOVE_VEHICLE_FAIL'
};

export const geofenceGetAllStart = () => {
  return {
    type: GEOFENCE.GET_ALL_START
  };
};

export const geofenceGetAllSuccess = (payload) => {
  return {
    type: GEOFENCE.GET_ALL_SUCCESS,
    payload
  };
};

export const geofenceGetAllFail = (payload) => {
  return {
    type: GEOFENCE.GET_ALL_FAIL,
    payload
  };
};

export const geofenceGetOneStart = (payload) => {
  return {
    type: GEOFENCE.GET_ONE_START,
    payload
  };
};

export const geofenceGetOneSuccess = (payload) => {
  return {
    type: GEOFENCE.GET_ONE_SUCCESS,
    payload
  };
};

export const geofenceGetOneFail = (payload) => {
  return {
    type: GEOFENCE.GET_ONE_FAIL,
    payload
  };
};

export const geofenceCreateStart = (payload) => {
  return {
    type: GEOFENCE.CREATE_START,
    payload
  };
};

export const geofenceCreateSuccess = (payload) => {
  return {
    type: GEOFENCE.CREATE_SUCCESS,
    payload
  };
};

export const geofenceCreateFail = (payload) => {
  return {
    type: GEOFENCE.CREATE_FAIL,
    payload
  };
};

export const geofenceEditStart = (payload) => {
  return {
    type: GEOFENCE.EDIT_START,
    payload
  };
};

export const geofenceEditSuccess = (payload) => {
  return {
    type: GEOFENCE.EDIT_SUCCESS,
    payload
  };
};

export const geofenceEditFail = (payload) => {
  return {
    type: GEOFENCE.EDIT_FAIL,
    payload
  };
};

export const geofenceDeleteStart = (payload) => {
  return {
    type: GEOFENCE.DELETE_START,
    payload
  };
};

export const geofenceDeleteSuccess = (payload) => {
  return {
    type: GEOFENCE.DELETE_SUCCESS,
    payload
  };
};

export const geofenceDeleteFail = (payload) => {
  return {
    type: GEOFENCE.DELETE_FAIL,
    payload
  };
};

export const geofenceAddVehicleStart = (payload) => {
  return {
    type: GEOFENCE.ADD_VEHICLE_START,
    payload
  };
};

export const geofenceAddVehicleSuccess = (payload) => {
  return {
    type: GEOFENCE.ADD_VEHICLE_SUCCESS,
    payload
  };
};

export const geofenceAddVehicleFail = (payload) => {
  return {
    type: GEOFENCE.ADD_VEHICLE_FAIL,
    payload
  };
};

export const geofenceRemoveVehicleStart = (payload) => {
  return {
    type: GEOFENCE.REMOVE_VEHICLE_START,
    payload
  };
};

export const geofenceRemoveVehicleSuccess = (payload) => {
  return {
    type: GEOFENCE.REMOVE_VEHICLE_SUCCESS,
    payload
  };
};

export const geofenceRemoveVehicleFail = (payload) => {
  return {
    type: GEOFENCE.REMOVE_VEHICLE_FAIL,
    payload
  };
};