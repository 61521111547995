import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { resolve } from 'nested-prop-resolver';
import {Hidden} from 'react-grid-system';

import { MapContainer, MapFilters, FilterElement} from './styles';
import { GoogleMap,  Circle, Rectangle, Polygon, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import PageCheckBox from '../../molecules/PageCheckBox';
import {P2} from '../../atoms/Typography';
import NotificationIcon from '../../../assets/NotificationIcon.png';
import LocationIcon from '../../../assets/LocationIcon.png';
import IncidentIcon from '../../../assets/IncidentIcon.png';
import { truncateName} from '../../../utils/helpers';
import {
    MAP_ZOOM_LEVEL,
    MAP_ANCHOR_SIZE,
    GOOGLE_MAPS_KEY
  } from '../../../constants';
  import { getDateTime } from '../../../utils/timeFormatters';

const GeoAreas = ({geofences, visible}) => {
    const shapeOptions = {
        strokeColor: '#007aff',
        strokeWeight: 1,
        fillColor: '#007aff',
        fillOpacity: 0.33                
    };
    return geofences.map((geofence, index) => {
        switch (geofence.shape.type) {
            case 'circle':
            return (
                <Circle
                    visible={visible}
                    options={shapeOptions}
                    center={ {lat: geofence.shape.geofence_center_point[0], lng: geofence.shape.geofence_center_point[1]}}
                    radius={geofence.shape.radius}
                    key={index}
                    />
            );
            case 'rectangle':
            const rectanglePoints = {
                north: resolve(geofence.shape.points, '[0][0][0]', 0),
                east: resolve(geofence.shape.points, '[0][0][1]', 0),
                south: resolve(geofence.shape.points, '[0][1][0]', 0),
                west: resolve(geofence.shape.points, '[0][2][1]', 0)
            }
            return (
                <Rectangle
                options={shapeOptions}
                bounds={rectanglePoints}
                key={index}
                visible={visible}
                />
            );
            case 'polygon':
            const polygonPoints = resolve(geofence.shape.points, '[0]', []);
            return (
                <Polygon
                options={shapeOptions}
                path={polygonPoints.map(p => ({ lat: p[0], lng: p[1] }))}
                key={index}
                visible={visible}
                />
            );
            default:
            return null;
        }
    });
};

const NotificationsMarkers = ({notifications, activeNotificationId, visible}) => {
    const [activeMarker, setActiveMarker] = useState(activeNotificationId);

    useEffect(()=>{
        setActiveMarker(activeNotificationId);
    },[activeNotificationId])

    const handleActiveMarker = (marker) =>{
        if (marker === activeMarker){
            return;
        }
        setActiveMarker(marker);
    }
    return notifications.map((item, index) => {
        return (
            <Marker
                visible={visible}
                key={index}
                position={{lat: Number(item.latitude), lng: Number(item.longitude)}}
                icon={{
                    url: NotificationIcon,
                    anchor: {
                        x: MAP_ANCHOR_SIZE,
                        y: MAP_ANCHOR_SIZE
                    }
                }}
                onClick = {()=>handleActiveMarker(item.id)}
            >
                {activeMarker === item.id && visible ? (
                    <InfoWindow position={{lat: Number(item.latitude), lng: Number(item.longitude)}} onCloseClick={() => setActiveMarker(null)}>
                        <div>
                            <strong>{item.title}</strong>
                            <br/>{item.body}
                        </div>
                    </InfoWindow>
                ) : null}
            </Marker>
        )
    })
}

const GeoMarkers = ({geofences, activeGeofenceId, visible}) => {
    const [activeMarker, setActiveMarker] = useState(null);

    useEffect(()=>{
        setActiveMarker(activeGeofenceId);
    },[activeGeofenceId])

    const handleActiveMarker = (marker) =>{
        if (marker === activeMarker){
            return;
        }
        setActiveMarker(marker);
    }
    return geofences.map((item, index) => {
        return (
            <Marker
                visible={visible}
                key={index}
                position={{lat: Number(item.shape.geofence_center_point[0]), lng: Number(item.shape.geofence_center_point[1])}}
                icon={{
                    url: LocationIcon,
                    anchor: {
                        x: MAP_ANCHOR_SIZE,
                        y: MAP_ANCHOR_SIZE
                    }
                }}
                onClick = {()=>handleActiveMarker(item.id)}               
            >
                {activeMarker === item.id && visible? (
                    <InfoWindow position={{lat: Number(item.shape.geofence_center_point[0]), lng: Number(item.shape.geofence_center_point[1])}} onCloseClick={() => setActiveMarker(null)}>
                        <div>{truncateName(item.location_name, 50)}</div>
                    </InfoWindow>
                ) : null}
            </Marker>
        )
    })
}

const IncidentMarkers = ({incidents, activeIncidentId, visible}) => {
    const [activeMarker, setActiveMarker] = useState(null);

    useEffect(()=>{
        setActiveMarker(activeIncidentId);
    },[activeIncidentId])

    const handleActiveMarker = (marker) =>{
        if (marker === activeMarker){
            return;
        }
        setActiveMarker(marker);
    }
    
    return incidents.map((item, index) => {
        if(item.event_data && item.event_data[0].sensor && item.event_data[0].sensor.latitude){
            return (
                <Marker
                    visible={visible}
                    key={index}
                    position={{lat: Number(item.event_data[0].sensor.latitude), lng: Number(item.event_data[0].sensor.longitude)}}
                    icon={{
                        url: IncidentIcon,
                        anchor: {
                            x: MAP_ANCHOR_SIZE,
                            y: MAP_ANCHOR_SIZE
                        }
                    }}
                    onClick = {()=>handleActiveMarker(item.report_id)}               
                >
                    {activeMarker === item.report_id && visible ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div>{getDateTime(item.start_at)}</div>
                        </InfoWindow>
                    ) : null}
                </Marker>
            )
        }
    })
}

const EventsMap = ({geofenceList, location, notificationsList, incidentsList, selectedGeofence, selectedNotification, selectedIncident}) => {
    const [geofences, setGeofences] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [incidents, setIncidentes] = useState([]);
    const [mapCenter, setMapCenter] = useState({lat:0,lng:0});
    const [activeNotification, setActiveNotification] = useState(null);
    const [activeGeofence, setActiveGeofence] = useState(null);
    const [activeIncident, setActiveIncident] = useState(null);
    const [showGeofences, setShowGeofence] = useState(true);
    const [showNotifications, setShowNotifications] = useState(true);
    const [showIncidents, setShowIncidents] = useState(true);
    
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_KEY
    });

    useEffect(() => {
        setGeofences(geofenceList)
        if( geofenceList.length === 0){   
            setMapCenter(location);
        }
    }, [geofenceList]); 
    
    useEffect(() => {
        setNotifications(notificationsList.slice(0, 3))
    }, [notificationsList]); 

    useEffect(() => {
        setIncidentes(incidentsList.slice(0, 3))
    }, [incidentsList]); 

    useEffect(()=>{
        if(selectedGeofence){
            setActiveGeofence(selectedGeofence.id)
            setMapCenter({lat: selectedGeofence.shape.center_point[0], lng: selectedGeofence.shape.center_point[1]});
            setShowGeofence(true);
        }
    }, [selectedGeofence]);

    useEffect(()=>{
        if(selectedNotification){
            setActiveNotification(selectedNotification.id);
            setMapCenter({lat: Number(selectedNotification.latitude), lng: Number(selectedNotification.longitude)});
            setShowNotifications(true);
        }
    }, [selectedNotification]);

    useEffect(()=>{
        if(selectedIncident){
            if(selectedIncident.event_data && selectedIncident.event_data[0].sensor && selectedIncident.event_data[0].sensor.latitude){
                setActiveIncident(selectedIncident.report_id);
                setMapCenter({lat: Number(selectedIncident.event_data[0].sensor.latitude), lng: Number(selectedIncident.event_data[0].sensor.longitude)});
                setShowIncidents(true);
            }
        }
    }, [selectedIncident]);
    
    return (
        <MapContainer>            
            <MapFilters>
                <Hidden xs sm><FilterElement><P2>Show on map:</P2></FilterElement></Hidden>
                <FilterElement><PageCheckBox value={showGeofences} onChange={()=>{setShowGeofence(!showGeofences)}}>Geofences</PageCheckBox></FilterElement>
                <FilterElement><PageCheckBox value={showNotifications} onChange={()=>{setShowNotifications(!showNotifications)}}>Notifications</PageCheckBox></FilterElement>
                <FilterElement><PageCheckBox value={showIncidents} onChange={()=>{setShowIncidents(!showIncidents)}}>Incident Reports</PageCheckBox></FilterElement>
            </MapFilters> 

            {isLoaded  && <GoogleMap           
                mapContainerStyle={{
                    height: '46rem',
                    width: '100%'
                }}
                center={mapCenter}
                zoom={MAP_ZOOM_LEVEL-2}
                version="weekly"
            >   
                <GeoAreas visible={showGeofences} geofences={geofences}/>
                <GeoMarkers visible={showGeofences} geofences={geofences}  activeGeofenceId={activeGeofence}/>
                <NotificationsMarkers visible={showNotifications} notifications={notifications}  activeNotificationId={activeNotification}/>
                <IncidentMarkers visible={showIncidents} incidents={incidents} activeIncidentId={activeIncident} />
            </GoogleMap>
            }
        </MapContainer>
    );
}

EventsMap.propTypes = {
    geofencesList: PropTypes.array,
    notificationsList: PropTypes.array,
    incidentsList: PropTypes.array,
    location: PropTypes.exact({
        lat: PropTypes.number,
        lng: PropTypes.number
    }),
    selectedGeofence: PropTypes.object,
    selectedNotification: PropTypes.object,
    selectedIncident: PropTypes.object
}

EventsMap.defaultProps = {
    geofencesList: [],
    notificationsList: [],
    incidentsList: [],
    location: {lat: 0, lng: 0}
}

export default EventsMap;