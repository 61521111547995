import styled from 'styled-components';

// export const Container = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   width: 100%;
//   height: ${({ height }) => height};
//   background-color: ${({ backgroundColor }) => backgroundColor};
//   border-top-right-radius: 0.8rem;
//   border-bottom-right-radius: 0.8rem;
//   margin-bottom: 0.8rem;
//   cursor: pointer;
// `;

// export const DetailsContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
// `;

export const Container = styled.div`
  width: 24.4rem;
  position:relative;
  z-index:99;
`;

export const Vehicle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  border-radius: 4px;
  border-bottom: 1px solid #EAECE9;
  background: #FFFFFF;
  &:hover{
    background: #CCE3ED;
  }
`;

export const SelectedContainer = styled.div`
  border: 2px solid #0071A4;
  border-radius: 3.4rem;
  cursor: pointer;
  &:hover{
    background: #CCE3ED;
  }
  & ${Vehicle} {
    border:0;
    background:transparent;
  }  
`
export const VehicleInfo = styled.div`
  margin-left:0.8rem;
  width: 14rem;
  & p, & h3 {
    margin:0;
  }
`

export const BgImage = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  background-image: url("${({source}) => source }");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4.8rem;
  margin-left: 0.4rem;
`;

export const VehicleList = styled.ul`
  padding:0;
  margin:0;
  position:absolute;
  display: ${({show}) => show? 'block' : 'none'}; 
`;

export const CheckedMarkPlaceholder = styled.div`
  width: 24px;
`;