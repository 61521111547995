import { take, call, put } from 'redux-saga/effects';
import { 
  PASSWORD,
  forgotPasswordSuccess,
  forgotPasswordFail,
  resetPasswordSuccess,
  resetPasswordFail
} from '../actions/password';
import { forgotPassword, resetPassword } from '../../api/password';

export function* handleForgotPassword() {
  while(true) {
    const { payload } = yield take(PASSWORD.FORGOT_START);
    try {
      const response = yield call(forgotPassword, payload);
      yield put(forgotPasswordSuccess(payload.email));
    } catch (error) {
      yield put(forgotPasswordFail(error.toString()));
    }
  }
}

export function* handleResetPassword() {
  while(true) {
    const { payload } = yield take(PASSWORD.RESET_START);
    try {
      const response = yield call(resetPassword, payload);
      yield put(resetPasswordSuccess());
    } catch (error) {
      yield put(resetPasswordFail(error));
    }
  }
}
