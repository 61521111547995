import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './VBox.style';

const VBox = ({ children, padding, margin, justify, align, gap, height }) => {
  return (
    <Container data-testid="container" 
      padding={padding} 
      margin={margin} 
      justify={justify} 
      align={align}
      gap={gap}
      height={height}
    >
      {children}
    </Container>
  );
};

VBox.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
  margin: PropTypes.string,
  justify: PropTypes.oneOf(['center', 'space-between', 'space-evenly', 'space-around', 'flex-start', 'flex-end']),
  align: PropTypes.oneOf(['center', 'stretch', 'flex-start', 'flex-end']),
  gap: PropTypes.string,
  height: PropTypes.string
};

VBox.defaultProps = {
  padding: '0rem',
  margin: '0rem',
  justify: null,
  align: null,
  gap: null,
  height:null
};

export default VBox;
