import { createHttpClient, HTTP_METHOD } from './httpClient';

export const getAll = (params) => {
  return createHttpClient(`/v1/web/incident/get_all_video_recordings?ut=usr&vehicle_id=${params.vehicle_id}` , HTTP_METHOD.GET);
};

export const deleteVideo = (params) => {
  return createHttpClient(`/v1/web/user-media/delete?ut=usr` , HTTP_METHOD.POST, params);
};

export const getAllPhotos = (params) => {
  return createHttpClient(`/v1/web/incident/images?ut=usr&vehicle_id=${params.vehicle_id}` , HTTP_METHOD.GET);
};

