import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentContainer = styled.div`
  justify-content: flex-start;
  padding: 0 1.6rem;
  position: relative;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: stretch;
  padding: 1.7rem 1.6rem;
`;

export const DevicesContainer = styled.div`
  margin-top:1.6rem;
`;