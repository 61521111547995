import React from 'react';
import PropTypes from 'prop-types';

import {Text2} from '../../atoms/Typography';
import { Container, Circle } from './Switch.styles';
import HBox from '../HBox';

const Switch = ({ margin, value, onChange, showText }) => {
  const onClick = () => {
    onChange(!value);
  };

  return (
    <HBox align='center' justify='flex-end'>
      <Container margin={margin} isEnabled={value} onClick={onClick}>
        <Circle isEnabled={value}/>
      </Container>
      {showText && <Text2>{value? "On" : "Off"}</Text2>}
    </HBox>
  );
};

Switch.propTypes = {
  margin: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  showText: PropTypes.bool
};

Switch.defaultProps = {
  margin: '0',
  value: false,
  onChange: () => {},
  showText: true
};

export default Switch;
