import React from 'react';
import PropTypes from 'prop-types';

import { LinkElement } from './Link.style';

const Link = ({ margin, children, color, onClick }) => {
  return (
    <LinkElement margin={margin} color={color} onClick={onClick}>
      {children}
    </LinkElement>
  );
};

Link.propTypes = {
  margin: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

Link.defaultProps = {
  margin: '0',
  color: '#0071A4'
};

export default Link;
