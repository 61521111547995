import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-grid-system';

import { getDateTime } from '../../../utils/timeFormatters';
import Card from '../../molecules/Card';
import PDFLink from '../../molecules/PDFLink';
import Loader from '../../atoms/Loader';
import {Text2, Text3} from '../../atoms/Typography';
import Link from '../../atoms/Link';
import DeleteIcon  from '../../../assets/DeleteIcon';
import DownloadIcon  from '../../../assets/DownloadIcon';
import CheckMarkIcon from '../../../assets/CheckMarkIcon';

import {ListHeader, ListItem, LinkContainer, Button, Status} from './styles';


const HomeIncidentsReports = ({incidents, onIncidentSelect, onShowIncidentsPopup, handleClickSend, onDeleteIncidentReport, isLoading}) => {
    const incidentsList = incidents.slice(0, 3);
    
    const handleClickItem = (incident) =>{
        onIncidentSelect(incident);
    }
    return(
        <Card height='35rem' title='Incident Reports'>
            <ListHeader>
                <Row gutterWidth={10}>
                    <Col xs={3}><Text2>Status</Text2></Col>
                    <Col xs={3}><Text2>Date/Time</Text2></Col>
                    <Col xs={3}><Text2></Text2></Col>
                    <Col xs={3}></Col>
                </Row>
            </ListHeader>
            {isLoading &&
                <center>
                    <Loader/>
                </center>
            }
            {!isLoading && incidentsList.length>0 ?
                (incidentsList.map((item, i) => (
                    <ListItem key={i} onClick={()=>handleClickItem(item)}>
                        <Row gutterWidth={10} align="center">
                            <Col xs={3}>
                                <Status sent={!!item.report_url}>
                                    <Text3 color="#fff">{!!item.report_url? "Sent": "Not Sent"}</Text3>
                                </Status>
                            </Col>
                            <Col xs={3}><Text2>{getDateTime(item.start_at)}</Text2></Col>
                            <Col xs={3} >
                                    <Link onClick={()=>handleClickSend(item)}>Send</Link>
                            </Col>
                            <Col xs={3}>
                                {item.report_url?
                                    <a href={item.report_url} target="_blank"><DownloadIcon /></a>
                                :
                                    <PDFLink report={item}><DownloadIcon/></PDFLink>
                                }
                                <Button onClick={()=>{onDeleteIncidentReport(item)}}><DeleteIcon /></Button>
                            </Col>
                        </Row>  
                    </ListItem>
                )))
                :
                (!isLoading &&
                    <ListItem>
                        <Row>
                            <Col xs={12}><Text2>No Incident Reports</Text2></Col>
                        </Row>
                    </ListItem>    
                )

            }
            <br/>
            {incidentsList.length > 0 &&
                <LinkContainer>
                    <Link onClick={onShowIncidentsPopup}>See All</Link>
                </LinkContainer>
            }
        </Card>
    );
};

HomeIncidentsReports.propTypes = {
    incidents: PropTypes.array,
    onIncidentSelect: PropTypes.func.isRequired,
    onShowIncidentsPopup: PropTypes.func.isRequired,
    handleClickSend: PropTypes.func.isRequired,
    onDeleteIncidentReport: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
}

HomeIncidentsReports.defaultProps = {
    incidents: [],
    isLoading: false
}

export default HomeIncidentsReports;