export const PROFILE = {
  // Get
  GET_START: 'PROFILE_GET_START',
  GET_SUCCESS: 'PROFILE_GET_SUCCESS',
  GET_FAIL: 'PROFILE_GET_FAIL',
  //Update
  UPDATE_START: 'PROFILE_UPDATE_START',
  UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  UPDATE_FAIL: 'PROFILE_UPDATE_FAIL',
  //Update emergency
  EMERGENCY_UPDATE_START: 'PROFILE_EMERGENCY_UPDATE_START',
  EMERGENCY_UPDATE_SUCCESS: 'PROFILE_EMERGENCY_UPDATE_SUCCESS',
  EMERGENCY_UPDATE_FAIL: 'PROFILE_EMERGENCY_UPDATE_FAIL',
  //Update incident reprting
  INCIDENT_REPORTING_UPDATE_START: 'PROFILE_INCIDENT_REPORTING_UPDATE_START',
  INCIDENT_REPORTING_UPDATE_SUCCESS: 'PROFILE_INCIDENT_REPORTING_UPDATE_SUCCESS',
  INCIDENT_REPORTING_UPDATE_FAIL: 'PROFILE_INCIDENT_REPORTING_UPDATE_FAIL',
};

export const profileGetStart = () => {
  return {
    type: PROFILE.GET_START
  };
};

export const profileGetSuccess = (payload) => {
  return {
    type: PROFILE.GET_SUCCESS,
    payload
  };
};

export const profileGetFail = (payload) => {
  return {
    type: PROFILE.GET_FAIL,
    payload
  };
};

export const profileUpdateStart = (payload) => {
  return {
    type: PROFILE.UPDATE_START,
    payload
  };
};

export const profileUpdateSuccess = (payload) => {
  return {
    type: PROFILE.UPDATE_SUCCESS,
    payload
  };
};

export const profileUpdateFail = (payload) => {
  return {
    type: PROFILE.UPDATE_FAIL,
    payload
  };
};

export const emergencyUpdateStart = (payload) => {
  return {
    type: PROFILE.EMERGENCY_UPDATE_START,
    payload
  };
};

export const emergencyUpdateSuccess = (payload) => {
  return {
    type: PROFILE.EMERGENCY_UPDATE_SUCCESS,
    payload
  };
};

export const emergencyUpdateFail = (payload) => {
  return {
    type: PROFILE.EMERGENCY_UPDATE_FAIL,
    payload
  };
};

export const incidentReportingUpdateStart = (payload) => {
  return {
    type: PROFILE.INCIDENT_REPORTING_UPDATE_START,
    payload
  };
};

export const incidentReportingUpdateSuccess = (payload) => {
  return {
    type: PROFILE.INCIDENT_REPORTING_UPDATE_SUCCESS,
    payload
  };
};

export const incidentReportingUpdateFail = (payload) => {
  return {
    type: PROFILE.INCIDENT_REPORTING_UPDATE_FAIL,
    payload
  };
};

