import React from 'react';

const GsensorIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 4C19.2133 4.01333 23.4958 7.95504 24 13H19.0625V9.99996L15.0325 14L19.0625 18V15H24C23.4942 20.0533 19.1992 24 13.975 24H14L13.7733 23.9966C8.66333 23.8716 4.4975 19.9716 4 15H8.9375V18L12.9675 14L8.9375 9.99996V13H4C4.49667 8.02837 8.66333 4.12752 13.7733 4.00336L14 4ZM14.025 13C13.4692 13 13.0175 13.4475 13.0175 14C13.0175 14.5525 13.4692 15 14.0258 15C14.5817 15 15.0325 14.5525 15.0325 14C15.0325 13.4475 14.5817 13 14.025 13Z" fill="black"/>
    </svg>
  );
};

export default GsensorIcon;