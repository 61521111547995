import React from 'react';
import PropTypes from 'prop-types';

const DeleteIcon = ({ color, className }) => {
  return (
    <svg className={className} width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.69824 20.2686H12.7754C14.1289 20.2686 14.999 19.4512 15.0693 18.0977L15.6582 5.44141H16.625C17.0732 5.44141 17.416 5.08984 17.416 4.65039C17.416 4.21094 17.0645 3.87695 16.625 3.87695H12.7227V2.55859C12.7227 1.20508 11.8613 0.414062 10.3936 0.414062H7.05371C5.58594 0.414062 4.72461 1.20508 4.72461 2.55859V3.87695H0.839844C0.400391 3.87695 0.0488281 4.21973 0.0488281 4.65039C0.0488281 5.09863 0.400391 5.44141 0.839844 5.44141H1.80664L2.39551 18.0977C2.46582 19.46 3.32715 20.2686 4.69824 20.2686ZM6.36816 2.6377C6.36816 2.18945 6.68457 1.89941 7.16797 1.89941H10.2793C10.7627 1.89941 11.0791 2.18945 11.0791 2.6377V3.87695H6.36816V2.6377ZM4.87402 18.6953C4.39062 18.6953 4.03906 18.335 4.0127 17.8164L3.42383 5.44141H14.0146L13.4434 17.8164C13.4258 18.3438 13.083 18.6953 12.582 18.6953H4.87402ZM6.13965 17.2803C6.51758 17.2803 6.75488 17.043 6.74609 16.6914L6.48242 7.49805C6.47363 7.14648 6.22754 6.91797 5.86719 6.91797C5.49805 6.91797 5.26074 7.15527 5.26953 7.50684L5.5332 16.7002C5.54199 17.0518 5.78809 17.2803 6.13965 17.2803ZM8.73242 17.2803C9.10156 17.2803 9.35645 17.0518 9.35645 16.7002V7.50684C9.35645 7.15527 9.10156 6.91797 8.73242 6.91797C8.36328 6.91797 8.11719 7.15527 8.11719 7.50684V16.7002C8.11719 17.0518 8.36328 17.2803 8.73242 17.2803ZM11.3252 17.2891C11.6768 17.2891 11.9229 17.0518 11.9316 16.7002L12.1953 7.50684C12.2041 7.15527 11.9668 6.92676 11.5977 6.92676C11.2373 6.92676 10.9912 7.15527 10.9824 7.50684L10.7188 16.7002C10.71 17.043 10.9473 17.2891 11.3252 17.2891Z" fill={color}/>
    </svg>
  );
};

DeleteIcon.propTypes = {
  color: PropTypes.string
};

DeleteIcon.defaultProps = {
  color: '#E24444'
}

export default DeleteIcon;
