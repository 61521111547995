import React from 'react';

import { Container } from './styles';
import CenterWrapper from '../../atoms/CenterWrapper';
import OverlayContainer from '../../atoms/OverlayContainer';

const AuthContainer = ({children}) => {
  return (
    <Container>
        <CenterWrapper>
            <OverlayContainer padding="7rem 4rem" width="40rem">
                {children}
            </OverlayContainer>
        </CenterWrapper>
    </Container>
  );
};

export default AuthContainer;
