import React from 'react';

const LocationIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="location.north.circle">
        <g id="location.north.circle_2">
          <path d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM10.0361 17.2842C9.77246 17.9521 10.4844 18.4443 11.0557 17.8818L13.7627 15.166C13.9033 15.0254 14.0791 15.0254 14.2109 15.166L16.9268 17.8818C17.4893 18.4443 18.21 17.9521 17.9375 17.2842L14.668 8.96094C14.4131 8.31055 13.5605 8.31934 13.2969 8.97852L10.0361 17.2842Z" fill="#363F45"/>
          <path d="M14 22.7334C18.9658 22.7334 23.0791 18.6289 23.0791 13.6543C23.0791 8.68848 18.9658 4.5752 13.9912 4.5752C9.02539 4.5752 4.9209 8.68848 4.9209 13.6543C4.9209 18.6289 9.03418 22.7334 14 22.7334ZM14 20.9492C9.95703 20.9492 6.71387 17.6973 6.71387 13.6543C6.71387 9.61133 9.94824 6.36816 13.9912 6.36816C18.0342 6.36816 21.2861 9.61133 21.2949 13.6543C21.2949 17.6973 18.043 20.9492 14 20.9492ZM10.0361 17.2842C9.77246 17.9521 10.4844 18.4443 11.0557 17.8818L13.7627 15.166C13.9033 15.0254 14.0791 15.0254 14.2109 15.166L16.9268 17.8818C17.4893 18.4443 18.21 17.9521 17.9375 17.2842L14.668 8.96094C14.4131 8.31055 13.5605 8.31934 13.2969 8.97852L10.0361 17.2842Z" fill="black" fillOpacity="0.2"/>
        </g>
      </g>
    </svg>

  );
};

export default LocationIcon;