import React from 'react';
import PropTypes from 'prop-types';

import { TextElement } from './Text.styles';

const Text = ({ fontSize, fontStyle, color, margin, fontWeight, children }) => {
  return (
    <TextElement
      fontSize={fontSize}
      fontStyle={fontStyle}
      color={color}
      margin={margin}
      fontWeight={fontWeight}
    >
      {children}
    </TextElement>
  );
};

Text.propTypes = {
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string, 
  fontStyle: PropTypes.oneOf(['normal', 'italic']), 
  color: PropTypes.string, 
  margin: PropTypes.string, 
  fontWeight: PropTypes.number,
  children: PropTypes.node.isRequired
};

Text.defaultProps = {
  fontSize: '1.4rem', 
  fontStyle: 'normal', 
  color: 'black', 
  margin: '0rem', 
  fontWeight: 400,
};

export default Text;
