import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 0 1.6rem;
  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

export const MenuButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items:center;
`;
