import styled from 'styled-components';

export const MapContainer = styled.div`
    position: relative;
`;

export const FilterElement = styled.div`
    float:left;
`;

export const MapFilters = styled.div`
    display: flex;
    position: absolute;
    top: 1rem;
    left: 1rem;
    background: #F5F5F5;
    box-shadow: -2px 2px 16px rgba(36, 36, 36, 0.15);
    border-radius: 0.8rem;
    padding: 0 1.6rem;
    z-index: 9;
    @media (max-width: 768px) {
        position:static;
        display:block;
        padding:0;
    }
`;