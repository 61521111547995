export const LOCATION = {
  GET_START: 'LOCATION_GET_START',
  GET_SUCCESS: 'LOCATION_GET_SUCCESS',
  GET_FAIL: 'LOCATION_GET_FAIL'
};

export const locationGetStart = () => {
  return {
    type: LOCATION.GET_START
  };
};

export const locationGetSuccess = (payload) => {
  return {
    type: LOCATION.GET_SUCCESS,
    payload
  };
};

export const locationGetFail = (payload) => {
  return {
    type: LOCATION.GET_FAIL,
    payload
  };
};
