import { all } from 'redux-saga/effects';
import * as userSaga from './userSaga';
import * as passwordSaga from './passwordSaga';
import * as profileSaga from './profileSaga';
import * as vehicleSaga from './vehicleSaga';
import * as videoSaga from './videoSaga';
import * as geofenceSaga from './geofenceSaga';
import * as incidentSaga from './incidentSaga';
import * as locationSaga from './locationSaga';
import * as notificationSaga from './notificationSaga';
import * as activateAccountSaga from './activationAccountSaga';
import * as countriesSaga from './countriesSaga';
import * as streamSaga from './streamSaga';
import * as tripnodeSaga from './tripnodeSaga';
import * as tripSaga from './tripSaga';
import * as accountCleanupSaga from './accountCleanupSaga';
import * as accountDeletionSaga from './accountDeletionSaga';

function* rootSaga() {
  yield all([
    userSaga.handleLogin(),
    userSaga.handleLoginSuccess(),
    userSaga.handleLogout(),
    activateAccountSaga.handleActivation(),
    activateAccountSaga.handleResendActivationLink(),
    passwordSaga.handleForgotPassword(),
    passwordSaga.handleResetPassword(),
    profileSaga.handleProfileGetStart(),
    profileSaga.handleProfileUpdateStart(),
    profileSaga.handleProfileEmergencyUpdateStart(),
    profileSaga.handleProfileIncidentReportingUpdateStart(),
    vehicleSaga.handleVehicleGetStart(),
    vehicleSaga.handleVehicleAddStart(),
    vehicleSaga.handleVehicleEditStart(),
    vehicleSaga.handleVehicleDeleteStart(),
    vehicleSaga.handleVehicleImageUploadStart(),
    vehicleSaga.handleVehicleSearchStart(),
    vehicleSaga.handleVehicleDeleteDeviceStart(),
    vehicleSaga.handleVehicleTransferDevicesStart(),
    vehicleSaga.handleVehicleGetDevicesStart(),
    videoSaga.handleVideoGetAllStart(),
    videoSaga.handleVideoDeleteStart(),
    geofenceSaga.handleGeofenceGetStart(),
    geofenceSaga.handleGeofenceAddStart(),
    geofenceSaga.handleGeofenceEditStart(),
    geofenceSaga.handleGeofenceDeleteStart(),
    geofenceSaga.handleGeofenceAddVehicleStart(),
    geofenceSaga.handleGeofenceRemoveVehicleStart(),
    incidentSaga.handleIncidentGetAllStart(),
    incidentSaga.handleIncidentSendStart(),
    incidentSaga.handleIncidentDeleteStart(),
    locationSaga.handleLocationGetStart(),
    notificationSaga.handleNotificationGetStart(),
    notificationSaga.handleNotificationDeleteStart(),
    notificationSaga.handleNotificationDeleteAllStart(),
    countriesSaga.handleCountriesGetStart(),
    streamSaga.handleStreamStart(),
    streamSaga.handleStreamStop(),
    streamSaga.handleContinueStream(),
    tripnodeSaga.handleGetTripNodeStart(),
    tripSaga.handleGetTripNodesStart(),
    accountCleanupSaga.handleRequestAccountCleanup(),
    accountCleanupSaga.handleAccountCleanup(),
    accountDeletionSaga.handleRequestAccountDeletion(),
    accountDeletionSaga.handleAccountDeletion(),
  ]);
}

export default rootSaga;
