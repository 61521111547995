import { take, call, put } from 'redux-saga/effects';
import { 
  COUNTRIES,
  countriesGetSuccess,
  countriesGetFail
} from '../actions/countries';
import { getCountries } from '../../api/countries';

export function* handleCountriesGetStart() {
  while(true) {
    yield take(COUNTRIES.GET_START);
    try {
      const response = yield call(getCountries);
      yield put(countriesGetSuccess(response));
    } catch (error) {
      yield put(countriesGetFail(error.toString()));
    }
  }
}