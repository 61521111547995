import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    @media(max-width:991px){
        flex-direction:row;
        flex-wrap: wrap;
    }
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    @media(max-width:991px){
        flex-direction:row;
        flex-wrap: wrap;
    }
`;