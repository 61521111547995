import React, {useState, useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {Container, Nav, NavigationMenu, List, ListElement, LogoContainer} from './Navbar.styles';
import Logo from '../../../assets/logo-white.png';
import Image from '../../atoms/Image';
import BurgerButton from '../../atoms/BurgerButton';
import TextMenuItem from '../../molecules/TextMenuItem';
import NavLink from '../../atoms/NavLink';


const NavBar = () => {
    const nav = useHistory();
    const [isNavExpanded, setIsNavExapanded] = useState(false);

    const onMenuClick = (value) => {
        setIsNavExapanded(false);
        nav.push(value);
    };

    const navLinks = [
        {
            link: 'https://drivesmarter.com/solutions/',
            label: 'Solutions'
        },
        {
            link: 'https://drivesmarter.com/product/',
            label: 'Products'
        },
        {
            link: 'https://drivesmarter.com/about/',
            label: 'About'
        },
        {
            link: 'https://drivesmarter.com/support/',
            label: 'Support'
        }
    ]

    return (
        <Container>
            <Nav>
                <LogoContainer>
                    <Image src={Logo}/>
                </LogoContainer>    
                <BurgerButton onClick ={()=>{setIsNavExapanded(!isNavExpanded)}}/>
                <NavigationMenu expanded={isNavExpanded}>
                    <List expanded={isNavExpanded}>
                        {navLinks.map((el) =>(
                            <ListElement key={el.label} >
                                <NavLink href={el.link}>{el.label}</NavLink>
                            </ListElement>
                        ))}
                        <ListElement>    
                            <TextMenuItem color="#33627c" onClick={() => onMenuClick('/login')}>Login</TextMenuItem>
                        </ListElement>
                    </List>
                </NavigationMenu>
            </Nav>
        </Container>
    );
};

export default NavBar;