export const ACCOUNT_DELETION = {
    REQUEST_START: 'ACCOUNT_DELETION_REQUEST_START',
    REQUEST_SUCCESS: 'ACCOUNT_DELETION_REQUEST_SUCCESS',
    REQUEST_FAIL: 'ACCOUNT_DELETION_REQUEST_FAIL',
    START: 'ACCOUNT_DELETION_START',
    SUCCESS: 'ACCOUNT_DELETION_SUCCESS',
    FAIL: 'ACCOUNT_DELETION_FAIL',
}

export const accountDeletionRequestStart = (payload) => {
    return {
        type: ACCOUNT_DELETION.REQUEST_START,
        payload,
    };
};

export const accountDeletionRequestSuccess = (payload) => {
    return {
        type: ACCOUNT_DELETION.REQUEST_SUCCESS,
        payload,
    };
};

export const accountDeletionRequestFail = (payload) => {
    return {
        type: ACCOUNT_DELETION.REQUEST_FAIL,
        payload,
    };
};

export const accountDeletionStart = (payload) => {
    return {
        type: ACCOUNT_DELETION.START,
        payload,
    };
};

export const accountDeletionSuccess = (payload) => {
    return {
        type: ACCOUNT_DELETION.SUCCESS,
        payload,
    };
};

export const accountDeletionFail = (payload) => {
    return {
        type: ACCOUNT_DELETION.FAIL,
        payload,
    };
};