import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';

import Link from '../../atoms/Link';
import Button from '../../atoms/Button';
import {H1, P1} from '../../atoms/Typography';
import CenterWrapper from '../../atoms/CenterWrapper';
import AuthContainer from '../../organisms/AuthContainer';
import { activationStart, resendActivationLinkStart } from '../../../redux/actions/accountActivation';
import {errorResendActivationLinkAgain, notify, errorNotification, warningNotification} from '../../../utils/notifications';


const VerifyAccount = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const activation = useSelector(state => state.activation);
    const [error, setError] = useState(false);
    const [userDetails, setUserDetails] = useState(false);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    

    useEffect(()=>{
        if(params.has('token')){
            try {
                const decodedToken = jwtDecode(token);
                console.log(decodedToken);
                setUserDetails(decodedToken);
                dispatch(activationStart({confirmToken: token}));
            } catch (error) {
                setError(true);
            }
        }
    },[token]);

    useEffect(()=>{
        if(activation.error){
            switch(activation.error.status){
                case 'INVALID_OR_EXPIRED_TOKEN': {
                    const resendActivationLink = async () => {
                        const resend = await errorResendActivationLinkAgain();
                        if(resend){
                            dispatch(resendActivationLinkStart({email: userDetails.email}));
                        }else{
                            setError(true);
                        }
                    }
                    resendActivationLink().catch(()=>{
                        setError(true);
                        errorNotification({title:'Something went wrong', text:'Please try again later!'})
                    });
                    return;
                }
                case 'EMAIL_ALREADY_VERIFIED': 
                    warningNotification({title:'', text:'Email has already been verified. Try to login!'})
                    return;
                case 'USER_NOT_REGISTERED': 
                    setError(true);
                    errorNotification({title:'', text:'You do not have a Smart Driver account.'})
                    return;
                case 'TO_MANY_ATTEMPTS_ON_ACTIVATION_EMAIL': 
                    setError(true);
                    errorNotification({title:'', text:'Activation link has been sent on email a few seconds ago. Please try again later.'})
                    return;
                default:
                    setError(true);
                    errorNotification({text: 'We could not activate your account.'})
            }
        }

        if(activation.data && activation.data.message){
            notify({title:'', text: activation.data.message})
            setError(false);
        }
    },[activation]);

    const toLogin = () =>{
        history.push('/login');
    };

    const openApp = () =>{
        window.location = 'drivesmarter://resetpassword';
    };

    return (
    <AuthContainer>
        {
            error ?
            (<>
                <CenterWrapper>
                    <H1 fontSize='3rem'>ACTIVATION ERROR!</H1>
                </CenterWrapper>
                <P1> We could not activate your account, please try again later!</P1>
            </>):(
                activation.isLoading ?
                <>
                    <CenterWrapper>
                        <H1 fontSize='3rem'>VALIDATING...</H1>
                    </CenterWrapper>
                    <P1>Validating the account, please wait...</P1>
                </>
                :
                <>
                    <CenterWrapper>
                        <H1 fontSize='3rem'>CONGRATULATIONS!</H1>
                    </CenterWrapper>
                    <P1>Your Drive Smarter account <strong>{userDetails.email}</strong> has been verified and is now ready to use.</P1>
                    <P1>You can now log into your account using your Drive Smarter mobile app.</P1>
                </>  
            )
        }
        
        <CenterWrapper>
            <Link onClick={()=>toLogin()}>Login to User Portal</Link>
            {userDetails.openApp && isMobile && <Button  onClick={()=>openApp()}>Go to DriveSmarter App</Button>}
        </CenterWrapper>
    </AuthContainer>
  );
};

export default VerifyAccount;
