import { take, call, put } from 'redux-saga/effects';
import { 
  GEOFENCE,
  geofenceGetAllStart,
  geofenceGetAllSuccess,
  geofenceGetAllFail,
  geofenceCreateSuccess,
  geofenceCreateFail,
  geofenceEditSuccess,
  geofenceEditFail,
  geofenceDeleteSuccess,
  geofenceDeleteFail,
  geofenceAddVehicleSuccess,
  geofenceAddVehicleFail,
  geofenceRemoveVehicleSuccess,
  geofenceRemoveVehicleFail
} from '../actions/geofence';
import { 
  getAllGeofences, 
  addGeofence, 
  editGeofence, 
  deleteGeofence,
  addVehicleToGeofence,
  removeVehicleFromGeofence 
} from '../../api/geofence';

export function* handleGeofenceGetStart() {
  while(true) {
    yield take(GEOFENCE.GET_ALL_START);
    try {
      const response = yield call(getAllGeofences);
      yield put(geofenceGetAllSuccess(response));
    } catch (error) {
      yield put(geofenceGetAllFail(error.toString()));
    }
  }
}

export function* handleGeofenceAddStart() {
  while(true) {
    const { payload } = yield take(GEOFENCE.CREATE_START);
    try {
      yield call(addGeofence, payload);
      yield put(geofenceCreateSuccess(payload));
      yield put(geofenceGetAllStart());
    } catch (error) {
      yield put(geofenceCreateFail(error));
    }
  }
}

export function* handleGeofenceEditStart() {
  while(true) {
    const { payload } = yield take(GEOFENCE.EDIT_START);
    try {
      yield call(editGeofence, payload);
      yield put(geofenceEditSuccess(payload));
      yield put(geofenceGetAllStart());
    } catch (error) {
      yield put(geofenceEditFail(error.toString()));
    }
  }
}

export function* handleGeofenceDeleteStart() {
  while(true) {
    const { payload } = yield take(GEOFENCE.DELETE_START);
    try {
      yield call(deleteGeofence, payload);
      yield put(geofenceDeleteSuccess(payload));
      yield put(geofenceGetAllStart());
    } catch (error) {
      yield put(geofenceDeleteFail(error.toString()));
    }
  }
}

export function* handleGeofenceAddVehicleStart() {
  while(true) {
    const { payload } = yield take(GEOFENCE.ADD_VEHICLE_START);
    try {
      yield call(addVehicleToGeofence, payload);
      yield put(geofenceAddVehicleSuccess(payload));
      yield put(geofenceGetAllStart());
    } catch (error) {
      yield put(geofenceAddVehicleFail(error));
    }
  }
}

export function* handleGeofenceRemoveVehicleStart() {
  while(true) {
    const { payload } = yield take(GEOFENCE.REMOVE_VEHICLE_START);
    try {
      yield call(removeVehicleFromGeofence, payload);
      yield put(geofenceRemoveVehicleSuccess(payload));
      yield put(geofenceGetAllStart());
    } catch (error) {
      yield put(geofenceRemoveVehicleFail(error.toString()));
    }
  }
}
