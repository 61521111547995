import styled from 'styled-components';

export const Container = styled.div`
    text-align: right;
`;

export const VideoContainer = styled.div`
    background: #F4F5F6;
    cursor: pointer;
`;

export const ListItem = styled.div`
    padding: 0.8rem 1.6rem;
    background: #F4F5F6;
    border: 1px solid #EAECE9;
    border-radius: 4px;
    margin-top: 8px;
    &:hover{
        background:#EAECE9;
    }
`;