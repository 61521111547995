import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Col, Row, Visible } from 'react-grid-system';
import { resolve } from 'nested-prop-resolver';
import ReactDOM from 'react-dom';

import GalleryMap from '../../organisms/GalleryMap';
import GalleryVideoPlayer from '../../organisms/GalleryVideoPlayer';
import GalleryVideoCollection from '../../organisms/GalleryVideoCollection';
import VehicleSelector from '../../molecules/VehicleSelector';
import VBox from '../../atoms/VBox';
import HBox from '../../atoms/HBox';
import Button from '../../atoms/Button';
import { Spacer, CircleButtonWrapper } from './styles';
import { 
  videoLoadSelected,
  videoDeleteStart
} from '../../../redux/actions/video';
import {getNodesStart} from '../../../redux/actions/trip';
import DownloadVideoIcon from '../../../assets/DownloadVideoIcon';
import DeleteIcon from '../../../assets/DeleteIcon';
import ConfirmDeletePopup from '../../molecules/ConfirmDeletePopup';
import Snackbar from '../../molecules/Snackbar';
import * as DOMPurify from 'dompurify';

const Gallery = () => {
  const dispatch = useDispatch();
  const video = useSelector((state) => state.video);
  const vehicle = useSelector((state) => state.vehicle);
  const videoError = useSelector((state) => state.video.errorAll);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const [currentVideoPlaySecond, setCurrentVideoPlaySecond] = useState(0);
  const [showConfirmDeleteVideo, setShowConfirmDeleteVideo] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    type: 'success',
    show: false
  });

  useEffect(() => {
    // Set a default video for the player, when there is no selected video
    if (video.selected === null) {
      const allVideos = resolve(video, 'all.all_recordings', []);
      if (allVideos.length > 0) {
        dispatch(videoLoadSelected(allVideos[0]));
        dispatch(getNodesStart({tripId: allVideos[0].trip_id}));
      }
    }
    if(video.deletedSuccessfuly){
      setNotification({...notification, show:true});
    }
    if(video.error){
      setNotification({text: 'An error occurred', type:'error', show:true})
    }
  }, [video]);

  const openConfirm = () => {
    setShowConfirmDeleteVideo(true);
    if(video.selected.video_url){
      setNotification({
        message: 'Video deleted successfully',
        type: 'success',
        show: false
      });
    }else{
      setNotification({
        message: 'Photo deleted successfully',
        type: 'success',
        show: false
      });
    }
  }

  const handleDeleteVideo = async () => {    
    dispatch(videoDeleteStart({video_url:video.selected.video_url? video.selected.video_url : video.selected.trip_node[0].image_url, selectedVehicle: vehicle.selectedVehicle}))
    setShowConfirmDeleteVideo(false);
  }

  const download = () => {
    let url ="";
    let name="";
    if(video.selected.video_url){
      url = video.selected.video_url;
      name = "my-video"
    }else{
      url = video.selected.trip_node[0].image_url;
      name = "my-image"
    }
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    setFetching(true);
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        setFetching(false);
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = DOMPurify.sanitize(blobURL);
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
        setNotification({message: 'File downloaded successfully', type:'success',  show: true })
      })
      .catch(() => setError(true));
  };

  return (
    <Container fluid>
        {notification && notification.show && ReactDOM.createPortal(
          <Snackbar 
            onClose={()=>setNotification(null)} 
            text={notification.message}
            type={notification.type}/>,
          document.getElementById('root-notification'))}
        <Row>
          <Col>
            <VehicleSelector/>
          </Col>
        </Row>
        <Spacer/>
        <Row>
          <Col lg={8}>
              <Row>
                <Col>
                  <GalleryVideoPlayer 
                    onDeleteVideo={()=>openConfirm()} 
                    onDownloadVideo={()=>download()}
                    isDownloading={fetching}
                    handleVideoTimeUpdate={(second)=>setCurrentVideoPlaySecond(second)}
                  />
                </Col>
              </Row>
              <Spacer/>
              <Row>
                <Col>
                  <GalleryMap 
                    currentVideoPlaySecond={currentVideoPlaySecond}
                  />
                  {video.selected &&
                    <Visible xs>
                      <VBox gap="1.6rem" margin="1.5rem 0">           
                        <Button isLoading={fetching} onClick={()=>download(video.selected.video_url, "my-video")}>
                          <HBox gap="0.8rem" align="center">
                            {fetching ?
                              <>Downloading...</>:
                              <><DownloadVideoIcon color="#fff"/>Download</>
                            }
                          </HBox>
                        </Button>
                        <CircleButtonWrapper onClick={() => handleDeleteVideo()}>
                          <DeleteIcon color="#fff" />
                        </CircleButtonWrapper>
                      </VBox>
                    </Visible>
                  }
                </Col>
              </Row>  
          </Col>
          <Col lg={4} >
            <GalleryVideoCollection />
          </Col>
        </Row>
        {showConfirmDeleteVideo && 
          (video.selected.video_url ? 
            <ConfirmDeletePopup
              title="Delete Video"
              description="Are you sure you want to delete this video? It will be deleted from User Portal and will no longer be accessible." 
              onCancel={()=>setShowConfirmDeleteVideo(false)} 
              onDelete={handleDeleteVideo}
              deleteButtonText="Delete Video"
            />
          :
            <ConfirmDeletePopup
              title="Delete Photo"
              description="Are you sure you want to delete this photo? It will be deleted from User Portal and will no longer be accessible." 
              onCancel={()=>setShowConfirmDeleteVideo(false)} 
              onDelete={handleDeleteVideo}
              deleteButtonText="Delete Photo"
            />
          )}
    </Container>
  );
};

export default Gallery;
