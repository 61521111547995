import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { resolve } from 'nested-prop-resolver';
import { Container, MenuButton } from './styles';
import UserProfilePopup from '../../organisms/UserProfilePopup';
import Text from '../../atoms/Text';
import Loader from '../../atoms/Loader';
import ProfileIcon from '../../../assets/ProfileIcon';
import MenuDropDownIcon from '../../../assets/MenuDropDownIcon';
import { DEFAULT_UNKNOWN_DATA } from '../../../constants';
import {P2} from '../../atoms/Typography';
import Snackbar from '../../molecules/Snackbar';

const ProfileMenu = () => {
  const profile = useSelector(state => state.profile);
  const [toggleProfile, setToggleProfile] = useState(false);
  const [notification, setNotification] = useState({
    message: null,
    type: 'success'
  });
  const name = resolve(profile, 'data.my_information.firstname', DEFAULT_UNKNOWN_DATA);

  const onToggleProfile = () => {
    setToggleProfile(!toggleProfile);
  };

  useEffect(()=>{
    if(profile.messageSuccess){
      setNotification({text: profile.messageSuccess, type: 'success'});
    }
    if(profile.error){
      setNotification({text:'An error occurred', type: 'error'});
    }
  },[profile])

  return (
    <Container>
      {notification && notification.text && ReactDOM.createPortal(
        <Snackbar 
          onClose={()=>setNotification(null)} 
          text={notification.text}
          type={notification.type}/>,
        document.getElementById('root-notification'))}
      <MenuButton onClick={onToggleProfile}>
        <ProfileIcon />
        {profile.isLoading ? <Loader size="1.4rem" margin="0 0.8rem 0 0.8rem" /> :<P2>{name}</P2>}
        {profile.isLoading || <MenuDropDownIcon />}
      </MenuButton>
      {toggleProfile && <UserProfilePopup onClose={()=>setToggleProfile(false)}/>}
    </Container>
  );
};

export default ProfileMenu;
