import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  ${({ justify }) => justify && `justify-content: ${justify};`};
  ${({ align }) => align && `align-items: ${align};`};
  ${({ gap }) => gap && `gap: ${gap};`};
  ${({ height }) => height && `height: ${height};`};
  flex-wrap: ${({ isWrap }) => isWrap? 'wrap': 'nowrap'};
`;