export const VEHICLE = {
  // Get all vehicles
  GET_ALL_START: 'VEHICLE_GET_ALL_START',
  GET_ALL_SUCCESS: 'VEHICLE_GET_ALL_SUCCESS',
  GET_ALL_FAIL: 'VEHICLE_GET_ALL_FAIL',
  // Select one vehicle
  SELECT_ONE: 'VEHICLE_SELECT_ONE',
  // Get one vehicle
  GET_ONE_START: 'VEHICLE_GET_ONE_START',
  GET_ONE_SUCCESS: 'VEHICLE_GET_ONE_SUCCESS',
  GET_ONE_FAIL: 'VEHICLE_GET_ONE_FAIL',
  // Create a vehicle
  CREATE_START: 'VEHICLE_CREATE_START',
  CREATE_SUCCESS: 'VEHICLE_CREATE_SUCCESS',
  CREATE_FAIL: 'VEHICLE_CREATE_FAIL',
  // Edit a vehicle
  EDIT_START: 'VEHICLE_EDIT_START',
  EDIT_SUCCESS: 'VEHICLE_EDIT_SUCCESS',
  EDIT_FAIL: 'VEHICLE_EDIT_FAIL',
  // Delete a vehicle
  DELETE_START: 'VEHICLE_DELETE_START',
  DELETE_SUCCESS: 'VEHICLE_DELETE_SUCCESS',
  DELETE_FAIL: 'VEHICLE_DELETE_FAIL',
  // Upload vehicle image 
  UPLOAD_START: 'IMAGE_UPLOAD_START',
  UPLOAD_SUCCESS: 'IMAGE_UPLOAD_SUCCESS',
  UPLOAD_FAIL: 'IMAGE_UPLOAD_FAIL',
  // Serach vehicles
  SEARCH_START: 'VEHICLE_SEARCH_START',
  SEARCH_SUCCESS: 'VEHICLE_SEARCH_SUCCESS',
  SEARCH_FAIL: 'VEHICLE_SEARCH_FAIL',
  // delete devices from vehicle
  DELETE_DEVICE_START: 'VEHICLE_DELETE_DEVICE_START',
  DELETE_DEVICE_SUCCESS: 'VEHICLE_DELETE_DEVICE_SUCCESS',
  DELETE_DEVICE_FAIL: 'VEHICLE_DELETE_DEVICE_FAIL',
  //transfer devices
  TRANSFER_DEVICES_START: 'VEHICLE_TRANSFER_DEVICES_START',
  TRANSFER_DEVICES_SUCCESS: 'VEHICLE_TRANSFER_DEVICES_SUCCESS',
  TRANSFER_DEVICES_FAIL: 'VEHICLE_TRANSFER_DEVICES_FAIL',
  //Get vehicle dashcams
  GET_DEVICES_START: 'VEHICLE_GET_DEVICES_START',
  GET_DEVICES_SUCCESS: 'VEHICLE_GET_DEVICES_SUCCESS',
  GET_DEVICES_FAIL: 'VEHICLE_GET_DEVICES_FAIL'
};

export const vehicleGetAllStart = (payload) => {
  return {
    type: VEHICLE.GET_ALL_START,
    payload
  };
};

export const vehicleGetAllSuccess = (payload) => {
  return {
    type: VEHICLE.GET_ALL_SUCCESS,
    payload
  };
};

export const vehicleGetAllFail = (payload) => {
  return {
    type: VEHICLE.GET_ALL_FAIL,
    payload
  };
};

export const vehicleSelectOne = (payload) => {
  return {
    type: VEHICLE.SELECT_ONE,
    payload
  };
};

export const vehicleGetOneStart = (payload) => {
  return {
    type: VEHICLE.GET_ONE_START,
    payload
  };
};

export const vehicleGetOneSuccess = (payload) => {
  return {
    type: VEHICLE.GET_ONE_SUCCESS,
    payload
  };
};

export const vehicleGetOneFail = (payload) => {
  return {
    type: VEHICLE.GET_ONE_FAIL,
    payload
  };
};

export const vehicleCreateStart = (payload) => {
  return {
    type: VEHICLE.CREATE_START,
    payload
  };
};

export const vehicleCreateSuccess = (payload) => {
  return {
    type: VEHICLE.CREATE_SUCCESS,
    payload
  };
};

export const vehicleCreateFail = (payload) => {
  return {
    type: VEHICLE.CREATE_FAIL,
    payload
  };
};

export const vehicleEditStart = (payload) => {
  return {
    type: VEHICLE.EDIT_START,
    payload
  };
};

export const vehicleEditSuccess = (payload) => {
  return {
    type: VEHICLE.EDIT_SUCCESS,
    payload
  };
};

export const vehicleEditFail = (payload) => {
  return {
    type: VEHICLE.EDIT_FAIL,
    payload
  };
};

export const vehicleDeleteStart = (payload) => {
  return {
    type: VEHICLE.DELETE_START,
    payload
  };
};

export const vehicleDeleteSuccess = (payload) => {
  return {
    type: VEHICLE.DELETE_SUCCESS,
    payload
  };
};

export const vehicleDeleteFail = (payload) => {
  return {
    type: VEHICLE.DELETE_FAIL,
    payload
  };
};

export const imageUploadStart = (payload) => {
  return {
    type: VEHICLE.UPLOAD_START,
    payload
  };
};

export const imageUploadSuccess = (payload) => {
  return {
    type: VEHICLE.UPLOAD_SUCCESS,
    payload
  };
};

export const imageUploadFail = (payload) => {
  return {
    type: VEHICLE.UPLOAD_FAIL,
    payload
  };
};

export const vehicleSearchStart = (payload) => {
  return {
    type: VEHICLE.SEARCH_START,
    payload
  };
};

export const vehicleSearchSuccess = (payload) => {
  return {
    type: VEHICLE.SEARCH_SUCCESS,
    payload
  };
};

export const vehicleSearchFail = (payload) => {
  return {
    type: VEHICLE.SEARCH_FAIL,
    payload
  };
};

export const vehicleDeleteDeviceStart = (payload) => {
  return {
    type: VEHICLE.DELETE_DEVICE_START,
    payload
  };
};

export const vehicleDeleteDeviceFail = (payload) => {
  return {
    type: VEHICLE.DELETE_DEVICE_FAIL,
    payload
  };
};

export const vehicleDeleteDeviceSuccess = (payload) => {
  return {
    type: VEHICLE.DELETE_DEVICE_SUCCESS,
    payload
  };
};

export const vehicleTransferDevicesStart = (payload) => {
  return {
    type: VEHICLE.TRANSFER_DEVICES_START,
    payload
  };
};

export const vehicleTransferDevicesSuccess = (payload) => {
  return {
    type: VEHICLE.TRANSFER_DEVICES_SUCCESS,
    payload
  };
};
export const vehicleTransferDevicesFail = (payload) => {
  return {
    type: VEHICLE.TRANSFER_DEVICES_FAIL,
    payload
  };
};

export const vehicleGetDevicesStart = (payload) => {
  return {
    type: VEHICLE.GET_DEVICES_START,
    payload
  };
};

export const vehicleGetDevicesSuccess = (payload) => {
  return {
    type: VEHICLE.GET_DEVICES_SUCCESS,
    payload
  };
};
export const vehicleGetDevicesFail = (payload) => {
  return {
    type: VEHICLE.GET_DEVICES_FAIL,
    payload
  };
};