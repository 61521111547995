import moment from 'moment';

export const getTime = (dateObj) => {
  if (dateObj === undefined || dateObj === null) {
    return 'n/a';
  }
  return moment(dateObj).format('h:mm A');
};

export const getDate = (dateObj) => {
  if (dateObj === undefined || dateObj === null) {
    return 'n/a';
  }
  return moment(dateObj).format('MMM D, yyyy');
};

export const getDateTime = (dateObj) => {
  if (dateObj === undefined || dateObj === null) {
    return 'n/a';
  }
  return moment(dateObj).format('MM/DD/YYYY h:mm A');
};
