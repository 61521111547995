import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Container, Row, Col} from 'react-grid-system';
import ReactDOM from 'react-dom';

import styles from './Home.module.css';
import Loader from '../../atoms/Loader';
import VehicleSelector from '../../molecules/VehicleSelector';
import Snackbar from '../../molecules/Snackbar';
import EventsMap from '../../organisms/EventsMap';
import ActiveGeofences from '../../organisms/ActiveGeofences';
import HomeRecentFiles from '../../organisms/HomeRecentFiles';
import HomeNotifications from '../../organisms/HomeNotifications';
import HomeIncidentsReports from '../../organisms/HomeIncidentsReports';

import {confirmDelete} from '../../../utils/notifications';
import { notificationDeleteStart, notificationDeleteAllStart } from '../../../redux/actions/notification'; 
import { incidentDeleteStart } from '../../../redux/actions/incident'; 
import NotificationsListPopup from '../../organisms/NotificationsListPopup';
import IncidentsListPopup from '../../organisms/IncidentsListPopup';
import IncidentReportPopup from '../../organisms/IncidentReportPopup';
import {getDateTime} from '../../../utils/timeFormatters';

const Home = () => {
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicle);  
  const profile = useSelector((state) => state.profile);  
  const location = useSelector((state) => state.location);
  const geofences = useSelector((state) => state.geofence);
  const notifications = useSelector((state)=>state.notification);
  const incidents = useSelector((state)=>state.incident);
  const videos = useSelector((state)=>state.video);

  const [geofenceList, setGeofenceList] = useState([]);
  const [mapCenterPoint, setMapCenterPoint] = useState({lat: 0, lng: 0});
  const [selectedVehicleId, setSelectedVehicleId] = useState(-1);
  const [selectedGeofence, setSelectedGeofence] = useState(null);
  const [notificationsList, setNotificationsList] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [incidentsList, setIncidentsList] = useState([]);
  const [selectedIncident, setSelectedIncident] = useState(null);
  const [videosList, setVideosList] = useState([]);
  const [showNotificationsPopup, setShowNotificationsPopup] = useState(false);
  const [showIncidentsPopup, setShowIncidentsPopup] = useState(false);
  const [showIncidentReportPopup, setShowIncidentReportPopup] = useState(false);
  const [activeIncidentReport, setIncidentReport] = useState(null);
  const [updatedIncidentReport, setUpdatedIncidentReport] = useState(null);
  const [notification, setNotification] = useState({
    message: null,
    type: 'success'
  });

  useEffect(() => {
    if(geofences.data && vehicle.selectedVehicle){
      const filteredGeofences = geofences.data.filter((el) => el.vehicle_ids.includes(vehicle.selectedVehicle.vehicle_id));
      setGeofenceList(filteredGeofences)
      setSelectedGeofence(filteredGeofences[0]);
    }
    if(location.data){
      setMapCenterPoint(location.data)
    }
    if(vehicle.selectedVehicle){
      setSelectedVehicleId(vehicle.selectedVehicle.vehicle_id);
    }
    if(notifications.data){
      setNotificationsList(notifications.data);
    }
    if(incidents.data && incidents.data.incident_reports){
      setIncidentsList(incidents.data.incident_reports);
    }
    if(geofences.error || vehicle.error || notifications.error || incidents.error){
      setNotification({text: 'An error occurred', type:'error'})
    }
    if(notifications.successMessage){
      setNotification({text: notifications.successMessage, type: 'success'});
    }
    if(incidents.successMessage){
      setNotification({text: incidents.successMessage, type: 'success'});
    }
  }, [geofences, location, vehicle, notifications, incidents]);

  useEffect(()=> {
    if(videos.all && videos.all.all_recordings){
      setVideosList(videos.all.all_recordings.slice(0,2))
    }else{
      setVideosList([]);
    }
  }, [videos]);

  const handleSelectGeofence = (geofence) => {
    setSelectedGeofence(geofence);
  }

  const handleSelectNotification = (notification) => {
    setSelectedNotification(notification);
  }

  const handleSelectIncident = (incident) => {
    setSelectedIncident(incident);
  }

  const handleDeleteAllNotifications = () => {
    confirmDelete({title:'', text: `Are you sure you want to delete all notifications?<br/> They will no longer be accessible.`}).then((result)=>{
        if (result.isConfirmed) {
            dispatch(notificationDeleteAllStart({vehicleId: selectedVehicleId}));
        }
    })
  };

  const handleDeleteNotification = (notification) => {
    confirmDelete({title:'', text: `Are you sure you want to delete notification?<br/>It will no longer be available.`}).then((result)=>{
        if (result.isConfirmed) {
            dispatch(notificationDeleteStart(notification));
        }
    })
  }

  const handleOpenIncidentReport = (report) => {
    setUpdatedIncidentReport(null)
    setIncidentReport(report);
    setShowIncidentReportPopup(true);
  }
  const handleIncidentUpdated = ()=>{
    setUpdatedIncidentReport(activeIncidentReport);
    setIncidentReport(null)
    setShowIncidentReportPopup(false);
  }
  const closeIncidentsListPopup = () => {
    setShowIncidentsPopup(false);
    setUpdatedIncidentReport(null);
  }

  const handleDeleteIncidentReport = (report) => {
    confirmDelete({title:'', text: `Are you sure you want to delete the incident report for ${getDateTime(report.start_at)}? It will no longer be accessible.`}).then((result)=>{
      if (result.isConfirmed) {
        const params = {
          report_id: report.report_id,
          user_cog_id: profile.data.user_id,
          vehicle_id: selectedVehicleId
        }
          setShowIncidentReportPopup(false)
          dispatch(incidentDeleteStart(params));
      }
    })
  }

  if (vehicle.isLoading || geofences.isLoading || location.isLoading) {
    return (
      <center>
        <Loader margin="20rem" />
      </center>
    );
  }

  return (
    <>
    {notification && notification.text && ReactDOM.createPortal(
        <Snackbar 
          onClose={()=>setNotification(null)} 
          text={notification.text}
          type={notification.type}/>,
        document.getElementById('root-notification'))}
      <Container fluid>
        <Row>
          <Col>
            <VehicleSelector/>
          </Col>
        </Row>
        <Row gutterWidth={16}>
          <Col lg={8} className={styles.col}>
            <EventsMap
              geofenceList={geofenceList}
              notificationsList={notificationsList}
              incidentsList={incidentsList}
              location={mapCenterPoint}
              selectedGeofence={selectedGeofence}
              selectedNotification={selectedNotification}
              selectedIncident={selectedIncident}
              />
          </Col>
          <Col lg={4} className={styles.col}>
            <ActiveGeofences 
              onGeofenceSelect={handleSelectGeofence} 
              geofences={geofenceList}
            />
          </Col>
        </Row>
        <Row gutterWidth={16}>
          <Col lg={4} className={styles.col}>
            <HomeRecentFiles videos={videosList}/>
          </Col>
          <Col lg={4} className={styles.col}>
            <HomeNotifications 
              notifications ={notificationsList} 
              onNotificationSelect={handleSelectNotification}
              onDelete={handleDeleteNotification}
              onDeleteAll={handleDeleteAllNotifications}
              onShowNotificationsPopup={()=>setShowNotificationsPopup(true)}
              isLoading={notifications.isLoading}
            />
          </Col>
          <Col lg={4} className={styles.col}>
            <HomeIncidentsReports 
              incidents={incidentsList} 
              onIncidentSelect={handleSelectIncident}
              onShowIncidentsPopup={()=>setShowIncidentsPopup(true)}
              handleClickSend={handleOpenIncidentReport}
              onDeleteIncidentReport={handleDeleteIncidentReport}
              isLoading={incidents.isLoading}
            />
          </Col>
        </Row>
      </Container>

      {showNotificationsPopup &&
        <NotificationsListPopup 
          notifications={notificationsList} 
          onClose={()=>setShowNotificationsPopup(false)}
          onDeleteNotification={handleDeleteNotification}
          onDeleteAllNotifications={handleDeleteAllNotifications}
          isLoading={notifications.isLoading}
        />
      }
      {showIncidentsPopup && 
        <IncidentsListPopup
          onClose={()=>closeIncidentsListPopup()}
          incidentsList={incidentsList}
          handleClickSend={handleOpenIncidentReport}
          isLoading={incidents.isLoading}
          updatedIncident={updatedIncidentReport}
          onDeleteIncidentReport={handleDeleteIncidentReport}
        />}
        {showIncidentReportPopup && 
          <IncidentReportPopup
            incident={activeIncidentReport}
            onClose={()=>setShowIncidentReportPopup(false)}
            onIncidentUpdated={handleIncidentUpdated}
            onDeleteIncidentReport={handleDeleteIncidentReport}
            />
        }
      <br/>
    </>
  );
};

export default Home;
