import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './HBox.style';

const HBox = ({ children, padding, margin, justify, align, gap, isWrap, height }) => {
  return (
    <Container data-testid="container" 
      padding={padding} 
      margin={margin}
      justify={justify} 
      align={align}
      gap={gap}
      isWrap={isWrap}
      height={height}
    >
      {children}
    </Container>
  );
};

HBox.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string,
  margin: PropTypes.string,
  justify: PropTypes.oneOf(['center', 'space-between', 'space-evenly', 'space-around', 'flex-start', 'flex-end']),
  align: PropTypes.oneOf(['center', 'stretch', 'flex-start', 'flex-end']),
  gap: PropTypes.string,
  isWrap: PropTypes.bool,
  height: PropTypes.string,
};

HBox.defaultProps = {
  padding: '0rem',
  margin: '0rem',
  justify: null,
  align: null,
  isWrap: false,
  height: null
};

export default HBox;
