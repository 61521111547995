import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, Marker, Circle, Rectangle, Polygon, useJsApiLoader } from '@react-google-maps/api';
import HBox from '../../../components/atoms/HBox';
import VBox from '../../atoms/VBox';
import {Text1} from '../../../components/atoms/Typography';
import Button from '../../../components/atoms/Button';
import LocationIcon from '../../../assets/LocationIcon.png';
import SquareIcon from '../../../assets/SquareDashedIcon';
import SquareSelectedIcon from '../../../assets/SquareDashedSelectedIcon';
import CircleIcon from '../../../assets/CircleDashedIcon';
import CircleSelectedIcon from '../../../assets/CircleDashedSelectedIcon';
import PolygonSelectedIcon from '../../../assets/PolygonSelectedIcon';
import PolygonIcon from '../../../assets/PolygonIcon';
import TextInput from '../../molecules/TextInput';

import {
  Container,
  ShapeSelectorButtonContainer,
  GoogleMapContainer
} from './styles';
import {
  MAP_ANCHOR_SIZE,
  MAP_SCALE_SIZE,
  MAP_ZOOM_LEVEL,
  GOOGLE_MAPS_KEY
} from '../../../constants';

const GeofencePicker = ({ shape, data, onUpdateData, shapesOptions, handleSelectShape, error }) => {
  const [markerPosition, setMarkerPosition] = useState(data.location);
  const [centerPointInput, setCenterPointInput] = useState(data.address? data.address: `${data.location.lat},${data.location.lng}`);
  const [formErrors, setFormErrors] = useState({centerPoint: ''});

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_KEY
  });

  useEffect(()=>{
    handleCenterClick();
  },[markerPosition]);

  // ============================================== Circle ==============================================
  const circleRef = useRef(null);
  // Call setCircleRadius with new edited path
  const onCircleEdit = useCallback(() => {
    if (circleRef.current) {
      const nextRadius = circleRef.current.getRadius();
      if (JSON.stringify(data.radius) !== JSON.stringify(nextRadius)) {
        onUpdateData({
          ...data,
          radius: nextRadius
        });
      }
    }
  }, [onUpdateData]);

  const onCircleDragged = useCallback(()=>{
    if (circleRef.current) {
      const nextCenter = circleRef.current.getCenter();
      let diffLat = data.geofenceCenter.lat- nextCenter.lat();
      let diffLng = data.geofenceCenter.lng - nextCenter.lng();
      if (JSON.stringify(data.location) !== JSON.stringify(nextCenter)) {
        diffLat = data.geofenceCenter.lat- nextCenter.lat();
        diffLng = data.geofenceCenter.lng - nextCenter.lng();
        const nextPath = data.points.map(latLng => {
          return { lat: latLng.lat - diffLat, lng: latLng.lng - diffLng };
        });

        onUpdateData({
          ...data,
          geofenceCenter: {
            lat: nextCenter.lat(),
            lng: nextCenter.lng()
          },
          points: nextPath,
          bounds: {
            east: data.bounds.east - diffLng,
            west: data.bounds.west - diffLng,
            north: data.bounds.north - diffLat,
            south: data.bounds.south - diffLat
          },
        });
      }
    }
  }, [onUpdateData])

  // Bind refs to current Circle
  const onCircleLoad = useCallback(circle => {
    circleRef.current = circle;
  }, [onCircleEdit]);

  // Clean up refs
  const onCircleUnmount = useCallback(() => {
    circleRef.current = null;
  }, []);

  // ============================================== Rectangle ==============================================

  const rectangleRef = useRef(null);

  // Call setCircleRadius with new edited path
  const onRectangleEdit = useCallback(() => {
    if (rectangleRef.current) {
      const nextBounds = rectangleRef.current.getBounds();
      const center = nextBounds.getCenter();
      const ne = nextBounds.getNorthEast();
      const sw = nextBounds.getSouthWest();

      const bounds = {
        north: ne.lat(),
        south: sw.lat(),
        east: ne.lng(),
        west: sw.lng()
      };
      if (JSON.stringify(data.bounds) !== JSON.stringify(bounds)) {
        const diffLat = data.geofenceCenter.lat- center.lat();
        const diffLng = data.geofenceCenter.lng - center.lng();
        const nextPath = data.points.map(latLng => {
          return { lat: latLng.lat - diffLat, lng: latLng.lng - diffLng };
        });
        onUpdateData({
          ...data,
          bounds,
          points: nextPath,
          geofenceCenter: {
            lat: center.lat(),
            lng: center.lng()
          }
        });
      }
    }
  }, [onUpdateData]);

  // Bind refs to current Rectangle
  const onRectangleLoad = useCallback(rectangle => {
    rectangleRef.current = rectangle;
  }, [onRectangleEdit]);

  // Clean up refs
  const onRectangleUnmount = useCallback(() => {
    rectangleRef.current = null;
  }, []);

  // ============================================== Polygon ==============================================

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const polygonListenersRef = useRef([]);

  // Call setPolygonPath with new edited path
  const onPolygonEdit = useCallback(() => {
    if (polygonRef.current) {
      var bounds = new google.maps.LatLngBounds();
      const nextPath = polygonRef.current.getPath().getArray().map(latLng => {
        bounds.extend(latLng);
        return { lat: latLng.lat(), lng: latLng.lng() };
      });
      const center = bounds.getCenter();
      const diffLat = data.geofenceCenter.lat- center.lat();
      const diffLng = data.geofenceCenter.lng - center.lng();
      if (JSON.stringify(data.points) !== JSON.stringify(nextPath)) {
        onUpdateData({
          ...data,
          points: nextPath,
          bounds: {
            east: data.bounds.east - diffLng,
            west: data.bounds.west - diffLng,
            north: data.bounds.north - diffLat,
            south: data.bounds.south - diffLat
          },
          geofenceCenter: {
            lat: center.lat(),
            lng: center.lng()
          }
        });
      }
    }
  }, [onUpdateData]);

  // Bind refs to current Polygon and listeners
  const onPolygonLoad = useCallback(polygon => {
    polygonRef.current = polygon;
    const path = polygon.getPath();
    polygonListenersRef.current.push(
      path.addListener('set_at', onPolygonEdit),
      path.addListener('insert_at', onPolygonEdit),
      path.addListener('remove_at', onPolygonEdit)
    );
  }, [onPolygonEdit]);

  // Clean up refs
  const onPolygonUnmount = useCallback(() => {
    polygonListenersRef.current.forEach(lis => lis.remove());
    polygonRef.current = null;
  }, []);

  const handleCenterClick = () => {
    const diffLat = data.geofenceCenter.lat - markerPosition.lat;
    const diffLng = data.geofenceCenter.lng - markerPosition.lng;
    let nextPath = data.points;
    nextPath = data.points.map(latLng => {
      return { lat: latLng.lat - diffLat, lng: latLng.lng - diffLng };
    });
    
    onUpdateData({
      ...data,
      geofenceCenter: markerPosition,
      bounds: {
        east: data.bounds.east - diffLng,
        west: data.bounds.west - diffLng,
        north: data.bounds.north - diffLat,
        south: data.bounds.south - diffLat
      },
      points: nextPath
    });
  };

  const handleMapClick = (e) => {
    onUpdateData({
      ...data,
      address: `${e.latLng.lat()}, ${e.latLng.lng()}`,
    });
    setMarkerPosition({lat: e.latLng.lat(), lng: e.latLng.lng()})
    setCenterPointInput(`${e.latLng.lat()}, ${e.latLng.lng()}`);
    
  }

  const handleClickShape = (shape) => {
    handleCenterClick();
    handleSelectShape(shape);
  }

  const handleCenterPointChange = value => {
    setCenterPointInput(value);
    const coordinates = value.trim().split(',');
    if (coordinates.length === 2 && inrange(-90,coordinates[0],90) && inrange(-180,coordinates[1],180)) {
      setFormErrors({centerPoint: ""})
      const coordinatesObject = {lat: Number(coordinates[0]), lng: Number(coordinates[1])};
      onUpdateData({
        ...data,
        address: value,
        location: coordinatesObject
      });
      setMarkerPosition(coordinatesObject)
    }else{
      onUpdateData({
        ...data,
        address: value,
        location: {lat: null, lng: null}
      });
      setFormErrors({centerPoint: "Please insert a valid coordinate. Example: 41.881832, -87.623177"})
    }
  }

  const inrange = (min,number,max) => {
      if ( number && !isNaN(number) && (number >= min) && (number <= max) ){
          return true;
      } else {
          return false;
      };
  }

  const getGeoArea = () => {
    switch (shape.value) {
      case 0:
        return (
          <Circle
            editable
            draggable
            options={{
              strokeColor: '#007aff',
              strokeWeight: 1,
              fillColor: '#007aff',
              fillOpacity: 0.33
            }}
            center={data.geofenceCenter}
            radius={data.radius}
            onRadiusChanged={onCircleEdit}
            onDragEnd={onCircleDragged}
            onLoad={onCircleLoad}
            onUnmount={onCircleUnmount}
          />
        );
      case 1:
        return (
          <Rectangle
            editable
            draggable
            options={{
              strokeColor: '#007aff',
              strokeWeight: 1,
              fillColor: '#007aff',
              fillOpacity: 0.33
            }}
            center={data.geofenceCenter}
            bounds={data.bounds}
            onBoundsChanged={onRectangleEdit}
            onLoad={onRectangleLoad}
            onUnmount={onRectangleUnmount}
          />
        );
      case 2:
        return (
          <Polygon
            editable
            draggable
            options={{
              strokeColor: '#007aff',
              strokeWeight: 1,
              fillColor: '#007aff',
              fillOpacity: 0.33
            }}
            center={data.geofenceCenter}
            path={data.points}
            onMouseUp={onPolygonEdit}
            onDragEnd={onPolygonEdit}
            onLoad={onPolygonLoad}
            onUnmount={onPolygonUnmount}
          />
        );
      default:
        return null;
    }
  };
  
  return (
    <Container>
      <HBox justify="space-between" isWrap={true}>
        <TextInput 
          titled 
          error={formErrors.centerPoint} 
          title="Center Point" 
          label="Latitude, Longitude" 
          width="46rem" 
          value={centerPointInput} 
          margin="0 0 -0.5rem 0.25rem"
          onChange={handleCenterPointChange}
        />
        <HBox>
          <VBox align="flex-start" margin="0 0 0 5rem">
            <Text1 fontSize="1.4rem" color="#5C6670" margin="0 0 0 1rem">Drawing Tools</Text1>
            <HBox>
              <Button buttonType="danger2" onClick={()=>handleClickShape(shapesOptions[1])}>
                <ShapeSelectorButtonContainer>
                  {shape.value === 1 ? <SquareSelectedIcon /> : <SquareIcon />}
                </ShapeSelectorButtonContainer>
              </Button>
              <Button buttonType="danger2" onClick={()=>handleClickShape(shapesOptions[0])}>
                <ShapeSelectorButtonContainer>
                  {shape.value === 0 ? <CircleSelectedIcon /> : <CircleIcon />}
                </ShapeSelectorButtonContainer>
              </Button>
              <Button buttonType="danger2" onClick={()=>handleClickShape(shapesOptions[2])}>
                <ShapeSelectorButtonContainer>
                  {shape.value === 2 ? <PolygonSelectedIcon /> : <PolygonIcon />}
                </ShapeSelectorButtonContainer>
              </Button>
              <Button buttonType="secondary" onClick={()=>handleCenterClick()}>
                Center
              </Button>
            </HBox>
          </VBox>
        </HBox>        
      </HBox>
      <GoogleMapContainer>
        { isLoaded && <GoogleMap
          mapContainerStyle={{
            height: '100%',
            width: '100%'
          }}
          center={data.location}
          zoom={MAP_ZOOM_LEVEL}
          version="weekly"
          onClick={(e) => handleMapClick(e)}
          on
        >
          <Marker
            position={markerPosition}
            icon={{
              url: LocationIcon,
              anchor: {
                x: MAP_ANCHOR_SIZE,
                y: MAP_ANCHOR_SIZE
              },
              scaledSize: {
                width: MAP_SCALE_SIZE,
                height: MAP_SCALE_SIZE
              }
            }}
          />
          {getGeoArea()}
        </GoogleMap>
        }
      </GoogleMapContainer>
    </Container>
  );
};

GeofencePicker.propTypes = {
  shape: PropTypes.object.isRequired,
  data: PropTypes.shape({
    address: PropTypes.string.isRequired,
    location: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired
    }).isRequired,
    radius: PropTypes.number.isRequired,
    bounds: PropTypes.object.isRequired,
    points: PropTypes.arrayOf(PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired
    })).isRequired
  }).isRequired,
  onUpdateData: PropTypes.func.isRequired
};

export default GeofencePicker;
