import React from 'react';
import PropTypes from 'prop-types';

import Text from '../../atoms/Text';
import {Text2} from '../../atoms/Typography';
import { Container, InputElement, PasswordElement, Star } from './TextInput.styles';

const TextInput = ({label, width, margin, background, required, placeholder, isPassword, error, value, reference, name, onChange}) => {
  const onChangeText = (e) => {
    onChange(e.target.value);
  };

  return (
    <Container width={width} margin={margin}>
      {isPassword ? 
        <PasswordElement
          error={error && error.length > 0 ? true : false}
          placeholder={placeholder}
          value={value}
          onChange={onChangeText}
          name={name}
        /> 
        :
        <>
          {label && <Text2 color="#5C6670">
            {label}
            {required && <Star>*</Star>}
          </Text2>}
          <InputElement
            ref={reference}
            background={background}
            error={error && error.length > 0 ? true : false}
            placeholder={placeholder}
            value={value}
            onChange={onChangeText}
            name={name}
          />
        </>
      }
      {error && error.length > 0 ? <Text margin="0.2rem 0 0 1.6rem" color="#E24444" fontSize="1.2rem">{error}</Text> : null}
    </Container>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  placeholder: PropTypes.string,
  isPassword: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  reference: PropTypes.object
};

TextInput.defaultProps = {
  width: '100%',
  margin: '0rem',
  placeholder: '',
  isPassword: false,
  error: '',
  value: ''
};

export default TextInput;
