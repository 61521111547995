import React from 'react';
import PropTypes from 'prop-types';

import Card from '../../molecules/Card';

import {Text3, Text2} from '../../atoms/Typography';
import {ListItem, Button, Container, LinkContainer} from './styles';
import DeleteIcon  from '../../../assets/DeleteIcon';
import MapIcon from '../../../assets/MapIcon';
import Link from '../../atoms/Link';
import { getDateTime } from '../../../utils/timeFormatters';
import Loader from '../../atoms/Loader';

const HomeNotifications = ({notifications, onNotificationSelect, onDelete, onDeleteAll, onShowNotificationsPopup, isLoading}) => {
    const notificationList = notifications.slice(0, 3);
    const handleNotificationSelect = (notification) => {
        onNotificationSelect(notification);
    }

    return(
        <Card height='35rem' title='Notifications' button="Clear All" buttonOnClick={()=>onDeleteAll()}>
            {!isLoading && notificationList.map((notification, index) => (
                <ListItem key={index}>
                    <Container textColor="#5C6670">
                        <Text3>{getDateTime(notification.createdAt)}</Text3>
                        <Button onClick={()=>onDelete(notification)}><DeleteIcon /></Button>
                    </Container>
                    <Container>
                        <Text2>{notification.body}</Text2>
                        <Button onClick={()=>handleNotificationSelect(notification)}><MapIcon/></Button>
                    </Container>
                </ListItem>
            ))}
            {!isLoading && notificationList.length ===0 && (
            <ListItem>
                    <Container>
                        <Text2>No notifications</Text2>
                    </Container>
                </ListItem> 
            )}
            {isLoading &&
                <center>
                    <Loader/>
                </center>
            }
            <br/>
            {notificationList.length > 0 &&
                <LinkContainer>
                    <Link onClick={onShowNotificationsPopup}>See All</Link>
                </LinkContainer>
            }
        </Card>
    );
};

HomeNotifications.propTypes = {
    notifications: PropTypes.array.isRequired,
    onNotificationSelect: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onDeleteAll: PropTypes.func.isRequired,
    onShowNotificationsPopup: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
};

HomeNotifications.defaultProps = {
    notifications: [],
    isLoading: false
}


export default HomeNotifications;