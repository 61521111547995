import React from 'react'
import { errorNotification } from '../../../utils/notifications'

const viewer = {}

function uid() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

/**
 * CustomSigner class takes the url in constructor with a getSignedUrl method which returns the signedURL
 */
 class CustomSigner {
    constructor (_url) {
      this.url = _url;
    }
  
    getSignedURL () {
      return this.url;
    }
  }

class LiveFeedView extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef()
    }

    componentWillUnmount() {
        if(viewer.signalingClient){
            viewer.signalingClient.close();
            viewer.signalingClient = null;
        }

        if(viewer.peerConnection){
            viewer.peerConnection.close();
            viewer.peerConnection = null;
        }
        if(viewer.remoteStream){
            viewer.remoteStream.getTracks().forEach(track => track.stop());
            viewer.remoteStream = null;
        }
        if (viewer.localStream) {
            viewer.localStream.getTracks().forEach(track => track.stop());
            viewer.localStream = null;
        }
        if(viewer.peerConnectionStatsInterval){
            clearInterval(viewer.peerConnectionStatsInterval)
            viewer.peerConnectionStatsInterval = null;
        }
        if(viewer.dataChannel){
            viewer.dataChannel = null;
        }
    }
    async componentDidMount(){
        const url = this.props.streamDetails.url
        const configuration = this.props.streamDetails.configuration
        if(configuration.iceServers){
            configuration.iceServers.unshift({ urls: `stun:stun.kinesisvideo.us-east-1.amazonaws.com:443` })
        }
        // Create Signaling Client
        viewer.signalingClient = new window.KVSWebRTC.SignalingClient({
            requestSigner : new CustomSigner(url),
            region: 'default region, (any text) as region is already part of signedurl',
            role:'VIEWER',
            clientId: uid(),
            channelARN: 'default channel, (any text) as channelARN is already part of signedurl',
            channelEndpoint: 'default endpoint (any text) as endpoint is already part of signedurl'
        });

        viewer.peerConnection = new RTCPeerConnection(configuration);
        
        viewer.signalingClient.on('open', async () => {
            try {
                viewer.localStream = await navigator.mediaDevices.getUserMedia({video: { width: { ideal: 640 }, height: { ideal: 480 } }, audio:"true"});
                viewer.localStream.getTracks().forEach(track => viewer.peerConnection.addTrack(track, viewer.localStream));
            } catch (e) {
                console.error('[VIEWER] Could not find webcam');
                return;
            }
            
            // Create an SDP offer to send to the master
            await viewer.peerConnection.setLocalDescription(
                await viewer.peerConnection.createOffer({
                    offerToReceiveAudio: true,
                    offerToReceiveVideo: true,
                }),
            );

            // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
            viewer.signalingClient.sendSdpOffer(viewer.peerConnection.localDescription);
        });

        viewer.signalingClient.on('sdpAnswer', async answer => {
            // Add the SDP answer to the peer connection
            await viewer.peerConnection.setRemoteDescription(answer);
        });
        viewer.signalingClient.on('iceCandidate', candidate => {
            // Add the ICE candidate received from the MASTER to the peer connection
            viewer.peerConnection.addIceCandidate(candidate);
        });
        viewer.signalingClient.on('close', () => {
        });
        viewer.signalingClient.on('error', error => {
            return errorNotification({
                title: 'Error!',
                text: 'Please click "Stop" and try again'
              });
        });

        // Send any ICE candidates to the other peer
        viewer.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
            if (candidate) {
                // When trickle ICE is enabled, send the ICE candidates as they are generated.
                viewer.signalingClient.sendIceCandidate(candidate);
            } 
        });

        // As remote tracks are received, add them to the remote view
        viewer.peerConnection.addEventListener('track', async (event) => {
            if (this.videoRef.current.srcObject) {
                return;
            }
            viewer.remoteStream = event.streams[0];
            this.videoRef.current.srcObject = event.streams[0];
        });
        viewer.signalingClient.open();
    }

    render() {
        return (
          <video ref={this.videoRef} style={{width: '100%', maxHeight: '540px', minHeight: '100px', position: 'relative' }} autoPlay poster="/images/loading.png" controls />
        )
    }
}
export default LiveFeedView;