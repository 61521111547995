import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Visible } from 'react-grid-system';
import {Container, Nav, NavigationMenu, List, ListElement, LogoContainer, ProfileMenuContainer} from './Navbar.styles';
import Logo from '../../../assets/logo-blue.png';
import Image from '../../atoms/Image';
import BurgerButton from '../../atoms/BurgerButton';
import NavLink from '../../atoms/NavLink';
import ProfileMenu from '../../layouts/ProfileMenu';


const AuthenticatedNavBar = () => {
    const nav = useHistory();
    const routesInfo = useLocation();

    const [isNavExpanded, setIsNavExapanded] = useState(false);
    const [currentRoute, setCurrentRoute] = useState('/');


    useEffect(() => {
        const { pathname } = routesInfo;
        setCurrentRoute(pathname);
    }, [routesInfo]);

    const handleMenuClick = (event, value) => {
        event.preventDefault();
        setIsNavExapanded(false);
        nav.push(value);
        return false;
    };

    const navLinks = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/live-view',
            label: 'Live View'
        },
        {
            link: '/gallery',
            label: 'Gallery'
        },
        {
            link: '/fleet',
            label: 'Admin'
        }
    ]

    return (
        <Container>
            <Nav>
                <LogoContainer>
                    <Image src={Logo}/>
                </LogoContainer>    
                <BurgerButton onClick ={()=>{setIsNavExapanded(!isNavExpanded)}}/>
                <NavigationMenu expanded={isNavExpanded}>
                    <List expanded={isNavExpanded}>
                        {navLinks.map((el) =>(
                            <ListElement key={el.label} active={currentRoute === el.link}>
                                <NavLink href="" onClick={(event) => handleMenuClick(event,el.link)}>{el.label}</NavLink>
                            </ListElement>
                        ))}
                        <ListElement>
                            <Visible sm xs>
                                <ProfileMenu />
                            </Visible>    
                        </ListElement>
                    </List>
                </NavigationMenu>
                <ProfileMenuContainer>
                    <ProfileMenu />
                </ProfileMenuContainer>
            </Nav>
        </Container>
    );
};

export default AuthenticatedNavBar;