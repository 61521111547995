import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './ClickWrapper.styles';

const ClickWrapper = ({ margin, padding, color, disabled, onClick, children }) => {
  return (
    <Container margin={margin} padding={padding} color={color} disabled={disabled} onClick={disabled ? null : onClick}>{children}</Container>
  );
};

ClickWrapper.propTypes = {
  margin: PropTypes.string,
  padding: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ClickWrapper.defaultProps = {
  margin: '0',
  padding: '0',
  color: 'transparent',
  disabled: false
};

export default ClickWrapper;
