import React from 'react';
import PropTypes from 'prop-types';

import {Text1, Text4} from '../../atoms/Typography';
import HBox from '../../atoms/HBox';
import LiveViewIcon from '../../../assets/LiveViewIcon';
import SpeedIcon from '../../../assets/SpeedIcon';
import LocationIcon from '../../../assets/LocationIcon';
import GsensorIcon from '../../../assets/GsensorIcon';
import {Container, DetailsContainer} from './styles';

const LiveViewDetails = ({speed, location, gSensor}) => {
    return(
        <Container>
            <HBox align="center" gap="0.8rem">
                <LiveViewIcon/>
                <Text4>Live View</Text4>
            </HBox>
            <DetailsContainer>
                <HBox align="center" gap="0.8rem">
                    <SpeedIcon/>
                    <Text1>Speed <strong>{speed}</strong></Text1>
                </HBox>
                <HBox align="center" gap="0.8rem">
                    <LocationIcon/>
                    <Text1>Location <strong>{location}</strong></Text1>
                </HBox>
                <HBox align="center" gap="0.8rem">
                    <GsensorIcon/>
                    <Text1>G-Sensor <strong>{gSensor}</strong></Text1>
                </HBox>
            </DetailsContainer>
        </Container>
    )
}

LiveViewDetails.propTypes = {
    speed: PropTypes.string,
    location: PropTypes.string,
    gSensor: PropTypes.string
};

LiveViewDetails.defaultProps = {
    speed: "N/A",
    location: "N/A",
    gSensor: "N/A"
};

export default LiveViewDetails;