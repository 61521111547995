import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {Container, Col, Row} from 'react-grid-system';
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';

import VehicleSelector from '../../molecules/VehicleSelector';
import LiveViewDevice from '../../molecules/LiveViewDevice';
import LiveViewDetails from '../../molecules/LiveViewDetails';
import {Text2, P1} from '../../atoms/Typography';
import Button from '../../atoms/Button';
import styles from './LiveView.module.css';
import LiveViewFeed from '../../organisms/LiveViewFeed';
import {errorNotification} from '../../../utils/notifications';
import {getDirection, getGsensor} from '../../../utils/helpers';

import {vehicleGetDevicesStart} from '../../../redux/actions/vehicle';
import { streamContinueStart, streamStart, streamStopStart } from '../../../redux/actions/stream';
import { getTripNodeStart } from '../../../redux/actions/tripnode';

import {
  MAP_ZOOM_LEVEL,
  GOOGLE_MAPS_KEY
} from '../../../constants';

const LIVE_REMOTE_VIEW_SECONDS = 600 // ten minutes
const LIVE_REMOTE_VIEW_SECONDS_UNTIL_CLOSE = 60
const LAST_NODE_REQUEST_FREQUANCY = 10 //10 seconds

const LiveView = () => {
  const dispatch = useDispatch();
  const selectedVehicle = useSelector((state)=> state.vehicle.selectedVehicle);
  const stream = useSelector((state)=> state.stream);
  const stateTripNode = useSelector((state)=>state.tripnode.data);

  const [streamingDevice, setStreamingDevice] = useState(null);
  const [streamingCounter, setStreamingCounter] = useState(0);
  const [streamingTimeFinished, setStreamingTimeFinished] = useState(true);
  const [tripNode, setTripNode] = useState(null);

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_KEY
  });

  let devices = [];
  if(selectedVehicle){
    devices = selectedVehicle.dashcams.filter(device => device.device_type !== 'RADAR' );
  }
  
  useEffect(()=>{
    const vehicleDevicesTimer = setInterval(()=>{
      if(selectedVehicle){
        dispatch(vehicleGetDevicesStart({reg_no: selectedVehicle.reg_no}))
      }
    }, 10000);
    return ()=>{
      clearInterval(vehicleDevicesTimer)
    }
  },[selectedVehicle])

  useEffect(()=>{
    if(stream.error){
      errorNotification({title: '', text: stream.error.text})
      setStreamingDevice(null);
      setStreamingCounter(0);
      setTripNode(null);
    }
    if(!stream.streamDetails && !stream.isLoading){
      setStreamingDevice(null);
      setStreamingCounter(0)
      setStreamingTimeFinished(true);
      setTripNode(null);
    }
  }, [stream]);

  useEffect(()=>{     
    const streamingTimer = streamingCounter>0 && setInterval(()=>setStreamingCounter(streamingCounter-1),1000);
    if(streamingCounter === 1 && !streamingTimeFinished){
      setStreamingCounter(LIVE_REMOTE_VIEW_SECONDS_UNTIL_CLOSE);
      setStreamingTimeFinished(true);
    }
    if(streamingCounter === 0 && streamingTimeFinished && streamingDevice){
      dispatch(streamStopStart({device_serial: streamingDevice.cam_id}))
      setTripNode(null);
    }
    
    if(streamingDevice && streamingCounter % LAST_NODE_REQUEST_FREQUANCY === 0){
      dispatch(getTripNodeStart({camId:streamingDevice.cam_id}));
    }

    return ()=>{
      clearInterval(streamingTimer)
    }
  },[streamingCounter]);

  useEffect(()=>{
    if(stateTripNode && streamingDevice){
      setTripNode({
        direction: getDirection(stateTripNode.direction),
        speed: stateTripNode.speed.mph? `${stateTripNode.speed.mph} m/h` : `${stateTripNode.speed.kmh} km/h`,
        location: stateTripNode.location,
        gSensor: getGsensor(stateTripNode.gyro)
      });
    }
  }, [stateTripNode])

  const handleStartStream = (device) => {
    if(!streamingDevice){
      setStreamingDevice(device);
      dispatch(streamStart({device_serial: device.cam_id}))
      setStreamingCounter(LIVE_REMOTE_VIEW_SECONDS);
      setStreamingTimeFinished(false);
    }else{
      errorNotification({title:'A device is already streaming', text: 'Please Stop it and the start another one.'})
    }
  }

  const handleStopStream = (device) => {
    dispatch(streamStopStart({device_serial: device.cam_id}))
    setStreamingCounter(0);
    setStreamingDevice(null);
    setTripNode(null);
  }

  const continueStreaming= () => {
    dispatch(streamContinueStart({device_serial: streamingDevice.cam_id}))
    setStreamingCounter(LIVE_REMOTE_VIEW_SECONDS);
    setStreamingTimeFinished(false);
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <div className={styles.dashcamContainer}>
              <VehicleSelector/>
              {devices.map(device => 
                <LiveViewDevice
                  key={device.id}
                  name={device.alias}
                  icon={device.icon_url}
                  isConnected={!!device.is_connected}
                  onStart={()=>handleStartStream(device)}
                  onStop={()=>handleStopStream(device)}
                  // isConnected={true}
                  isStreaming={streamingDevice? streamingDevice.id === device.id : false}
                />)}
                {!devices.length && <Text2>You haven’t connected any devices. Go to DriveSmarter app to add devices to your account</Text2>}
            </div>
          </Col>
        </Row>
        <Row gutterWidth={16}>
          <Col lg={9} className={styles.col}>
            {stream.streamDetails && streamingDevice?
                <div className={styles.liveViewContainer}>
                  {streamingTimeFinished &&
                    <div className={styles.liveViewTimeLimit}>
                      <div className={styles.liveViewTimeContent}>
                      <P1>
                        Live View automatically pauses after 10 minutes  
                        <br/>Click the button below to unpause Live View session, otherwise it will end in<br/> 00:{streamingCounter<10 ? '0':''}{streamingCounter} 
                      </P1>  
                      <br/>
                        <Button buttonType='primary' onClick={()=>continueStreaming()}>Unpause LIVE VIEW</Button>
                        </div>
                    </div>
                  }
                  <LiveViewFeed streamDetails={stream.streamDetails}/>
                </div>
              :
              <div className={styles.videoPlaceHolder}>
                <Text2 color="#fff">
                  {devices.length >0 ? 
                    `Click "Start Live View" to see what's going on.`
                    :
                    `Live View unavailable. No devices connected`
                  }
                </Text2>
              </div>
            }            
          </Col>
          <Col lg={3} className={styles.col}>       
            <LiveViewDetails
              speed={tripNode? tripNode.speed : "N/A"}
              location={tripNode && (tripNode.location.lat!=0 || tripNode.location.lng!=0)?  `${tripNode.location.lat} ${tripNode.location.lng}`: "N/A"}
              gSensor={tripNode? `${tripNode.gSensor}G` : "N/A"}
            />
          </Col>
        </Row>
        <Row gutterWidth={16}>
          <Col lg={9} className={styles.col}>
            {tripNode && (tripNode.location.lat!==0 || tripNode.location.lng!==0) && isLoaded ?
              ( <GoogleMap           
                mapContainerStyle={{
                    height: '25rem',
                    width: '100%'
                }}
                center={{lat: Number(tripNode.location.lat), lng: Number(tripNode.location.lng)}}
                zoom={MAP_ZOOM_LEVEL}
                version="weekly">
                  <Marker 
                    position={
                      {lat: Number(tripNode.location.lat), 
                      lng: Number(tripNode.location.lng)}
                    }
                  />
              </GoogleMap>)
              :
              (<div className={styles.mapPlaceHolder}>
                <Text2 color="#fff">Location not available.</Text2>
              </div>)
            }
            
          </Col>
          <Col lg={3} className={styles.col}>
          </Col>
        </Row>
      </Container>      
      <br/>
    </>
  );
};

export default LiveView;
