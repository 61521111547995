import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './CenterWrapper.styles';

const CenterWrapper = ({ width, height, children }) => {
  return (
    <Container width={width} height={height}>{children}</Container>
  );
};

CenterWrapper.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.node.isRequired
};

CenterWrapper.defaultProps = {
  width: '100%',
  height: '100%'
};

export default CenterWrapper;
