import { ACCOUNT_CLEANUP } from "../actions/accountCleanup";

const initialState = {
    data: null,
    isLoading: false,
    error: null
};

const accountCleanupReducer = (state = initialState, action) => {
    switch(action.type) {
        case ACCOUNT_CLEANUP.REQUEST_START:
            return { data: null, isLoading: true, error: null };
        case ACCOUNT_CLEANUP.REQUEST_SUCCESS:
            return { data: action.payload, isLoading: false, error: null };
        case ACCOUNT_CLEANUP.REQUEST_FAIL:
            return { data: null, isLoading: false, error: action.payload };
        case ACCOUNT_CLEANUP.START:
            return { data: null, isLoading: true, error: null };
        case ACCOUNT_CLEANUP.SUCCESS:
            return { data: action.payload, isLoading: false, error: null };
        case ACCOUNT_CLEANUP.FAIL:
            return { data: null, isLoading: false, error: action.payload};    
        default:
            return state;
    }
};

export default accountCleanupReducer;

