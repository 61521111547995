import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import Popup from '../Popup';
import VBox from '../../atoms/VBox';
import HBox from '../../atoms/HBox';
import {Text1} from '../../atoms/Typography';
import Button from '../../atoms/Button';

const ConfirmDeletePopup = ({ title, description, onCancel, onDelete, deleteButtonText }) => {

    return (
        <Container>
            <Popup
                title={title}
                onClose={onCancel}
                width="52rem"
                background="#F4F5F6"
            >
                <VBox margin="3.2rem 0 0 0" gap="3.2rem">
                    <HBox justify="center" align="center" margin="1.5rem 0rem">
                        <Text1 align="center">{description}</Text1>
                    </HBox>
                    <HBox justify="flex-end" gap="1rem">
                        <Button buttonType="secondary" onClick={onCancel}>Cancel</Button>
                        <Button buttonType="danger" onClick={onDelete}>{deleteButtonText}</Button>
                    </HBox>
                </VBox>
            </Popup>
        </Container>
    );
}

ConfirmDeletePopup.propTypes = {
    title: PropTypes.string.isRequired,
    deleteButtonText: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
}

export default ConfirmDeletePopup;