import { PROFILE } from '../actions/profile';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  messageSuccess: null,

};

const profileReducer = (state = initialState, action) => {
  switch(action.type) {
    case PROFILE.GET_START:
      return { data: null, isLoading: true, error: null };
    case PROFILE.GET_SUCCESS:
      return { data: action.payload, isLoading: false, error: null };
    case PROFILE.GET_FAIL:
      return { data: null, isLoading: false, error: action.payload };
    case PROFILE.UPDATE_START:      
      return { ...state, isLoading: true, error: null, messageSuccess:null };
    case PROFILE.UPDATE_SUCCESS:
      state.data.my_information.email = action.payload.email;
      state.data.my_information.firstname = action.payload.name;
      state.data.my_information.mobile = action.payload.phone_number;
      state.data.my_information.country_id = action.payload.country_id;
      return { ...state, isLoading: false, error: null, messageSuccess: 'Profile information saved successfully' };
    case PROFILE.UPDATE_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case PROFILE.EMERGENCY_UPDATE_START:      
      return { ...state, isLoading: true, error: null, messageSuccess:null };
    case PROFILE.EMERGENCY_UPDATE_SUCCESS:
      return { data: {...state.data, emergency_contact: action.payload.data.emergency_contact, }, isLoading: false, error: null, messageSuccess: 'Profile information saved successfully' };
    case PROFILE.EMERGENCY_UPDATE_FAIL:
      return { ...state, isLoading: false, error: action.payload };
      case PROFILE.INCIDENT_REPORTING_UPDATE_START:      
      return { ...state, isLoading: true, error: null, messageSuccess:null };
    case PROFILE.INCIDENT_REPORTING_UPDATE_SUCCESS:
      const first_notice = {
        insurance_email: action.payload.insurance_email,
        insurance_carrier: action.payload.insurance_name,
        insurance_mobile: action.payload.insurance_phone_number,
        policy_number: action.payload.insurance_policy
      };
      return { data: {...state.data, first_notice: first_notice}, isLoading: false, error: null, messageSuccess: 'Profile information saved successfully' };
    case PROFILE.INCIDENT_REPORTING_UPDATE_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

export default profileReducer;
