import React from 'react';

const CheckMarkIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="done">
        <path id="Vector" d="M8.8 15.9L4.6 11.7L3.2 13.1L8.8 18.7L20.8 6.70005L19.4 5.30005L8.8 15.9Z" fill="#55B48E"/>
      </g>
    </svg>

  );
};

export default CheckMarkIcon;