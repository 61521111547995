import styled from 'styled-components';

export const Container = styled.div`
    background-color: #242b2d;
`;

export const Nav = styled.nav`
    height: 6rem;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 1rem 0rem;
    color: black;
    max-width: 117rem;
    margin-left: auto;
    margin-right:auto;

`;

export const NavigationMenu = styled.div`
    margin-left: auto;
`;

export const List = styled.ul`
    display: flex;
    padding: 0;
    @media (max-width: 768px) {
        display: ${({expanded}) => expanded ? 'block': 'none'};
        // navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar)
        position: absolute;
        z-index: 9999;
        top: 79px;
        left: 0;
        // stacks the li tags vertically 
        flex-direction: column;
    // makes menu span full height and width
        width: 100%;
        height: calc(100vh - 8rem);
        background-color: #252b2d;
        border-top: 1px solid black;
        margin:0;
    }
`;

export const ListElement = styled.li`
    list-style-type: none;
    margin: 0 1rem;
    @media (max-width: 768px) {
        text-align: center;
        margin: 0;  
        &:hover{
            background-color: #33627c;
        }
    }
`;

export const LogoContainer = styled.div`
    padding: 0 25px;
`;