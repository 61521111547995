import React from 'react';
import PropTypes from 'prop-types';

const MenuDropDownIconOpened = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M10.0001 6.79419C10.2199 6.80047 10.4208 6.88208 10.584 7.05786L15.3552 11.9421C15.4933 12.0802 15.5686 12.256 15.5686 12.4631C15.5686 12.8775 15.2422 13.2102 14.8278 13.2102C14.627 13.2102 14.4323 13.1286 14.2879 12.9842L10.0064 8.58339L5.71233 12.9842C5.56794 13.1223 5.3796 13.2102 5.17243 13.2102C4.75809 13.2102 4.43164 12.8775 4.43164 12.4631C4.43164 12.256 4.50698 12.0802 4.64509 11.9421L9.42257 7.05786C9.59208 6.88208 9.78041 6.79419 10.0001 6.79419Z" fill="#5C6670"/>
    </svg>
  );
};

MenuDropDownIconOpened.propTypes = {
  color: PropTypes.string
};

MenuDropDownIconOpened.defaultProps = {
  color: '#5C6670'
};

export default MenuDropDownIconOpened;
