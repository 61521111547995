import styled from 'styled-components';

export const ContentContainer = styled.div`
    height:41rem;
    overflow:auto;
    margin-bottom:3rem;
`;

export const ListHeader = styled.div`
    padding: 0 1.6rem;
`

export const ListItem = styled.div`
    padding: 1.5rem 1.6rem;
    background: #F4F5F6;
    border: 1px solid #EAECE9;
    border-radius: 4px;
    margin-top: 8px;
    &:hover{
        background:#EAECE9;
    }
`;

export const LinkContainer = styled.div`
    text-align: right;
`;

export const Status = styled.div`
    display: flex;
    padding: 0.4rem 0.8rem;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    border-radius: 0.4rem;
    background: ${({sent})=> sent? '#55B48E': '#E24444'};
    max-width: 6.3rem;
`;

export const Button = styled.button`
    background: transparent;
    border:0;
`;

export const PaginationWrapper = styled.div`
    position:relative;
    top: 3rem;
    @media(max-width:991px){
        position: static;
    }
`