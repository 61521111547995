import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Container } from './VehiclePopupTabMenu.styles';
import TabMenuItem from '../../molecules/TabMenuItem';

const VehiclePopupTabMenu = ({ selectedTab, onSelectTab, margin, isEdit }) => {
  const [menu, setMenu] = useState([
    {
      title: 'Details',
      selected: true,
      visible: true,
    },
    {
      title: 'Devices',
      selected: false,
      visible: isEdit? true : false,
    }
  ]);

  useEffect(() => {
    const newMenu = menu.map(m => {
      if(m.title === selectedTab) {
        m.selected = true;
      } else {
        m.selected = false;
      }
      return m;
    });

    setMenu(newMenu);
  }, [selectedTab]);

  const onTabClick = (title) => {
    const newMenu = menu.map(m => {
      if(m.title === title) {
        m.selected = true;
      } else {
        m.selected = false;
      }
      return m;
    });

    setMenu(newMenu);
    onSelectTab(title);
  };

  return(
    <Container margin={margin}>
      {menu && menu.map(m => m.visible && <TabMenuItem key={m.title} title={m.title} selected={m.selected} onClick={() => onTabClick(m.title)} />)}
    </Container>
  );
};

VehiclePopupTabMenu.propTypes = {
  margin: PropTypes.string,
  selectedTab: PropTypes.string,
  onSelectTab: PropTypes.func.isRequired
};

VehiclePopupTabMenu.defaultProps = {
  margin: '0',
  selectedTab: 'Details'
};

export default VehiclePopupTabMenu;
