import React from 'react';

const InsuranceDetailsIcon = () => {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.99121 18.8125C8.14941 18.8125 8.38672 18.7598 8.61523 18.6367C13.6689 15.8857 15.4268 14.6113 15.4268 11.4121V4.77637C15.4268 3.72168 15.0225 3.34375 14.1348 2.96582C13.168 2.55273 9.88086 1.41016 8.93164 1.08496C8.63281 0.988281 8.29883 0.926758 7.99121 0.926758C7.69238 0.926758 7.34961 1.00586 7.05957 1.08496C6.11035 1.35742 2.82324 2.56152 1.85645 2.96582C0.96875 3.33496 0.564453 3.72168 0.564453 4.77637V11.4121C0.564453 14.6113 2.32227 15.8682 7.37598 18.6367C7.60449 18.7598 7.8418 18.8125 7.99121 18.8125ZM7.99121 16.9492C7.86816 16.9492 7.74512 16.9053 7.49023 16.7471C3.48242 14.3037 2.23438 13.6445 2.23438 11.0869V5.05762C2.23438 4.74121 2.2959 4.61816 2.54199 4.5127C3.84277 3.99414 6.36523 3.15918 7.52539 2.70215C7.72754 2.63184 7.86816 2.60547 7.99121 2.60547C8.12305 2.60547 8.26367 2.63184 8.46582 2.70215C9.62598 3.15918 12.1309 4.04688 13.4404 4.5127C13.6953 4.60938 13.7568 4.74121 13.7568 5.05762V11.0869C13.7568 13.6621 12.4561 14.3564 8.50098 16.7471C8.24609 16.9053 8.12305 16.9492 7.99121 16.9492Z" fill="#363F45"/>
      <path d="M7.99121 18.8125C8.14941 18.8125 8.38672 18.7598 8.61523 18.6367C13.6689 15.8857 15.4268 14.6113 15.4268 11.4121V4.77637C15.4268 3.72168 15.0225 3.34375 14.1348 2.96582C13.168 2.55273 9.88086 1.41016 8.93164 1.08496C8.63281 0.988281 8.29883 0.926758 7.99121 0.926758C7.69238 0.926758 7.34961 1.00586 7.05957 1.08496C6.11035 1.35742 2.82324 2.56152 1.85645 2.96582C0.96875 3.33496 0.564453 3.72168 0.564453 4.77637V11.4121C0.564453 14.6113 2.32227 15.8682 7.37598 18.6367C7.60449 18.7598 7.8418 18.8125 7.99121 18.8125ZM7.99121 16.9492C7.86816 16.9492 7.74512 16.9053 7.49023 16.7471C3.48242 14.3037 2.23438 13.6445 2.23438 11.0869V5.05762C2.23438 4.74121 2.2959 4.61816 2.54199 4.5127C3.84277 3.99414 6.36523 3.15918 7.52539 2.70215C7.72754 2.63184 7.86816 2.60547 7.99121 2.60547C8.12305 2.60547 8.26367 2.63184 8.46582 2.70215C9.62598 3.15918 12.1309 4.04688 13.4404 4.5127C13.6953 4.60938 13.7568 4.74121 13.7568 5.05762V11.0869C13.7568 13.6621 12.4561 14.3564 8.50098 16.7471C8.24609 16.9053 8.12305 16.9492 7.99121 16.9492Z" fill="black" fillOpacity="0.2"/>
    </svg>
  );
};

export default InsuranceDetailsIcon;
