import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resolve } from 'nested-prop-resolver';
import { v4 as uuid } from 'uuid';
import {Container, Row, Col} from 'react-grid-system';
import ReactDOM from 'react-dom';

import VBox from '../../atoms/VBox';
import HBox from "../../atoms/HBox";
import Link from "../../atoms/Link";
import Button from "../../atoms/Button";
import Switch from "../../atoms/Switch";
import {H1, Text1, Text2} from "../../atoms/Typography";
import Loader from '../../atoms/Loader';

import Pagination from '../../molecules/Pagination';
import AddEditVehiclePopup from '../../organisms/AddEditVehiclePopup';
import DeleteVehiclePopup from '../../molecules/DeleteVehiclePopup';
import ViewVehicleGeofencePopup from '../../layouts/ViewVehicleGeofencesPopup';
import DeviceTransferPopup from '../DeviceTransferPopup';
import Snackbar from '../../molecules/Snackbar';

import { errorNotification } from '../../../utils/notifications';
import { truncateName } from '../../../utils/helpers';
import { DEFAULT_UNKNOWN_DATA } from '../../../constants';

import MapIcon from '../../../assets/MapIcon';
import PlusIcon from "../../../assets/PlusIcon";
import MenuDropDownIcon from "../../../assets/MenuDropDownIcon";
import MenuDropDownIconOpened from "../../../assets/MenuDropDownIconOpened";
import PencilIcon from '../../../assets/PencilIcon';
import DeleteIcon from '../../../assets/DeleteIcon';

import {
  vehicleCreateStart,
  vehicleEditStart,
  vehicleDeleteStart,
  vehicleTransferDevicesStart,
  vehicleGetAllStart
} from '../../../redux/actions/vehicle';
import {
  geofenceAddVehicleStart,
  geofenceRemoveVehicleStart
} from "../../../redux/actions/geofence";

import {Card, CardBody, VehicleRow, Vehicle, GeofenceContainer, Geofence} from "./styles";

const VehicleTable = () => {
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicle);

  const [isShowAddVehiclePopup, setIsShowAddVehiclePopup] = useState(false);
  const [isShowEditVehiclePopup, setIsShowEditVehiclePopup] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [vehicleSelected, setVehicleSelected] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const geofence = useSelector((state) => state.geofence);
  const geofenceData = resolve(geofence, 'data', []);
  const vehicleList = resolve(vehicle, 'vehicles', []);
  const isLoading = resolve(vehicle, 'isLoading', false);
  const [isShowViewGeofencePopup, setIsShowViewGeofencePopup] = useState(false);
  const [isShowDeletePopup, setIsShowDeletePopup] = useState(false);
  const [isShowTransferPopup, setIsShowTransferPopup] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState(false);
  const [showAllGeofencesForVehicle, setShowAllGeofencesForVehicle] = useState(0);
  const [notification, setNotification] = useState({
    message: null,
    type: 'success'
  });
  const profile = useSelector((state) => state.profile);

  useEffect(()=>{
    const vehicleTimer = setInterval(()=>{
        dispatch(vehicleGetAllStart({user_cog_id: profile.user_id}))
    }, 3600000);
    return ()=>{
      clearInterval(vehicleTimer)
    }
  },[profile]);

  useEffect(() => {
    if(vehicleList){
      sortVehiclesByAlias('ascending')
      setVehicles(vehicleList.slice(0, 5));
      setPageIndex(1);
    }
    if(vehicle.selectedVehicle){
      setVehicleSelected(vehicle.selectedVehicle)
    }
    if(vehicle.error){
      setNotification({message: 'An error occured', type:'error' })
    }
    if(vehicle.successMessage){
      setNotification({message: vehicle.successMessage, type: 'success'});
    }
    if(!vehicle.isLoading){
      setIsShowTransferPopup(false);
    }
  }, [vehicle]);

  const onAddVehicle = () => {
    setIsShowAddVehiclePopup(true);
  };

  const onEditVehicle = (obj, event) => {
    event.stopPropagation();
    setVehicleSelected(obj);
    setIsShowEditVehiclePopup(true);
  };

  const handleDeleteVehicleClick = (obj, event) => {
    event.stopPropagation();
    setVehicleSelected(obj);
    setIsShowDeletePopup(true);
  }

  const handleCloseVehicleGeofencePopup = (obj) => {
    setVehicleSelected(obj);
    setIsShowViewGeofencePopup(false);
  }

  const handleDeleteVehicle = () => {
    dispatch(vehicleDeleteStart(vehicleSelected));
    setIsShowDeletePopup(false);
  };

  const handleOnChangePage = (index, range) => {
    setPageIndex(index);
    setVehicles(vehicle.vehicles.slice((index-1)*5, index*5));
  };

  const onAddVehicleSave = (data) => {
    data.set('reg_no',uuid());
    dispatch(vehicleCreateStart(data));
    setIsShowAddVehiclePopup(false);
  };

  const onAddVehicleCancel = () => {
    setIsShowAddVehiclePopup(false);
  };

  const onEditVehicleSave = (data) => {
    dispatch(vehicleEditStart(data));
    setIsShowEditVehiclePopup(false);
  };

  const onEditVehicleCancel = () => {
    setIsShowEditVehiclePopup(false);
  };

  const sortVehiclesByAlias = (direction) => {
    if(direction === 'ascending'){
      vehicleList.sort(( a, b) => (
        b.alias < a.alias ? 1 : -1  
      ));
    }else{
      vehicleList.sort(( a, b) => (
        a.alias - b.alias ? 1 : -1
      ))
    }
  }

  const handleOpenDeleteVehiclePopup = () => {
    setIsShowDeletePopup(true);
    setIsShowEditVehiclePopup(false);
  }
  const handleCloseDeleteVehiclePopup = () => {
    setIsShowDeletePopup(false);
  }

  const handleShowTransferPopup = () => {
    setIsShowTransferPopup(true);
  }

  const handleCloseTransferPopup = () => {
    setIsShowTransferPopup(false);
  }

  const handleTransferDevices = (from, to) => {
    dispatch(vehicleTransferDevicesStart({fromVehicleId: from, toVehicleId: to}));
  }

  const handleClickVehicle = (id) => {
    if(id === selectedVehicleId){
      setSelectedVehicleId(false);
    }else{
      setSelectedVehicleId(id);
    }
  };

  const handleToggleGeofence = (value, geofenceId, vehicleId) => {
    const data = {
      id: geofenceId,
      vehicle_id: vehicleId
    }
    if(value){
      dispatch(geofenceAddVehicleStart(data));
    }else{
      dispatch(geofenceRemoveVehicleStart(data));
    } 
  };

  const showAllGeofences = (vehicleId) => {
    if(showAllGeofencesForVehicle === vehicleId){
      setShowAllGeofencesForVehicle(0);
    }else{
      setShowAllGeofencesForVehicle(vehicleId);
    }
  }

  const showGeofencesForVehicle = (vehicleId) => {
    const vehicleGeofences = geofenceData.filter(item=> item.vehicle_ids.includes(vehicleId))
    const geofencesDisabled = geofenceData.filter(item=> !item.vehicle_ids.includes(vehicleId))
    return (
      <Col>
        <Container fluid component={GeofenceContainer}>
          <Row component={Geofence} >
            <Col xs={4}><Text2 bold={true}>Geofence</Text2></Col>
            <Col xs={4}><Text2 bold={true}>Location</Text2></Col>
            <Col xs={4}>
              <HBox padding="0 3.8rem 0 0" justify="flex-end">
                <Text2 bold={true}>Status</Text2>
              </HBox>
            </Col>
          </Row>
          {vehicleGeofences.map((geofence)=>
            <Row component={Geofence} key={geofence.id}>
              <Col xs={4} style={{overflowWrap: "break-word"}}><Text2>{truncateName(geofence.location_name, 50)}</Text2></Col>
              <Col xs={4}>
                <HBox gap="1rem" align="center">
                  <MapIcon/>
                  <Text2>{geofence.city}</Text2>
                </HBox>
              </Col>
              <Col xs={4}><Switch value={true} onChange={() => handleToggleGeofence(false, geofence.id, vehicleId)} margin="0 1rem 0 0"/></Col>
            </Row>
          )}
          {vehicleGeofences.length>0 && geofencesDisabled.length>0 && showAllGeofencesForVehicle !== vehicleId &&
            (<Link onClick={()=>showAllGeofences(vehicleId)}>Show All Geofences</Link>)
          }

          {(vehicleGeofences.length === 0 || showAllGeofencesForVehicle === vehicleId) && geofencesDisabled.map((geofence)=>
            <Row component={Geofence} key={geofence.id}>
              <Col xs={4} style={{overflowWrap: "break-word"}}><Text2>{truncateName(geofence.location_name, 50)}</Text2></Col>
              <Col xs={4}>
                <HBox gap="1rem" align="center">
                  <MapIcon/>
                  <Text2>{geofence.city}</Text2>
                </HBox>
              </Col>
              <Col xs={4}><Switch value={false} onChange={() => handleToggleGeofence(true, geofence.id, vehicleId)} margin="0 1rem 0 0"/></Col>
            </Row>
          )}
          {showAllGeofencesForVehicle === vehicleId &&
            (<Link onClick={()=>showAllGeofences(vehicleId)}>Hide Inactive Geofences</Link>)
          }
        </Container>
      </Col>
    );
  }


  const getVehicleTableRow = (obj) => {
    const id = resolve(obj, 'vehicle_id', 0);
    const alias = resolve(obj, 'alias', DEFAULT_UNKNOWN_DATA);
    const make = resolve(obj, 'vehicle_make', null);
    const model = resolve(obj, 'vehicle_model', null);
    const year = resolve(obj, 'year', null);
    const selected = obj.vehicle_id === selectedVehicleId;

    //get all vehicle cameras
    const camerasRow = obj.dashcams.reduce((accumulator, device) => {
      accumulator.push(device.model_name)
      return accumulator;
    }
    , []).join(", ");
    // get all vehicle geofences
    let geofencesRows = [];
    geofencesRows = geofenceData.reduce((accumulator, geofence) => {
      if(geofence.vehicle_ids.includes(id)){
        accumulator.push(geofence.location_name); 
      }
      return accumulator
    }, []);

    return (
      <Row key={id} selected={selected} component={VehicleRow}>
        <Col xs={12}>
          <Row selected={selected} component={Vehicle}  onClick={()=>handleClickVehicle(id)}>
            <Col xs={3} md={4}>
              <VBox justify="center" height="100%">
                <Text2>{alias}</Text2>
                {(make || model || year) &&
                  <Text1 fontSize='1.2rem' color='#5C6670'>{make} {model} {year}</Text1>
                }
                </VBox>
            </Col>
            <Col xs={3} md={4}>
              <HBox align="center" height="100%">
                <Text1>{camerasRow}</Text1>
              </HBox>
            </Col>
            <Col xs={3} md={2}>
              <HBox align="center" height="100%">
                <Text1>{geofencesRows.length} Geofences On </Text1>
                {selected ? <MenuDropDownIconOpened/> : <MenuDropDownIcon/>}
              </HBox>
            </Col>
            <Col xs={3} md={2}>
              <HBox justify="flex-end" isWrap={true}>
                <Button buttonType="danger2" onClick={(event) => onEditVehicle(obj, event)}>
                  <PencilIcon/>
                </Button>
                <Button buttonType="danger2" onClick={(event) => handleDeleteVehicleClick(obj, event)}>
                  <DeleteIcon/>
                </Button>
              </HBox>
            </Col>
          </Row>
        </Col>
        {selected && showGeofencesForVehicle(obj.vehicle_id)}
      </Row>
    );
  };

  const noVehiclesTableRow = () => {
    return (
      <VBox align="center" gap="1rem">
        <Text1><strong>You haven’t added any vehicles yet. Click the button below to add new vehicle.</strong></Text1>
        <Button buttonType="primary" onClick={onAddVehicle}>Add Vehicle</Button>
      </VBox>
    )
  }  

  return (
    <>
      {notification && notification.message && ReactDOM.createPortal(
        <Snackbar 
          onClose={()=>setNotification(null)} 
          text={notification.message}
          type={notification.type}/>,
        document.getElementById('root-notification'))}
      <Card>
        <CardBody>
          <HBox justify='space-between' align='center' margin='0 0 1.2rem 0' isWrap={true}>
              <H1 fontSize='2.4rem'>Vehicles</H1>
              <HBox gap="2rem" >
                <Button buttonType="primary" onClick={handleShowTransferPopup}>Device Transfer</Button>
                <Button buttonType="primary" circle={true} onClick={onAddVehicle}><PlusIcon/></Button>
              </HBox>
          </HBox>
          {isLoading &&
                <center>
                    <Loader/>
                </center>
            }
          {!isLoading && 
            <Container fluid>
              {vehicles.length > 0 &&
                  <Row >
                    <Col xs={3} md={4}><Text1>Name</Text1></Col>
                    <Col xs={3} md={4}><Text1>Devices</Text1></Col>
                    <Col xs={3} md={2}><Text1>Geofences</Text1></Col>
                    <Col xs={3} md={2}></Col>
                  </Row>
              }
              {vehicles.length > 0 ? 
                vehicles.map((obj) => getVehicleTableRow(obj)) : noVehiclesTableRow()
              }
            </Container>        
          }
        </CardBody>
        {vehicles.length > 0 && <Pagination 
          count={vehicle.vehicles? vehicle.vehicles.length: 0}
          pageIndex={pageIndex}
          onChangePage={handleOnChangePage}
        />}
      </Card>
      {isShowAddVehiclePopup && (
        <AddEditVehiclePopup 
          data={{}} 
          onCancel={onAddVehicleCancel} 
          onSave={onAddVehicleSave} 
        />
      )}
      {isShowEditVehiclePopup && (
        <AddEditVehiclePopup 
          isEdit={true} 
          data={vehicleSelected} 
          onCancel={onEditVehicleCancel} 
          onSave={onEditVehicleSave} 
          onDelete={handleOpenDeleteVehiclePopup} 
        />
      )}
      {isShowViewGeofencePopup && (
        <ViewVehicleGeofencePopup 
          vehicle={vehicleSelected}  
          onCancel={handleCloseVehicleGeofencePopup} 
        />
      )}
      {isShowDeletePopup && (
        <DeleteVehiclePopup 
          onCancel={handleCloseDeleteVehiclePopup} 
          onDelete={handleDeleteVehicle}
          onMoveDevices={handleShowTransferPopup}
          vehicleName={vehicleSelected.alias} 
          devices={vehicleSelected.dashcams}
        />
      )}
      {isShowTransferPopup && (
        <DeviceTransferPopup
          onCancel={handleCloseTransferPopup}
          onTransfer={handleTransferDevices}
          selectedVehicle={vehicleSelected}
          isLoading={vehicle.isLoading}
        />
      )}
    </>
  );
};

export default VehicleTable;
