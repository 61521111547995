export const TRIP = {
    GET_NODES_START: 'TRIP_GET_NODES_START',
    GET_NODES_SUCCESS: 'TRIP_GET_NODES_SUCCESS',
    GET_NODES_FAIL: 'TRIP_GET_NODES_FAIL',
}

export const getNodesStart = (payload) => {
    return {
        type: TRIP.GET_NODES_START,
        payload,
    };
};

export const getNodesSuccess = (payload) => {
    return {
        type: TRIP.GET_NODES_SUCCESS,
        payload,
    };
};

export const getNodesFail = (payload) => {
    return {
        type: TRIP.GET_NODES_FAIL,
        payload,
    };
};