import React from 'react';
import BurgerIcon from '../../../assets/BurgerIcon';
import {ButtonElement, IconBar} from './BurgerButtonElement.styles';

const BurgerButton = ({onClick}) => {
    return (
        <ButtonElement onClick={onClick}>
            <IconBar/>
            <IconBar/>
            <IconBar/>
        </ButtonElement>
    );
};

export default BurgerButton;