import React from 'react'
import PropTypes from 'prop-types';

const DownloadVideoIcon = ({color}) => {
  return (
    <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="square.and.arrow.down 2">
        <g id="Group">
          <path id="Vector" d="M2.90426 21.3918H13.0957C15.0067 21.3918 16 20.4073 16 18.5166V9.802C16 7.91127 15.0067 6.92676 13.0957 6.92676H2.90426C0.993348 6.92676 0 7.91127 0 9.802V18.5166C0 20.4073 0.993348 21.3918 2.90426 21.3918ZM1.73454 18.3652V9.95335C1.73454 9.09318 2.18164 8.66129 3.00483 8.66129H12.9879C13.8038 8.66129 14.2655 9.09318 14.2655 9.95335V18.3652C14.2655 19.2253 13.8038 19.6572 12.9879 19.6572H3.00483C2.18164 19.6572 1.73454 19.2253 1.73454 18.3652Z" fill={color}/>
          <path id="Vector_2" d="M7.99641 15.6522C8.2158 15.6522 8.40773 15.5848 8.6167 15.3774L11.59 12.5063C11.7388 12.3575 11.8248 12.1999 11.8248 11.9885C11.8248 11.5745 11.4905 11.2843 11.0925 11.2843C10.8851 11.2843 10.6873 11.3679 10.5458 11.5255L9.2989 12.8473L8.736 13.4966L8.81941 12.1782V2.99805C8.81941 2.56406 8.44562 2.19116 7.99641 2.19116C7.5472 2.19116 7.18066 2.56406 7.18066 2.99805V12.1782L7.26565 13.4966L6.69392 12.8473L5.45425 11.5255C5.31277 11.3679 5.10045 11.2843 4.90037 11.2843C4.49508 11.2843 4.17529 11.5745 4.17529 11.9885C4.17529 12.1999 4.25568 12.3575 4.40283 12.5063L7.37617 15.3774C7.59393 15.5848 7.78427 15.6522 7.99641 15.6522Z" fill={color}/>
        </g>
      </g>
    </svg>

  )
}

DownloadVideoIcon.propTypes = {
  color: PropTypes.string
}

DownloadVideoIcon.defaultProps = {
  color: "#fff"
}

export default DownloadVideoIcon
