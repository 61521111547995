import { createHttpClient, HTTP_METHOD } from "./httpClient";

export const getAllNotifications = (params) => {
    return createHttpClient(`/v2/mode/user/notifications?vehicle_id=${params.vehicleId}`, HTTP_METHOD.GET, null, false, true);
}

export const deleteNotification = (params) => {
    return createHttpClient(`/v2/mode/user/notification/${params.id}`, HTTP_METHOD.DELETE, null, false, true);
}

export const deleteAllNotifications = (params) => {
    return createHttpClient(`/v2/mode/vehicle/${params.vehicleId}/notifications`, HTTP_METHOD.DELETE, null, false, true);
}