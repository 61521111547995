import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './PageCheckBox.styles';
import Text from '../../atoms/Text';
import CheckIcon from '../../../assets/CheckIcon';

const PageCheckBox = ({ margin, children, value, onChange }) => {
  const onClick = () => {
    onChange(!value);
  };

  return (
    <Container margin={margin} onClick={onClick}>
      <CheckIcon isChecked={value}/>
      <Text color="#000000">{children}</Text>
    </Container>
  );
};

PageCheckBox.propTypes = {
  margin: PropTypes.string,
  children: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

PageCheckBox.defaultProps = {
  margin: '0'
};

export default PageCheckBox;
