import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: ${({ isEnabled }) => isEnabled ? 'row-reverse' : 'row'};
  width: 5rem;
  height: 3rem;
  margin: ${({ margin }) => margin};
  border-radius: 1.4rem;
  background: ${({ isEnabled }) => isEnabled ? '#0071A4' : '#787880'};
  cursor: pointer;
`;

export const Circle = styled.div`
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 2.6rem;
  margin: 0.2rem 0.2rem;
  background: #ffffff;
  text-shadow: 1rem 1rem;
`;
