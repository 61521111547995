import React from 'react';
import PropTypes from 'prop-types';

const PencilIcon = ({ color, className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
      <path d="M20.082 9.49719L21.04 8.54797C21.5146 8.07336 21.5321 7.5548 21.1015 7.11535L20.7587 6.76379C20.328 6.33312 19.7919 6.37707 19.3261 6.84289L18.3681 7.78332L20.082 9.49719ZM9.31535 20.2462L19.2382 10.3234L17.5331 8.62707L7.61027 18.5323L6.74894 20.6066C6.65226 20.8702 6.92472 21.1603 7.1884 21.0636L9.31535 20.2462Z" fill="#5C6670"/>
    </svg>
  );
};

PencilIcon.propTypes = {
  color: PropTypes.string
};
PencilIcon.defaultProps = {
  color: "#0071A4"
}


export default PencilIcon;
