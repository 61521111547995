export const USER = {
  // Login
  LOGIN_START: 'USER_LOGIN_START',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAIL: 'USER_LOGIN_FAIL',
  // Logout
  LOGOUT: 'USER_LOGOUT'
};

export const userLoginStart = (payload) => {
  return {
    type: USER.LOGIN_START,
    payload
  };
};

export const userLoginSuccess = (payload) => {
  return {
    type: USER.LOGIN_SUCCESS,
    payload
  };
};

export const userLoginFail = (payload) => {
  return {
    type: USER.LOGIN_FAIL,
    payload
  };
};

export const userLogout = () => {
  return {
    type: USER.LOGOUT
  };
};
