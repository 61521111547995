export const ACTIVATION = {
    START: 'ACTIVATION_START',
    SUCCESS: 'ACTIVATION_SUCCESS',
    FAIL: 'ACTIVATION_FAIL',
    RESEND_START: 'ACTIVATION_RESEND_START',
    RESEND_SUCCESS: 'ACTIVATION_RESEND_SUCCESS',
    RESEND_FAIL: 'ACTIVATION_RESEND_FAIL',
};

export const activationStart = (payload) => {
    return {
        type: ACTIVATION.START,
        payload
    };
};

export const activationSuccess = (payload) => {
    return {
        type: ACTIVATION.SUCCESS,
        payload
    };
};


export const activationFail = (payload) => {
    return {
        type: ACTIVATION.FAIL,
        payload
    };
};

export const resendActivationLinkStart = (payload) => {
    return {
        type: ACTIVATION.RESEND_START,
        payload
    };
}

export const resendActivationLinkSuccess = (payload) => {
    return {
        type: ACTIVATION.RESEND_SUCCESS,
        payload
    };
}

export const resendActivationLinkFail = (payload) => {
    return {
        type: ACTIVATION.RESEND_FAIL,
        payload
    };
}