import styled, {css} from 'styled-components';

export const Card = styled.div`    
    background: #ffffff;
    border-radius: 12px;
    padding: 2.4rem 2.4rem 2.4rem 2.4rem;
`;

export const CardBody = styled.div`
    padding-bottom: 4rem;
`
export const VehicleRow = styled.div`
    border-bottom: 1px solid #EAECE9;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
    border-radius: 4px;
    align-items: center !important;
    ${({selected})=>(selected === true) && css`
        border-left: 4px solid #0071A4;
`};}
`
export const Vehicle = styled.div`
    padding: 1.7rem 0; 
    ${({selected})=>(selected === true) && css`
        background: #F4F5F6;
        padding-bottom: 1.7rem;
    `};
    &:hover{
        background:#F4F5F6;
    }
`;

export const GeofenceContainer = styled.div`
    padding-bottom: 1.7rem;
`;

export const Geofence = styled.div`
    padding: 1.7rem 0;
`