import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { resolve } from 'nested-prop-resolver';
import { ContentContainer } from './styles';

import HBox from '../../atoms/HBox';
import Button from '../../atoms/Button';
import GeofencePicker from '../../molecules/GeofencePicker';
import TextInput from '../../molecules/TextInput';
import Popup from '../../molecules/Popup';
import {
  getRectangleCoordinates,
  getPolygonCoordinate,
  generateGeoJsonCircle
} from '../../../utils/helpers';
import {
  MAP_CIRCLE_RADIUS,
  MAP_SQUARE_DIFF
} from '../../../constants';

const shapesOptions = [
  { value: 0, label: 'Circle' },
  { value: 1, label: 'Rectangle' },
  { value: 2, label: 'Polygon' }
];

const AddEditGeofencePopup = ({ isEdit, data, onCancel, onSave }) => {
  const [name, setName] = useState('');
  const [shape, setShape] = useState(shapesOptions[1]);
  const currentLocation = data.shape? {lat: data.shape.geofence_center_point[0], lng: data.shape.geofence_center_point[1]}: useSelector(state => state.location.data);
  const [pickerData, setPickerData] = useState({
    address: data.city? data.city : '' ,
    location: currentLocation,
    geofenceCenter: currentLocation,
    radius: MAP_CIRCLE_RADIUS,
    bounds: getRectangleCoordinates(currentLocation, MAP_SQUARE_DIFF),
    points: getPolygonCoordinate(currentLocation, MAP_SQUARE_DIFF, 5)
  });
  const [formErrors, setFormErrors] = useState({geoFenceName: '', centerPoint: ''});
  
  useEffect(() => {
    if (data) {
      const locationName = resolve(data, 'location_name');
      const addressValue = resolve(data, 'city');
      const locationValue = resolve(data, 'shape.center_point');
      const shapeValue = resolve(data, 'shape.type');
      const radiusValue = resolve(data, 'shape.radius');
      const pointsValue = resolve(data, 'shape.points');
      const geofenceCenter = resolve(data, 'shape.geofence_center_point');
      if (locationName) {
        setName(locationName);
      }

     let pickerDataObj = { ...pickerData };

      if (addressValue) {
        pickerDataObj.address = addressValue;
      }

      if (locationValue) {
        const location = { lat: locationValue[0], lng: locationValue[1] };
        pickerDataObj.location = location;
        pickerDataObj.bounds = getRectangleCoordinates(location, MAP_SQUARE_DIFF);
        pickerDataObj.points = getPolygonCoordinate(location, MAP_SQUARE_DIFF, 5);
        pickerDataObj.geofenceCenter = { lat: geofenceCenter[0], lng: geofenceCenter[1] };
      }
      if (shapeValue) {
        const option = shapesOptions.find(op => op.label.toLowerCase() === shapeValue);
        if (option) {
          setShape(option);
        }
      }

      if (radiusValue) {
        pickerDataObj.radius = radiusValue;
      }
      
      if (pointsValue) {
        if (shapeValue === 'rectangle') {
          pickerDataObj.bounds = {
            north: resolve(pointsValue, '[0][0][0]', 0),
            east: resolve(pointsValue, '[0][0][1]', 0),
            south: resolve(pointsValue, '[0][1][0]', 0),
            west: resolve(pointsValue, '[0][2][1]', 0)
          };
        }

        if (shapeValue === 'polygon') {
          const polygonPoints = resolve(pointsValue, '[0]', []);
          pickerDataObj.points = polygonPoints.map(p => ({ lat: p[0], lng: p[1] }));
        }
      }

      setPickerData(pickerDataObj);
    }
  }, [data]);

  const handleNameChange = (value) => {
    setName(value);
    setFormErrors({
      ...formErrors,
      geoFenceName: ''
    });
  };

  const handleSelectShape = (value) => {
    setShape(value);
  };

  const onUpdateData = (obj) => {
    setPickerData(obj);

    if(pickerData.address.length > 0){
      setFormErrors({
        ...formErrors,
        centerPoint: ''
      })
    }
  };

  const handleSave = () => {
    const geofenceObj = {
      ...data,
      name,
      address: `${pickerData.location.lat}, ${pickerData.location.lng}`
    };
    if(name.length === 0){
      setFormErrors({
        ...formErrors,
        geoFenceName: 'Field is required'
      });
      return false;
    }

    if(name.length > 256) {
      setFormErrors({
        ...formErrors,
        geoFenceName: 'Geofence name is too long'
      });
      return false;
    }
    if (!pickerData.location.lat || !pickerData.location.lng || !inrange(-90,pickerData.location.lat,90) || !inrange(-180,pickerData.location.lng,180)) {
      setFormErrors({
        ...formErrors,
        centerPoint: 'Please insert a valid coordinate. Example: 41.881832, -87.623177'
      });
      return false;
    }
  
    const geofenceCenterPoint = [
      resolve(pickerData, 'geofenceCenter.lat', 0), 
      resolve(pickerData, 'geofenceCenter.lng', 0)
    ]

    switch (shape.value) {
    case 0:
      const points = generateGeoJsonCircle(pickerData.location, pickerData.radius, 32);
      onSave({
        ...geofenceObj,
        address: `${pickerData.geofenceCenter.lat}, ${pickerData.geofenceCenter.lng}`,
        shape: {
          type: 'circle',
          center_point: geofenceCenterPoint,
          geofence_center_point: geofenceCenterPoint,
          radius: pickerData.radius,
          points: [points],
        }
      });
      break;
    case 1:
      onSave({
        ...geofenceObj,
        address: `${pickerData.geofenceCenter.lat}, ${pickerData.geofenceCenter.lng}`,
        shape: {
          type: 'rectangle',
          center_point: geofenceCenterPoint,
          geofence_center_point: geofenceCenterPoint,
          radius: 0,
          points: [
            [
              [
                resolve(pickerData, 'bounds.north', 0),
                resolve(pickerData, 'bounds.east', 0)
              ],  
              [
                resolve(pickerData, 'bounds.south', 0),
                resolve(pickerData, 'bounds.east', 0)
              ],
              [
                resolve(pickerData, 'bounds.south', 0),
                resolve(pickerData, 'bounds.west', 0)
              ],
              [
                resolve(pickerData, 'bounds.north', 0),
                resolve(pickerData, 'bounds.west', 0)
              ],
              [
                resolve(pickerData, 'bounds.north', 0),
                resolve(pickerData, 'bounds.east', 0)
              ]
            ]
          ]
        }
      });
      break;
    case 2:
      onSave({
        ...geofenceObj,
        address: `${pickerData.geofenceCenter.lat}, ${pickerData.geofenceCenter.lng}`,
        shape: {
          type: 'polygon',
          center_point: geofenceCenterPoint,
          geofence_center_point: geofenceCenterPoint,
          radius: 0,
          points: [resolve(pickerData, 'points', []).map(p => [p.lat, p.lng])]
        }
      });
      break;
    default:
      break;
    }
  };

  const inrange = (min,number,max) => {
    if ( number && !isNaN(number) && (number >= min) && (number <= max) ){
        return true;
    } else {
        return false;
    };
  }

  return (
    <Popup 
      title={`${isEdit ? 'Edit' : 'Create'} Geofence`} 
      backgroundColor="#F4F5F6" 
      onClose = {onCancel}
    >
      <ContentContainer>
        <HBox direction="row">
          <TextInput 
            titled 
            required={true}
            error={formErrors.geoFenceName} 
            title="Name" 
            label="Geofence Name" 
            width="46rem" 
            value={name} 
            margin="0 0 1.6rem 0" 
            onChange={handleNameChange} 
          />
        </HBox>
        <GeofencePicker 
          error={formErrors.centerPoint} 
          shape={shape} 
          data={pickerData} 
          onUpdateData={onUpdateData} 
          shapesOptions={shapesOptions} 
          handleSelectShape={handleSelectShape} 
        />
      </ContentContainer>
      <HBox gap="1rem" justify="flex-end">
        <Button buttonType="secondary" onClick={onCancel}>Cancel</Button>
        <Button buttonType="primary" onClick={handleSave}>Save</Button>
      </HBox>
    </Popup>
  );
};

AddEditGeofencePopup.propTypes = {
  isEdit: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

AddEditGeofencePopup.defaultProps = {
  isEdit: false,
  data: {}
};

export default AddEditGeofencePopup;
