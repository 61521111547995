import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;  
  background:rgba(0,0,0,0.4);
`;

export const ContentContainer = styled.div`
  align-items: center;
  max-width: ${({ width }) => width};
  width:82%;
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: ${({ margin }) => margin};
  border-radius: 0.8rem;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.3), 0 15px 12px 0 rgba(0, 0, 0, 0.22);
  padding:2.4rem;
  overflow: auto;
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${({ height }) => height};
`;

export const ChildContainer = styled.div`
  flex: 1;
`;
