import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './TabMenuItem.styles';
import{Text1} from "../../atoms/Typography";

const TabMenuItem = ({ title, selected, onClick }) => {
  return (
    <Container border={selected ? "0.2rem solid #0071A4" : "0.1rem solid #5C6670"} onClick={onClick}>
      <Text1 fontSize="2rem" color={selected ? "#0071A4" : "#000"}><strong>{title}</strong></Text1>
    </Container>
  );
};

TabMenuItem.propTypes = {
  selected: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

TabMenuItem.defaultProps = {
  selected: false
};

export default TabMenuItem;
