import React from 'react';
import PropTypes from 'prop-types';

import {Container} from './styles';
import Popup from '../Popup';
import VBox from '../../atoms/VBox';
import HBox from '../../atoms/HBox';
import {Text1} from '../../atoms/Typography';
import Button from '../../atoms/Button';
import Device from '../Device';

const DeleteVehiclePopup = ({ vehicleName, devices, onCancel, onDelete, onMoveDevices }) => {

    return (
        <Container>
            <Popup
                title='Delete Vehicle'
                onClose={onCancel}
                width="52rem"
                background="#F4F5F6"
            >
                <VBox margin="3.2rem 0 0 0" gap="3.2rem">
                    {devices.length > 0 ?
                        (<VBox justify="center" align="center" gap="1.6rem">
                            <Text1><strong>{vehicleName}</strong></Text1>
                            <Text1>has connected devices, that will be deleted as well:</Text1>
                            {devices.map((item) =>
                                <Device
                                    key={item.id}
                                    iconUrl={item.icon_url}
                                    deviceType={item.device_type}
                                    alias={item.alias}
                                    serialNumber={item.cam_id}
                                />
                            )} 
                            <Text1>Do you want to move these devices to another vehicle?</Text1>
                            <Button onClick={onMoveDevices}>Device Transfer</Button>
                        </VBox>)
                        :
                        (<VBox justify="center" align="center" margin="1.5rem 0rem">
                            <Text1>Are you sure you want to delete</Text1>
                            <Text1><strong>{vehicleName}</strong></Text1>
                        </VBox>)
                    }
                    <HBox justify="flex-end" gap="1rem">
                        <Button buttonType="secondary" onClick={onCancel}>Cancel</Button>
                        <Button buttonType="danger" onClick={onDelete}>Delete Vehicle</Button>
                    </HBox>
                </VBox>
            </Popup>
        </Container>
    );
}

DeleteVehiclePopup.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    devices: PropTypes.array,
    vehicleName: PropTypes.string.isRequired,
    onMoveDevices: PropTypes.func.isRequired
}

DeleteVehiclePopup.defaultProps = {
    devices: []
}

export default DeleteVehiclePopup;