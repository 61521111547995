import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import rootReducer from '../reducers';
import rootSaga from '../sagas';
import { USER_OBJECT_KEY } from '../../constants';
import { userLoginSuccess } from '../actions/user';

const getSessionData = store => {
  const user = JSON.parse(localStorage.getItem(USER_OBJECT_KEY));
  if (user) {
    store.dispatch(userLoginSuccess(user));
  }
};

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  let middleware = [];
  if(process.env.NODE_ENV === 'development'){
    middleware = [sagaMiddleware, logger];
  }else{
    middleware = [sagaMiddleware]
  }
  const store = createStore(
    rootReducer,
    compose(applyMiddleware(...middleware))
  );
  sagaMiddleware.run(rootSaga);
  getSessionData(store);

  return store;
};
  
export default configureStore;
