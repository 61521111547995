import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: stretch;
  height: fit-content;
  padding: 2.4rem;
  border-radius: 0.8rem;
  background: #ffffff;
  position:relative;
  padding-bottom: 6rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: stretch;
  justify-content: center;

`;

export const ContentContainer = styled.div`
  margin-top: 0.8rem;
  min-height: 60rem;
  height:100%;
  @media(max-width:991px){
    min-height: auto;
  }
`;
