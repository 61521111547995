import React from 'react';
import Swal from 'sweetalert2';

export const errorNotification =  ({ title = "Something went wrong", text }) => {
    return Swal.fire({
        title: title,
        html: text,
        icon: 'error',
      })
};

export const warningNotification =  ({ title = "Something went wrong", text }) => {
  return Swal.fire({
      title: title,
      html: text,
      icon: 'warning',
    })
};

export const confirm =  ({ title = "Are you sure?", text }) => {
  return Swal.fire({
      title: title,
      html: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes!',
      showCloseButton: true,
    })
};

export const confirmDelete =  ({ title = "Are you sure?", text }) => {
  return Swal.fire({
      title: title,
      html: text,
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Delete',
      buttonsStyling: false,
      reverseButtons: true,
      customClass: {
        confirmButton: 'swal-confirm-delete-button',
        cancelButton: 'swal-cancel-delete-button'
    },
    })
};

export const notify = ({title="Success", text}) => {
  return Swal.fire({
    title: title,
    html: text,
    icon: 'success',
    showCloseButton: true,
    confirmButtonText: "Close"
  })
}

export const errorResendActivationLinkAgain = async () =>{
  return Swal.fire({
      icon:'warning',
      title:'Your activation code has expired',
      showCloseButton: true,
      showDenyButton: true,
      denyButtonText: "Close",
      confirmButtonText: "Send email again",
      html: "<div class='swal-text custom-error-message'><ul><li>Click “SEND EMAIL AGAIN”</li><li>Check your inbox for a new verification email</li><li>Follow the steps to verify your email address</li></ul></div>"
  }).then((result)=>{
      return result.isConfirmed;
  })
}

export const accountNotActivated = async () =>{
  return Swal.fire({
      icon:'warning',
      title:'Your Email address has not been verified yet',
      showCloseButton: true,
      showDenyButton: true,
      denyButtonText: "Close",
      confirmButtonText: "Send email again",
      html: `<div class='swal-text custom-error-message'>
              <p>Check your email inbox and follow the steps to verify your email address.</p>
              <p>If you don't see it in your inbox, please check your Spam folder.</p>
            </div>`
  }).then((result)=>{
      return result.isConfirmed;
  })
}

export const confirmCleanup = async () => {
  return Swal.fire({
      title: "Clean Up Account",
      text: "Your data will be deleted without the opportunity to recover it.Are you sure you want to clean up your account?",
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: "Clean Up Account",
      denyButtonText: "Cancel",
      reverseButtons: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'swal-confirm-delete-button2',
        denyButton: 'swal-cancel-button'
    },
    }).then(confirm => {
      return confirm.isConfirmed;
    });
}

export const confirmAccountDeletion = async () => {
  return Swal.fire({
      title: "Delete Account",
      text: "You account will be deleted as well as your data without the opportunity to recover it. Are you sure you want to delete your account?",
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: "Delete Account",
      denyButtonText: "Cancel",
      reverseButtons: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'swal-confirm-delete-button2',
        denyButton: 'swal-cancel-button'
    },
    }).then(confirm => {
      return confirm.isConfirmed;
    });
}

