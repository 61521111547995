import { HTTP_METHOD, createHttpClient } from "./httpClient";

export const startStream = (params) => {
    return createHttpClient('/v5/stream/start?web=1', HTTP_METHOD.POST, params, false, true);
}

export const stopStream = (params) => {
    return createHttpClient('/v5/stream/stop', HTTP_METHOD.POST, params, false, true);
}

export const continueStream = (params) => {
    return createHttpClient('/v5/stream/continue', HTTP_METHOD.POST, params, false, true);
}