import React, {useState} from "react";
import PropTypes from "prop-types";

import TextInput from "../TextInput";
import {Container} from './styles';
import Button from "../../atoms/Button";
import PhoneNumberSelector from "../PhoneNumberSelector";
import HBox from '../../atoms/HBox';
import validator from 'validator';

const IncidentReportingForm = ({countries, phoneNumber, name, email, policy, onSave, isLoading, onCancel}) => {
    let selectedPhoneCountry = countries[0];
    if(!!phoneNumber){
        const selectedCountry = countries.reduce((accumulator, currentValue)=>{
            const phonePrefix = `+${currentValue.phone_prefix.replace('-','')}`
            if(phoneNumber.startsWith(phonePrefix)){
                if(currentValue.phone_prefix.length > accumulator.phone_prefix.length){
                    return currentValue;
                }
            }
            return accumulator;
        },{phone_prefix:''});

        if(selectedCountry.id){
            selectedPhoneCountry = selectedCountry;
            phoneNumber = phoneNumber.replace(`+${selectedPhoneCountry.phone_prefix.replace('-','')}`, '')
        }
    }

    const [formValues, setFormValues] = useState({
        name: name,
        phoneNumberCountry: selectedPhoneCountry,
        phoneNumber: phoneNumber,
        email: email,
        policy: policy
    });
    const [formErrors, setFormErrors] = useState({
        name: "",
        phoneNumberCountry: "",
        phoneNumber: "",
        email: "",
        policy: ""
    });

    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };

    const onChangeFormData = (key, value) => {
        setFormValues({
          ...formValues,
          [key]: value
        });
      };
     
    const submitForm = () => {
        setFormErrors({
            name: "",
            phoneNumberCountry: "",
            phoneNumber: "",
            email: "",
            policy: ""
        });
        if(formValues.email.trim() && !validateEmail(formValues.email)){
            setFormErrors({...formErrors, email: 'Not a valid email'})
            return;
        }

        if(!formValues.phoneNumber.trim()){
            setFormValues({...formValues, phoneNumber: ''})
        }else{
            const phoneNumber = "+"+formValues.phoneNumberCountry.phone_prefix.replace('-','') + formValues.phoneNumber
            if(!validator.isMobilePhone(phoneNumber, 'any',{strictMode:true})){
                setFormErrors({...formErrors, phoneNumber: 'Not a valid phone number'})
                return;
            }
        }

        onSave(formValues);
    }  

    return(
        <Container>
            <TextInput 
                label="Insurance Carrier"
                value={formValues.name} 
                error={formErrors.name} 
                onChange={value => onChangeFormData("name", value)}
                background="#F4F5F6"
            />
            <HBox justify='space-between'>
                <PhoneNumberSelector 
                    countries={countries} 
                    selectedCountry={formValues.phoneNumberCountry} 
                    onSelect={value => onChangeFormData('phoneNumberCountry', value)}
                />
                <TextInput
                    width='20rem'
                    label="Phone number"
                    value={formValues.phoneNumber}
                    error={formErrors.phoneNumber}
                    onChange={value => onChangeFormData("phoneNumber", value)}
                    background="#F4F5F6"
                />
            </HBox>
            <TextInput 
                label="Insurance Email"
                value={formValues.email} 
                error={formErrors.email}  
                onChange={value => onChangeFormData("email", value)}
                background="#F4F5F6"
            />
            <TextInput 
                label="Policy Number"
                value={formValues.policy} 
                error={formErrors.policy} 
                onChange={value => onChangeFormData("policy", value)}
                background="#F4F5F6"
            />
            <HBox justify='flex-end' gap="0.8rem">
                <Button buttonType="secondary" onClick={onCancel}>Cancel</Button>
                <Button isLoading={isLoading} disabled={!isLoading} buttonType="primary" onClick={()=>submitForm()}>Save Changes</Button>
            </HBox>
        </Container>
    );
}

IncidentReportingForm.propTypes = {
    countries: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        phone_prefix: PropTypes.string.isRequired,
        flag: PropTypes.string.isRequired
    })).isRequired,
    phoneNumber: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    policy: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
};

IncidentReportingForm.defaultProps = {
    isLoading: false
};

export default IncidentReportingForm;
