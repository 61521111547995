import styled from 'styled-components';

export const MenuContainer = styled.div`
  justify-content: flex-start;
  height: 4.8rem;
`;

export const BrowseContainer = styled.div`
  display: flex;
  height: 21rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid #C6C6C8;
  background: #F5F5F5;
`;

export const TabContainer = styled.div`
  width:100%;
  min-height:21rem;
`;