export const INCIDENT = {
  // Get all incidents
  GET_ALL_START: 'INCIDENT_GET_ALL_START',
  GET_ALL_SUCCESS: 'INCIDENT_GET_ALL_SUCCESS',
  GET_ALL_FAIL: 'INCIDENT_GET_ALL_FAIL',
  //Send incident
  SEND_START: 'INCIDENT_SEND_START',
  SEND_SUCCESS: 'INCIDENT_SEND_SUCCESS',
  SEND_FAIL: 'INCIDENT_SEND_FAIL',
  // Delete Incident
  DELETE_START: 'INCIDENT_DELETE_START',
  DELETE_SUCCESS: 'INCIDENT_DELETE_SUCCESS',
  DELETE_FAIL: 'INCIDENT_DELETE_FAIL'
};

export const incidentGetAllStart = (payload) => {
  return {
    type: INCIDENT.GET_ALL_START,
    payload
  };
};

export const incidentGetAllSuccess = (payload) => {
  return {
    type: INCIDENT.GET_ALL_SUCCESS,
    payload
  };
};

export const incidentGetAllFail = (payload) => {
  return {
    type: INCIDENT.GET_ALL_FAIL,
    payload
  };
};

export const incidentsSendStart = (payload) => {
  return {
    type: INCIDENT.SEND_START,
    payload
  };
};

export const incidentSendSuccess = (payload) => {
  return {
    type: INCIDENT.SEND_SUCCESS,
    payload
  };
};

export const incidentSendFail = (payload) => {
  return {
    type: INCIDENT.SEND_FAIL,
    payload
  };
};

export const incidentDeleteStart = (payload) => {
  return {
    type: INCIDENT.DELETE_START,
    payload
  };
};

export const incidentDeleteSuccess = (payload) => {
  return {
    type: INCIDENT.DELETE_SUCCESS,
    payload
  };
};

export const incidentDeleteFail = (payload) => {
  return {
    type: INCIDENT.DELETE_FAIL,
    payload
  };
};
